import { type TFunction } from 'i18next';

import { type IconName } from '@/types/shared';

import { type NotificationType } from './Notification';

export const getIconName = (type: NotificationType): IconName => {
  switch (type) {
    case 'success':
      return 'DoneCircle';
    case 'danger':
      return 'ExclamationCircle';
    default:
      return 'Info';
  }
};

export const getDefaultTitle = (type: NotificationType, t: TFunction) => {
  switch (type) {
    case 'success':
      return t('notifications:success');
    case 'danger':
      return t('notifications:danger');
    case 'warning':
      return t('notifications:warning');
    case 'info':
      return t('notifications:info');
    default:
      return '';
  }
};
