import { RadioInput, type RadioInputProps } from '@/components/shared/form/inputs/RadioInput';
import type { SelectOption } from '@/types/shared';

import style from './RadioGroupInput.module.sass';

type RadioGroupInputProps = Omit<RadioInputProps,
  | 'label'
  | 'checked'
> & {
  options?: SelectOption[];
};

export const RadioGroupInput = ({
  options = [],
  value,
  ...restProps
}: RadioGroupInputProps) => {
  const renderOption = (option: SelectOption) => (
    <RadioInput
      key={option.value}
      label={option.label}
      checked={value === option.value}
      value={option.value}
      {...restProps}
    />
  );

  return (
    <div className={style.wrapper}>
      {options.map(renderOption)}
    </div>
  );
};
