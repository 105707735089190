import classNames from 'classnames/bind';
import { type InputHTMLAttributes } from 'react';

import { Icon } from '@/components/shared/Icon';
import { JointCheckboxState } from '@/resources/enums';

import style from './CheckboxInput.module.sass';

const cx = classNames.bind(style);

export type CheckboxInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  state?: JointCheckboxState;
  isHovered?: boolean;
};

export const CheckboxInput = ({
  className,
  checked,
  state,
  isHovered,
  ...restProps
}: CheckboxInputProps) => {
  const wrapperClassName = cx('wrapper', className);

  const checkboxChecked = checked || Boolean(state);

  const renderIcon = () => {
    const iconName = state === JointCheckboxState.Ambiguous ? 'Dash' : 'Done';

    return (
      <div className={style.iconWrapper}>
        <Icon name={iconName} className={style.icon} />
      </div>
    );
  };

  const checkboxVisualClassName = cx('checkboxVisual', {
    isHovered,
  });

  return (
    <>
      <label className={wrapperClassName}>
        <input
          type='checkbox'
          className={style.hiddenInput}
          checked={checkboxChecked}
          {...restProps}
        />
        <div className={checkboxVisualClassName}>
          {renderIcon()}
        </div>
      </label>
    </>
  );
};
