import { useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { FirebaseError } from '@firebase/util';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import { TFunction } from 'i18next';

import { NewPasswordFields } from '@/components/company/user/NewPasswordFields/NewPasswordFields';
import { CompaniesLogosHeader } from '@/components/shared/CompaniesLogosHeader';
import { LoginManagementHeader } from '@/components/shared/LoginManagementHeader';
import { ButtonAsync } from '@/components/shared/buttons';
import { useAuthentication } from '@/hooks/user';
import { PasswordErrorPage } from '@/pages/company';
import { handleFirebasePasswordPolicyError } from '@/utils/firebase';
import type { NewPasswordFormFormValue, PasswordPolicyErrors } from '@/types/user';

export const passwordSchema = (t: TFunction) => {
  return yup.object({
    newPassword: yup.string()
      .required(t('loginPage:resetPassword.errors.newPasswordRequired')),
    confirmPassword: yup.string()
      .required(t('loginPage:resetPassword.errors.confirmPasswordRequired'))
      .oneOf(
        [yup.ref('newPassword')],
        t('loginPage:resetPassword.errors.confirmPasswordSameAsPassword'),
      ),
  });
};

export const ResetPassword = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [policyErrors, setPolicyErrors] = useState<PasswordPolicyErrors>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    resetPassword,
    signInWithEmailAndPassword,
  } = useAuthentication();
  const {
    code,
    email,
  } = useParams();

  const onSubmit = async (
    values: NewPasswordFormFormValue,
  ) => {
    try {
      setIsBusy(true);

      if (!code || !email) {
        return;
      }

      await resetPassword(code, values.newPassword);
      await signInWithEmailAndPassword(email, values.newPassword);
      navigate('/');
    } catch (error) {
      handleFirebasePasswordPolicyError(
        error as FirebaseError,
        setPolicyErrors,
        setErrorCode,
      );
    } finally {
      setIsBusy(false);
    }
  };

  const initialValues: NewPasswordFormFormValue = {
    newPassword: '',
    confirmPassword: '',
  };

  if (errorCode) {
    return <PasswordErrorPage errorCode={errorCode} />;
  }

  const validationSchema = passwordSchema(t);

  return (
    <>
      <CompaniesLogosHeader />
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}>
        <Form>
          <LoginManagementHeader
            title={t('loginPage:resetPassword.setNewPassword')}
            subtitle={t('loginPage:resetPassword.setNewPasswordInfo')}
          />
          <NewPasswordFields
            policyErrors={policyErrors}
          />
          <ButtonAsync
            type='submit'
            width='full'
            isBusy={isBusy}>
            {t('loginPage:resetPassword.setPasswordAndSignIn')}
          </ButtonAsync>
        </Form>
      </Formik>
    </>
  );
};
