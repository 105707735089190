import { useAuthentication } from '@/hooks/user/useAuthentication';

export const useAuthUserRole = () => {
  const {
    user,
    loading,
  } = useAuthentication();

  const authUserRole = user?.gsRole;

  return {
    authUserRole,
    isLoading: loading,
  };
};
