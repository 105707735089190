import classNames from 'classnames/bind';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/components/shared/Icon';
import { Tooltip } from '@/components/shared/Tooltip';
import {
  Body,
  type BodyProps,
} from '@/components/shared/typography/Body';
import { useIsTruncated } from '@/hooks/dom';
import type { Nullable } from '@/types/shared';

import style from './StoreReview.module.sass';

const cx = classNames.bind(style);

type StoreReviewProps = {
  text: string;
  textSize?: BodyProps['size'];
  reviewCount: Nullable<number>;
  reviewCountSize?: BodyProps['size'];
};

export const StoreReview = ({
  text,
  reviewCount,
  textSize = 'base',
  reviewCountSize = 'small',
}: StoreReviewProps) => {
  const { t } = useTranslation();
  const ratingRef = useRef<HTMLDivElement>(null);
  const isRatingTruncated = useIsTruncated(ratingRef);

  const renderStar = () => {
    if (reviewCount === null || reviewCount === 0) {
      const starWithNoReviewsClassName = cx('starIcon', 'noReviews');

      return (
        <Icon
          name='EmptyStar'
          className={starWithNoReviewsClassName}
        />
      );
    }

    const starWithReviewsClassName = cx('starIcon', 'withReviews');

    return (
      <Icon
        name='Star'
        className={starWithReviewsClassName}
      />
    );
  };

  const renderReviewCount = () => {
    const renderText = () => reviewCount === null
      ? t('common:unknown')
      : t('common:reviewCount', { count: reviewCount });

    return (
      <Body size={reviewCountSize} className={style.reviewCount}>
        {renderText()}
      </Body>
    );
  };

  return (
    <div className={style.reviewWrapper}>
      {renderStar()}
      {reviewCount === 0
        ? renderReviewCount()
        : (
          <>
            {text &&
              <>
                <Tooltip
                  placement='top'
                  disabled={!isRatingTruncated}
                  content={
                    <Body size='small'>{text}</Body>
                  }>
                  <Body
                    size={textSize}
                    className={style.rating}
                    ref={ratingRef}>
                    {text}
                  </Body>
                </Tooltip>
                <span className={style.separatorBullet}>
                  •
                </span>
              </>
            }
            {renderReviewCount()}
          </>
        )
      }
    </div>
  );
};
