import { ContainerLoader } from '@/components/shared/loaders/ContainerLoader';

import style from './PageLoader.module.sass';

export const PageLoader = () => {
  return (
    <div className={style.wrapper}>
      <ContainerLoader />
    </div>
  );
};
