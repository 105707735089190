import classNames from 'classnames/bind';

import style from './Skeleton.module.sass';

type SkeletonProps = {
  className?: string;
  skeletonsNumber?: number;
  delayAnimation?: number;
  height?: number;
  width?: number;
};

const cx = classNames.bind(style);

export const Skeleton = ({
  className,
  skeletonsNumber = 1,
  delayAnimation = 200,
  height,
  width,
}: SkeletonProps) => {
  const skeletonClassNames = cx('skeleton', className);

  return (
    <>
      {
        Array
          .from({ length: skeletonsNumber })
          .map((_, index) => {
            const delay = index * delayAnimation;

            return (
              <div
                key={index}
                className={skeletonClassNames}
                style={{ animationDelay: `${delay}ms`, height, width }}
              />
            );
          })
      }
    </>
  );
};
