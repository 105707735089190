import { ReactElement, useContext } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';

import { LoginPage } from '@/components/App/AppLayout/LoginPage';
import {
  UserRegistrationPage,
  RegistrationConfirmationPage,
  ResetPasswordPage,
  ForgotPasswordPage,
} from '@/pages/company';
import {
  VeriformPage,
  VeriformExpiredPage,
  VeriformPageConfirmationPage,
} from '@/pages/store';
import { AuthorisedCustomerCompanyUserContext } from '@/contexts/user';

type LoginGatewayProps = {
  children: ReactElement;
};

export const LoginGateway = ({
  children,
}: LoginGatewayProps) => {
  const [searchParams] = useSearchParams();
  const forceGoogle = searchParams.get('forceGoogle');
  const {
    isCustomerCompanyUserAuthorized,
    hasTriedToLogin,
  } = useContext(AuthorisedCustomerCompanyUserContext);

  if (!isCustomerCompanyUserAuthorized && hasTriedToLogin) {
    return (
      <Routes>
        <Route path='*' element={<LoginPage isUserNotAuthorisedForThisCompanyError={true} />} />
      </Routes>
    );
  }

  if (!isCustomerCompanyUserAuthorized) {
    return (
      <Routes>
        <Route path='*' element={<LoginPage forceGoogle={Boolean(forceGoogle)} />} />
        <Route
          path='register-me'
          element={<UserRegistrationPage />}
        />
        <Route
          path='forgot-password'
          element={<ForgotPasswordPage />}
        />
        <Route
          path='email-sent'
          element={<RegistrationConfirmationPage />}
        />
        <Route path='reset-password/:code/:email' element={<ResetPasswordPage />} />
        <Route path='veriforms/:id/:token'>
          <Route index={true} element={<VeriformPage />} />
          <Route path='confirmation' element={<VeriformPageConfirmationPage />} />
          <Route path='expired' element={<VeriformExpiredPage />} />
        </Route>
      </Routes>
    );
  }

  return (
    <>{children}</>
  );
};
