import classNames from 'classnames/bind';
import { InputHTMLAttributes, type ReactNode } from 'react';

import style from './RadioInput.module.sass';

const cx = classNames.bind(style);

export type RadioInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: ReactNode;
  disabled?: boolean;
  inputVisualClassName?: string;
  variant?: 'small' | 'base';
  isHovered?: boolean;
};

export const RadioInput = ({
  label,
  disabled,
  className,
  inputVisualClassName,
  variant = 'base',
  isHovered,
  ...restProps
}: RadioInputProps) => {
  const wrapperClassName = cx('wrapper', className, variant, {
    disabled,
    withLabel: label,
  });

  const inputVisualClassNames = cx('inputVisual', inputVisualClassName, variant, {
    isHovered,
  });

  return (
    <label className={wrapperClassName}>
      <input
        {...restProps}
        disabled={disabled}
        type='radio'
        className={style.input}
      />
      <div className={inputVisualClassNames} />
      <div className={style.labelText}>
        {label}
      </div>
    </label>
  );
};
