import {
  captureMessage,
} from '@sentry/react';
import { AxiosError } from 'axios';

export const sentryLogIssue = (
  errorOrMessage: string | Error | AxiosError,
  captureContext?: Parameters<typeof captureMessage>[1],
) => {
  // Sentry will recognize error object even though they expects string
  captureMessage(errorOrMessage as string, captureContext ?? 'error');
};
