import * as React from "react";
import { forwardRef } from "react";
const SvgDownload = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M15.5578 11.1108L12.0004 14.6683L8.44287 11.1108",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12.0002 3.99683V14.6683",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.0032 16.4458C20.0032 18.4105 18.4105 20.0033 16.4458 20.0033H7.55406C5.58932 20.0033 3.99658 18.4105 3.99658 16.4458",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgDownload);
export default ForwardRef;
