import { Initials } from '@/components/shared/Initials';

import style from './UserAvatar.module.sass';

type UserAvatarProps = {
  imageUrl?: string | null;
  firstName?: string;
  lastName?: string;
  alt?: string | null;
  fontSize?: number;
};

export const UserAvatar = ({
  imageUrl,
  firstName,
  lastName,
  alt,
  fontSize,
}: UserAvatarProps) => {
  if (!imageUrl) {
    if (!firstName) {
      return (
        <div className={style.defaultAvatar} />
      );
    }

    return (
      <Initials
        firstName={firstName}
        lastName={lastName}
        fontSize={fontSize}
      />
    );
  }

  return (
    <img
      src={imageUrl}
      srcSet={imageUrl}
      alt={alt || undefined}
      className={style.avatarImage}
    />
  );
};
