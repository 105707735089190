import classNames from 'classnames/bind';
import { PropsWithChildren } from 'react';

import style from './Container.module.sass';
const cx = classNames.bind(style);

type ContainerProps = PropsWithChildren<{
  className?: string;
}>;

export const Container = ({
  children,
  className,
}: ContainerProps) => {
  const wrapperClassName = cx('wrapper', className);

  return (
    <div className={wrapperClassName}>
      {children}
    </div>
  );
};
