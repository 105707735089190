import classNames from 'classnames/bind';
import { Listbox } from '@headlessui/react';
import {
  Fragment,
  useCallback,
  useDeferredValue,
  useMemo, type ReactNode,
} from 'react';

import { Button } from '@/components/shared/buttons';
import { Icon } from '@/components/shared/Icon';
import { SelectInputLabel, type OptionVariant } from '@/components/shared/form/inputs/SelectInput/SelectInputLabel';
import { SelectInputOptions } from '@/components/shared/form/inputs/SelectInput/SelectInputOptions';
import { Tooltip } from '@/components/shared/Tooltip';
import { type SelectOption } from '@/types/shared';

import style from './SelectInput.module.sass';

const cx = classNames.bind(style);

type ButtonRenderPropArg = {
  open: boolean;
  disabled: boolean;
};

type SingleOnChangeHandler = (name: string) => (newValue: string) => void;
type MultipleOnChangeHandler = (options: SelectOption[]) => void;

export type SelectInputProps = {
  name: string;
  options?: SelectOption[];
  value: string | SelectOption[];
  placeholder?: string;
  sizeVariant?: 'small' | 'medium';
  disabled?: boolean;
  onChange: MultipleOnChangeHandler | SingleOnChangeHandler;
  error?: string;
  className?: string;
  optionClassName?: string;
  optionsClassName?: string;
  isInverted?: boolean;
  popperAlign?: 'left' | 'right' | 'auto';
  optionVariant?: OptionVariant;
  popperTitle?: string;
  deselectable?: boolean;
  optionsListClassName?: string;
  'data-cy'?: string;
  wrapperClassName?: string;
  isMultiple?: boolean;
  renderValueLabel?: (value: string | SelectOption[]) => ReactNode;
};

type OptionWithSubtitleProps = {
  label: ReactNode;
  subtitle?: ReactNode;
};

const OptionWithSubtitle = ({
  label,
  subtitle,
}: OptionWithSubtitleProps) => {
  return (
    <>
      <span className={style.optionTitleLabel}>
        {label}
      </span>
      {
        subtitle &&
        <span className={style.optionSubtitle}>
          {subtitle}
        </span>
      }
    </>
  );
};

export const SelectInput = ({
  options = [],
  value,
  name,
  onChange,
  placeholder,
  error,
  disabled: disabledProp,
  sizeVariant = 'small',
  className,
  optionClassName,
  optionsClassName,
  isInverted,
  optionVariant = 'tickRight',
  popperAlign = 'auto',
  popperTitle,
  deselectable = false,
  optionsListClassName,
  'data-cy': dataCy,
  wrapperClassName,
  isMultiple,
  renderValueLabel,
}: SelectInputProps) => {
  const deferredValue = useDeferredValue(value);

  const selectedOption = useMemo(() => {
    const currentOption = options.find((option) => option.value === deferredValue);

    return currentOption ?? '';
  }, [
    options,
    deferredValue,
  ]);

  const chevronIconClass = cx('chevronIcon', sizeVariant, {
    selectHasValue: !!value,
  });

  const onSelectSingle = useCallback((option: SelectOption) => {
    if (!option || deselectable && value === option.value) {
      (onChange as SingleOnChangeHandler)(name)('');
      return;
    }

    (onChange as SingleOnChangeHandler)(name)(option.value);
  }, [
    deselectable,
    name,
    onChange,
    value,
  ]);

  const onSelectMultiple = useCallback((newOptions: SelectOption[]) => {
    (onChange as MultipleOnChangeHandler)(newOptions);
  }, [
    onChange,
  ]);

  const renderOptionLabel = (
    option: SelectOption,
    isActive?: boolean,
  ) => {
    const label = (
      <div className={style.optionLabel}>
        {option.label}
      </div>
    );

    if (option.tooltipProps) {
      return (
        <Tooltip
          {...option.tooltipProps}
          visible={isActive}>
          {label}
        </Tooltip>
      );
    }

    return label;
  };

  const renderOption = (option: SelectOption) => {
    return (
      <Listbox.Option
        key={option.value}
        value={option}
        disabled={option.disabled}
        className={optionClassName}>
        {
          (optionRenderPropArg) => (
            <SelectInputLabel
              {...optionRenderPropArg}
              optionVariant={optionVariant}>
              {renderOptionLabel(option, optionRenderPropArg.active)}
            </SelectInputLabel>
          )
        }
      </Listbox.Option>
    );
  };

  const renderButtonContent = () => {
    if (renderValueLabel) {
      const customValueLabel = renderValueLabel(value);

      if (customValueLabel !== undefined) return customValueLabel;
    }

    if (!selectedOption) {
      return <span className={style.placeholder}>{placeholder}</span>;
    }

    return (
      <div className={style.selectedOptionWrapper}>
        {renderOptionLabel(selectedOption)}
      </div>
    );
  };

  const renderButton = ({ disabled }: ButtonRenderPropArg) => {
    const buttonClass = cx('button', sizeVariant, className, {
      disabled,
      error,
    });

    return (
      <Button.Unstyled className={buttonClass}>
        {renderButtonContent()}
      </Button.Unstyled>
    );
  };

  const popperClassName = cx('popper', optionsClassName, popperAlign, {
    isInverted,
  });

  const optionsListClassNames = cx('optionsList', optionsListClassName);
  const wrapperClassNames = cx('wrapper', wrapperClassName);

  return (
    <div
      data-cy={dataCy}
      className={wrapperClassNames}>
      <Listbox
        value={isMultiple ? value as SelectOption[] : selectedOption}
        onChange={isMultiple ? onSelectMultiple : onSelectSingle}
        disabled={disabledProp}
        multiple={isMultiple}>
        <Listbox.Button as={Fragment}>
          {renderButton}
        </Listbox.Button>
        <Listbox.Options as='div' className={popperClassName}>
          <SelectInputOptions
            popperTitle={popperTitle}
            optionsListClassName={optionsListClassNames}>
            {options.map(renderOption)}
          </SelectInputOptions>
        </Listbox.Options>
      </Listbox>
      <Icon name='ChevronDown' className={chevronIconClass} />
    </div>
  );
};

SelectInput.OptionWithSubtitle = OptionWithSubtitle;
