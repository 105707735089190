import { ReactNode, createContext } from 'react';

export type CarouselModalData = {
  slides: ReactNode[];
  currentIndex?: number;
};

export type CarouselContextValue = {
  openModal: (data: CarouselModalData) => void;
  closeModal: () => void;
} | null;

export const CarouselContext = createContext<CarouselContextValue>(null);
