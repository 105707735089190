import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { ContainerLoader } from '@/components/shared/loaders/ContainerLoader';
import type { Nullable } from '@/types/shared';

type WithQueryLoaderProps = {
  error?: Nullable<Error>;
  isLoading: boolean;
  children: ReactNode;
};

export const WithQueryLoader: FC<WithQueryLoaderProps> = ({
  error,
  isLoading,
  children,
}) => {
  if (isLoading) {
    return (
      <ContainerLoader />
    );
  }

  if (error) {
    return (
      <Navigate
        to='/404'
        replace={true}
      />
    );
  }

  return (
    <>{children}</>
  );
};
