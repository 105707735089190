import * as React from "react";
import { forwardRef } from "react";
const SvgBulb = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M15 15.1899H9",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M8.54094 14.8959C6.76494 13.6409 5.69994 11.4499 6.07494 9.03795C6.48094 6.43095 8.65394 4.34995 11.2749 4.04295C14.9129 3.61595 17.9999 6.44795 17.9999 9.99995C17.9999 12.0249 16.9939 13.8119 15.4569 14.8979C15.1809 15.0929 14.9999 15.3949 14.9999 15.7329V18.4999C14.9999 19.8809 13.8809 20.9999 12.4999 20.9999H11.4999C10.1189 20.9999 8.99994 19.8809 8.99994 18.4999V15.7369C8.99994 15.3959 8.81794 15.0919 8.54094 14.8959Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20 5.00006L21.19 3.81006",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M4.00006 15L2.81006 16.19",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.00006 4.00006L3.81006 2.81006",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20 15L21.19 16.19",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M21 10H22.69",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1.31006 10H3.00006",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9 18H14.87",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgBulb);
export default ForwardRef;
