import * as React from "react";
import { forwardRef } from "react";
const SvgHomeActive = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M3 18V10.9597C3 9.5927 3.62139 8.29979 4.68885 7.44582L8.8765 4.09568C10.7026 2.63477 13.2974 2.63477 15.1235 4.09568L19.3111 7.44581C20.3786 8.29978 21 9.59269 21 10.9597V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18Z",
  fill: "#003027",
  stroke: "#003027",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M11.75 13H12.25C13.7688 13 15 14.2312 15 15.75V21H9V15.75C9 14.2312 10.2312 13 11.75 13Z",
  fill: "#F5FAFA"
}));
const ForwardRef = forwardRef(SvgHomeActive);
export default ForwardRef;
