import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import { SubdomainsIncludedRecord } from '@/queries/subdomain/fetchSubdomains';
import { Subdomain } from '@/types/subdomain';

export const fetchSubdomain = async (context: QueryFunctionContext) => {
  const [, subdomainId] = context.queryKey;

  const response = await Api.get<Subdomain, SubdomainsIncludedRecord>(`/api/subdomains/${subdomainId}`);

  const parsedSubdomain = linkCollectionRelationships(response);

  return parsedSubdomain;
};
