import classNames from 'classnames/bind';
import { type PropsWithChildren } from 'react';

import { Button } from '@/components/shared/buttons';
import { Icon } from '@/components/shared/Icon';

import style from './FilterEntry.module.sass';

const cx = classNames.bind(style);

export type FilterEntryProps = PropsWithChildren<{
  onRemove: () => void;
  className?: string;
  variant?: 'neutral' | 'white';
}>;

export const FilterEntry = ({
  children,
  onRemove,
  className,
  variant = 'neutral',
}: FilterEntryProps) => {
  const badgeClassNames = cx('badge', className, variant);

  return (
    <div className={badgeClassNames}>
      <div className={style.content}>
        {children}
      </div>
      <Button.Unstyled className={style.closeButton} onClick={onRemove}>
        <Icon name='Delete' className={style.closeIcon} />
      </Button.Unstyled>
    </div>
  );
};
