import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikErrors } from 'formik';
import { AxiosError } from 'axios';

import { FieldRenderer } from '@/components/shared/form/FieldRenderer';
import { FieldWrapper } from '@/components/shared/form/FieldWrapper';
import { TextInput } from '@/components/shared/form/inputs/TextInput';
import { FileUploader } from '@/components/shared/form/inputs/FileUploader';
import { StoreBusinessTypeField } from '@/components/store/StoreBusinessTypeField';
import { TelephoneCountryInput } from '@/components/shared/form/inputs/TelephoneCountryInput';
import { TextAreaInput } from '@/components/shared/form/inputs/TextAreaInput';
import { CountrySelectInput } from '@/components/shared/form/inputs/CountrySelectInput';
import {
  useDeleteVeriformInvoice,
  useUploadVeriformInvoice,
} from '@/hooks/stores';
import { notification } from '@/utils/notification';
import { sentryLogIssue } from '@/utils/sentry';
import { SENTRY_413_ERROR_MESSAGE } from '@/resources/sentry';
import type {
  UpdateVeriformAttributes,
  VeriformInvoice,
} from '@/types/veriform';

import style from './VeriformFormFields.module.sass';

import type { MultiStepNavigation } from '../MultiStepFormNavigation';

type VeriformFormFieldsProps = {
  invoices?: VeriformInvoice[];
  refetchVeriform: () => Promise<unknown>;
  defaultCountryName?: string;
  errors: FormikErrors<UpdateVeriformAttributes>;
  multiStepNavigation: MultiStepNavigation;
};

export const VeriformFormFields = ({
  invoices,
  refetchVeriform,
  defaultCountryName,
  errors,
  multiStepNavigation,
}: VeriformFormFieldsProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { token, id: veriformId } = useParams();

  const { mutateAsync: uploadVeriformInvoice } = useUploadVeriformInvoice();

  const { mutateAsync: deleteVeriformInvoice } = useDeleteVeriformInvoice();

  const addInvoiceToVeriform = useCallback(
    async (file: File) => {
      try {
        const result = await uploadVeriformInvoice(
          {
            veriformId,
            file,
            token,
          },
          {
            onError: (error) => {
              if (error.response?.status === 401) {
                navigate(`/veriforms/${veriformId}/${token}/expired`);
              }
            },
          },
        );

        await refetchVeriform();

        return result.attributes.url;
      } catch (e) {
        const error = e as AxiosError;

        if (error.response?.status === 413) {
          sentryLogIssue(SENTRY_413_ERROR_MESSAGE);
        }

        notification.danger({
          title: t('store:veriform.errors.uploadInvoice.title'),
          body: t('store:veriform.errors.uploadInvoice.body'),
        });
      }
    },
    [t, uploadVeriformInvoice, veriformId, token, refetchVeriform, navigate],
  );

  const removeInvoiceFromVeriform = useCallback(
    async (index: number) => {
      const invoiceId = invoices?.[index]?.id;

      try {
        await deleteVeriformInvoice({
          invoiceId,
          veriformId,
          token,
        });

        await refetchVeriform();

        notification.info({
          title: t('store:veriform.messages.deleteInvoice.title'),
          body: t('store:veriform.messages.deleteInvoice.body'),
        });
      } catch (error) {
        notification.danger({
          title: t('store:veriform.errors.deleteInvoice.title'),
          body: t('store:veriform.errors.deleteInvoice.body'),
        });
      }
    },
    [t, refetchVeriform, invoices, veriformId, deleteVeriformInvoice, token],
  );

  const notifyAboutNewFiles = (fileUrls: string[]) => {
    const count = fileUrls.length;

    notification.success({
      title: t('store:veriform.messages.uploadInvoice.title', { count }),
      body: t('store:veriform.messages.uploadInvoice.body', { count }),
    });
  };

  const invoiceFileNames = invoices?.map(
    (invoice) => invoice.attributes.originalFilename,
  );

  return (
    <div className={style.formWrapper}>
      {multiStepNavigation.currentStep === 0 && (
        <>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.productSource')}
            label={t('store:veriform.labels.productSource')}
            name='sourcedFrom'
            errorName='none'
            errorWrapperClassName={style.errorWrapper}
            customError={errors.sourcedFrom ? t('form:errors.required') : undefined}
            isMandatory={true}>
            <FieldRenderer
              placeholder={t('store:veriform.placeholders.productSource')}
              name='sourcedFrom'
              Component={TextInput}
              sizeVariant='medium'
              error={errors.sourcedFrom}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.serialNumbers')}
            label={t('store:veriform.labels.serialNumbers')}
            errorWrapperClassName={style.errorWrapper}
            name='serialNumbers'>
            <FieldRenderer
              placeholder={t('store:veriform.placeholders.serialNumbers')}
              name='serialNumbers'
              Component={TextInput}
              sizeVariant='medium'
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.invoices')}
            label={t('store:veriform.labels.invoices')}
            errorWrapperClassName={style.errorWrapper}
            name='invoices'>
            <FileUploader
              name='invoices'
              iconName='File'
              isMultiple={true}
              uploadFile={addInvoiceToVeriform}
              onBeforeRemoveFile={removeInvoiceFromVeriform}
              onAfterAddFiles={notifyAboutNewFiles}
              value={invoiceFileNames ?? []}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.businessName', {
              context: 'your',
            })}
            label={t('store:veriform.labels.businessName')}
            name='businessName'
            errorName='none'
            customError={errors.businessName ? t('form:errors.required') : undefined}
            errorWrapperClassName={style.errorWrapper}
            isMandatory={true}>
            <FieldRenderer
              placeholder={t('store:veriform.placeholders.businessName')}
              name='businessName'
              Component={TextInput}
              sizeVariant='medium'
              error={errors.businessName}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.tradingNames')}
            label={t('store:veriform.labels.tradingNames')}
            errorWrapperClassName={style.errorWrapper}
            name='tradingAs'>
            <FieldRenderer
              placeholder={t('store:veriform.placeholders.tradingNames')}
              name='tradingAs'
              Component={TextInput}
              sizeVariant='medium'
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.email')}
            label={t('store:veriform.labels.email')}
            errorWrapperClassName={style.errorWrapper}
            name='emailAddress'>
            <FieldRenderer
              placeholder={t('store:veriform.placeholders.email')}
              name='emailAddress'
              Component={TextInput}
              sizeVariant='medium'
            />
          </FieldWrapper>
        </>
      )}

      {multiStepNavigation.currentStep === 1 && (
        <>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.location')}
            label={t('store:veriform.labels.location')}
            errorWrapperClassName={style.errorWrapper}
            name='countryId'>
            <FieldRenderer
              name='countryId'
              sizeVariant='medium'
              placeholder={t('store:veriform.placeholders.location')}
              Component={CountrySelectInput}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.phoneNumber')}
            label={t('store:veriform.labels.phoneNumber')}
            errorWrapperClassName={style.errorWrapper}
            name='phoneNumber'>
            <TelephoneCountryInput
              name='phoneNumber'
              defaultCountryName={defaultCountryName}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.website')}
            label={t('store:veriform.labels.website')}
            errorWrapperClassName={style.errorWrapper}
            name='website'>
            <FieldRenderer
              placeholder={t('store:veriform.placeholders.website')}
              name='website'
              Component={TextInput}
              sizeVariant='medium'
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.addressLine')}
            label={t('store:veriform.labels.addressLine')}
            errorWrapperClassName={style.errorWrapper}
            name='addressLine'>
            <FieldRenderer
              className={style.textarea}
              placeholder={t('store:veriform.placeholders.addressLine')}
              name='addressLine'
              Component={TextAreaInput}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.additionalAddresses')}
            label={t('store:veriform.labels.additionalAddresses')}
            errorWrapperClassName={style.errorWrapper}
            name='additionalAddresses'>
            <FieldRenderer
              className={style.textarea}
              placeholder={t('store:veriform.placeholders.additionalAddresses')}
              name='additionalAddresses'
              Component={TextAreaInput}
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.warehouseAddress')}
            label={t('store:veriform.labels.warehouseAddress')}
            errorWrapperClassName={style.errorWrapper}
            name='warehouseAddress'>
            <FieldRenderer
              className={style.textarea}
              placeholder={t('store:veriform.placeholders.warehouseAddress')}
              name='warehouseAddress'
              Component={TextAreaInput}
            />
          </FieldWrapper>
        </>
      )}

      {multiStepNavigation.currentStep === 2 && (
        <>
          <StoreBusinessTypeField />
          <FieldWrapper
            title={t('store:veriform.fieldTitles.tradeRegNum')}
            label={t('store:veriform.labels.tradeRegNum')}
            errorWrapperClassName={style.errorWrapper}
            name='tradeRegNum'>
            <FieldRenderer
              placeholder={t('store:veriform.placeholders.tradeRegNum')}
              name='tradeRegNum'
              Component={TextInput}
              sizeVariant='medium'
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.taxRegNum')}
            label={t('store:veriform.labels.taxRegNum')}
            errorWrapperClassName={style.errorWrapper}
            name='taxRegNum'>
            <FieldRenderer
              placeholder={t('store:veriform.placeholders.taxRegNum')}
              name='taxRegNum'
              Component={TextInput}
              sizeVariant='medium'
            />
          </FieldWrapper>
          <FieldWrapper
            title={t('store:veriform.fieldTitles.activeChannels')}
            label={t('store:veriform.labels.activeChannels')}
            errorWrapperClassName={style.errorWrapper}
            name='activeChannels'>
            <FieldRenderer
              placeholder={t('store:veriform.placeholders.activeChannels')}
              name='activeChannels'
              Component={TextInput}
              sizeVariant='medium'
            />
          </FieldWrapper>
        </>
      )}
    </div>
  );
};
