import { FC, ReactElement } from 'react';
import { QueryClientProvider } from 'react-query';

import { queryClient } from '@/resources/queryClient';

type ReactQueryClientProviderProps = {
  children: ReactElement;
};

export const ReactQueryClientProvider: FC<ReactQueryClientProviderProps> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};
