import * as React from "react";
import { forwardRef } from "react";
const SvgListingItem = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M16 7H11",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M11 11H16",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M11 15H16",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M4 4.778V19.5C4 20.243 4.782 20.727 5.447 20.394L6.218 20.008C6.5 19.867 6.831 19.867 7.112 20.008L8.885 20.894C9.166 21.035 9.498 21.035 9.779 20.894L11.552 20.008C11.833 19.867 12.165 19.867 12.446 20.008L14.219 20.894C14.501 21.035 14.832 21.035 15.114 20.894L16.885 20.008C17.167 19.867 17.498 19.867 17.78 20.008L18.552 20.394C19.218 20.727 20 20.243 20 19.5V4.778C20 3.796 19.204 3 18.222 3H5.778C4.796 3 4 3.796 4 4.778Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.87401 6.875C7.80601 6.875 7.74901 6.931 7.75001 7C7.75001 7.069 7.80601 7.125 7.87501 7.125C7.94401 7.125 8.00001 7.069 8.00001 7C8.00001 6.931 7.94401 6.875 7.87401 6.875",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.87401 10.875C7.80501 10.875 7.74901 10.931 7.75001 11C7.75001 11.069 7.80601 11.125 7.87501 11.125C7.94401 11.125 8.00001 11.069 8.00001 11C8.00001 10.931 7.94401 10.875 7.87401 10.875",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.87401 14.875C7.80501 14.875 7.74901 14.931 7.75001 15C7.75001 15.069 7.80601 15.125 7.87501 15.125C7.94401 15.125 8.00001 15.069 8.00001 15C8.00001 14.931 7.94401 14.875 7.87401 14.875",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgListingItem);
export default ForwardRef;
