import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { Channel } from '@/types/channel';

export const fetchSettingsChannel = async (context: QueryFunctionContext) => {
  const [, channelId] = context.queryKey;
  const response = await Api.get<Channel>(`/api/channels/${channelId}`);

  return response.data;
};

