import { FC } from 'react';

import styles from './Initials.module.sass';

type InitialsProps = {
  firstName: string;
  lastName?: string;
  fontSize?: number;
};

export const Initials: FC<InitialsProps> = ({
  firstName,
  lastName,
  fontSize = 32,
}) => {
  const initials = [firstName[0], lastName?.[0]].join('').toUpperCase();

  return (
    <svg
      className={styles.svgElement}
      viewBox='0 0 100 100'
      preserveAspectRatio='xMinYMid meet'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <text
        x='50%'
        y='50%'
        fontSize={fontSize}
        textAnchor='middle'
        dominantBaseline='central'
        fill='currentColor'>
        {initials}
      </text>
    </svg>
  );
};
