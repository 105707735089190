import { downloadFile } from '@/utils/download';

export const downloadBlob = (
  response: BlobPart,
  filename: string,
  mimeType = 'application/pdf',
): void => {
  const blob = new Blob([response], { type: mimeType });

  const url = window.URL.createObjectURL(blob);

  downloadFile(url, filename);

  window.URL.revokeObjectURL(url);
};
