import { type Swiper } from 'swiper';

export const handleKeyDown = (swiper?: Swiper) => (event: KeyboardEvent) => {
  switch (event.key) {
    case 'ArrowRight':
      return swiper?.slideNext(300);
    case 'ArrowLeft':
      return swiper?.slidePrev(300);
    default:
      break;
  }
};
