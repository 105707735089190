import { SortableSortSelect } from '@/components/shared/sortable/SortableSortSelect';
import { useBotsSortParams } from '@/hooks/bot';

export const BotsSortSelect = () => {
  const {
    sortBy,
    options,
  } = useBotsSortParams();

  return (
    <SortableSortSelect
      sortBy={sortBy}
      options={options}
    />
  );
};
