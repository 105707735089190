import { fetchUser } from '@/queries/user';
import { useDataQuery } from '@/hooks/shared';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { User } from '@/types/user';

export const useUser = (userId?: string) => {
  const {
    data: user,
    ...rest
  } = useDataQuery<User>([QUERY_KEYS.user, userId], fetchUser, {
    enabled: Boolean(userId),
  });

  return {
    user,
    ...rest,
  };
};
