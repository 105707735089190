import { createContext } from 'react';

import { TrademarkNiceClassifications } from '@/types/trademark';

export type TrademarkNiceClassesModalData = {
  niceClasses: TrademarkNiceClassifications[];
  trademarkName: string;
};

export type TrademarkNiceClassesModalContextValue = {
  openModal: (data: TrademarkNiceClassesModalData) => void;
  closeModal: () => void;
};

const TrademarkModalDefaultValue: TrademarkNiceClassesModalContextValue = {
  openModal: () => '',
  closeModal: () => '',
};

export const TrademarkNiceClassesModalContext = createContext<
  TrademarkNiceClassesModalContextValue
>(TrademarkModalDefaultValue);
