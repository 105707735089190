import { useTranslation } from 'react-i18next';

import { BooleanBadge } from '@/components/shared/badges/BooleanBadge';

type CompanyRemoteAdminBadgeProps = {
  allowRemoteAdmin?: boolean;
};

export const CompanyRemoteAdminBadge = ({
  allowRemoteAdmin,
}: CompanyRemoteAdminBadgeProps) => {
  const { t } = useTranslation();

  return (
    <BooleanBadge
      value={allowRemoteAdmin}
      trueText={t('company:remoteAdmin.true')}
      falseText={t('company:remoteAdmin.false')}
    />
  );
};
