import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { CompaniesLogosHeader } from '@/components/shared/CompaniesLogosHeader';
import { UserAuthPageLayout } from '@/components/App/AppLayout/LoginPage/UserAuthPageLayout';

import style from './RegistrationConfirmationPage.module.sass';

export const RegistrationConfirmationPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    emailAddress,
  } = location.state as { emailAddress: string, } ?? {};

  useEffect(() => {
    if (!emailAddress) {
      navigate('/');
    }
  });

  if (!emailAddress) {
    return null;
  }

  return (
    <UserAuthPageLayout>
      <div className={style.wrapper}>
        <CompaniesLogosHeader />
        <div>
          <div className={style.content}>
            <h1 className={style.title}>{t('loginPage:emailSent.checkEmail')}</h1>
            <p className={style.text}>{t('loginPage:emailSent.emailSentMessage')}</p>
            <p className={style.email}>{emailAddress}</p>
            <p className={style.text}>{t('loginPage:emailSent.clickButtonToContinue')}</p>
            <p className={style.text}>{t('loginPage:emailSent.closeThePage')}</p>
          </div>
        </div>
      </div>
    </UserAuthPageLayout>
  );
};
