import * as React from "react";
import { forwardRef } from "react";
const SvgLink = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M16.4779 13.9618L18.6698 11.7699C20.4104 9.98532 20.3926 7.13287 18.6299 5.37011C16.8671 3.60736 14.0147 3.58958 12.2301 5.33023L10.0382 7.52214",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M14.6681 9.33189L9.33188 14.6681",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.52212 10.0382L5.3302 12.2301C3.58956 14.0147 3.60734 16.8671 5.37009 18.6299C7.13284 20.3926 9.9853 20.4104 11.7699 18.6698L13.9618 16.4779",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgLink);
export default ForwardRef;
