import { QueryClient } from 'react-query';

const ONE_MINUTE = 1000 * 60;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: ONE_MINUTE,
    },
  },
});
