import { useAuthUserRole } from '@/hooks/user';
import { UserRoles } from '@/resources/enums';
import { PartialUserSystemRole } from '@/types/user';

export const useUserRole = (): PartialUserSystemRole => {
  const { authUserRole } = useAuthUserRole();

  return {
    isGuest: authUserRole === UserRoles.Guest,
    isUser: authUserRole === UserRoles.User,
    isAdmin: authUserRole === UserRoles.Admin,
  };
};
