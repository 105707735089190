import { useTranslation } from 'react-i18next';

import { NavButton } from '@/components/shared/buttons';
import { IconWithGlow } from '@/components/shared/IconWithGlow';

import style from './PasswordErrorPage.module.sass';

type PasswordErrorPageProps = {
  errorCode: string;
}

export const PasswordErrorPage = ({
  errorCode,
}: PasswordErrorPageProps) => {
  const { t } = useTranslation();

  const renderErrorMessage = () => {
    switch (errorCode) {
      case 'auth/expired-action-code':
        return (t('loginPage:resetPassword.errors.codeExpired'));
      case 'auth/invalid-action-code':
        return (t('loginPage:resetPassword.errors.invalidCode'));
      case 'auth/user-disabled':
        return (t('loginPage:resetPassword.errors.userDisabled'));
      case 'auth/user-not-found':
        return (t('loginPage:resetPassword.errors.userNotFound'));
      default:
        return (t('loginPage:resetPassword.errors.requestFailed'));
    }
  };

  return (
    <div className={style.wrapper}>
      <IconWithGlow
        sizeVariant='large'
        type='danger'
        iconName='ExclamationCircle'
      />
      <p className={style.message}>{renderErrorMessage()}</p>
      <NavButton navigateTo='/'>
        {t('loginPage:resetPassword.returnToSignIn')}
      </NavButton>
    </div>
  );
};
