import { useTranslation } from 'react-i18next';

import { Dropdown } from '@/components/shared/Dropdown';
import { Loader } from '@/components/shared/loaders/Loader';
import { getStoreRoute } from '@/utils/routes';
import { StoreStatus } from '@/resources/enums';
import { useBranding } from '@/hooks/branding';
import { AllowStoreContext } from '@/contexts/store/AllowStoreContext';
import { useUnsafeContext } from '@/hooks/shared';
import { useRemoveStoreFromWhiteList } from '@/hooks/stores';
import { Store } from '@/types/store';
import { ResellerStore } from '@/types/reseller';

type StoreContextMenuProps<T> = {
  store: T;
};

type RenderClickableItemProps = {
  onClick: () => void;
  iconName: 'Cancel' | 'DoneCircle';
  text: string;
  shouldShowLoader?: boolean;
};

export const StoreContextMenu = <T extends Store | ResellerStore>({
  store,
}: StoreContextMenuProps<T>) => {
  const { t } = useTranslation();
  const { branding } = useBranding();

  const {
    openModal,
  } = useUnsafeContext(AllowStoreContext);

  const storeId = store.id;
  const resellerId = store.relationships?.reseller.data.id;
  const whitelistingId = store.linked?.whitelisting?.id;

  const {
    mutateAsync: removeStoreFromWhiteListAsync,
    isLoading: isRemoveStoreFromWhiteListLoading,
  } = useRemoveStoreFromWhiteList(storeId, resellerId);

  const renderClickableItem = ({
    onClick,
    iconName,
    text,
    shouldShowLoader,
  }: RenderClickableItemProps) => {
    return (
      <Dropdown.ClickableItem
        onClick={onClick}
        closeOnClick={true}
        iconName={shouldShowLoader ? undefined : iconName}>
        {
          shouldShowLoader
            ? <Loader size='small' />
            : text
        }
      </Dropdown.ClickableItem>
    );
  };

  const renderStoreActions = () => {
    const storeStatus = store?.attributes.status;

    if (storeStatus === StoreStatus.ActionInProgress) {
      return null;
    }

    const onAllowClick = () => {
      openModal({
        storeId,
        resellerId,
        whitelistingId,
        companyId: branding?.attributes.companyId,
        storeName: store.attributes.name,
        storeSubdomain: store.linked?.subdomain?.linked?.channel?.attributes.name,
        storeSubdomainUrl: store.linked?.subdomain?.attributes.url,
      });
    };

    if (storeStatus === StoreStatus.Allowed) {
      const onDisallowClick = () => {
        removeStoreFromWhiteListAsync({
          storeId,
          whitelistingId,
        });
      };

      return (
        <>
          {
            renderClickableItem({
              onClick: onAllowClick,
              iconName: 'DoneCircle',
              text: t('store:actions.update'),
            })
          }
          {
            renderClickableItem({
              onClick: onDisallowClick,
              iconName: 'Cancel',
              text: t('store:actions.disallow'),
              shouldShowLoader: isRemoveStoreFromWhiteListLoading,
            })
          }
        </>
      );
    }

    return (
      renderClickableItem({
        onClick: onAllowClick,
        iconName: 'DoneCircle',
        text: t('store:actions.allow'),
      })
    );
  };

  return (
    <>
      <Dropdown.ClickableItem
        navigateTo={getStoreRoute(storeId)}
        iconName='ArrowLink'>
        {t('common:open')}
      </Dropdown.ClickableItem>
      {renderStoreActions()}
    </>
  );
};
