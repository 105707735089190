import { useDataQuery } from '@/hooks/shared';
import { fetchEnforcementPolicyDefinition } from '@/queries/policyDefinition';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const useEnforcementPolicyDefinition = (enforcementPolicyDefinitionId?: string) => {
  const {
    data: enforcementPolicyDefinition,
    ...rest
  } = useDataQuery(
    [QUERY_KEYS.enforcementPolicyDefinitions, enforcementPolicyDefinitionId],
    fetchEnforcementPolicyDefinition,
  );

  return {
    enforcementPolicyDefinition,
    ...rest,
  };
};
