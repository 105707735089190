import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { FieldRenderer } from '@/components/shared/form/FieldRenderer';
import { TextAreaInput } from '@/components/shared/form/inputs/TextAreaInput';
import { FieldWrapper } from '@/components/shared/form/FieldWrapper';
import { useStoreOtherBusinessTypeId } from '@/hooks/stores';

import style from './StoreBusinessTypeTextArea.module.sass';

export const StoreBusinessTypeTextArea = () => {
  const { t } = useTranslation();
  const [field] = useField('businessTypeId');
  const otherBusinessTypeId = useStoreOtherBusinessTypeId();

  const isDisabled = (
    field.value !== otherBusinessTypeId
    &&
    field.value !== ''
  );

  return (
    <FieldWrapper
      name='businessTypeOther'>
      <FieldRenderer
        className={style.wrapper}
        name='businessTypeOther'
        disabled={isDisabled}
        Component={TextAreaInput}
        placeholder={t('store:veriform.placeholders.businessTypeOther')}
      />
    </FieldWrapper>
  );
};
