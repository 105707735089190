type ToggleOptions = {
  disableConcatWhen?: boolean;
};

export const toggle = <T>(
  array: T[],
  element: T,
  options: ToggleOptions = {},
) => {
  if (array.includes(element)) {
    return array.filter((e) => e !== element);
  }

  if (options.disableConcatWhen) {
    return array;
  }

  return array.concat(element);
};
