import { LabelHTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames/bind';

import { FieldWrapperTooltip } from '@/components/shared/form/FieldWrapperTooltip';

import style from './FieldWrapperLabel.module.sass';

const cx = classNames.bind(style);

type FieldWrapperLabelProps = PropsWithChildren<{
  isDisabled?: boolean;
  htmlFor?: LabelHTMLAttributes<HTMLLabelElement>['htmlFor'];
  tooltip?: ReactElement;
  isTipIconVisible?: boolean;
  labelClassName?: string;
}>;

export const FieldWrapperLabel = ({
  isDisabled,
  htmlFor,
  tooltip,
  isTipIconVisible,
  labelClassName,
  children,
}: FieldWrapperLabelProps) => {
  const labelClass = cx(style.label, labelClassName, {
    isDisabled,
  });

  const renderLabel = () => (
    <label htmlFor={htmlFor} className={labelClass}>
      {children}
    </label>
  );

  if (tooltip) {
    return (
      <FieldWrapperTooltip
        tooltip={tooltip}
        isTipIconVisible={isTipIconVisible}>
        {renderLabel()}
      </FieldWrapperTooltip>
    );
  }

  return (
    renderLabel()
  );
};
