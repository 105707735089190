import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormWrapper } from '@/components/shared/form/FormWrapper';
import { Body } from '@/components/shared/typography/Body';
import { VeriformForm } from '@/components/veriform/VeriformForm';
import { WithQueryLoader } from '@/components/shared/WithQueryLoader';
import { Header } from '@/components/shared/typography/Header';
import {
  MultiStepBreadcrumbs,
  useMultiStepNavigation,
} from '@/components/veriform/MultiStepFormNavigation';
import { useVeriform } from '@/hooks/veriform';

import style from './Veriform.module.sass';

export const Veriform = () => {
  const { t } = useTranslation();

  const { id, token } = useParams();
  const multiStepNavigation = useMultiStepNavigation(3);

  const {
    values,
    isLoading,
    refetch: refetchVeriform,
  } = useVeriform(id, token);

  const renderForm = () => {
    if (!values) {
      return null;
    }

    return (
      <FormWrapper isBackButtonVisible={false} titleClassName={style.title}>
        <MultiStepBreadcrumbs
          currentStep={multiStepNavigation.currentStep}
          numberOfSteps={multiStepNavigation.numberOfSteps}
        />
        <Header variant='h2' className={style.header}>
          {t('store:veriform.title')}
        </Header>
        <Body className={style.subtitle} size='medium'>
          {t('store:veriform.subtitle')}
        </Body>
        <Body className={style.mandatory} size='medium'>
          {t('common:mandatory')}
        </Body>
        <VeriformForm
          values={values}
          id={id}
          token={token}
          refetchVeriform={refetchVeriform}
          multiStepNavigation={multiStepNavigation}
        />
      </FormWrapper>
    );
  };

  return (
    <WithQueryLoader isLoading={isLoading}>{renderForm()}</WithQueryLoader>
  );
};
