import { useDataQuery } from '@/hooks/shared';
import { fetchCountries } from '@/queries/country';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { type Country } from '@/types/country';

export const useCountries = () => {
  const {
    data: countries,
    ...rest
  } = useDataQuery<Country[]>(QUERY_KEYS.countries, fetchCountries, {
    refetchOnMount: false,
  });

  return {
    countries,
    ...rest,
  };
};
