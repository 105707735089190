import classNames from 'classnames/bind';
import { type ReactNode } from 'react';

import { ImageWithFallback } from '@/components/shared/ImageWithFallback';
import { Header } from '@/components/shared/typography/Header';
import { Skeleton } from '@/components/shared/loaders/Skeleton';
import { ImageFallback } from '@/types/shared';

import style from './DetailsHeader.module.sass';
const cx = classNames.bind(style);

type DetailsHeaderProps = {
  name?: string;
  logoUrl?: string;
  children?: ReactNode;
  wrapperClassName?: string;
  fallback?: ImageFallback;
  isLoading?: boolean;
};

export const DetailsHeader = ({
  name,
  logoUrl,
  children,
  fallback,
  wrapperClassName,
  isLoading,
}: DetailsHeaderProps) => {
  const wrapperClassNames = cx('wrapper', wrapperClassName);

  const renderImage = () => {
    if (!logoUrl) {
      return null;
    }

    return (
      <div className={style.logoWrapper}>
        <ImageWithFallback
          loaderWrapper={style.loaderWrapper}
          className={style.logoImage}
          src={logoUrl}
          alt={name}
          fallback={fallback}
        />
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className={wrapperClassNames}>
        <div className={style.detailsWrapper}>
          {renderImage()}
          <div className={style.nameWrapper}>
            <div className={style.headerWrapper}>
              <Skeleton height={45} width={400} />
            </div>
          </div>
        </div>
        {children}
      </div>
    );
  }

  return (
    <div className={wrapperClassNames}>
      <div className={style.detailsWrapper}>
        {renderImage()}
        <div className={style.nameWrapper}>
          <div className={style.headerWrapper}>
            <Header variant='h2' className={style.name}>
              {name}
            </Header>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
