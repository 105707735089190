import classNames from 'classnames/bind';
import useClickAway from 'react-use/esm/useClickAway';
import { forwardRef, memo, useRef, useState, type ForwardedRef } from 'react';
import { useNavigate, type To } from 'react-router-dom';
import type { MouseEvent, PropsWithChildren, ReactNode } from 'react';

import { Button } from '@/components/shared/buttons';
import { Icon } from '@/components/shared/Icon';
import { useDropdownContext } from '@/contexts/shared/DropdownContext';
import { DropdownContext, type DropdownContextValue } from '@/contexts/shared';
import type { DropdownTriggerContext, IconName } from '@/types/shared';

import style from './Dropdown.module.sass';

const cx = classNames.bind(style);

export type VerticalPosition = 'top' | 'bottom' | undefined;
export type HorizontalPosition = 'right' | 'center' | 'left' | undefined;
type Width = 'normal' | 'wide' | 'full';

type DropdownProps = PropsWithChildren<{
  position: [VerticalPosition, HorizontalPosition];
  trigger: ReactNode | ((context: DropdownTriggerContext) => ReactNode);
  width?: Width;
  onToggle?: (isOpen: boolean) => void;
  asComponent?: string;
  popperClassName?: string;
}>;

export type ClickableItemProps = PropsWithChildren<{
  onClick?: () => void;
  closeOnClick?: boolean;
  navigateTo?: To;
  disabled?: boolean;
  iconName?: IconName;
  variant?: 'primary' | 'danger';
  asComponent?: string;
}>;

type CloseHandlerProps = {
  children: (closeMenu: () => void) => JSX.Element;
};

type PopperProps = PropsWithChildren<{
  verticalPosition: VerticalPosition;
  horizontalPosition: HorizontalPosition;
  width?: Width;
  isOpen?: boolean;
  className?: string;
}>;

const CloseHandler = ({
  children,
}: CloseHandlerProps) => {
  const {
    setIsOpen,
  } = useDropdownContext();

  const closeMenu = () => {
    setIsOpen(false);
  };

  return children(closeMenu);
};

const ClickableItem = ({
  onClick,
  children,
  disabled,
  closeOnClick,
  navigateTo,
  iconName,
  variant = 'primary',
  asComponent,
}: ClickableItemProps) => {
  const navigate = useNavigate();

  const {
    setIsOpen,
  } = useDropdownContext();

  const Component = asComponent ? asComponent : Button.Unstyled;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (closeOnClick) {
      setIsOpen(false);
    }

    onClick?.();

    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  const buttonClassName = cx('clickableItem', variant);

  return (
    <Component
      disabled={disabled}
      onClick={handleClick}
      className={buttonClassName}>
      {
        iconName &&
        <Icon name={iconName} className={style.clickableItemIcon} />
      }
      {children}
    </Component>
  );
};

const Popper = memo(forwardRef(({
  children,
  verticalPosition,
  horizontalPosition,
  isOpen,
  width,
  className,
}: PopperProps, ref: ForwardedRef<HTMLDivElement>) => {
  const dropdownClassName = cx(
    'dropdown',
    verticalPosition,
    horizontalPosition,
    width,
    className,
    {
      isOpen,
    },
  );

  return (
    <div className={dropdownClassName} ref={ref}>
      {children}
    </div>
  );
}));

Popper.displayName = 'Popper';

export const Dropdown = ({
  children,
  position,
  trigger,
  width = 'normal',
  onToggle,
  asComponent,
  popperClassName,
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useClickAway(dropdownRef, () => {
    setIsOpen(false);
    onToggle?.(false);
  });

  const [
    verticalPosition = 'bottom',
    horizontalPosition = 'center',
  ] = position;

  const contextValue: DropdownContextValue = {
    isOpen,
    setIsOpen,
  };

  const toggleIsOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setIsOpen((prev) => !prev);
    onToggle?.(!isOpen);
  };

  const renderTrigger = () => {
    if (typeof trigger === 'function') {
      return trigger({
        isOpen,
        setIsOpen,
        toggleIsOpen,
      });
    }

    const Component = asComponent ? asComponent : Button.Unstyled;

    return (
      <Component className={style.trigger} onClick={toggleIsOpen}>
        {trigger}
      </Component>
    );
  };

  return (
    <DropdownContext.Provider value={contextValue}>
      <div ref={dropdownRef} className={style.wrapper}>
        {renderTrigger()}
        <Popper
          className={popperClassName}
          width={width}
          isOpen={isOpen}
          verticalPosition={verticalPosition}
          horizontalPosition={horizontalPosition}>
          {children}
        </Popper>
      </div>
    </DropdownContext.Provider>
  );
};

Dropdown.CloseHandler = CloseHandler;
Dropdown.ClickableItem = ClickableItem;
Dropdown.Popper = Popper;
