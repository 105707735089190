import { UserAuthPageLayout } from '@/components/App/AppLayout/LoginPage/UserAuthPageLayout';
import { ResetPassword } from '@/components/company/user/ResetPassword';

export const ResetPasswordPage = () => {
  return (
    <UserAuthPageLayout>
      <ResetPassword />
    </UserAuthPageLayout>
  );
};
