import { PropsWithChildren } from 'react';

import style from './BulletList.module.sass';

type BulletListProps = PropsWithChildren;
type ItemProps = PropsWithChildren;

export const Item = ({
  children,
}: ItemProps) => {
  return (
    <li>
      {children}
    </li>
  );
};

export const BulletList = ({
  children,
}: BulletListProps) => {
  return (
    <ul className={style.list}>
      {children}
    </ul>
  );
};

BulletList.Item = Item;
