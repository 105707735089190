import { useLocation, useNavigate } from 'react-router-dom';
import { Updater } from 'react-query/types/core/utils';
import {
  useQuery,
  type UseQueryOptions,
  type SetDataOptions,
  type QueryFunction,
  type QueryKey,
} from 'react-query';
import { AxiosError } from 'axios';

import { queryClient } from '@/resources/queryClient';
import { type UseQueryError } from '@/types/shared';

/* eslint-disable indent */

export const useDataQuery = <
  TData,
  TError = UseQueryError<TData>,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TData, TQueryKey>,
  queryOptions?: Omit<UseQueryOptions<TData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (import.meta.env.MODE === 'test') {
    if (queryOptions) queryOptions.suspense = false;
  }

  const useQueryResult = useQuery(
    queryKey,
    queryFn,
    {
      ...queryOptions,
      onError: (err) => {
        const error = err as AxiosError<TError>;

        queryOptions?.onError?.(err);

        if (error.response?.status === 404) {
          navigate('/404', {
            replace: true,
            state: {
              context: pathname,
            },
          });
        }
      },
    },
  );

  const setData = (
    updater: Updater<TData | undefined, TData>,
    options?: SetDataOptions,
  ) => {
    return queryClient.setQueryData<TData>(queryKey, updater, options);
  };

  return {
    ...useQueryResult,
    setData,
  };
};
