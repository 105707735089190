import * as React from "react";
import { forwardRef } from "react";
const SvgGreyScoutLarge = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 56,
  height: 56,
  viewBox: "0 0 56 56",
  fill: "currentColor",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_74_1325)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M20.1418 10.4231C18.7103 16.1086 13.727 19.9924 7.7161 19.9924H7.6405C7.357 19.9973 6.923 20.0107 6.4904 20.0774C2.9631 20.6241 0.176399 23.6802 0.00979876 27.1859C-0.170101 30.9728 2.2064 34.2515 5.7897 35.1608C6.4596 35.3302 7.1309 35.3576 7.8421 35.3864C8.2362 35.4026 8.6436 35.4187 9.0461 35.4623C14.4837 36.0476 18.8566 40.046 19.9794 45.3295C20.0354 45.1834 20.0949 45.0393 20.1579 44.8967C21.1848 42.5567 22.911 40.9785 25.1916 40.2772C25.039 40.2441 24.8892 40.2083 24.7429 40.1689C18.2574 38.4494 14.3192 32.1617 15.5813 25.543C16.5256 20.5932 20.4547 16.6687 25.3267 15.6624C22.8991 14.8178 20.9818 12.8839 20.1425 10.4238L20.1418 10.4231Z",
  fill: "currentColor"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M49.5985 20.3367C49.1666 20.2644 48.7333 20.2454 48.4456 20.2377C42.098 20.1962 36.9292 15.8346 35.8806 9.62976C35.8736 9.589 35.8701 9.54894 35.8638 9.50819C35.7868 8.99662 35.7658 8.48295 35.7476 7.98474C35.7364 7.67837 35.7252 7.37129 35.7007 7.06491C35.3794 3.05954 32.0649 0.0217836 27.9909 0H27.9524C24.1234 0 20.748 3.09046 20.419 6.90751C20.0676 10.9782 22.7731 14.5831 26.712 15.2921C27.244 15.3877 27.7977 15.4031 28.3332 15.4186C28.7518 15.4305 29.1844 15.4432 29.6142 15.4924C29.631 15.4945 29.6471 15.498 29.6639 15.5001C35.0567 16.1452 39.487 20.3283 40.4446 25.692C41.629 32.3255 37.6166 38.5661 31.1122 40.2083C30.5914 40.3397 30.037 40.4297 29.4357 40.4901C28.98 40.5358 28.4991 40.566 27.9692 40.5737C24.5406 40.6243 22.1326 42.1752 20.8124 45.1841C19.4992 48.1741 19.9731 51.039 22.1802 53.4703C24.2123 55.7091 26.8331 56.4821 29.757 55.7056C32.6893 54.9277 34.5611 52.9679 35.3192 49.8809C35.469 49.2703 35.4949 48.6484 35.5005 48.12C35.5061 47.543 35.5523 46.9753 35.6328 46.4187C36.4525 40.7304 41.0235 36.2458 46.8622 35.6872C47.2654 35.6485 47.6728 35.6373 48.0669 35.6267C48.7781 35.6063 49.4494 35.5874 50.1214 35.425C53.7152 34.5593 56.1309 31.3079 55.9944 27.5197C55.8684 24.0118 53.1188 20.9228 49.5985 20.3353V20.3367Z",
  fill: "currentColor"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_74_1325"
}, /* @__PURE__ */ React.createElement("rect", {
  width: 56,
  height: 56,
  fill: "white"
}))));
const ForwardRef = forwardRef(SvgGreyScoutLarge);
export default ForwardRef;
