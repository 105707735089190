import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tabs } from '@/components/shared/Tabs';
import { StoresExportButton } from '@/components/store/StoresExportButton';
import { useResellers, useResellersSortParams } from '@/hooks/resellers';
import { useCompanyStores, useStoresSortParams } from '@/hooks/stores';
import { usePaginationParams } from '@/hooks/shared';
import { usePersistedValue } from '@/hooks/utils';
import {
  getResellersRoute,
  getStoresRoute,
} from '@/utils/routes';

import style from './StoresTabs.module.sass';

export const StoresTabs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    sortBy: storesSortBy,
  } = useStoresSortParams();

  const {
    sortBy: resellersSortBy,
  } = useResellersSortParams();

  const {
    page,
    perPage,
  } = usePaginationParams();

  const paginationParams = {
    page,
    perPage,
  };

  const baseParams = {
    filter: {},
    pagination: paginationParams,
  };

  const isOnStoresPage = pathname === getStoresRoute();

  const persistedCompanyStoresConfig = usePersistedValue({
    ...baseParams,
    sort: {
      sort: storesSortBy,
    },
  });

  const persistedResellersConfig = usePersistedValue({
    ...baseParams,
    sort: {
      sort: resellersSortBy,
    },
  });

  const {
    meta: storesMeta,
    isLoading: isStoresMetaLoading,
  } = useCompanyStores(persistedCompanyStoresConfig);

  const {
    meta: resellersMeta,
    isLoading: isResellersMetaLoading,
  } = useResellers(persistedResellersConfig);

  const storesCount = usePersistedValue(storesMeta?.pagination.totalEntriesUnfiltered);
  const resellersCount = usePersistedValue(resellersMeta?.pagination.totalEntriesUnfiltered);

  const isLoading = isStoresMetaLoading || isResellersMetaLoading;
  const translationContext = isLoading ? undefined : 'withCount';

  const config = [
    {
      isActive: isOnStoresPage,
      handleClick: () => navigate(getStoresRoute()),
      label: t('store:title', {
        context: translationContext,
        count: storesCount,
      }),
      isLoading,
      body: (
        <StoresExportButton />
      ),
    },
    {
      isActive: !isOnStoresPage,
      handleClick: () => navigate(getResellersRoute()),
      label: t('reseller:title', {
        context: translationContext,
        count: resellersCount,
      }),
      isLoading,
      body: null,
    },
  ];

  return (
    <div className={style.tabsWrapper}>
      <Tabs
        config={config}
      />
    </div>
  );
};
