import { useContext, type Context } from 'react';

export const useUnsafeContext = <TValue>(context: Context<TValue>) => {
  const contextValue = useContext(context);

  if (isNonNullable(contextValue)) {
    return contextValue;
  }

  throw new Error('Context not found!');
};

const isNonNullable = <T>(param: T): param is NonNullable<T> => {
  return param !== null && param !== undefined;
};
