import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/shared/Card';
import { Icon } from '@/components/shared/Icon';

import style from './NavigationCard.module.sass';

const cx = classNames.bind(style);

type NavigationCardProps = {
  isDisabled?: boolean;
  onClick?: () => void;
  navigationVariant?: 'nextPage' | 'viewAll';
};

export const NavigationCard = ({
  isDisabled,
  onClick,
  navigationVariant = 'nextPage',
}: NavigationCardProps) => {
  const { t } = useTranslation();

  const cardClassNames = cx({
    isDisabled,
  });

  return (
    <Card
      className={cardClassNames}
      isContextMenuDisabled={true}
      onClick={isDisabled ? undefined : onClick}>
      <div className={style.innerWrapper}>
        <div className={style.textWrapper}>
          {t(`common:${navigationVariant}`)}
          <div className={style.icon}>
            <Icon name='ArrowRight' />
          </div>
        </div>
      </div>
    </Card>
  );
};
