import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import * as yup from 'yup';

import { FormActions } from '@/components/shared/form/FormWrapper/FormActions';
import { VeriformFormFields } from '@/components/veriform/VeriformFormFields';
import { Button, ButtonAsync } from '@/components/shared/buttons';
import { useFormikMutationHandler } from '@/hooks/shared';
import { useUpdateVeriform } from '@/hooks/veriform';
import { useStoreOtherBusinessTypeId } from '@/hooks/stores';
import { Veriform } from '@/types/veriform';

import style from './VeriformForm.module.sass';

import type { MultiStepNavigation } from '../MultiStepFormNavigation';

type VeriformFormProps = {
  values: Veriform;
  id?: string;
  token?: string;
  refetchVeriform: () => Promise<unknown>;
  multiStepNavigation: MultiStepNavigation;
};

const validationSchema = yup.object({
  sourcedFrom: yup.string().required(),
  businessName: yup.string().required(),
});

export const VeriformForm = ({
  values,
  id,
  token,
  refetchVeriform,
  multiStepNavigation,
}: VeriformFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const otherBusinessTypeId = useStoreOtherBusinessTypeId();

  const { mutateAsync: updateVeriformAsync, isLoading } = useUpdateVeriform(
    id,
    token,
    otherBusinessTypeId,
  );

  const initialValues = useMemo(
    () => ({
      businessName: values.attributes?.businessName || '',
      businessTypeId: values.linked?.businessType?.id || '',
      businessTypeOther: values.attributes?.businessTypeOther || '',
      emailAddress: values.attributes?.emailAddress || '',
      phoneNumber: values.attributes?.phoneNumber || '',
      serialNumbers: values.attributes?.serialNumbers?.join(',') || '',
      sourcedFrom: values.attributes?.sourcedFrom || '',
      tradingAs: values.attributes?.tradingAs || '',
      website: values.attributes?.website || '',
      activeChannels: values.attributes?.activeChannels || '',
      additionalAddresses: values.attributes?.additionalAddresses || '',
      addressLine: values.attributes?.addressLine || '',
      taxRegNum: values.attributes?.taxRegNum || '',
      tradeRegNum: values.attributes?.tradeRegNum || '',
      warehouseAddress: values.attributes?.warehouseAddress || '',
      countryId: String(values.attributes?.countryId || ''),
    }),
    [values],
  );

  const onSubmit = useFormikMutationHandler(updateVeriformAsync, {
    onSuccess: () => navigate('confirmation'),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({ errors }) => (
        <Form>
          <VeriformFormFields
            invoices={values.linked?.invoices}
            refetchVeriform={refetchVeriform}
            defaultCountryName={values.linked?.country?.attributes.name}
            errors={errors}
            multiStepNavigation={multiStepNavigation}
          />
          <FormActions>
            {
              multiStepNavigation.isPreviousStepAvailable &&
              <Button
                className={style.button}
                sizeVariant='medium'
                type='button'
                variant='secondary'
                onClick={multiStepNavigation.previousStep}>
                {t('store:veriform.back')}
              </Button>
            }
            {
              multiStepNavigation.isNextStepAvailable &&
              <Button
                className={style.button}
                sizeVariant='medium'
                type='button'
                disabled={Object.keys(errors).length > 0}
                onClick={multiStepNavigation.nextStep}>
                {t('store:veriform.next')}
              </Button>
            }
            {
              !multiStepNavigation.isNextStepAvailable &&
              <ButtonAsync
                className={style.button}
                sizeVariant='medium'
                type='submit'
                isBusy={isLoading}>
                {t('store:veriform.submit')}
              </ButtonAsync>
            }
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};
