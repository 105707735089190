import { PropsWithChildren, useCallback, useMemo, useTransition } from 'react';
import { StringParam, useQueryParam, type UrlUpdateType } from 'use-query-params';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import { ViewMode } from '@/resources/enums';
import { ViewModeContext, ViewModeContextValue } from '@/contexts/shared';
import { DEFAULT_VIEW_MODE, useViewModeStore } from '@/hooks/zustsand/viewMode';

import { extractViewMode } from './utils';

type ViewModeSelectorContextProps = PropsWithChildren<{
  permittedValues: ViewMode[];
  defaultValue?: ViewMode;
}>;

export type SetViewModeOptions = {
  shouldUpdatePreferredMode?: boolean;
  updateType?: UrlUpdateType;
};

export const ViewModeSelectorContext = ({
  permittedValues,
  defaultValue: defaultViewModeValue = DEFAULT_VIEW_MODE,
  children,
}: ViewModeSelectorContextProps) => {
  const {
    preferredViewMode,
    setPreferredViewMode,
    setRecentViewMode,
  } = useViewModeStore();

  const [
    viewModeQueryParam,
    setViewModeParam,
  ] = useQueryParam('view', StringParam);

  const [isChanging, startViewModeTransition] = useTransition();

  const defaultValue = preferredViewMode ?? defaultViewModeValue;
  const viewMode = extractViewMode(viewModeQueryParam, defaultValue);

  useEffectOnce(() => {
    setRecentViewMode(viewMode);
  });

  const setViewMode = useCallback((newViewMode: ViewMode, options?: SetViewModeOptions) => {
    if (isChanging) return;

    startViewModeTransition(() => {
      const {
        updateType,
        shouldUpdatePreferredMode,
      } = options ?? {};

      setRecentViewMode(newViewMode);

      if (shouldUpdatePreferredMode) {
        setPreferredViewMode(newViewMode);
        return;
      }

      setViewModeParam(newViewMode, updateType);
    });
  }, [
    isChanging,
    setPreferredViewMode,
    setViewModeParam,
    setRecentViewMode,
  ]);

  const contextValue = useMemo<ViewModeContextValue>(() => ({
    viewMode,
    permittedValues,
    defaultValue,
    isChanging,
    setViewMode,
  }), [
    viewMode,
    permittedValues,
    defaultValue,
    isChanging,
    setViewMode,
  ]);

  return (
    <ViewModeContext.Provider value={contextValue}>
      {children}
    </ViewModeContext.Provider>
  );
};
