import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries';
import { Channel, ChannelSubdomain } from '@/types/channel';

export const fetchSettingsChannels = async () => {
  const response = await Api.get<Channel[], ChannelSubdomain>('/api/channels');

  const data = linkCollectionRelationships(response);

  return data;
};
