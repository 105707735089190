import { useMemo } from 'react';

import { useBranding } from '@/hooks/branding/useBranding';
import { useConfig } from '@/hooks/shared/useConfig';
import { CompanyType } from '@/resources/enums';

export const useCompanyType = () => {
  const { config } = useConfig();
  const { branding } = useBranding();

  const companyType = useMemo(() => {
    if (!branding) {
      return;
    }

    if (branding.attributes.name === config.VITE_ADMIN_COMPANY_NAME) {
      return CompanyType.Admin;
    }

    return CompanyType.Customer;
  }, [
    branding,
    config.VITE_ADMIN_COMPANY_NAME,
  ]);

  return {
    companyType,
  };
};
