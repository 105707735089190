import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterEntriesContainer } from '@/components/shared/FilterEntriesContainer';
import { LengthyFilterEntry } from '@/components/shared/FilterEntry/LengthyFilterEntry';
import type { FilterEntryProps } from '@/components/shared/FilterEntry';
import { ResellerStoreListingsFiltersProps } from '@/components/resellers/ResellerStoreListings/ResellerStoreListingsFilters';
import { PRODUCT_NAME, ITEM_NUMBER, AGE, BRAND_ID } from '@/resources/queryParams';
import { useBuildClearValue } from '@/hooks/shared';
import { getAgeFilterEntryTranslationKey } from '@/utils/filters/getEntryTranslationKey';
import { getBrandFilterEntry } from '@/utils/brand';
import type { ListingsFilterParams } from '@/types/listing';

type ResellerStoreListingsFilterEntriesProps = {
  filterValues: ListingsFilterParams;
  filterEntriesVariant: FilterEntryProps['variant'];
  aggregationMapping: ResellerStoreListingsFiltersProps['aggregationMapping'];
};

export const ResellerStoreListingsFilterEntries = ({
  filterValues,
  filterEntriesVariant,
  aggregationMapping,
}: ResellerStoreListingsFilterEntriesProps) => {
  const { t } = useTranslation();
  const buildClearValue = useBuildClearValue<ListingsFilterParams>();

  const ageFilterName = useMemo(() => {
    const translationKey = getAgeFilterEntryTranslationKey(filterValues.age);

    if (!translationKey) return filterValues.age;

    return t(translationKey);
  }, [
    t,
    filterValues.age,
  ]);

  const productName = filterValues[PRODUCT_NAME];
  const itemNumber = filterValues[ITEM_NUMBER];

  const brandAgg = aggregationMapping?.brand;

  const filterBrand = useMemo(() => {
    if (!filterValues.brandId || !brandAgg) return;

    return getBrandFilterEntry({
      brandAgg,
      brandId: filterValues.brandId,
      t,
    });
  }, [
    t,
    brandAgg,
    filterValues,
  ]);

  return (
    <FilterEntriesContainer>
      {
        productName &&
        <LengthyFilterEntry
          name={productName}
          entryLabel={t('listing:filterable.labels.productName')}
          onRemove={buildClearValue(PRODUCT_NAME)}
          variant={filterEntriesVariant}
        />
      }
      {
        itemNumber &&
        <LengthyFilterEntry
          name={itemNumber}
          entryLabel={t('listing:filterable.labels.itemNumber')}
          onRemove={buildClearValue(ITEM_NUMBER)}
          variant={filterEntriesVariant}
        />
      }
      {
        filterBrand &&
        <LengthyFilterEntry
          name={filterBrand}
          entryLabel={t('bot:filterable.labels.brand')}
          onRemove={buildClearValue(BRAND_ID)}
          variant={filterEntriesVariant}
        />
      }
      {
        ageFilterName &&
        <LengthyFilterEntry
          name={ageFilterName}
          entryLabel={t('listing:filterable.labels.age.name')}
          onRemove={buildClearValue(AGE)}
          variant={filterEntriesVariant}
        />
      }
    </FilterEntriesContainer>
  );
};
