import { Api } from '@/utils/api';
import type { DeleteVeriformInvoiceValues } from '@/types/veriform';

export const deleteVeriformInvoice = ({
  veriformId,
  invoiceId,
  token,
}: DeleteVeriformInvoiceValues) => {
  return Api.delete(`api/veriforms/${veriformId}/invoices/${invoiceId}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
