import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyCollection } from '@/components/shared/EmptyCollection';
import { BotsGrid } from '@/components/bot/BotsGrid';
import { BotsList } from '@/components/bot/BotsList';
import { type CardContextMenuOption } from '@/components/shared/Card/CardContextMenu';
import activityImage from '@/assets/images/activity.svg';
import { ViewMode } from '@/resources/enums';
import { useRoutePathContext, useViewModeContext } from '@/contexts/shared';
import { useUnsafeContext } from '@/hooks/shared';
import { DeleteBotContext } from '@/contexts/company';
import { determineRbacAccess } from '@/hooks/user/useRbac';
import { useUserSystemRole } from '@/hooks/user';
import type { Bot } from '@/types/bot';

type BotsProps = {
  bots: Bot[];
  pages: number;
  isFilterActive?: boolean;
};

export const Bots = memo(({
  bots,
  pages,
  isFilterActive,
}: BotsProps) => {
  const { t } = useTranslation();

  const userRole = useUserSystemRole();

  const {
    getInstanceBotRoute,
    getInstanceBotEditRoute,
  } = useRoutePathContext();

  const {
    viewMode,
  } = useViewModeContext();

  const {
    openModal,
  } = useUnsafeContext(DeleteBotContext);

  const contextMenu = (bot: Bot) => {
    const isRemoteAdminAllowed = bot.linked?.company?.attributes.allowRemoteAdmin;

    const handleDeleteBot = () => {
      openModal({
        botId: bot.id,
        botName: bot?.attributes.name,
        companyId: bot.linked?.company?.id,
      });
    };

    const canManage = determineRbacAccess({
      rolesWhitelist: [
        'isGsAdmin',
        'isAdmin',
      ],
      isRemoteAdminAllowed,
      companyId: bot.linked?.company?.id,
      userRole,
    });

    const options: CardContextMenuOption[] = [
      {
        iconName: 'ArrowLink',
        to: getInstanceBotRoute(bot.id),
        label: t('common:open'),
      },
      {
        iconName: 'Pen',
        to: getInstanceBotEditRoute(bot.id),
        label: t('common:edit'),
      },
      {
        iconName: 'Trash',
        onClick: handleDeleteBot,
        label: t('common:delete'),
        variant: 'danger',
      },
    ];

    return {
      shouldDisplayPlaceholder: !canManage,
      options,
    };
  };

  if (bots.length === 0) {
    if (isFilterActive) {
      return (
        <EmptyCollection
          header={t('bot:list.noResults')}
          animatedMediaName='MagnifyingGlass'
        />
      );
    }

    return (
      <EmptyCollection
        header={t('bot:list.noBots')}
        subHeader={t('bot:list.noBotsSubheader')}
        imageSource={activityImage}
      />
    );
  }

  if (viewMode === ViewMode.Grid) {
    return (
      <BotsGrid
        bots={bots}
        pages={pages}
        contextMenu={contextMenu}
      />
    );
  }

  return (
    <BotsList
      bots={bots}
      contextMenu={contextMenu}
    />
  );
});

Bots.displayName = 'Bots';
