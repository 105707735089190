import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { UseCompaniesParams } from '@/hooks/company/useCompanies';
import { CompaniesRequestMeta, type Company } from '@/types/company';

export type CompaniesQueryKey = [string, UseCompaniesParams | undefined];

export const fetchCompanies = async (context: QueryFunctionContext<CompaniesQueryKey>) => {
  const [, params = {}] = context.queryKey;

  const {
    pagination,
    sort,
    filter,
  } = params;

  const page = pagination && {
    size: pagination.perPage,
    number: pagination.page,
  };

  const response = await Api.get<
    Company[],
    null,
    CompaniesRequestMeta
  >('/api/companies', {
    params: {
      page,
      sort: sort?.sort,
      filter,
    },
  });

  return {
    companies: response.data,
    meta: response.meta,
  };
};
