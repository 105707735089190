import { getKeys } from '@/utils/object';
import type {
  ApiDataResponse,
  ApiIncludedRecord,
  ApiRecord,
  ApiRecordRelationship,
  ApiRecordRelationshipArray,
  ApiRecordRelationshipData,
} from '@/types/shared';

type AnyApiRecord = ApiRecord<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
type DataResponse<TIncluded extends ApiIncludedRecord> = ApiDataResponse<AnyApiRecord | AnyApiRecord[], TIncluded>; // eslint-disable-line max-len

/* eslint-disable indent */

export const linkCollectionRelationships = <
  TIncluded extends ApiIncludedRecord,
  TResponse extends DataResponse<TIncluded>,
>(
  response: TResponse,
): TResponse['data'] => {
  /* eslint-enable indent */

  const {
    data,
  } = response;

  if (Array.isArray(data)) {
    return data.map((item) => handleItem(item, response));
  }

  return handleItem(data, response);
};

/* eslint-disable indent */

const handleItem = <
  TIncluded extends ApiIncludedRecord,
  TResponse extends DataResponse<TIncluded>,
>(
  item: AnyApiRecord,
  response: TResponse,
) => {
  /* eslint-enable indent */

  const {
    relationships,
  } = item;

  if (!relationships) {
    return item;
  }

  if (!item.linked) {
    item.linked = {};
  }

  const keys = getKeys(relationships);

  for (const key of keys) {
    const relationship = relationships[key];

    if (!relationship) {
      continue;
    }

    item.linked[key] = buildLinkedRelationship(relationship, response);
  }

  return item;
};

/* eslint-disable indent */

const buildLinkedRelationship = <
  TIncluded extends ApiIncludedRecord,
  TResponse extends DataResponse<TIncluded>,
>(
  relationship: ApiRecordRelationship<string> | ApiRecordRelationshipArray<string>,
  response: TResponse,
) => {
  /* eslint-enable indent */

  const relationshipData = relationship.data;

  if (Array.isArray(relationshipData)) {
    const relatedObjects = relationshipData.map((relationshipDatum) => {
      return getRelatedRecord(relationshipDatum, response);
    });

    return relatedObjects.filter(Boolean);
  }

  return getRelatedRecord(relationshipData, response);
};

/* eslint-disable indent */

const getRelatedRecord = <
  TIncluded extends ApiIncludedRecord,
  TResponse extends DataResponse<TIncluded>,
>(
  relationshipDatum: ApiRecordRelationshipData<string>,
  response: TResponse,
) => {
  /* eslint-enable indent */

  if (!relationshipDatum) {
    return;
  }

  const {
    id,
    type,
  } = relationshipDatum;

  const relationshipObject = (response.included as TIncluded[]).find((obj) => {
    return obj.type === type && obj.id == id;
  });

  if (relationshipObject?.relationships) {
    const nestedRelationshipObject = relationshipObject as unknown as AnyApiRecord;

    return handleItem(nestedRelationshipObject, response);
  }

  return relationshipObject;
};
