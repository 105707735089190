import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import sortBy from 'lodash-es/sortBy';

import { DetailsTable } from '@/components/shared/DetailsTable';
import { CountryLogotype } from '@/components/shared/CountryLogotype';
import { StyledLink } from '@/components/shared/StyledLink';
import { isResellerValid } from '@/components/resellers/utils';
import { StoreStatus } from '@/components/store/StoreStatus';
import { UnknownSetting } from '@/components/shared/UnknownSetting';
import { AddressLines } from '@/components/shared/AddressLines';
import { BrandGraphic } from '@/components/brand/BrandGraphic';
import { useIsOnInstance } from '@/hooks/shared';
import { getResellerRoute } from '@/utils/routes';
import { DetailsTableConfig } from '@/types/tables';
import type { Store } from '@/types/store';

import style from './StoreSettings.module.sass';

type StoreSettingsProps = {
  store: Store;
};

const cx = classNames.bind(style);

export const StoreSettings = ({
  store,
}: StoreSettingsProps) => {
  const { t } = useTranslation();
  const { isAdminInstance } = useIsOnInstance();

  const resellerId = store.relationships?.reseller.data?.id;
  const reseller = store.relationships?.reseller?.data;
  const brands = store.linked?.brands;

  const renderUnknownResellerOrLink = () => {
    if (!isResellerValid(reseller)) {
      return <UnknownSetting />;
    }

    if (isAdminInstance) {
      return (
        <div className={style.resellerName}>
          {store.linked?.reseller?.attributes.name}
        </div>
      );
    }

    return (
      <StyledLink to={getResellerRoute(resellerId, store.id)} className={style.resellerName}>
        {store.linked?.reseller?.attributes.name}
      </StyledLink>
    );
  };

  const renderBrandsHeader = () => {
    const brandsCount = store.attributes.brandsCount;

    if (!brandsCount) {
      return t('brand:brands');
    }

    return t('brand:brandsWithCount', { count: brandsCount });
  };

  const renderBrands = () => {
    if (!brands?.length) {
      return t('brand:noBrands');
    }

    const sortedAscBrands = sortBy(brands, 'attributes.name');

    return (
      <div className={style.brandsWrapper}>
        {sortedAscBrands.map((brand) => (
          <BrandGraphic
            key={brand.id}
            logoUrl={brand.attributes.logoUrl}
            name={brand.attributes.name}
          />
        ))}
      </div>
    );
  };

  const config: DetailsTableConfig = {
    rows: [
      {
        header: t('store:attributes.status'),
        body: (
          <div className={style.storeStatusWrapper}>
            <StoreStatus
              status={store.attributes.status}
              allowedUntil={store.linked?.whitelisting?.attributes.until}
            />
          </div>
        ),
        hidden: isAdminInstance,
      },
      {
        header: t('store:attributes.address'),
        body: (
          <AddressLines
            addressLine={store.attributes.addressLine}
            placeholder={<UnknownSetting />}
          />
        ),
      },
      {
        header: t('store:attributes.country'),
        body: (
          <CountryLogotype
            countryName={store.linked?.country?.attributes?.name}
          />
        ),
      },
      {
        header: t('store:attributes.email'),
        body: (
          store.attributes.email || <UnknownSetting />
        ),
      },
      {
        header: t('store:attributes.phoneNumber'),
        className: style.truncated,
        body: (
          store.attributes.phoneNumber
            ? <div className={style.phoneNumber}>{store.attributes.phoneNumber}</div>
            : <UnknownSetting />
        ),
      },
      {
        header: t('store:reseller'),
        body: renderUnknownResellerOrLink(),
      },
      {
        header: renderBrandsHeader(),
        body: renderBrands(),
        hidden: isAdminInstance,
        className: style.brandsColumn,
      },
    ],
    bodySize: 'medium',
  };

  const wrapperClassNames = cx('wrapper', {
    isAdminInstance,
  });

  return (
    <DetailsTable
      config={config}
      wrapperClassName={wrapperClassNames}
    />
  );
};
