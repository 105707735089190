import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import {
  linkCollectionRelationships,
} from '@/utils/queries/linkCollectionRelationships';
import { type UseStoreParams } from '@/hooks/stores/useCompanyStores';
import type {
  StoresRequestMeta,
  Store,
  StoreSubdomain,
  StoreReseller,
  StoreSubdomainChannel,
} from '@/types/store';

export type StoresQueryKey = [string, UseStoreParams | undefined]

type StoresIncludedRecord =
  | StoreSubdomain
  | StoreReseller
  | StoreSubdomainChannel

export const fetchCompanyStores = async (
  context: QueryFunctionContext<StoresQueryKey>,
) => {
  const [, params = {}] = context.queryKey;

  const {
    pagination,
    sort,
    filter,
  } = params;

  const page = pagination && {
    size: pagination.perPage,
    number: pagination.page,
  };

  const response = await Api.get<
    Store[],
    StoresIncludedRecord,
    StoresRequestMeta
  >('api/company_stores', {
    params: {
      page,
      sort: sort?.sort,
      filter,
    },
  });

  const parsedStores = linkCollectionRelationships(response);

  return {
    stores: parsedStores,
    meta: response.meta,
  };
};
