import classNames from 'classnames/bind';
import { ChangeEventHandler, memo, PropsWithChildren, useId } from 'react';

import { Button } from '@/components/shared/buttons';
import { Body } from '@/components/shared/typography/Body';
import { Icon } from '@/components/shared/Icon';
import type { IconName } from '@/types/shared';

import style from './SwitchableButton.module.sass';

const cx = classNames.bind(style);

type SwitchableButtonProps = PropsWithChildren<{
  onChange: ChangeEventHandler<HTMLInputElement>;
  isActive?: boolean;
  iconName?: IconName;
  subtitle?: string;
  className?: string;
}>;

export const SwitchableButton = memo(({
  onChange,
  isActive,
  children,
  subtitle,
  className,
  iconName,
}: SwitchableButtonProps) => {
  const inputId = useId();

  const buttonClassName = cx('button', className, {
    isActive,
  });

  return (
    <>
      <input
        aria-labelledby={inputId}
        name={inputId}
        id={inputId}
        type='checkbox'
        className={style.checkbox}
        onChange={onChange}
        checked={isActive || false}
      />
      <label
        aria-selected={isActive}
        aria-label={inputId}
        className={buttonClassName}
        htmlFor={inputId}>
        {
          iconName &&
          <Button.ButtonIcon className={style.iconWrapper}>
            <Icon name={iconName} />
          </Button.ButtonIcon>
        }
        {children}
        {
          subtitle &&
          <Body size='small' className={style.subtitle}>
            {subtitle}
          </Body>
        }
      </label>
    </>
  );
});

SwitchableButton.displayName = 'SwitchableButton';
