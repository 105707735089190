import 'react-international-phone/style.css';
import { CountrySelector, usePhoneInput } from 'react-international-phone';
import classNames from 'classnames/bind';
import { useField, useFormikContext } from 'formik';
import { useMemo } from 'react';

import { Button } from '@/components/shared/buttons';
import { countryDialCodes } from '@/resources/countryDialCodes';

import style from './TelephoneCountryInput.module.sass';

export interface TelephoneCountryInputProps {
  name: string;
  defaultCountryName?: string;
}

const cx = classNames.bind(style);

export const TelephoneCountryInput = ({
  name,
  defaultCountryName,
}: TelephoneCountryInputProps) => {
  const [
    field,
  ] = useField(name);

  const defaultCountryIso2 = useMemo(() => {
    if (!defaultCountryName) return 'us';
    return countryDialCodes.find((country) => country.name === defaultCountryName)?.iso2 || 'us';
  }, [defaultCountryName]);

  const { setFieldValue } = useFormikContext();

  const phoneInput = usePhoneInput({
    defaultCountry: defaultCountryIso2,
    value: field.value,
    onChange: (data) => {
      setFieldValue(name, data.phone);
    },
  });

  return (
    <div className={style.wrapper}>
      <CountrySelector
        selectedCountry={phoneInput.country}
        onSelect={(country) => phoneInput.setCountry(country.iso2)}
        className={style.countrySelectorWrapper}
        dropdownStyleProps={{
          className: style.countrySelectorDropdown,
          listItemClassName: style.countrySelectorDropdownItem,
          listItemDialCodeClassName: style.countrySelectorDropdownItemDialCode,
          listItemCountryNameClassName: style.countrySelectorDropdownItemCountryName,
        }}
        renderButtonWrapper={({ children, rootProps }) => (
          <Button.Unstyled {...rootProps}>
            {children}
          </Button.Unstyled>
        )}
      />
      <input
        className={cx('input', 'medium')}
        value={phoneInput.phone}
        onChange={phoneInput.handlePhoneValueChange}
      />

    </div>
  );
};
