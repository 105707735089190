import { type TFunction } from 'i18next';

import type { ApiError, FormikSubmitHandlerErrorMessagesMap } from '@/types/shared';

export const translateBaseError = (
  error: ApiError,
  t: TFunction,
  errorMessages: FormikSubmitHandlerErrorMessagesMap,
  errorStatus?: number,
) => {
  if (error.detail === 'Forbidden') {
    return errorMessages.forbidden || t('form:errors.base.forbidden');
  }

  if (error.detail === 'Not Found') {
    return errorMessages.notFound || t('form:errors.base.notFound');
  }

  if (errorStatus) {
    return t('common:errors.somethingWentWrongErrorWithCode', { statusCode: errorStatus });
  }

  return t('common:errors.somethingWentWrongError');
};
