export const downloadFile = (
  downloadUrl: string,
  filename?: string,
) => {
  const link = document.createElement('a');
  link.href = downloadUrl;

  if (filename) {
    link.download = filename;
  }

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};
