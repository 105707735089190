import * as React from "react";
import { forwardRef } from "react";
const SvgInfo = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("circle", {
  cx: 12,
  cy: 12,
  r: 9.5,
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M13.125 15.5H12V11.25H10.875",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M11.6725 8.07383L11.673 8.07339C11.7704 7.97614 11.9283 7.97629 12.0256 8.07372C12.1229 8.17116 12.1229 8.32901 12.0255 8.42639C11.9281 8.52376 11.7703 8.52381 11.6728 8.4265C11.5754 8.32919 11.5753 8.17133 11.6725 8.07383",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgInfo);
export default ForwardRef;
