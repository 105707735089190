import classNames from 'classnames/bind';
import {
  forwardRef,
  memo,
  type PropsWithChildren,
  type CSSProperties,
} from 'react';

import style from './Body.module.sass';

const cx = classNames.bind(style);

export type BodyProps = PropsWithChildren<{
  className?: string;
  size: 'small' | 'base' | 'medium' | 'large';
  style?: CSSProperties;
}>;

export const Body = memo(forwardRef<HTMLDivElement, BodyProps>(({
  children,
  className,
  size,
  style: styleProp,
}, ref) => {
  const bodyClassName = cx('body', size, className);

  return (
    <div
      className={bodyClassName}
      ref={ref}
      style={styleProp}>
      {children}
    </div>
  );
}));

Body.displayName = 'Body';
