import * as React from "react";
import { forwardRef } from "react";
const SvgItalic = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 25,
  height: 25,
  viewBox: "0 0 25 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M14.1811 18.0009H5.45361H9.43383L14.5641 6.00024H18.5443H9.81685",
  stroke: "currentColor",
  strokeWidth: 1.50008,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgItalic);
export default ForwardRef;
