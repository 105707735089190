import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries';
import { TrademarkOffice, TrademarkOfficeIncludedRecord } from '@/types/trademarkOffice';

export const fetchTrademarkOffice = async (context: QueryFunctionContext) => {
  const [, trademarkOfficeId] = context.queryKey;

  const response = await Api.get<TrademarkOffice, TrademarkOfficeIncludedRecord>(`/api/enforceable_areas/${trademarkOfficeId}`);

  const parsedTrademark = linkCollectionRelationships(response);

  return parsedTrademark;
};
