export const findFirst = <TItem, TResult>(
  array: TItem[] | undefined,
  callback: (item: TItem) => TResult | undefined,
) => {
  if (!array) return;

  for (const item of array) {
    const callbackResult = callback(item);

    if (callbackResult) return callbackResult;
  }
};
