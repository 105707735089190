import { GoogleAuthProvider } from '@firebase/auth';

const createGoogleProvider = () => {
  const provider = new GoogleAuthProvider();

  provider.setCustomParameters({
    prompt: 'select_account',
  });

  return provider;
};

export const googleProvider = createGoogleProvider();
