import { QUERY_KEYS } from '@/resources/dataKeys';
import { fetchActiveLoa } from '@/queries/loa';
import { useDataQuery } from '@/hooks/shared';

export const useActiveLoaCheck = (companyId: string | undefined) => {
  const {
    data: activeLoa,
    ...rest
  } = useDataQuery([QUERY_KEYS.loa, companyId], fetchActiveLoa);

  return {
    activeLoa,
    ...rest,
  };
};
