import { type AxiosError } from 'axios';
import { type FormikContextType } from 'formik';
import { type TFunction } from 'i18next';

import { translateBaseError } from '@/utils/formik/translateBaseError';
import { translateFormikError } from '@/utils/formik/translateFormikError';
import type {
  ApiDataError,
  ApiDataErrors,
} from '@/types/shared';

export const getFieldErrorMessage = (
  dataErrors: ApiDataErrors,
  t: TFunction,
) => {
  const apiErrorOrMessage: string | ApiDataError = Object.values(dataErrors.errors)[0];
  const errorData: ApiDataError = typeof apiErrorOrMessage === 'object'
    ? apiErrorOrMessage
    : {
      detail: apiErrorOrMessage,
      title: '',
      source: { pointer: '' },
    };

  const errorMessage = translateFormikError(errorData, t) || translateBaseError(errorData, t, {});

  return errorMessage;
};

const _formikTriggerFieldError = async (
  fieldId: string,
  formikHelpers: FormikContextType<unknown>,
  errorMessage: string,
) => {
  await formikHelpers.setTouched({ [fieldId]: true }, true); // 'await' required in order to properly handle formik error
  formikHelpers.setFieldError(fieldId, errorMessage);
};

export const handleFileUploadFallback = (
  error: AxiosError,
  formikHelpers: FormikContextType<unknown>,
  fieldId: string,
  t: TFunction,
) => {
  (async () => {
    const dataErrors = error.response?.data as ApiDataErrors;

    if (!dataErrors) {
      _formikTriggerFieldError(
        fieldId,
        formikHelpers,
        t('common:errors.somethingWentWrongError'),
      );

      return;
    }

    const errorMessage = getFieldErrorMessage(dataErrors, t);

    _formikTriggerFieldError(
      fieldId,
      formikHelpers,
      errorMessage,
    );
  })();
};
