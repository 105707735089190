import { getCompanyRoute } from '@/utils/routes/company';
import { getBotsRoute } from '@/utils/routes/bot';
import { RouteParam } from '@/types/shared';

export const getKeywordsRoute = (companyId: RouteParam) => {
  return `${getCompanyRoute(companyId)}${getBotsRoute()}/keywords`;
};

export const getKeywordRoute = (companyId: RouteParam, keywordId: RouteParam) => {
  return `${getKeywordsRoute(companyId)}/${keywordId}`;
};

export const getEditKeywordRoute = (companyId: RouteParam, keywordId: RouteParam) => {
  return `${getKeywordRoute(companyId, keywordId)}/edit`;
};

export const getDeleteKeywordRoute = (companyId: RouteParam, keywordId: RouteParam) => {
  return `${getKeywordRoute(companyId, keywordId)}/delete`;
};

export const getCreateMultipleKeywordsRoute = (companyId: RouteParam) => {
  return `${getKeywordsRoute(companyId)}/create/multiple`;
};
