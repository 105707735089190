import classNames from 'classnames/bind';
import { type PropsWithChildren } from 'react';

import { Card } from '@/components/shared/Card';
import { SectionHeader } from '@/components/shared/SectionHeader';

import style from './DashboardChartCard.module.sass';

const cx = classNames.bind(style);

type DashboardChartCardProps = PropsWithChildren<{
  title: string;
  className?: string;
}>;

export const DashboardChartCard = ({
  title,
  className,
  children,
}: DashboardChartCardProps) => {
  const wrapperClassName = cx('wrapper', className);

  return (
    <Card
      isContextMenuDisabled={true}
      className={wrapperClassName}>
      <div className={style.titleWrapper}>
        <SectionHeader title={title} headerSizeVariant='h3' />
      </div>
      {children}
    </Card>
  );
};
