import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { type UseCompanyStoresCsvParams } from '@/hooks/stores';

export type CompanyStoresCsvQueryKey = [string, UseCompanyStoresCsvParams];

export const fetchCompanyStoresCsv = async (
  context: QueryFunctionContext<CompanyStoresCsvQueryKey>,
) => {
  const [, params = {}] = context.queryKey;

  const {
    sort,
    filter,
  } = params;

  const response = await Api.get<BlobPart>('api/company_stores', {
    params: {
      filter,
      sort: sort?.sort,
    },
    responseType: 'blob',
    headers: {
      accept: 'text/csv',
    },
  });

  return response;
};
