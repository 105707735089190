import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/components/shared/Icon';

import styles from './MultiStepFormNavigation.module.sass';

const cx = classNames.bind(styles);

export const MultiStepBreadcrumbs = ({
  currentStep,
  numberOfSteps,
}: {
  currentStep: number;
  numberOfSteps: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {Array.from({ length: numberOfSteps }).map((_, index) => (
        <div className={styles.page} key={index}>
          <span className={cx('circle', { current: index === currentStep })}>
            {index < currentStep ? (
              <Icon name='Done' className={styles.icon} />
            ) : (
              index + 1
            )}
          </span>
          <span className={styles.description}>
            {t('store:veriform.breadcrumbsStepLabel', { step: index + 1 })}
          </span>
          {index !== numberOfSteps - 1 && <span className={styles.line}></span>}
        </div>
      ))}
    </div>
  );
};

