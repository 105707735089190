import { useDataQuery } from '@/hooks/shared';
import { fetchNotificationTemplate } from '@/queries/notificationTemplate';
import { QUERY_KEYS } from '@/resources/dataKeys';
import type { NotificationTemplate } from '@/types/notificationTemplate';

export const useNotificationTemplate = (
  notificationTemplateId: string | undefined,
  companyId: string | undefined,
) => {
  const {
    data: notificationTemplate,
    ...rest
  } = useDataQuery<NotificationTemplate>([
    QUERY_KEYS.notificationTemplate,
    notificationTemplateId,
    companyId,
  ], fetchNotificationTemplate, {
    enabled: Boolean(notificationTemplateId),
  });

  return {
    notificationTemplate,
    ...rest,
  };
};
