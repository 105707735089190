import {
  useContext,
  type ComponentPropsWithoutRef,
  type ComponentType,
  useCallback,
} from 'react';
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  type FieldInputProps,
  type FieldProps,
} from 'formik';
import { useTranslation } from 'react-i18next';

import { FormFieldContext } from '@/contexts/form';

import { getErrorMessage } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FieldComponentProps<T = any> = FieldInputProps<T> & {
  error?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FieldComponent<T = any> = ComponentType<FieldComponentProps<T>>;

type OmittedFieldProps =
  | 'value'
  | 'onChange'
  | 'onBlur';

type ExpectedFieldProps<T> = Omit<T, OmittedFieldProps>;

type FieldRendererProps<
  TComponent extends FieldComponent = FieldComponent,
  TProps extends ComponentPropsWithoutRef<TComponent> = ComponentPropsWithoutRef<TComponent>
> = ExpectedFieldProps<TProps> & {
  name: string;
  errorName?: string;
  Component: TComponent;
  placeholder?: string;
  isArrayField?: boolean;
};

export const FieldRenderer = <TComponent extends FieldComponent>({
  name,
  error,
  errorName = name,
  Component,
  placeholder,
  isArrayField,
  ...restProps
}: FieldRendererProps<TComponent>) => {
  const { t } = useTranslation();
  const {
    isMandatory,
    isDisabled,
  } = useContext(FormFieldContext);

  const renderField =
    useCallback((props: FieldProps<string>, arrayHelpers?: FieldArrayRenderProps) => {
      const ComponentTyped = Component as FieldComponent;

      const enhancedPlaceholder = isMandatory && placeholder
        ? placeholder + t('form:field.tips.required.placeholder')
        : placeholder;

      const propsToPass = {
        ...restProps,
        ...props.field,
        placeholder: enhancedPlaceholder,
        error: error || getErrorMessage(props, errorName),
        disabled: isDisabled,
        ...(arrayHelpers && { arrayHelpers }),
      };

      return (
        <ComponentTyped {...propsToPass} />
      );
    }, [
      error,
      errorName,
      Component,
      isDisabled,
      isMandatory,
      placeholder,
      t,
      restProps,
    ]);

  if (isArrayField) {
    return (
      <FieldArray name={name}>
        {(arrayHelpers) => (
          <Field name={name}>
            {(fieldProps: FieldProps) => renderField(fieldProps, arrayHelpers)}
          </Field>
        )}
      </FieldArray>
    );
  }

  return (
    <Field name={name}>
      {renderField}
    </Field>
  );
};
