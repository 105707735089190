import * as React from "react";
import { forwardRef } from "react";
const SvgGreylist = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z",
  fill: "#F7F7F7"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.00006 8.35568L8.66384 7.98691C8.9951 7.8029 9.20056 7.45374 9.20056 7.0748C9.16132 6.44465 8.62089 5.96426 7.99048 5.99916C7.42719 5.97578 6.92699 6.35656 6.79956 6.90575",
  stroke: "#526662",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 7.99992,
  cy: 7.99998,
  r: 4.50187,
  stroke: "#526662",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.05012 9.95085C8.05009 9.97846 8.02769 10.0008 8.00008 10.0008C7.97247 10.0008 7.9501 9.97842 7.95008 9.95081C7.95007 9.9232 7.97243 9.9008 8.00004 9.90077C8.01333 9.90075 8.02608 9.90602 8.03547 9.91542C8.04487 9.92481 8.05014 9.93756 8.05012 9.95085",
  stroke: "#526662",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgGreylist);
export default ForwardRef;
