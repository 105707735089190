import { createContext } from 'react';

export type FormFieldContextValue = {
  isMandatory?: boolean;
  isDisabled?: boolean;
}

export const FormFieldContext = createContext<FormFieldContextValue>({
  isMandatory: false,
  isDisabled: false,
});
