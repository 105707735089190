import * as React from "react";
import { forwardRef } from "react";
const SvgRedoRichText = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M17.0064 5.00064L20.0064 8.00064L17.0064 11.0006",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.0064 8.00064H11.0064C7.69244 8.00064 5.00644 10.4626 5.00644 13.5006V13.5006C5.00644 16.5386 7.69244 19.0006 11.0064 19.0006H19.0064",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgRedoRichText);
export default ForwardRef;
