import { createContext } from 'react';

export type BotKeywordsModalData = {
  keywordsBadges: JSX.Element[];
  isPositive: boolean;
};

export type BotKeywordsModalContextValue = {
  openModal: (data: BotKeywordsModalData) => void;
  closeModal: () => void;
};

export const BotKeywordsModalContext = createContext<
  BotKeywordsModalContextValue | null
>(null);

