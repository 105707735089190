import { useTranslation } from 'react-i18next';

import { Dropdown, HorizontalPosition, VerticalPosition } from '@/components/shared/Dropdown';
import { Button, ButtonProps } from '@/components/shared/buttons';
import { useRoutePathContext } from '@/contexts/shared';

import style from './CreateBotDropdown.module.sass';

type CreateBotDropdownProps = {
  triggerVariant?: ButtonProps['variant'];
  position?: [VerticalPosition, HorizontalPosition];
  triggerTitle?: string;
};

export const CreateBotDropdown = ({
  triggerTitle,
  triggerVariant,
  position = ['bottom', 'right'],
}: CreateBotDropdownProps) => {
  const { t } = useTranslation();

  const {
    getInstanceBotCreateRoute,
    getInstanceBotCreateMultipleRoute,
  } = useRoutePathContext();

  const dropdownTrigger = (
    <Button
      variant={triggerVariant}
      iconName='ChevronDown'
      className={style.button}
      iconWrapperClassName={style.buttonIcon}>
      {triggerTitle ?? t('common:createNew')}
    </Button>
  );

  return (
    <div>
      <Dropdown
        trigger={dropdownTrigger}
        position={position}
        asComponent='div'>
        <Dropdown.ClickableItem navigateTo={getInstanceBotCreateRoute()}>
          {t('bot:newBot')}
        </Dropdown.ClickableItem>
        <Dropdown.ClickableItem navigateTo={getInstanceBotCreateMultipleRoute()}>
          {t('bot:createAmazonBots')}
        </Dropdown.ClickableItem>
      </Dropdown>
    </div>
  );
};
