import toast from 'react-hot-toast';

import { Notification, type NotificationType, type NotificationProps } from '@/components/shared/Notification';

type NotificationOptions = {
  title?: string;
  body: string;
  duration?: number;
  action?: NotificationProps['action'];
};

const getDefaultDuration = (type: NotificationType) => {
  if (type === 'danger') return 6000;

  return 3500;
};

const buildShowNotification = (type: NotificationType) => ({
  title,
  body,
  action,
  duration = getDefaultDuration(type),
}: NotificationOptions) => {
  return toast.custom((toastProps) => (
    <Notification
      {...toastProps}
      type={type}
      title={title}
      body={body}
      action={action}
    />
  ), {
    duration,
  });
};

export const notification = {
  info: buildShowNotification('info'),
  success: buildShowNotification('success'),
  warning: buildShowNotification('warning'),
  danger: buildShowNotification('danger'),
};
