import { useDataQuery } from '@/hooks/shared';
import { fetchLoa } from '@/queries/loa';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const useLoa = (
  companyId: string | undefined,
  loaId: string | undefined,
) => {
  const {
    data: loa,
    ...rest
  } = useDataQuery([
    QUERY_KEYS.loa,
    companyId,
    loaId,
  ], fetchLoa);

  return {
    loa,
    ...rest,
  };
};
