import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import uniq from 'lodash/uniq';

import { GridContainer } from '@/components/shared/containers/GridContainer';
import { StoreListingGridCard } from '@/components/store/StoreListingGridCard';
import { Button } from '@/components/shared/buttons';
import { useListingsSelectionParams } from '@/hooks/listing';
import { toggle } from '@/utils/array';
import { Listing } from '@/types/listing';

import style from './StoreListingsGrid.module.sass';

type StoreListingsGridProps = {
  listings: Listing[];
  storeStatus: string;
  isSelectionAllowed?: boolean;
}

export const StoreListingsGrid = ({
  listings,
  isSelectionAllowed,
}: StoreListingsGridProps) => {
  const { t } = useTranslation();
  const [lastClickedListingIndex, setLastClickedListingIndex] = useState<null | number>(null);

  const [
    selectedListingIds,
    setSelectedListingIds,
  ] = useListingsSelectionParams();

  const buttonText = useMemo(() => (
    selectedListingIds.length === listings.length
      ? t('common:deselectAll')
      : t('common:selectAll')
  ), [
    t,
    listings.length,
    selectedListingIds.length,
  ]);

  const renderSelectionButton = () => {
    if (!isSelectionAllowed) {
      return null;
    }

    const selectOrDeSelectAll = () => {
      if (selectedListingIds.length === listings.length) {
        setSelectedListingIds([]);

        return;
      }

      setSelectedListingIds(listings.map((listing) => listing.id));
    };

    return (
      <Button
        className={style.selectionButton}
        variant='secondary'
        onClick={selectOrDeSelectAll}>
        {buttonText}
      </Button>
    );
  };

  const renderStoreListingGridCard = (listing: Listing, index: number) => {
    const isListingSelected = selectedListingIds.includes(listing.id);

    const selectListing = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (event.shiftKey) {
        if (lastClickedListingIndex === null || isListingSelected) {
          return;
        }

        const listingsIds = listings.map((item: Listing) => item.id);

        const newSelectedListingsIds = listingsIds.slice(
          Math.min(lastClickedListingIndex, index), Math.max(lastClickedListingIndex, index) + 1,
        );

        setSelectedListingIds(uniq([...selectedListingIds, ...newSelectedListingsIds]));

        return;
      }

      setSelectedListingIds(toggle(selectedListingIds, listing.id));

      const newIndex = isListingSelected ? null : index;
      setLastClickedListingIndex(newIndex);
    };

    return (
      <StoreListingGridCard
        key={listing.id}
        listing={listing}
        onClick={selectListing}
        checked={isListingSelected}
        isSelectionAllowed={isSelectionAllowed}
      />
    );
  };

  return (
    <>
      {renderSelectionButton()}
      <GridContainer>
        {listings.map(renderStoreListingGridCard)}
      </GridContainer>
    </>
  );
};
