import * as React from "react";
import { forwardRef } from "react";
const SvgUnderline = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 25,
  height: 25,
  viewBox: "0 0 25 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M6.00293 20.0012H18.0036",
  stroke: "currentColor",
  strokeWidth: 1.50008,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M17.003 4.00024V11.0006C17.003 13.7618 14.7639 16.0009 12.0027 16.0009C9.24156 16.0009 7.00244 13.7618 7.00244 11.0006V4.00024",
  stroke: "currentColor",
  strokeWidth: 1.50008,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgUnderline);
export default ForwardRef;
