import { useTranslation } from 'react-i18next';

import { Badge } from '@/components/shared/Badge';
import { Tooltip } from '@/components/shared/Tooltip';
import type { Nullable } from '@/types/shared';

type BotRunRowsCountProps = {
  count: Nullable<number>;
};

export const BotRunRowsCount = ({
  count,
}: BotRunRowsCountProps) => {
  const { t } = useTranslation();

  if (typeof count !== 'number') {
    return null;
  }

  const badgeVariant = count === 0 ? 'orange' : 'neutral';
  const tooltipContent = t('bot:run.acceptedRowsTooltip', { count });

  return (
    <Tooltip
      content={tooltipContent}>
      <Badge
        variant={badgeVariant}>
        {count}
      </Badge>
    </Tooltip>
  );
};
