import { type TFunction } from 'i18next';

import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { sortBy } from '@/utils/array';
import { parens } from '@/utils/string';
import type { AggregationEntry } from '@/types/shared';

type GetBrandOptions = {
  t: TFunction;
  brandAgg?: AggregationEntry[];
};

export const getBrandOptions = ({
  t,
  brandAgg,
}: GetBrandOptions) => {
  if (!brandAgg) {
    return [];
  }

  const brands = sortBy(brandAgg, (brand) => brand.label === null ? '' : brand.label);

  return brands?.map((brand) => ({
    value: String(brand.id),
    label: (
      <SelectInput.OptionWithSubtitle
        label={brand.label ?? t('bot:filterable.options.brand.noBrand')}
        subtitle={parens(brand.count)}
      />
    ),
  }));
};
