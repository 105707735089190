import {
  StringParam,
  useQueryParam,
} from 'use-query-params';

import { SORT_BY } from '@/resources/queryParams';

export const useSortParams = () => {
  const [sortBy, updateSortBy] = useQueryParam(SORT_BY, StringParam);

  return {
    sortBy,
    updateSortBy,
  };
};
