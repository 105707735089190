import { useTranslation } from 'react-i18next';

import { GoogleLoginForm } from '@/components/App/AppLayout/LoginPage/GoogleLoginForm';
import { PasswordLoginForm } from '@/components/App/AppLayout/LoginPage/GoogleLoginForm/PasswordLoginForm';
import { CompaniesLogosHeader } from '@/components/shared/CompaniesLogosHeader';
import { BrandingJumbotron } from '@/components/shared/BrandingJumbotron';
import { UserAuthPageLayout } from '@/components/App/AppLayout/LoginPage/UserAuthPageLayout';
import { LoginManagementHeader } from '@/components/shared/LoginManagementHeader';
import { useIsOnInstance } from '@/hooks/shared';
import { AuthMethodType } from '@/resources/enums';
import { useBranding } from '@/hooks/branding';

import style from './LoginPage.module.sass';

type LoginPageProps = {
  forceGoogle?: boolean;
  isUserNotAuthorisedForThisCompanyError?: boolean;
};

export const LoginPage = ({
  forceGoogle,
  isUserNotAuthorisedForThisCompanyError,
}: LoginPageProps) => {
  const { branding } = useBranding();
  const { t } = useTranslation();
  const { isAdminInstance } = useIsOnInstance();

  if (!branding) {
    return null;
  }

  const renderLoginForm = () => {
    if (branding.attributes.authenticationMethod === AuthMethodType.Google
      || forceGoogle) {
      return <GoogleLoginForm />;
    }

    return (
      <PasswordLoginForm
        isUserNotAuthorisedForThisCompanyError={isUserNotAuthorisedForThisCompanyError}
      />
    );
  };

  const renderBrandHeader = () => {
    if (!isAdminInstance) {
      return <CompaniesLogosHeader />;
    }

    return (
      <div className={style.brandingJumbotronWrapper}>
        <BrandingJumbotron />
      </div>
    );
  };

  return (
    <UserAuthPageLayout>
      {renderBrandHeader()}
      <LoginManagementHeader
        title={t('loginPage:welcomeBack')}
        subtitle={t('loginPage:pleaseSignIn')}
      />
      {renderLoginForm()}
    </UserAuthPageLayout>
  );
};
