import { Formik } from 'formik';

import { ResellerStoreListingsFilters, type ResellerStoreListingsFiltersProps } from '@/components/resellers/ResellerStoreListings/ResellerStoreListingsFilters';
import { FormikForm } from '@/components/shared/formik/FormikForm';
import { ResellerStoreListingsFilterEntries } from '@/components/resellers/ResellerStoreListings/ResellerStoreListingsFilterEntries';
import type { FilterEntryProps } from '@/components/shared/FilterEntry';
import { ViewModeSelector } from '@/components/shared/viewMode/ViewModeSelector';
import { FilteringResultsCount } from '@/components/shared/FilteringResultsCount';
import { useResellerStoresListingsParams } from '@/hooks/resellers';
import { EMPTY_ARRAY } from '@/resources/constants';
import { useListingsSelectionParams } from '@/hooks/listing';
import { PAGE } from '@/resources/queryParams';
import type { ListingsFilterParams } from '@/types/listing';

import style from './ResellerStoreListingsToolbar.module.sass';
import { ResellerStoreListingsSortSelect } from './ResellerStoreListingsSortSelect';

type ResellerStoreListingsToolbarProps = {
  filterValues: ListingsFilterParams;
  aggregationMapping: ResellerStoreListingsFiltersProps['aggregationMapping'];
  disableFilters?: boolean;
  filterEntriesVariant?: FilterEntryProps['variant'];
  totalEntries: number | undefined;
};

export const ResellerStoreListingsToolbar = ({
  filterValues,
  disableFilters,
  aggregationMapping,
  filterEntriesVariant,
  totalEntries,
}: ResellerStoreListingsToolbarProps) => {
  const {
    setValues,
  } = useResellerStoresListingsParams();

  const [, setSelectedListingIds] = useListingsSelectionParams();

  const updateQueryParams = (values: ListingsFilterParams) => {
    if (values.age === '') {
      values.age = undefined;
    }

    setValues({ ...values, [PAGE]: undefined });
    setSelectedListingIds(EMPTY_ARRAY);
  };

  return (
    <Formik
      validateOnBlur={false}
      enableReinitialize={true}
      validate={updateQueryParams}
      onSubmit={updateQueryParams}
      initialValues={filterValues}>
      <FormikForm
        className={style.wrapper}>
        <div className={style.mainToolbar}>
          <ResellerStoreListingsFilters
            aggregationMapping={aggregationMapping}
            disabled={disableFilters}
          />
          <div className={style.toolbarAside}>
            <ResellerStoreListingsSortSelect />
            <ViewModeSelector />
          </div>
        </div>
        <ResellerStoreListingsFilterEntries
          filterValues={filterValues}
          filterEntriesVariant={filterEntriesVariant}
          aggregationMapping={aggregationMapping}
        />
        <FilteringResultsCount
          values={filterValues}
          totalEntries={totalEntries}
        />
      </FormikForm>
    </Formik>
  );
};
