import { RefObject, useEffect, useState } from 'react';

export const useScrollableElementProperties = (
  ref: RefObject<HTMLDivElement>,
  scrollOffset = 0,
) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const { scrollTop, scrollHeight, clientHeight } = ref.current;

        const isAtBottom =
          scrollTop
          + clientHeight
          >= scrollHeight
          - scrollOffset;

        setIsScrolledToBottom(isAtBottom);
      }
    };

    const currentRef = ref.current;

    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef?.removeEventListener('scroll', handleScroll);
      }
    };
  }, [
    ref,
    scrollOffset,
  ]);

  useEffect(() => {
    const checkScrollable = () => {
      if (ref.current) {
        const { scrollHeight, clientHeight } = ref.current;
        const isContainerScrollable = scrollHeight > clientHeight;

        setIsScrollable(isContainerScrollable);
      }
    };

    checkScrollable();

    window.addEventListener('resize', checkScrollable);

    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, [ref]);

  return {
    isScrolledToBottom,
    isScrollable,
  };
};
