import {
  ensureIsDate,
  isNotValidDate,
} from './index';

type TimeFormat = Intl.DateTimeFormatOptions['timeStyle'];

export const formatTime = (date: Date | string, locale: string, format: TimeFormat = 'short') => {
  const dateObject = ensureIsDate(date);

  if (isNotValidDate(dateObject)) {
    return '';
  }

  const formatter = new Intl.DateTimeFormat(locale, {
    timeStyle: format,
  });

  return formatter.format(dateObject);
};
