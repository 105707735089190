import classNames from 'classnames/bind';

import { IconBox } from '@/components/shared/IconBox';
import type { IconName } from '@/types/shared';

import style from './FallbackIconBox.module.sass';

const cx = classNames.bind(style);

type FallbackIconBoxProps = {
  iconName?: IconName;
  wrapperClassName?: string;
};

export const FallbackIconBox = ({
  iconName = 'ImageAccess',
  wrapperClassName,
}: FallbackIconBoxProps) => {
  const wrapperClassNames = cx('wrapper', wrapperClassName);

  return (
    <IconBox
      wrapperClassName={wrapperClassNames}
      iconName={iconName}
    />
  );
};
