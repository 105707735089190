import { type UseQueryResult } from 'react-query';

export const extractQueriesError = (results: UseQueryResult[]) => {
  const failedResult = results.find((result) => result.error);
  const error = failedResult?.error;

  if (!error) return;

  if (error instanceof Error) return error;

  return new Error();
};
