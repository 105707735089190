import classNames from 'classnames/bind';
import toast, { type Toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { type MouseEventHandler } from 'react';

import { Body, type BodyProps } from '@/components/shared/typography/Body';
import { Header } from '@/components/shared/typography/Header';
import { Icon } from '@/components/shared/Icon';
import { Button, type ButtonProps } from '@/components/shared/buttons';

import style from './Notification.module.sass';
import { getDefaultTitle, getIconName } from './utils';

const cx = classNames.bind(style);

export type NotificationType = 'success' | 'danger' | 'warning' | 'info';

type OmittedToastProps = 'type' | 'message' | 'icon' | 'iconType';
type ToastProps = Omit<Toast, OmittedToastProps>;

export type NotificationProps = ToastProps & {
  type: NotificationType;
  title?: string;
  body: string;
  bodySize?: BodyProps['size'];
  action?: {
    size: ButtonProps['sizeVariant'];
    variant: ButtonProps['variant'];
    text: ButtonProps['children'];
    onClick: MouseEventHandler;
  };
};

export const Notification = ({
  type,
  title,
  body,
  visible,
  id,
  ariaProps,
  action,
}: NotificationProps) => {
  const { t } = useTranslation();

  const wrapperClassName = cx('box', type, {
    fadeOut: !visible,
  });

  const dismiss = () => {
    toast.dismiss(id);
  };

  const iconName = getIconName(type);
  const defaultTitle = getDefaultTitle(type, t);

  const renderAction = () => {
    if (!action) return;

    return (
      <Button
        sizeVariant={action.size}
        variant={action.variant}
        onClick={action.onClick}>
        {action.text}
      </Button>
    );
  };

  return (
    <div className={wrapperClassName} data-cy='toastNotification'>
      <div className={style.iconWrapper}>
        <Icon name={iconName} className={style.icon} />
      </div>
      <div className={style.contentWrapper} {...ariaProps}>
        <Header variant='h4' className={style.title}>
          {title || defaultTitle}
        </Header>
        <Body size='base' className={style.body}>
          {body}
        </Body>
        {renderAction()}
      </div>
      <Button.Unstyled className={style.closeButton} onClick={dismiss}>
        <Icon name='Delete' />
      </Button.Unstyled>
    </div>
  );
};
