import qs from 'qs';

import { getStoresRoute } from '@/utils/routes/store';
import { STORE_ID } from '@/resources/queryParams';
import type { RouteParam } from '@/types/shared';

export const getResellersRoute = () => {
  return `${getStoresRoute()}/parent-entities`;
};

export const getResellerRoute = (resellerId: RouteParam, storeId?: RouteParam) => {
  const queryString = qs.stringify({ [STORE_ID]: storeId });
  const baseRoute = `${getResellersRoute()}/${resellerId}`;

  if (!queryString) return baseRoute;

  return baseRoute + '?' + queryString;
};
