import classNames from 'classnames/bind';

import { Tooltip } from '@/components/shared/Tooltip';
import { Body } from '@/components/shared/typography/Body';
import { RadioInput } from '@/components/shared/form/inputs/RadioInput';

import style from './BodyTableRadio.module.sass';

const cx = classNames.bind(style);

type BodyTableRadioProps = {
  id: string;
  selectedIds: string;
  disabled?: boolean;
  className?: string;
  disabledTooltip?: string;
  isHovered?: boolean;
  onChange: () => void;
}

export const BodyTableRadio = ({
  disabled,
  className,
  disabledTooltip,
  isHovered,
  id,
  selectedIds,
  onChange,
}: BodyTableRadioProps) => {
  const isChecked = selectedIds === id;

  const wrapperClassName = cx('wrapper', className);

  return (
    <td className={wrapperClassName}>
      <div className={style.innerWrapper}>
        <Tooltip
          placement='top'
          startDelay={300}
          disabled={!disabled || !disabledTooltip}
          content={
            <Body size='base'>
              {disabledTooltip}
            </Body>
          }>
          <div>
            <RadioInput
              value={id}
              checked={isChecked}
              isHovered={isHovered}
              onChange={onChange}
            />
          </div>
        </Tooltip>
      </div>
    </td>
  );
};
