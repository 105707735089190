import { createContext } from 'react';

export type AllowStoreModalData = {
  storeName: string;
  storeId: string | undefined;
  companyId: string | undefined;
  storeSubdomain?: string;
  storeSubdomainUrl?: string;
  resellerId: string | undefined;
  whitelistingId: string | undefined;
}

export type AllowStoreContextValue = {
  openModal: (data: AllowStoreModalData) => void;
  closeModal: () => void;
}

export const AllowStoreContext = createContext<AllowStoreContextValue | null>(null);
