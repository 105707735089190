import classNames from 'classnames/bind';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { FieldRenderer } from '@/components/shared/form/FieldRenderer';
import { FieldWrapper } from '@/components/shared/form/FieldWrapper';
import { PasswordInput } from '@/components/shared/form/inputs/PasswordInput';
import { NewPasswordFormFormValue, PasswordPolicyErrors } from '@/types/user';

import style from './NewPasswordFields.module.sass';
import { getPasswordRequirements } from './utils';

const cx = classNames.bind(style);

type NewPasswordFormProps = {
  policyErrors?: PasswordPolicyErrors;
};

export const NewPasswordFields = ({
  policyErrors,
}: NewPasswordFormProps) => {
  const { t } = useTranslation();

  const {
    values: {
      newPassword,
    },
    setErrors,
  } = useFormikContext<NewPasswordFormFormValue>();

  useEffect(() => {
    if (policyErrors?.other) {
      setErrors({ newPassword: policyErrors?.other });
    }
  }, [
    policyErrors?.other,
    setErrors,
  ]);

  const passwordRequirements = getPasswordRequirements(newPassword, t, policyErrors);

  const renderRequirement = (
    {
      label,
      isMeetingRequirement,
      hasError,
    }: typeof passwordRequirements[0],
    index: number,
  ) => {
    const requirementClassNames = cx('requirement', {
      isMeetingRequirement,
      hasError,
    });

    return (
      <span key={index} className={requirementClassNames}>
        {label}
      </span>
    );
  };

  return (
    <div className={style.wrapper}>
      <FieldWrapper
        wrapperClassName={style.newPasswordField}
        name='newPassword'
        label={t('loginPage:resetPassword.newPassword')}
        customError={
          <div className={style.requirementsWrapper}>
            {passwordRequirements.map(renderRequirement)}
          </div>
        }>
        <FieldRenderer
          name='newPassword'
          errorName='newPassword'
          error={!!policyErrors?.other}
          Component={PasswordInput}
          sizeVariant='medium'
          placeholder={t('loginPage:resetPassword.enterNewPassword')}
        />
      </FieldWrapper>
      <FieldWrapper
        name='confirmPassword'
        label={t('loginPage:resetPassword.confirmPassword')}>
        <FieldRenderer
          name='confirmPassword'
          Component={PasswordInput}
          sizeVariant='medium'
          placeholder={t('loginPage:resetPassword.confirmNewPassword')}
        />
      </FieldWrapper>
    </div>
  );
};
