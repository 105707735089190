import classNames from 'classnames/bind';
import { Navigation, Swiper as SwiperCore } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  useMemo,
  useRef,
  useState,
  type ReactNode,
  useEffect,
} from 'react';

import { Icon } from '@/components/shared/Icon';
import type { CarouselItemsPerBreakpoint } from '@/types/shared';

import style from './Carousel.module.sass';
import { useEnsureActiveSlideVisibility } from './hooks';
import { handleKeyDown } from './utils';

const cx = classNames.bind(style);

const VIEW_ALL_CARD_AT_GROUP = 4;
const CAROUSEL_BREAKPOINT_CONFIG = {
  sm: 2,
  md: 4,
  lg: 5,
  xl: 6,
};
export const CAROUSEL_MAX_ITEMS_VISIBLE = CAROUSEL_BREAKPOINT_CONFIG.xl * 4 - 1;

type CarouselProps = {
  items: ReactNode[];
  itemsPerBreakpoint?: CarouselItemsPerBreakpoint;
  activeSlideIndex?: number;
  hasPadding?: boolean;
  prevIconClassName?: string;
  nextIconClassName?: string;
  wrapperClassName?: string;
  swiperClassName?: string;
  itemClassName?: string;
  navigationVariant?: 'default' | 'primary';
  allowArrowKeysSliding?: boolean;
  paginationConfig?: {
    paginationWrapperClassName: string;
    paginationItemClassName: string;
    activePaginationItemClassName: string;
  };
  viewAllCard?: JSX.Element;
};

export const Carousel = ({
  items,
  activeSlideIndex,
  hasPadding = true,
  prevIconClassName,
  nextIconClassName,
  allowArrowKeysSliding,
  navigationVariant = 'default',
  itemsPerBreakpoint = CAROUSEL_BREAKPOINT_CONFIG,
  wrapperClassName,
  swiperClassName,
  itemClassName,
  paginationConfig,
  viewAllCard,
}: CarouselProps) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [currentIndex, setCurrentIndex] = useState(activeSlideIndex ?? 0);
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  const paginationWrapperRef = useRef<HTMLDivElement>(null);

  useEnsureActiveSlideVisibility(swiper, activeSlideIndex);

  useEffect(() => {
    if (allowArrowKeysSliding) {
      window.addEventListener('keydown', handleKeyDown(swiper));

      return () => {
        window.removeEventListener('keydown', handleKeyDown(swiper));
      };
    }
  }, [
    swiper,
    allowArrowKeysSliding,
  ]);

  const breakpointsConfig = useMemo(() => ({
    0: {
      slidesPerView: itemsPerBreakpoint.sm,
      slidesPerGroup: itemsPerBreakpoint.sm,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: itemsPerBreakpoint.md,
      slidesPerGroup: itemsPerBreakpoint.md,
      spaceBetween: 16,
    },
    1280: {
      slidesPerView: itemsPerBreakpoint.lg,
      slidesPerGroup: itemsPerBreakpoint.lg,
      spaceBetween: 32,
    },
    1920: {
      slidesPerView: itemsPerBreakpoint.xl,
      slidesPerGroup: itemsPerBreakpoint.xl,
      spaceBetween: 32,
    },
  }), [
    itemsPerBreakpoint,
  ]);

  const currentBreakpoint = swiper?.currentBreakpoint as keyof typeof breakpointsConfig;

  const { slidesPerView } = breakpointsConfig?.[currentBreakpoint] ?? {};
  const viewAllCardPlaceIndex = VIEW_ALL_CARD_AT_GROUP * slidesPerView - 1;
  const shouldRenderViewAllCard = viewAllCard && viewAllCardPlaceIndex < items.length;

  const viewAllCardComponent = useMemo(() => (
    <div className={style.viewAllCard} key='view-all'>
      {viewAllCard}
    </div>
  ), [
    viewAllCard,
  ]);

  const content = useMemo(() => {
    const slides = shouldRenderViewAllCard
      ? [...items.slice(0, viewAllCardPlaceIndex), viewAllCardComponent]
      : items;

    const result = slides.map((item, index) => {
      return (
        <SwiperSlide key={index} className={itemClassName}>
          {item}
        </SwiperSlide>
      );
    });

    return result;
  }, [
    items,
    itemClassName,
    viewAllCardPlaceIndex,
    shouldRenderViewAllCard,
    viewAllCardComponent,
  ]);

  const navigateNextIconClassNames = cx(
    'navIcon',
    'nextIcon',
    navigationVariant,
    prevIconClassName,
  );
  const navigatePrevIconClassNames = cx(
    'navIcon',
    'prevIcon',
    navigationVariant,
    nextIconClassName,
  );
  const swiperContainerClassNames = cx('swiperContainer', wrapperClassName, {
    hasPadding,
  });

  const handleSlideChange = ({ activeIndex }: SwiperCore) => {
    setCurrentIndex(activeIndex);

    if (paginationConfig) {
      const paginationItem = paginationWrapperRef?.current?.children?.[activeIndex];

      if (paginationItem) {
        paginationItem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  };

  const renderPagination = () => {
    if (!paginationConfig || items?.length === 1) {
      return null;
    }

    const handlePaginationClick = (index: number) => {
      if (swiper) {
        swiper.slideTo(index);
      }
    };

    const {
      paginationWrapperClassName,
      paginationItemClassName,
      activePaginationItemClassName,
    } = paginationConfig;

    return (
      <div
        className={paginationWrapperClassName}
        ref={paginationWrapperRef}>
        {
          [...Array.from(items)]
            .map((_, index) => {
              const itemClassNames = cx(paginationItemClassName, {
                [activePaginationItemClassName]: index === currentIndex,
              });

              return (
                <div
                  key={index}
                  onClick={() => handlePaginationClick(index)}
                  className={itemClassNames}>
                  {items[index]}
                </div>
              );
            })
        }
      </div>
    );
  };

  return (
    <div className={swiperContainerClassNames}>
      <div
        ref={navigationPrevRef}
        className={navigatePrevIconClassNames}>
        <Icon name={navigationVariant === 'default' ? 'ArrowLeft' : 'ChevronLeft'} />
      </div>
      <Swiper
        initialSlide={activeSlideIndex}
        onSlideChange={handleSlideChange}
        className={swiperClassName}
        onSwiper={setSwiper}
        touchStartPreventDefault={false}
        modules={[Navigation]}
        navigation={{
          prevEl: navigationPrevRef?.current,
          nextEl: navigationNextRef?.current,
        }}
        pagination={{ clickable: true }}
        breakpoints={breakpointsConfig}>
        {content}
      </Swiper>
      {renderPagination()}
      <div
        ref={navigationNextRef}
        className={navigateNextIconClassNames}>
        <Icon name={navigationVariant === 'default' ? 'ArrowRight' : 'ChevronRight'} />
      </div>
    </div>
  );
};
