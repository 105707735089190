import { useParams } from 'react-router-dom';
import useUnmount from 'react-use/esm/useUnmount';

import { StoreDetails } from '@/components/store/StoreDetails';
import { WithQueryLoader } from '@/components/shared/WithQueryLoader';
import { ResellerStoreListings } from '@/components/resellers/ResellerStoreListings';
import { Container } from '@/components/shared/containers/Container';
import { SectionBackground } from '@/components/shared/SectionBackground';
import { ViewModeSelectorContext } from '@/components/shared/viewMode/ViewModeSelectorContext';
import { useCompanyStore } from '@/hooks/stores';
import { useUnsafeContext } from '@/hooks/shared';
import { AllowStoreContext } from '@/contexts/store/AllowStoreContext';
import { ViewMode } from '@/resources/enums';
import { useSetDefaultListingsAgeFilter } from '@/hooks/shared';
import { getStoreRoute } from '@/utils/routes';

import style from './CustomerStorePage.module.sass';

const permittedValues = [ViewMode.Grid, ViewMode.List];

export const CustomerStorePage = () => {
  const { storeId } = useParams();

  const {
    store,
    isLoading: isStoreLoading,
    error: storeError,
  } = useCompanyStore(storeId);

  const {
    closeModal,
  } = useUnsafeContext(AllowStoreContext);

  useUnmount(() => closeModal());

  const {
    initialListingsMetaError,
    isInitialListingsMetaLoading,
    hasLoaded,
  } = useSetDefaultListingsAgeFilter(
    {
      storeId,
      navigationLink: `${getStoreRoute(storeId)}?`,
    },
  );

  const error = storeError || initialListingsMetaError;
  const isLoading = isStoreLoading || isInitialListingsMetaLoading;

  const renderContent = () => {
    if (!store || !hasLoaded) return null;

    return (
      <>
        <div className={style.detailsWrapper}>
          <StoreDetails store={store} />
        </div>
        <SectionBackground>
          <Container>
            <ViewModeSelectorContext permittedValues={permittedValues}>
              <ResellerStoreListings
                store={store}
                filterEntriesVariant='white'
              />
            </ViewModeSelectorContext>
          </Container>
        </SectionBackground>
      </>
    );
  };

  return (
    <WithQueryLoader
      isLoading={isLoading}
      error={error}>
      {renderContent()}
    </WithQueryLoader>
  );
};
