import * as React from "react";
import { forwardRef } from "react";
const SvgAllowlist = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z",
  fill: "#ECF9EF"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M11.7335 5.3429L7.99997 9.06294L6.48083 7.54381",
  stroke: "#51A461",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12.1437 7.05813C12.5977 9.05446 11.5646 11.0909 9.68543 11.9035C7.80631 12.7161 5.6149 12.0741 4.47126 10.376C3.32763 8.67795 3.55655 6.40595 5.01595 4.97013C6.47535 3.53431 8.75078 3.34243 10.43 4.51357",
  stroke: "#51A461",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgAllowlist);
export default ForwardRef;
