import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { CountryLogotype } from '@/components/shared/CountryLogotype';
import { sortBy } from '@/utils/array';
import { parens } from '@/utils/string';
import type { AggregationEntry } from '@/types/shared';

type GetCountryOptionsProps = {
  countryAgg: AggregationEntry[];
  optionWrapperClassName: string;
};

export const getCountryOptions = ({
  countryAgg,
  optionWrapperClassName,
}: GetCountryOptionsProps) => {
  const countries = sortBy(countryAgg, 'label');

  return countries.map((country) => ({
    value: String(country.id),
    label: (
      <div className={optionWrapperClassName}>
        <SelectInput.OptionWithSubtitle
          label={
            <CountryLogotype countryName={String(country.label)} />
          }
          subtitle={parens(country.count)}
        />
      </div>
    ),
  }));
};
