import qs from 'qs';

import { STORE_ID } from '@/resources/queryParams';
import { getEnforcementsRoute } from '@/utils/routes/enforcement';
import type { RouteParam } from '@/types/shared';

export const getTestPurchasesRoute = () => {
  return `${getEnforcementsRoute()}/test-purchases`;
};

export const getTestPurchaseRoute = (testPurchaseId: RouteParam) => {
  return `${getTestPurchasesRoute()}/${testPurchaseId}`;
};

export const getCreateTestPurchaseRoute = (
  storeId: RouteParam,
) => {
  const baseRoute = `${getTestPurchasesRoute()}/create`;
  const queryString = qs.stringify({
    [STORE_ID]: storeId,
  });

  return baseRoute + '?' + queryString;
};

export const getEditTestPurchaseRoute = (testPurchaseId: RouteParam) => {
  return `${getTestPurchaseRoute(testPurchaseId)}/edit`;
};
