import classNames from 'classnames/bind';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { type CategoricalChartState } from 'recharts/types/chart/types';
import { DataKey } from 'recharts/types/util/types';
import { useNavigate } from 'react-router-dom';

import { Body } from '@/components/shared/typography/Body';
import { EmptyCollection } from '@/components/shared/EmptyCollection';
import { BotRunPlotDataEntry } from '@/components/bot/BotDetails/BotRunsActivity/BotRunsActivity';
import { getBotRunLegendLabel } from '@/components/bot/BotDetails/BotRunsActivity/utils';
import { BotRunsActivityTooltip } from '@/components/bot/BotDetails/BotRunsActivity/BotRunsActivityTooltip';
import { COLORS } from '@/resources/styles';
import { formatDateStyle } from '@/utils/date';
import { toggle } from '@/utils/array';
import { formatBotDurationToMinutes } from '@/utils/bot/formatBotDuration';
import { useIsOnInstance } from '@/hooks/shared';
import { useRoutePathContext } from '@/contexts/shared';

import style from './BotRunsActivityChart.module.sass';

const cx = classNames.bind(style);

type BotRunsActivityChartProps = {
  plotData: BotRunPlotDataEntry[];
  linesSettings: {
    label: string;
    color: string;
  }[];
  isLegendVisible?: boolean;
  isYAxisFormatted?: boolean;
};

export const BotRunsActivityChart = ({
  plotData,
  isLegendVisible,
  linesSettings,
  isYAxisFormatted,
}: BotRunsActivityChartProps) => {
  const { t, i18n } = useTranslation();
  const { isCustomerInstance } = useIsOnInstance();
  const [disabledLines, setDisabledLines] = useState<string[]>([]);

  const navigate = useNavigate();

  const {
    getInstanceBotRunRoute,
  } = useRoutePathContext();

  const chartWrapperClassName = cx('chartWrapper', { isCustomerInstance });

  if (plotData.length < 2) {
    return (
      <EmptyCollection
        header={t('dashboard:activity.noActivityHeader')}
        subHeader={t('dashboard:activity.noActivitySubheader')}
        wrapperClassName={style.emptyActivity}
      />
    );
  }

  const handleClickLegendElement = (dataKey?: DataKey<string>) => {
    const areAllLinesDisabled = disabledLines.length === linesSettings.length - 1;
    const results = toggle(
      disabledLines,
      (dataKey ?? '') as string,
      {
        disableConcatWhen: areAllLinesDisabled,
      },
    );

    setDisabledLines(results);
  };

  const renderLine = (name: string, color: string) => (
    <Line
      key={`${name}-${disabledLines.length}`}
      hide={disabledLines.includes(name)}
      type='monotone'
      dataKey={name}
      stroke={color}
      strokeWidth={1.5}
      dot={{
        fill: 'white',
        stroke: color,
        radius: 2,
      }}
      activeDot={{
        fill: 'white',
        stroke: color,
        radius: 3,
      }}
    />
  );

  const dateFormatter = (value: string) => {
    return formatDateStyle(value, i18n.language, {
      month: 'short',
      day: '2-digit',
    });
  };

  const tooltipContentRenderer = (props: TooltipProps<number, string>) => (
    <BotRunsActivityTooltip {...props} />
  );

  const handleClickChart = (data: CategoricalChartState) => {
    const activePayload = data?.activePayload;

    if (!activePayload?.length) {
      return;
    }
    const runId = activePayload[0].payload.runId;

    navigate(getInstanceBotRunRoute(runId));
  };

  const handleYAxisFormat = (value: string) => {
    if (!isYAxisFormatted) {
      return value;
    }

    return formatBotDurationToMinutes(+value);
  };

  return (
    <ResponsiveContainer
      height='100%'
      minHeight={400}
      className={chartWrapperClassName}>
      <LineChart
        height={400}
        data={plotData}
        onClick={isCustomerInstance ? undefined : handleClickChart}
        margin={{
          top: 64,
          right: 48,
          left: 24,
          bottom: 78,
        }}>
        <CartesianGrid stroke={COLORS.PRIMARY_200} vertical={false} />
        <XAxis
          dataKey='name'
          axisLine={{
            strokeWidth: 1,
            stroke: COLORS.PRIMARY_200,
          }}
          tickLine={false}
          tickMargin={16}
          fontSize={12}
          tickFormatter={dateFormatter}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          fontSize={12}
          tickCount={10}
          allowDecimals={false}
          tickFormatter={handleYAxisFormat}
        />
        <Tooltip
          content={tooltipContentRenderer}
          isAnimationActive={false}
          cursor={{
            stroke: COLORS.PRIMARY_300,
          }}
        />
        {
          isLegendVisible &&
          <Legend
            onClick={({ dataKey }) => handleClickLegendElement(dataKey)}
            iconSize={12}
            iconType='circle'
            wrapperStyle={{ bottom: 40 }}
            formatter={(_, entry) => (
              <Body size='small' className={style.legendLabel}>
                {getBotRunLegendLabel(entry.value, t)}
              </Body>
            )}
          />
        }
        {linesSettings.map(({ label, color }) => (
          renderLine(label, color)
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
