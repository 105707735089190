import { useTranslation } from 'react-i18next';

import { BotRunStatusInfo } from '@/components/bot/BotRunStatusInfo';
import { BotRunTime } from '@/components/bot/BotRunTime';
import { Kicker } from '@/components/shared/typography/Kicker';
import { BotRunRowsCount } from '@/components/bot/BotRunRowsCount';
import type { BotRun } from '@/types/bot';

import style from './BotLatestRunDetails.module.sass';

type BotLatestRunDetailsProps = {
  botRun?: BotRun;
};

export const BotLatestRunDetails = ({
  botRun,
}: BotLatestRunDetailsProps) => {
  const { t } = useTranslation();

  if (!botRun) {
    return (
      <div className={style.placeholderWrapper}>
        <Kicker size='base' className={style.placeholder}>
          {t('bot:runStatus.noRunCompleted')}
        </Kicker>
      </div>
    );
  }

  return (
    <div className={style.wrapper}>
      <div className={style.section}>
        <Kicker size='base' className={style.kicker}>
          {t('bot:attributes.latestRun')}
        </Kicker>
        <BotRunRowsCount count={botRun.attributes.numRowsAccepted} />
      </div>
      <div className={style.section}>
        <BotRunStatusInfo
          status={botRun.attributes.status}
          isSlow={botRun.attributes.slow}
        />
        <BotRunTime
          time={botRun.attributes.startedAt}
        />
      </div>
    </div>
  );
};
