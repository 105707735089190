import classNames from 'classnames/bind';
import { type PropsWithChildren } from 'react';

import style from './Header.module.sass';

const cx = classNames.bind(style);

export type HeaderVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export type HeaderProps = PropsWithChildren<{
  className?: string;
  variant: HeaderVariants;
}>;

export const Header = ({
  children,
  className,
  variant,
}: HeaderProps) => {
  const headerClassName = cx(variant, className);

  const ElementComponent = variant;

  return (
    <ElementComponent className={headerClassName}>
      {children}
    </ElementComponent>
  );
};
