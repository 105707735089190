import { MouseEvent } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import useToggle from 'react-use/esm/useToggle';

import { Button, type ButtonProps } from '@/components/shared/buttons/Button';

import style from './WrappedItems.module.sass';

const cx = classNames.bind(style);

type WrappedItemsProps = {
  items: ReactNode[];
  disableToggling?: boolean;
  initialItemsCount?: number;
  wrapperClassName?: string;
  toggleButtonClassName?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  buttonProps?: Omit<Partial<ButtonProps>, 'className' | 'onClick'>;
  CustomButton?: React.ComponentType<{
    onClick: (e: MouseEvent<HTMLElement>) => void;
    children: ReactNode;
    customButtonProps?: Record<string, unknown>;
  }>;
  customButtonText?: string;
  customButtonProps?: Record<string, unknown>;
  isHideButtonShown?: boolean;
};

export const WrappedItems = ({
  items,
  disableToggling,
  initialItemsCount = 4,
  wrapperClassName,
  toggleButtonClassName,
  onClick,
  buttonProps = {
    variant: 'secondary',
    sizeVariant: 'small',
    width: 'fitContent',
  },
  CustomButton,
  customButtonText,
  customButtonProps,
  isHideButtonShown = true,
}: WrappedItemsProps) => {
  const { t } = useTranslation();
  const [isExpanded, toggleIsExpanded] = useToggle(false);

  const visibleItems = isExpanded ? items : items.slice(0, initialItemsCount);
  const howManyMore = items.length - initialItemsCount;
  const hasMore = howManyMore > 0;

  const wrapperClassNames = cx('wrapper', wrapperClassName);
  const toggleButtonClassNames = cx('toggleButton', toggleButtonClassName);

  const handleToggle = (e: MouseEvent<HTMLElement>) => {
    onClick?.(e);

    if (!disableToggling) {
      toggleIsExpanded();
    }
  };

  const howManyMoreNumber = `+${howManyMore}`;

  const renderControl = () => {
    if (!isHideButtonShown && isExpanded) {
      return null;
    }

    const controlContent = isExpanded
      ? t('common:hide')
      : customButtonText ?? howManyMoreNumber;

    if (CustomButton) {

      return (
        <CustomButton
          onClick={handleToggle}
          {...customButtonProps}>
          {controlContent}
        </CustomButton>
      );
    }

    return (
      <Button
        {...buttonProps}
        className={toggleButtonClassNames}
        onClick={handleToggle}>
        {controlContent}
      </Button>
    );
  };

  return (
    <div className={wrapperClassNames}>
      {visibleItems.map((element) => element)}
      {hasMore && renderControl()}
    </div>
  );
};
