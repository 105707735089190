import { useDataQuery } from '@/hooks/shared';
import { fetchCompanyStoresCsv } from '@/queries/store';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { SortConfig } from '@/types/shared';
import type {
  StoresFilterParams,
} from '@/types/store';

type UseCompanyStoresCsvOptions = {
  enabled: boolean;
};

export type UseCompanyStoresCsvParams = {
  filter?: StoresFilterParams;
  sort?: SortConfig;
};

export const useCompanyStoresCsv = (
  params: UseCompanyStoresCsvParams,
  options?: UseCompanyStoresCsvOptions,
) => {
  return useDataQuery([QUERY_KEYS.companyStoresCsv, params], fetchCompanyStoresCsv, {
    enabled: options?.enabled,
  });
};
