import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { FilterEntry } from '@/components/shared/FilterEntry';
import { BotSubdomain } from '@/components/bot/BotSubdomain';
import { LengthyFilterEntry } from '@/components/shared/FilterEntry/LengthyFilterEntry';
import { FilterEntriesContainer } from '@/components/shared/FilterEntriesContainer';
import { BotsFiltersProps } from '@/components/bot/BotsFilters/BotsFilters';
import { useCompanies } from '@/hooks/company';
import { getBotStatusLabel } from '@/utils/bot';
import { useBuildClearValue, useIsOnInstance } from '@/hooks/shared';
import { useSettingsChannels } from '@/hooks/settingChannel';
import { getBrandFilterEntry } from '@/utils/brand';
import type { BotsFilterParams } from '@/types/bot';

import {
  getChannelNameBySubdomainId,
  getSubdomainById,
  getSlowRunInfo,
  getSchedulingInfo,
} from './utils';

type BotFilterEntriesProps = {
  filterValues: BotsFilterParams;
  aggregationMapping: BotsFiltersProps['aggregationMapping'];
};

export const BotFilterEntries = ({
  filterValues,
  aggregationMapping,
}: BotFilterEntriesProps) => {
  const { t } = useTranslation();
  const { isCustomerInstance } = useIsOnInstance();
  const buildClearValue = useBuildClearValue<BotsFilterParams>();

  const {
    companies,
  } = useCompanies({}, { disabled: isCustomerInstance });

  const {
    settingsChannels: channels,
  } = useSettingsChannels();

  const filterCompany = useMemo(() => {
    if (!filterValues.companyId || !companies) return;

    return companies.find((company) => company.id === filterValues.companyId);
  }, [
    companies,
    filterValues.companyId,
  ]);

  const filterSubdomain = useMemo(() => {
    if (!filterValues.subdomainId || !channels) return;
    return getSubdomainById(channels, filterValues.subdomainId);
  }, [
    channels,
    filterValues.subdomainId,
  ]);

  const channelName = useMemo(() => {
    if (!filterValues.subdomainId || !channels) return;
    return getChannelNameBySubdomainId(channels, filterValues.subdomainId);
  }, [
    channels,
    filterValues.subdomainId,
  ]);

  const brandAgg = aggregationMapping?.brand;

  const filterBrand = useMemo(() => {
    if (!filterValues.brandId || !brandAgg) return;

    return getBrandFilterEntry({
      brandAgg,
      brandId: filterValues.brandId,
      t,
    });
  }, [
    t,
    brandAgg,
    filterValues,
  ]);

  return (
    <FilterEntriesContainer>
      {
        filterValues.name &&
        <LengthyFilterEntry
          name={filterValues.name}
          entryLabel={t('bot:filterable.labels.name')}
          onRemove={buildClearValue('name')}
        />
      }
      {
        filterValues.status &&
        <LengthyFilterEntry
          name={getBotStatusLabel(filterValues.status, t)}
          entryLabel={t('bot:filterable.labels.status')}
          onRemove={buildClearValue('status')}
        />
      }
      {
        filterSubdomain &&
        <FilterEntry onRemove={buildClearValue('subdomainId')}>
          <BotSubdomain
            name={channelName}
            url={filterSubdomain.attributes.url}
            nameSize='small'
          />
        </FilterEntry>
      }
      {
        filterCompany &&
        <LengthyFilterEntry
          name={filterCompany.attributes.name}
          entryLabel={t('bot:filterable.labels.company')}
          onRemove={buildClearValue('companyId')}
        />
      }
      {
        filterBrand &&
        <LengthyFilterEntry
          name={filterBrand}
          entryLabel={t('bot:filterable.labels.brand')}
          onRemove={buildClearValue('brandId')}
        />
      }
      {
        filterValues.duration &&
        <LengthyFilterEntry
          name={getSlowRunInfo(filterValues.duration, t)}
          entryLabel={t('bot:filterable.labels.duration')}
          onRemove={buildClearValue('duration')}
        />
      }
      {
        filterValues.scheduling &&
        <LengthyFilterEntry
          name={getSchedulingInfo(filterValues.scheduling, t)}
          entryLabel={t('bot:filterable.labels.scheduling')}
          onRemove={buildClearValue('scheduling')}
        />
      }
    </FilterEntriesContainer>
  );
};
