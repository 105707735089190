import { type PropsWithChildren } from 'react';

import style from './SectionBackground.module.sass';

type SectionBackgroundProps = PropsWithChildren;

export const SectionBackground = ({
  children,
}: SectionBackgroundProps) => {
  return (
    <div className={style.wrapper}>
      {children}
    </div>
  );
};
