import { useRef } from 'react';

import { Header } from '@/components/shared/typography/Header';
import { Tooltip } from '@/components/shared/Tooltip';
import { useIsTruncated } from '@/hooks/dom';
import { useMediaQuery } from '@/hooks/shared';

import style from './StoreListItemStoreName.module.sass';

type StoreListItemStoreNameProps = {
  storeName: string;
}

export const StoreListItemStoreName = ({
  storeName,
}: StoreListItemStoreNameProps) => {
  const ref = useRef(null);
  const isTruncated = useIsTruncated(ref);
  const isDesktopHd = useMediaQuery('(min-width: 1920px)');

  return (
    <Tooltip
      content={storeName}
      disabled={!isTruncated}>
      <div>
        <Header variant={isDesktopHd ? 'h4' : 'h5'}>
          <div ref={ref} className={style.header}>
            {storeName}
          </div>
        </Header>
      </div>
    </Tooltip>
  );
};
