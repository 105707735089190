import * as React from "react";
import { forwardRef } from "react";
const SvgImage = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M2.99609 6.99825C2.99609 4.78819 4.7877 2.99658 6.99776 2.99658H17.0019C19.212 2.99658 21.0036 4.78819 21.0036 6.99825V17.0024C21.0036 19.2125 19.212 21.0041 17.0019 21.0041H6.99776C4.7877 21.0041 2.99609 19.2125 2.99609 17.0024V6.99825Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12 16.0019L16.5879 11.4139C17.3692 10.6329 18.6358 10.6329 19.4171 11.4139L21.0037 13.0006",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 7.50063,
  cy: 7.49818,
  r: 1.50063,
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M3.35156 18.6467L7.58332 14.4149C8.36465 13.6338 9.63118 13.6338 10.4125 14.4149L16.8322 20.8346",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgImage);
export default ForwardRef;
