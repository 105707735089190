import { useCallback, useEffect, useMemo, useState, type ChangeEvent } from 'react';
import debounce from 'lodash-es/debounce';
import usePrevious from 'react-use/esm/usePrevious';

import { TextInput, type TextInputProps } from '@/components/shared/form/inputs/TextInput';

type DebouncedTextInputProps = TextInputProps & {
  debounceMs?: number;
};

export const DebouncedTextInput = ({
  debounceMs,
  onChange,
  value,
  ...restProps
}: DebouncedTextInputProps) => {
  const [inputValue, setInputValue] = useState(value);

  const debouncedOnChange = useMemo(() => {
    if (!onChange) return;

    return debounce(onChange, debounceMs ?? 0);
  }, [
    onChange,
    debounceMs,
  ]);

  const previousValue = usePrevious(value);

  useEffect(() => {
    if (value === previousValue || value === inputValue) return;

    setInputValue(value);
  }, [
    value,
    inputValue,
    previousValue,
  ]);

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

    debouncedOnChange?.(event);
  }, [
    debouncedOnChange,
  ]);

  return (
    <TextInput
      onChange={onInputChange}
      value={inputValue}
      {...restProps}
    />
  );
};
