import { useTranslation } from 'react-i18next';

import { Loader } from '@/components/shared/loaders/Loader';

import style from './ContainerLoader.module.sass';

type ContainerLoaderProps = {
  label?: string;
};

export const ContainerLoader = ({
  label,
}: ContainerLoaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <Loader />
      <div className={style.label}>
        {label || t('common:loadingEllipsis')}
      </div>
    </div>
  );
};
