import { Container } from '@/components/shared/containers/Container';
import { ViewModeSelectorContext } from '@/components/shared/viewMode/ViewModeSelectorContext';
import { StoresView } from '@/components/store/StoresView';
import { StoresTabs } from '@/components/store/StoresTabs';
import { useFilteredStoresQueriesMapping } from '@/components/store/StoresView/hooks';
import { ViewMode } from '@/resources/enums';
import { usePersistQueryString } from '@/hooks/browser';
import { usePaginationParams } from '@/hooks/shared';
import { useStoresSortParams, useStoresFilterParams, useCompanyStores } from '@/hooks/stores';
import { getStoresRoute } from '@/utils/routes';
import { UseStoreParams } from '@/hooks/stores/useCompanyStores';

const permittedValues = [ViewMode.Grid, ViewMode.List];

export const CustomerStoresPage = () => {
  usePersistQueryString(getStoresRoute(), { write: true });

  const {
    sortBy,
  } = useStoresSortParams();

  const {
    values,
  } = useStoresFilterParams();

  const {
    page,
    perPage,
  } = usePaginationParams();

  const paginationParams = {
    page,
    perPage,
  };

  const sortParams = {
    sort: sortBy,
  };

  const paramsConfig: UseStoreParams = {
    filter: {
      subdomainId: values.subdomainId,
      countryId: values.countryId,
      status: values.status,
      brandId: values.brandId,
      name: values.name,
    },
    pagination: paginationParams,
    sort: sortParams,
  };

  const {
    dataMapping,
    isLoading: isMetaMappingLoading,
    error: dataMappingError,
  } = useFilteredStoresQueriesMapping(paramsConfig);

  const {
    stores,
    meta: scopedMeta,
    error: storesError,
    isLoading: isStoresLoading,
  } = useCompanyStores(paramsConfig);

  return (
    <Container>
      <StoresTabs />
      <ViewModeSelectorContext permittedValues={permittedValues}>
        <StoresView
          dataMapping={dataMapping}
          scopedMeta={scopedMeta}
          stores={stores}
          storesError={storesError}
          values={values}
          isMetaMappingLoading={isMetaMappingLoading}
          isStoresLoading={isStoresLoading}
          dataMappingError={dataMappingError}
        />
      </ViewModeSelectorContext>
    </Container>
  );
};
