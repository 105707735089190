import classNames from 'classnames/bind';
import { memo } from 'react';

import style from './CountryFlag.module.sass';
import { detectEmojiFlagsSupport, getCountryFlag, tldToCountryName } from './utils';

const cx = classNames.bind(style);

type FlagProps = {
  countryName: string | undefined;
  tld?: never;
} | {
  countryName?: never;
  tld?: string | undefined;
};

type CountryFlagProps = FlagProps & {
  /**
   * @default 'span'
   */
  tagName?: keyof JSX.IntrinsicElements;
};

export const CountryFlag = memo(({
  countryName,
  tld,
  tagName = 'span',
}: CountryFlagProps) => {
  const safeCountryName = tld ? tldToCountryName(tld) : countryName;
  const flag = getCountryFlag(safeCountryName);
  const areEmojiFlagsSupported = detectEmojiFlagsSupport();
  const Component = tagName;

  const componentClassName = cx('component', {
    customFont: !areEmojiFlagsSupported,
  });

  return (
    <Component className={componentClassName}>
      {flag}
    </Component>
  );
});

CountryFlag.displayName = 'CountryFlag';
