import { useDataQuery } from '@/hooks/shared';
import { fetchStore } from '@/queries/store';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const useStore = (storeId?: string) => {
  const {
    data: store,
    ...rest
  } = useDataQuery([QUERY_KEYS.store, storeId], fetchStore, {
    enabled: Boolean(storeId),
  });

  return {
    store,
    ...rest,
  };
};
