import { type i18n, type TFunction } from 'i18next';

import { formatDate, formatTime } from '@/utils/date';
import { DEFAULT_DATE_FORMAT } from '@/resources/datesFormat';

export const getFileDownloadedDateSuffix = (
  t: TFunction,
  i18n: i18n,
  prefix: string,
  dateFormat?: string,
) => {
  const date = formatDate(
    new Date(),
    t('form:dateTime.dateFormat', dateFormat ?? DEFAULT_DATE_FORMAT),
  );
  const time = formatTime(new Date(), i18n.language, 'medium').replace(' ', '_');

  const fileName = `${prefix}-${date}-${time}.csv`;

  return fileName;
};
