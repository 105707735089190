import classNames from 'classnames/bind';

import style from './SeparatorWithLogo.module.sass';
const cx = classNames.bind(style);

type SeparatorWithLogoProps = {
  logoUrl: string;
  wrapperClassName?: string;
};

export const SeparatorWithLogo = ({
  logoUrl,
  wrapperClassName,
}: SeparatorWithLogoProps) => {
  const wrapperClassNames = cx('wrapper', wrapperClassName);

  return (
    <div className={wrapperClassNames}>
      <div className={style.imageWrapper}>
        <img
          className={style.image}
          src={logoUrl}
          alt='logo'
        />
      </div>
    </div>
  );
};
