import { useDataQuery } from '@/hooks/shared';
import { fetchCompanyStore } from '@/queries/store';
import { QUERY_KEYS } from '@/resources/dataKeys';
import type { Nullable } from '@/types/shared';

export const useCompanyStore = (storeId: Nullable<string>) => {
  const {
    data: store,
    ...rest
  } = useDataQuery([QUERY_KEYS.companyStore, storeId], fetchCompanyStore, {
    enabled: !!storeId,
  });

  return {
    store,
    ...rest,
  };
};
