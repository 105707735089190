import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import { EnforcementPolicyDefinitionIncludedRecord } from '@/queries/policyDefinition/fetchEnforcementPolicyDefinitions';
import type { EnforcementPolicyDefinition } from '@/types/enforcementPolicyDefinition';

export const fetchEnforcementPolicyDefinition = async (context: QueryFunctionContext) => {
  const [, enforcementPolicyDefinitionId] = context.queryKey;

  const response = await Api.get<
    EnforcementPolicyDefinition,
    EnforcementPolicyDefinitionIncludedRecord
  >(`/api/policy_definitions/${enforcementPolicyDefinitionId}`);

  const enforcementPolicyDefinition = linkCollectionRelationships(response);

  return enforcementPolicyDefinition;
};
