import { Icon } from '@/components/shared/Icon';
import { useBranding } from '@/hooks/branding';
import LogoGreyScout from '@/assets/images/logo_grey_scout.svg?react';

import style from './CustomerCompanyJumbotron.module.sass';

type CustomerCompanyJumbotronProps = {
  textLogo?: boolean;
  isGsLogoVisible?: boolean;
};

export const CustomerCompanyJumbotron = ({
  textLogo,
  isGsLogoVisible = true,
}: CustomerCompanyJumbotronProps) => {
  const { branding } = useBranding();

  if (!branding) {
    return null;
  }

  const renderLogo = () => {
    return (
      textLogo
        ? <LogoGreyScout className={style.GSlogo} />
        : <Icon name='GreyScout' className={style.GSlogo} />
    );
  };

  return (
    <div className={style.wrapper}>
      {
        isGsLogoVisible &&
        <div className={style.appLogoWrapper}>
          {renderLogo()}
        </div>
      }
      <div className={style.brandingLogoWrapper}>
        <img
          className={style.logoImage}
          src={branding.attributes.logoUrl}
          alt={branding.attributes.name}
        />
      </div>
    </div>
  );
};
