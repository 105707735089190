import { useCallback } from 'react';

import { SortableSortSelect } from '@/components/shared/sortable/SortableSortSelect';
import { EMPTY_ARRAY } from '@/resources/constants';
import {
  useListingsSelectionParams,
  useListingsSortParams,
} from '@/hooks/listing';
import { type SortableOption } from '@/types/sortable';

type ListingsSortSelectProps = {
  sortOptions?: SortableOption[];
  defaultSortBy?: string;
};

export const ListingsSortSelect = ({
  sortOptions,
  defaultSortBy,
}: ListingsSortSelectProps) => {
  const {
    sortBy,
    options,
  } = useListingsSortParams(sortOptions, defaultSortBy);

  const [, setSelectedListingIds] = useListingsSelectionParams();

  const resetListingsSelection = useCallback(() => {
    setSelectedListingIds(EMPTY_ARRAY);
  }, [
    setSelectedListingIds,
  ]);

  return (
    <SortableSortSelect
      sortBy={sortBy}
      options={options}
      onAfterUpdate={resetListingsSelection}
    />
  );
};
