import classNames from 'classnames/bind';
import {
  ReactNode,
  useMemo,
} from 'react';

import { Body } from '@/components/shared/typography/Body';
import { SelectInput, type SelectInputProps } from '@/components/shared/form/inputs/SelectInput';
import type { SelectOption } from '@/types/shared';

import style from './ToolbarSelect.module.sass';

const cx = classNames.bind(style);

type ToolbarSelectProps = Omit<SelectInputProps, 'name'> & {
  label?: ReactNode;
  inputClassName?: string;
  isInputInverted?: boolean;
  inputName: string;
};

export const ToolbarSelect = ({
  label,
  options,
  inputClassName,
  isInputInverted,
  inputName,
  popperAlign = 'auto',
  ...rest
}: ToolbarSelectProps) => {
  const mappedOptions = useMemo<SelectOption[]>(() => {
    if (!options) {
      return [];
    }

    return options.map((option) => option);
  }, [
    options,
  ]);

  const selectClassName = cx('select', inputClassName);

  return (
    <div className={style.wrapper}>
      {
        label &&
        <Body size='base' className={style.label}>
          {label}
        </Body>
      }
      <SelectInput
        options={mappedOptions}
        className={selectClassName}
        isInverted={isInputInverted}
        popperAlign={popperAlign}
        optionVariant='tickRight'
        name={inputName}
        {...rest}
      />
    </div>
  );
};
