import classNames from 'classnames/bind';

import { Button, type UnstyledButtonProps } from '@/components/shared/buttons/Button';
import { Icon } from '@/components/shared/Icon';
import type { IconName } from '@/types/shared';

import style from './ButtonIcon.module.sass';

const cx = classNames.bind(style);

export type ButtonIconVariant = 'primary' | 'secondary' | 'primaryGhost' | 'dangerGhost';

export type ButtonIconProps = {
  isActive?: boolean;
  onClick?: UnstyledButtonProps['onClick'];
  iconName: IconName;
  className?: string;
  variant?: ButtonIconVariant;
  iconClassName?: string;
  ariaLabel?: string;
  disabled?: boolean;
  dataCy?: string;
  size?: 'normal' | 'small';
};

export const ButtonIcon = ({
  isActive,
  onClick,
  iconName,
  className,
  iconClassName,
  ariaLabel,
  disabled,
  variant = 'primaryGhost',
  size = 'normal',
  dataCy,
}: ButtonIconProps) => {
  const buttonClassName = cx('button', variant, size, className, {
    isActive,
  });

  const iconClassNames = cx('icon', iconClassName);

  return (
    <Button.Unstyled
      data-cy={dataCy}
      onClick={onClick}
      className={buttonClassName}
      disabled={disabled}
      ariaLabel={ariaLabel}>
      <Icon name={iconName} className={iconClassNames} />
    </Button.Unstyled>
  );
};
