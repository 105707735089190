import { useFilteredQueriesMapping } from '@/hooks/utils';
import { fetchBots } from '@/queries/bot';
import { type UseBotsParams } from '@/hooks/bot';
import { QUERY_KEYS } from '@/resources/dataKeys';
import type { Nullable } from '@/types/shared';

import { removeBotFilterValue } from './utils';

export const useFilteredBotsQueriesMapping = (
  companyId: Nullable<string>,
  queryKeyParams: UseBotsParams,
) => {
  return useFilteredQueriesMapping({
    queryFn: fetchBots,
    transformQueryKey: (queryKey, params) => {
      return removeBotFilterValue(queryKey, params.filterKey);
    },
    queryKey: [QUERY_KEYS.bots, queryKeyParams],
    mapping: [
      { dataKey: 'duration', params: { filterKey: 'latestRunSlow' as const } },
      { dataKey: 'status', params: { filterKey: 'latestRunStatus' as const } },
      { dataKey: 'company', params: { filterKey: 'companyId' as const }, disabled: !!companyId },
      { dataKey: 'subdomain', params: { filterKey: 'subdomainId' as const } },
      { dataKey: 'scheduling', params: { filterKey: 'isScheduled' as const } },
      { dataKey: 'brand', params: { filterKey: 'brandId' as const } },
    ],
  });
};
