import { ReactElement } from 'react';

import { Tooltip } from '@/components/shared/Tooltip';
import { Icon } from '@/components/shared/Icon';
import { useMediaQuery } from '@/hooks/shared';

import style from './FieldWrapperTooltip.module.sass';

type FieldWrapperTooltipProps = {
  tooltip: ReactElement;
  isTipIconVisible?: boolean;
  children: ReactElement;
};

export const FieldWrapperTooltip = ({
  tooltip,
  isTipIconVisible,
  children,
}: FieldWrapperTooltipProps) => {
  const isEnoughSpace = useMediaQuery('(min-width: 1600px)');
  const placement = isEnoughSpace ? 'left' : 'top';

  return (
    <Tooltip
      key={placement}
      placement={placement}
      content={tooltip}>
      <div className={style.content}>
        {children}
        {
          isTipIconVisible &&
          <Icon name='Info' className={style.infoIcon} />
        }
      </div>
    </Tooltip>
  );
};
