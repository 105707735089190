import useUnmount from 'react-use/esm/useUnmount';

import { GridContainer } from '@/components/shared/containers/GridContainer';
import { NavigationCard } from '@/components/shared/NavigationCard';
import { StoreGridCard } from '@/components/store/StoreGridCard/StoreGridCard';
import { CardContextMenuProps } from '@/components/shared/Card/CardContextMenu';
import { usePaginationParams } from '@/hooks/shared/usePaginationParams';
import { AllowStoreContext } from '@/contexts/store/AllowStoreContext';
import { useUnsafeContext } from '@/hooks/shared';
import { type ID } from '@/types/shared';
import { type Store } from '@/types/store';

type StoresListProps = {
  stores: Store[];
  pages: number;
  getStoreRoute: (storeId: ID) => string;
  contextMenu: (store: Store) => CardContextMenuProps;
  onClick: (store: Store) => void;
};

export const StoresGrid = ({
  stores,
  pages,
  contextMenu,
  onClick,
}: StoresListProps) => {

  const {
    closeModal,
  } = useUnsafeContext(AllowStoreContext);

  useUnmount(() => closeModal());

  const {
    page,
    updatePage,
  } = usePaginationParams();

  const goToNextPage = () => updatePage(page + 1);

  const renderStoreCard = (store: Store) => {

    return (
      <StoreGridCard
        contextMenu={contextMenu}
        onClick={() => onClick(store)}
        key={store.id}
        store={store}
      />
    );
  };

  return (
    <GridContainer>
      <>
        {stores.map(renderStoreCard)}
        {
          page < pages &&
          <NavigationCard onClick={goToNextPage} />
        }
      </>
    </GridContainer>
  );
};
