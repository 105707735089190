import { fetchCompany } from '@/queries/company';
import { useDataQuery } from '@/hooks/shared';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { type Company } from '@/types/company';

export const useCompany = (companyId?: string) => {
  const {
    data: company,
    ...rest
  } = useDataQuery<Company>([QUERY_KEYS.company, companyId], fetchCompany, {
    enabled: Boolean(companyId),
  });

  return {
    company,
    ...rest,
  };
};
