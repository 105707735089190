import { Body } from '@/components/shared/typography/Body';
import { Header } from '@/components/shared/typography/Header';

import style from './LoginManagementHeader.module.sass';

type LoginManagementHeaderProps = {
  title: string;
  subtitle?: string;
};

export const LoginManagementHeader = ({
  title,
  subtitle,
}: LoginManagementHeaderProps) => {

  return (
    <div className={style.wrapper}>
      <Header variant='h1' className={style.title}>
        {title}
      </Header>
      {
        subtitle &&
        <Body size='large' className={style.subtitle}>
          {subtitle}
        </Body>}
    </div>
  );
};
