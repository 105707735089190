import { useTranslation } from 'react-i18next';
import { type ChangeEvent } from 'react';

import { ToolbarSelect } from '@/components/shared/ToolbarSelect/ToolbarSelect';
import { useSortParams } from '@/hooks/shared/useSortParams';
import { usePaginationParams } from '@/hooks/shared';
import type { SortableOption } from '@/types/sortable';

import style from './SortableSortSelect.module.sass';

type SortableSortSelectProps = {
  sortBy: string;
  options: SortableOption[];
  onAfterUpdate?: () => void;
};

export const SortableSortSelect = ({
  sortBy,
  options,
  onAfterUpdate,
}: SortableSortSelectProps) => {
  const { t } = useTranslation();

  const {
    updateSortBy,
  } = useSortParams();

  const {
    page,
    updatePage,
  } = usePaginationParams();

  const changeSortBy = () => (valueOrEvent: string | ChangeEvent) => {
    if (typeof valueOrEvent !== 'string') return;

    updateSortBy(valueOrEvent);

    if (page !== 1) updatePage(undefined);

    if (onAfterUpdate) onAfterUpdate();
  };

  return (
    <ToolbarSelect
      inputName='sortBySelect'
      label={t('common:sortable.selectLabel')}
      value={sortBy}
      options={options}
      onChange={changeSortBy}
      inputClassName={style.selectInput}
      popperAlign='right'
      optionsListClassName={style.options}
    />
  );
};
