import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import { Card } from '@/components/shared/Card';
import { Icon } from '@/components/shared/Icon';
import { Body } from '@/components/shared/typography/Body';
import { MultilineHeader } from '@/components/shared/MultilineHeader';
import { BotSubdomain } from '@/components/bot/BotSubdomain';
import { StoreReview } from '@/components/store/StoreReview';
import { CountryLogotype } from '@/components/shared/CountryLogotype';
import { StoreStatus } from '@/components/store/StoreStatus';
import { StyledLink } from '@/components/shared/StyledLink';
import { CardContextMenuProps } from '@/components/shared/Card/CardContextMenu';
import { getStoreRoute } from '@/utils/routes';
import { useStopEventPropagation } from '@/hooks/dom';
import { useIsOnInstance } from '@/hooks/shared';
import { useUserSystemRole } from '@/hooks/user';
import type { ResellerStore } from '@/types/reseller';
import type { Store } from '@/types/store';

import style from './StoreGridCard.module.sass';

const cx = classNames.bind(style);

export type StoreGridCardProps<T> = {
  store: T;
  className?: string;
  shouldRenderHeaderLink?: boolean;
  onClick?: () => void;
  contextMenu?: (store: T) => CardContextMenuProps;
  indicator?: ReactNode;
};

export const StoreGridCard = <T extends Store | ResellerStore>({
  store,
  className,
  onClick,
  shouldRenderHeaderLink,
  contextMenu,
  indicator,
}: StoreGridCardProps<T>) => {
  const { t } = useTranslation();
  const { isAdminInstance } = useIsOnInstance();
  const { isGuest } = useUserSystemRole();

  const stopEventPropagation = useStopEventPropagation();

  const subdomain = store.linked?.subdomain;
  const ratingText = store.attributes.rating ?? t('common:unknown');

  const wrapperClassName = cx('card', className);

  const renderSubdomain = () => (
    <BotSubdomain
      name={subdomain?.linked?.channel?.attributes.name}
      url={subdomain?.attributes.url}
    />
  );

  const renderHeader = () => (
    <>
      {renderSubdomain()}
      <MultilineHeader
        variant='h4'
        ellipsisVariant='oneLine'
        className={style.name}>
        {store.attributes.name || t('common:unknown')}
      </MultilineHeader>
    </>
  );

  const renderListingCount = () => {
    if (isAdminInstance) {
      return null;
    }

    const {
      listingsCount,
      brandsCount,
    } = store.attributes ?? {};

    return (
      <div className={style.listingsInfo}>
        <Icon name='Searches' className={style.listingsIcon} />
        <Body size='base'>
          {t('store:listingsCount', { count: listingsCount })}
          {' - '}
          {t('store:brandsCount', { count: brandsCount ?? 0 })}
        </Body>
      </div>
    );
  };

  const renderStatus = () => {
    if (isAdminInstance) {
      return null;
    }

    return (
      <div className={style.storeStatus}>
        <StoreStatus
          status={store.attributes.status}
          allowedUntil={store.linked?.whitelisting?.attributes.until}
        />
      </div>
    );
  };

  const renderEmail = () => {
    if (!isAdminInstance) {
      return null;
    }

    const email = (
      'attributes' in store && 'email' in store.attributes
        ? store.attributes.email
        : undefined
    );

    return (
      <Body size='base' className={style.email}>
        {email || t('common:unknown')}
      </Body>
    );
  };

  const contextMenuProps = (isAdminInstance || isGuest) ? undefined : contextMenu?.(store);

  return (
    <Card
      onClick={onClick}
      resourceRoute={shouldRenderHeaderLink ? undefined : getStoreRoute(store.id)}
      className={wrapperClassName}
      contextMenuProps={contextMenuProps}>
      {
        shouldRenderHeaderLink ? (
          <StyledLink
            onClick={stopEventPropagation}
            to={getStoreRoute(store.id)}>
            {renderHeader()}
          </StyledLink>
        ) : renderHeader()
      }
      <div>
        {renderEmail()}
        <Body size='base'>
          <div className={style.storeInfo}>
            <StoreReview
              text={ratingText}
              reviewCount={store.attributes.reviewCount}
              reviewCountSize='base'
            />
            {renderListingCount()}
            <CountryLogotype
              className={style.country}
              countryName={store.linked?.country?.attributes.name}
            />
          </div>
        </Body>
        {renderStatus()}
      </div>
      {indicator}
    </Card>
  );
};
