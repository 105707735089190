import { fetchResellers } from '@/queries/resellers';
import { useDataQuery } from '@/hooks/shared';
import { QUERY_KEYS } from '@/resources/dataKeys';
import {
  ResellersSortConfig,
  ResellersPaginationConfig,
  ResellersFilterParams,
} from '@/types/reseller';

export type UseResellerParams = {
  filter?: ResellersFilterParams;
  pagination?: ResellersPaginationConfig;
  sort?: ResellersSortConfig;
};

export const useResellers = (params?: UseResellerParams) => {
  const {
    data: queryData,
    ...rest
  } = useDataQuery([QUERY_KEYS.resellers, params], fetchResellers);

  return {
    ...queryData,
    ...rest,
  };
};
