import { useLocation } from 'react-router';

interface CustomizedState {
  [key: string]: string;
}

export const useGetLocationState = (attribute: string) => {
  const location = useLocation();

  const state = location.state as CustomizedState;

  return state?.[attribute];
};
