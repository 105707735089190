import { FC, ReactNode } from 'react';

import style from './FormActions.module.sass';

type FormActionsProps = {
  children: ReactNode;
};

export const FormActions: FC<FormActionsProps> = ({
  children,
}) => {
  return (
    <div className={style.wrapper}>
      {children}
    </div>
  );
};
