import classNames from 'classnames/bind';
import { type ReactNode } from 'react';

import { Body } from '@/components/shared/typography/Body';

import style from './BoxText.module.sass';

const cx = classNames.bind(style);

type BoxTextProps = {
  text: string | ReactNode;
  paddingVariant?: 'small' | 'base' | 'large';
  className?: string;
};

export const BoxText = ({
  text,
  paddingVariant = 'base',
  className,
}: BoxTextProps) => {
  const textClassNames = cx('text', className, paddingVariant);

  return (
    <Body size='base' className={textClassNames}>
      {text}
    </Body>
  );
};
