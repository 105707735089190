import { useTranslation } from 'react-i18next';

import { AnimatedLoadingDots } from '@/components/shared/AnimatedLoadingDots';

import style from './RunningBotStatus.module.sass';

export const RunningBotStatus = () => {
  const { t } = useTranslation();

  return (
    <div className={style.runningStatus}>
      {t('bot:runStatus.running')}
      <AnimatedLoadingDots />
    </div>
  );
};
