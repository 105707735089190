import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { KeywordDetails } from '@/types/keyword';

export const fetchKeyword = async (context: QueryFunctionContext) => {
  const [, companyId, keywordId] = context.queryKey;

  const response = await Api.get<KeywordDetails>(`/api/companies/${companyId}/keywords/${keywordId}`);

  return response.data;
};
