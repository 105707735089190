import { PropsWithChildren, useEffect, useState } from 'react';

import { BreadcrumbContext } from '@/contexts/breadcrumb';

type AppBreadcrumbsProps = PropsWithChildren;

export const BREADCRUMBS_INITIAL_DELAY = 250;

export const AppBreadcrumbs = ({
  children,
}: AppBreadcrumbsProps) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsFirstRender(false), BREADCRUMBS_INITIAL_DELAY);
  }, []);

  return (
    <BreadcrumbContext.Provider value={{ isFirstRender: isFirstRender }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};
