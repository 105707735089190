import { useDataMutation } from '@/hooks/shared';
import { passwordResetRequest } from '@/mutations/user/passwordResetRequest';

export const usePasswordResetRequest = () => {
  const {
    mutate: passwordResetRequestMutation,
    mutateAsync: passwordResetRequestAsync,
    ...rest
  } = useDataMutation('passwordResetRequest', passwordResetRequest);

  return {
    passwordResetRequestMutation,
    passwordResetRequestAsync,
    ...rest,
  };
};
