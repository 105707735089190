import { useMemo } from 'react';

import { filterByQuery, type FilterByQueryCallback, type FilterByQueryOptions } from '@/utils/array';
import type { Nullable } from '@/types/shared';

export const useFilteredByQuery = <TItem, >(
  collection: TItem[] | undefined,
  query: Nullable<string>,
  callback: FilterByQueryCallback<TItem>,
  options: FilterByQueryOptions = {},
) => {
  return useMemo(() => {
    if (!collection) {
      return [];
    }

    if (!query) {
      return collection;
    }

    return filterByQuery(collection, query, callback, options);
  }, [
    collection,
    query,
    callback,
    options,
  ]);
};
