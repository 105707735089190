import { GREYSCOUT_COMPANY_ID } from '@/resources/constants';
import { UserSystemRole } from '@/types/user';

import { useUserSystemRole } from './useUserSystemRole';

export type KeyOfUserStatus = keyof UserSystemRole;

type UseRbacProps = {
  rolesWhitelist: KeyOfUserStatus[];
  isRemoteAdminAllowed: boolean | undefined;
  companyId: string | undefined;
};

type DetermineRbacAccessProps = UseRbacProps & {
  userRole: UserSystemRole;
};

export const determineRbacAccess = ({
  rolesWhitelist,
  isRemoteAdminAllowed,
  userRole,
  companyId,
}: DetermineRbacAccessProps) => {
  const isOnGsResourceRoute = companyId === GREYSCOUT_COMPANY_ID;

  const hasAccessGrantedByRole = rolesWhitelist.some((status) => userRole?.[status]);
  const {
    isGsAdmin,
    isGsUser,
    isGsGuest,
  } = userRole;
  const isGsRole = isGsAdmin || isGsUser || isGsGuest;

  if (isRemoteAdminAllowed || isOnGsResourceRoute) {
    return hasAccessGrantedByRole;
  }

  if (!isRemoteAdminAllowed) {
    return hasAccessGrantedByRole && !isGsRole;
  }

  return false;
};

export const useRbac = ({
  rolesWhitelist,
  isRemoteAdminAllowed,
  companyId,
}: UseRbacProps) => {
  const userRole = useUserSystemRole();

  return determineRbacAccess({
    rolesWhitelist,
    isRemoteAdminAllowed,
    userRole,
    companyId,
  });
};
