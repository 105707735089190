import { createContext } from 'react';

export type AuthorisedCustomerCompanyUserContextValue = {
  isCustomerCompanyUserAuthorized: boolean;
  isError: boolean;
  setHasTriedToLogin: (hasTriedToLogin: boolean) => void;
  hasTriedToLogin: boolean;
}

const defaultContextValue: AuthorisedCustomerCompanyUserContextValue = {
  isCustomerCompanyUserAuthorized: false,
  isError: false,
  setHasTriedToLogin: () => false,
  hasTriedToLogin: false,
};

export const AuthorisedCustomerCompanyUserContext =
  createContext<AuthorisedCustomerCompanyUserContextValue>(defaultContextValue);
