import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries';
import type { BotRunInfo, BotRunInfoProvider } from '@/types/bot';

type BotRunQueryKey = [string, string | undefined, string | undefined];

export type BotRunInfoIncludedRecord = | BotRunInfoProvider;

export const fetchBotRun = async (context: QueryFunctionContext<BotRunQueryKey>) => {
  const [, botId, botRunId] = context.queryKey;
  const response = await Api.get<BotRunInfo, BotRunInfoIncludedRecord>(`api/bots/${botId}/runs/${botRunId}`);

  const parsedBotRun = linkCollectionRelationships(response);

  return parsedBotRun;
};
