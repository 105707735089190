import { type TFunction } from 'i18next';

import { StoreStatus } from '@/resources/enums';
import type { Nullable } from '@/types/shared';

export const getStoreStatusLabel = (
  status: Nullable<string | boolean>,
  t: TFunction,
) => {
  switch (status) {
    case StoreStatus.ActionInProgress:
    case StoreStatus.Allowed:
    case StoreStatus.Greylisted:
      return t(`store:status.${status}`);
    default:
      return status;
  }
};
