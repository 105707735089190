import {
  useMutation,
  type UseMutationOptions,
  type MutationFunction,
  type MutationKey,
} from 'react-query';

import { type ApiDataErrors, type UseMutationError } from '@/types/shared';

/* eslint-disable indent */

export const useDataMutation = <
  TData,
  TPayload,
  TError = ApiDataErrors,
>(
  mutationKey: MutationKey,
  mutationFn: MutationFunction<TData, TPayload>,
  mutationOptions?: Omit<UseMutationOptions<TData, UseMutationError<TError, TPayload>, TPayload>, 'mutationKey' | 'mutationFn' | 'onError'>, // eslint-disable-line
) => {
  /* eslint-enable indent */

  const {
    ...rest
  } = useMutation<TData, UseMutationError<TError, TPayload>, TPayload>(
    mutationKey,
    mutationFn,
    mutationOptions,
  );

  return {
    ...rest,
  };
};
