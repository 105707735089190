import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '@/locales/en';

const resources = {
  en,
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: resources,
  interpolation: {
    escapeValue: false,
  },
  ns: [
    'account',
    'bot',
    'common',
    'company',
    'dashboard',
    'form',
    'loginPage',
    'navigation',
    'trademark',
    'user',
  ],
});

export type ResourcesType = typeof resources.en;
