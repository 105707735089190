import { type TFunction } from 'i18next';

import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { sortBy } from '@/utils/array';
import { parens } from '@/utils/string';
import { getStoreStatusLabel } from '@/utils/store';
import type { AggregationEntry } from '@/types/shared';

type GetStatusOptionsParams = {
  t: TFunction;
  statusAgg?: AggregationEntry[];
  optionWrapperClassName?: string;
};

export const getStoreStatusOptions = ({
  t,
  statusAgg,
  optionWrapperClassName,
}: GetStatusOptionsParams) => {
  const statuses = sortBy(statusAgg ?? [], 'label');

  return statuses.map((status) => ({
    value: String(status.id),
    label: (
      <div className={optionWrapperClassName}>
        <SelectInput.OptionWithSubtitle
          label={getStoreStatusLabel(status.label, t)}
          subtitle={parens(status.count)}
        />
      </div>
    ),
  }));
};
