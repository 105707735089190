import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  useField,
  useFormikContext,
} from 'formik';

import { FieldRenderer } from '@/components/shared/form/FieldRenderer';
import { FieldWrapper } from '@/components/shared/form/FieldWrapper';
import { StoreBusinessTypeTextArea } from '@/components/store/StoreBusinessTypeTextArea';
import {
  StoreBusinessTypeRadioInput,
} from '@/components/store/StoreBusinessTypeRadioInput';
import { useStoreOtherBusinessTypeId } from '@/hooks/stores';

export const StoreBusinessTypeField = () => {
  const otherBusinessTypeId = useStoreOtherBusinessTypeId();
  const [businessTypeOtherField] = useField('businessTypeOther');
  const { t } = useTranslation();

  const {
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (businessTypeOtherField.value) {
      setFieldValue('businessTypeId', otherBusinessTypeId);
    }
  }, [
    businessTypeOtherField.value,
    setFieldValue,
    otherBusinessTypeId,
  ]);

  return (
    <FieldWrapper
      title={t('store:veriform.fieldTitles.businessType')}
      label={t('store:veriform.labels.businessType')}
      name='businessTypeId'>
      <>
        <FieldRenderer
          name='businessTypeId'
          Component={StoreBusinessTypeRadioInput}
        />
        <StoreBusinessTypeTextArea />
      </>
    </FieldWrapper>
  );
};
