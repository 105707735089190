import { getSettingsHarvestingRoute } from '@/utils/routes/setting';
import { RouteParam } from '@/types/shared';

export const getChannelsRoute = () => {
  return `${getSettingsHarvestingRoute()}/channels`;
};

export const getCreateChannelRoute = () => {
  return `${getChannelsRoute()}/create`;
};

export const getChannelRoute = (channelId: RouteParam) => {
  return `${getChannelsRoute()}/${channelId}`;
};

export const getEditChannelRoute = (channelId: RouteParam) => {
  return `${getChannelRoute(channelId)}/edit`;
};

export const getDeleteChannelRoute = (channelId: RouteParam) => {
  return `${getChannelRoute(channelId)}/delete`;
};
