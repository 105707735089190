import { fetchCompanies } from '@/queries/company';
import { useDataQuery } from '@/hooks/shared';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { CompaniesFilterParams } from '@/types/company';
import { PaginationConfig, SortConfig } from '@/types/shared';

export type UseCompaniesParams = {
  pagination?: PaginationConfig;
  sort?: SortConfig;
  filter?: CompaniesFilterParams;
};

type UseCompaniesOptions = {
  disabled?: boolean;
};

export const useCompanies = (
  params?: UseCompaniesParams,
  options?: UseCompaniesOptions,
) => {
  const {
    data: queryData,
    ...rest
  } = useDataQuery([QUERY_KEYS.companies, params], fetchCompanies, {
    enabled: !options?.disabled,
  });

  return {
    ...queryData,
    ...rest,
  };
};
