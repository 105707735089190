import { useTranslation } from 'react-i18next';

import { ButtonAsync, Button } from '@/components/shared/buttons';
import { AllowStoreContext } from '@/contexts/store/AllowStoreContext';
import { useBranding } from '@/hooks/branding';
import { useUnsafeContext } from '@/hooks/shared';
import { useRemoveStoreFromWhiteList } from '@/hooks/stores/useRemoveStoreFromWhiteList';
import { StoreStatus } from '@/resources/enums';
import { Store } from '@/types/store';

type AllowStoreButtonProps = {
  store: Store;
};

export const AllowStoreButton = ({
  store,
}: AllowStoreButtonProps) => {
  const { t } = useTranslation();
  const { branding } = useBranding();

  const {
    openModal,
  } = useUnsafeContext(AllowStoreContext);

  const {
    mutateAsync: removeStoreFromWhiteListAsync,
    isLoading: isRemoveStoreFromWhiteListLoading,
  } = useRemoveStoreFromWhiteList(store.id, store.linked?.reseller?.id);

  if (store?.attributes.status === StoreStatus.ActionInProgress) {
    return null;
  }

  const onDisallowClick = () => {
    removeStoreFromWhiteListAsync({
      storeId: store.id,
      whitelistingId: store.linked?.whitelisting?.id,
    });
  };

  const onAllowClick = () => {
    openModal({
      storeId: store.id,
      companyId: branding?.attributes.companyId,
      storeName: store.attributes.name,
      storeSubdomain: store.linked?.subdomain?.linked?.channel.attributes.name,
      storeSubdomainUrl: store.linked?.subdomain?.attributes.url,
      resellerId: store.linked?.reseller?.id,
      whitelistingId: store.linked?.whitelisting?.id,
    });
  };

  if (store.attributes.status === StoreStatus.Allowed) {
    return (
      <>
        <Button
          onClick={onAllowClick}
          sizeVariant='medium'
          variant='secondary'>
          {t('store:actions.update')}
        </Button>
        <ButtonAsync
          isBusy={isRemoveStoreFromWhiteListLoading}
          onClick={onDisallowClick}
          sizeVariant='medium'>
          {t('store:actions.disallow')}
        </ButtonAsync>
      </>
    );
  }

  return (
    <Button
      onClick={onAllowClick}
      sizeVariant='medium'>
      {t('store:actions.allow')}
    </Button>
  );
};
