import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useGoBack = () => {
  const navigate = useNavigate();

  const willGoBackNavigate = history.length > 1;

  const goBack = useCallback(() => {
    if (willGoBackNavigate) {
      navigate(-1);
      return;
    }

    navigate('/', { replace: true });
  }, [
    navigate,
    willGoBackNavigate,
  ]);

  return {
    goBack,
    willGoBackNavigate,
  };
};
