import { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/components/shared/Icon';
import { MultilineBody } from '@/components/shared/MultilineBody';
import { Tooltip } from '@/components/shared/Tooltip';
import { Listing } from '@/types/listing';

import style from './ListingsListItemNumber.module.sass';

type ListingsListItemNumberProps = {
  listing: Listing;
}

export const ListingsListItemNumber = ({
  listing,
}: ListingsListItemNumberProps) => {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const itemNumberRef = useRef<HTMLDivElement>(null);

  const handleItemNumberCopy = (event: MouseEvent) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    event.stopPropagation();
    navigator.clipboard.writeText(listing.attributes.itemNumber);
    setIsTooltipOpen(true);

    timeoutId.current = setTimeout(() => {
      setIsTooltipOpen(false);
    }, 1000);
  };

  const getButtonRightPosition = () => {
    if (!wrapperRef.current || !itemNumberRef.current) {
      return;
    }

    return (
      wrapperRef.current.offsetWidth - itemNumberRef.current.offsetWidth > 0
        ? wrapperRef.current.offsetWidth - itemNumberRef.current.offsetWidth
        : 0
    );
  };

  const copyButtonStyle = {
    right: getButtonRightPosition(),
  };

  return (
    <Tooltip
      visible={isTooltipOpen}
      content={t('common:textCopiedToClipboard')}>
      <div ref={wrapperRef}>
        <MultilineBody
          size='base'
          content={listing.attributes.itemNumber}
          onClick={handleItemNumberCopy}
          className={style.itemNumber}>
          <span ref={itemNumberRef}>
            {listing.attributes.itemNumber}
          </span>
          <div className={style.copyButton} style={copyButtonStyle}>
            <Icon name='Copy' className={style.copyIcon} />
          </div>
        </MultilineBody>
      </div>
    </Tooltip>
  );
};
