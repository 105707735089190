import { QueryFunctionContext } from 'react-query';

import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import { Api } from '@/utils/api';
import type { Bot } from '@/types/bot';

import { BotsIncludedRecord } from './fetchBots';

export const fetchBot = async (context: QueryFunctionContext) => {
  const [, botId] = context.queryKey;
  const response = await Api.get<Bot, BotsIncludedRecord>(`/api/bots/${botId}`);

  const bot = linkCollectionRelationships(response);

  return bot;
};
