import lodashFilter from 'lodash-es/filter';

import { Nullable } from '@/types/shared';

export type FilterByQueryCallback<TItem> = (item: TItem) => string;

export type FilterByQueryOptions = {
  minQueryLength?: number;
};

export const filterByQuery = <TItem, >(
  collection: TItem[],
  query: Nullable<string>,
  callback: FilterByQueryCallback<TItem>,
  options: FilterByQueryOptions = {},
) => {
  const {
    minQueryLength = 1,
  } = options;

  if (typeof query !== 'string' || query.length < minQueryLength) {
    return collection;
  }

  try {
    const regex = new RegExp(query, 'i');

    return lodashFilter(collection, (item) => {
      const itemAttribute = callback(item);

      return regex.test(itemAttribute);
    });
  } catch (error) {
    return [] as TItem[];
  }
};
