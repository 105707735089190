import { sortSubdomains } from '@/components/bot/BotsFilters/utils';
import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { CountryFlag } from '@/components/shared/CountryFlag';
import { getTldFromUrl, parens, removeProtocolFromUrl } from '@/utils/string';
import type { AggregationEntry, SelectOption } from '@/types/shared';
import type { Channel } from '@/types/channel';

import style from './getSubdomainOptions.module.sass';

type GetSubdomainOptionsProps = {
  channels?: Channel[];
  subdomainAgg?: AggregationEntry[];
};

type ChannelWithSubdomainLabelProps = {
  name: string;
  subdomainUrl: string;
};

export const ChannelWithSubdomainLabel = ({
  name,
  subdomainUrl,
}: ChannelWithSubdomainLabelProps) => {
  return (
    <span>
      {name}
      &nbsp;
      <span className={style.flag}>
        <CountryFlag tld={getTldFromUrl(subdomainUrl)} />
      </span>
      &nbsp;
      <span className={style.domain}>
        {removeProtocolFromUrl(subdomainUrl)}
      </span>
    </span>
  );
};

export const getSubdomainOptions = ({
  channels,
  subdomainAgg,
}: GetSubdomainOptionsProps) => {
  if (!subdomainAgg || !channels) {
    return [];
  }

  return channels.reduce((acc, channel) => {
    const sortedSubdomains = sortSubdomains(channel.linked?.subdomains);

    sortedSubdomains?.forEach((subdomain) => {
      const subdomainAggregation = subdomainAgg.find((subdomainData) => {
        return String(subdomainData.id) === subdomain.id && subdomainData.count > 0;
      });

      if (!subdomainAggregation) return;

      const label = (
        <SelectInput.OptionWithSubtitle
          label={
            <ChannelWithSubdomainLabel
              name={channel.attributes.name}
              subdomainUrl={subdomain.attributes.url}
            />
          }
          subtitle={parens(subdomainAggregation.count)}
        />
      );

      acc.push({
        value: subdomain.id,
        label: label,
      });
    });

    return acc;
  }, [] as SelectOption[]);
};
