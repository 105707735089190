import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import {
  FC,
  ReactNode,
  ReactElement,
} from 'react';

import { FieldErrors } from '@/components/shared/form/FieldErrors';
import { FieldWrapperLabel } from '@/components/shared/form/FieldWrapper/FieldWrapperLabel';
import { Header } from '@/components/shared/typography/Header';
import {
  FormFieldContext,
  type FormFieldContextValue,
} from '@/contexts/form';

import style from './FieldWrapper.module.sass';

const cx = classNames.bind(style);

type FieldWrapperProps = {
  label?: ReactNode;
  isDisabled?: boolean;
  children: ReactNode;
  name: string;
  errorName?: string;
  labelTip?: ReactElement;
  isTipIconVisible?: boolean;
  isMandatory?: boolean;
  customError?: ReactNode;
  isErrorHidden?: boolean;
  isErrorWrapperIncluded?: boolean;
  errorWrapperClassName?: string;
  title?: string;
  wrapperClassName?: string;
  inputWrapperClassName?: string;
  labelClassName?: string;
};

export const FieldWrapper: FC<FieldWrapperProps> = ({
  children,
  label,
  isDisabled,
  name,
  labelTip,
  isTipIconVisible,
  isMandatory,
  customError,
  isErrorHidden = false,
  errorName = name,
  isErrorWrapperIncluded = true,
  errorWrapperClassName,
  title,
  wrapperClassName,
  inputWrapperClassName,
  labelClassName,
}) => {
  const { t } = useTranslation();

  const formFieldContextValue: FormFieldContextValue = {
    isMandatory,
    isDisabled,
  };

  const errorWrapperClass = cx('errorWrapper', errorWrapperClassName, {
    hidden: isErrorHidden,
  });
  const wrapperClassNames = cx('wrapper', wrapperClassName);
  const inputWrapperClassNames = cx('inputWrapper', inputWrapperClassName);

  const renderLabel = () => {
    if (!title && !label) {
      return null;
    }

    return (
      <div className={style.labelWrapper}>
        {
          title &&
          <Header className={style.title} variant='h4'>
            {title} {isMandatory && t('form:field.tips.required.title')}
          </Header>
        }
        <FieldWrapperLabel
          isDisabled={isDisabled}
          tooltip={labelTip}
          htmlFor={name}
          isTipIconVisible={isTipIconVisible}
          labelClassName={labelClassName}>
          {label}
        </FieldWrapperLabel>
      </div>
    );
  };

  return (
    <FormFieldContext.Provider value={formFieldContextValue}>
      <div className={wrapperClassNames}>
        {renderLabel()}
        <div className={inputWrapperClassNames}>
          {children}
        </div>
        {
          isErrorWrapperIncluded &&
          <div className={errorWrapperClass}>
            <FieldErrors name={errorName} />
            {
              customError
                ? <FieldErrors>{customError}</FieldErrors>
                : null
            }
          </div>
        }
      </div>
    </FormFieldContext.Provider>
  );
};
