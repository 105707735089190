import { BotRunStatus } from '@/resources/enums';
import { isPositive } from '@/utils/number';
import type { BotSubdomain } from '@/types/bot';
import type { AggregationEntry } from '@/types/shared';

type BotAggregationCounters = {
  [key in BotRunStatus]?: number;
} & {
  slow?: number;
  notSlow?: number;
} & {
  scheduled?: number;
  notScheduled?: number;
};

type BotsFiltersAggregationMapping = {
  company?: AggregationEntry[];
  duration?: AggregationEntry[];
  status?: AggregationEntry[];
  subdomain?: AggregationEntry[];
  scheduling?: AggregationEntry[];
};

export const aggregationToCounters = (aggregation?: BotsFiltersAggregationMapping) => {
  if (!aggregation || !aggregation.status || !aggregation.duration || !aggregation.scheduling) {
    return {};
  }

  const counters = aggregation.status.reduce((acc, status) => {
    if (typeof status.label === 'string' && status.label in BotRunStatus) {
      acc[status.label as BotRunStatus] = status.count;
    }

    return acc;
  }, {} as BotAggregationCounters);

  return {
    ...counters,
    slow: _getCountByLabel(aggregation, 'duration', true),
    notSlow: _getCountByLabel(aggregation, 'duration', false),
    scheduled: _getCountByLabel(aggregation, 'scheduling', true),
    notScheduled: _getCountByLabel(aggregation, 'scheduling', false),
  } as BotAggregationCounters;
};

export const sortSubdomains = (subdomains: BotSubdomain[] | undefined) => {
  if (!subdomains) return;

  return subdomains.sort((a, b) => {
    return a.attributes.url.localeCompare(b.attributes.url);
  });
};

export const getSlowFilterAvailableValues = (counters: BotAggregationCounters) => {
  const result = [] as ('slow' | 'notSlow')[];

  if (isPositive(counters.slow)) result.push('slow' as const);
  if (isPositive(counters.notSlow)) result.push('notSlow' as const);

  return result;
};

export const getStatusFilterAvailableValues = (counters: BotAggregationCounters) => {
  const result = [] as BotRunStatus[];

  if (isPositive(counters.OK)) result.push(BotRunStatus.OK);
  if (isPositive(counters.Failed)) result.push(BotRunStatus.Failed);
  if (isPositive(counters.Stopped)) result.push(BotRunStatus.Stopped);

  return result;
};

export const getScheduledFilterAvailableValues = (counters: BotAggregationCounters) => {
  const result = [] as ('scheduled' | 'notScheduled')[];

  if (isPositive(counters.scheduled)) result.push('scheduled' as const);
  if (isPositive(counters.notScheduled)) result.push('notScheduled' as const);

  return result;
};

const _getCountByLabel = (
  aggregation: BotsFiltersAggregationMapping,
  key: keyof BotsFiltersAggregationMapping,
  label: boolean,
) => {
  return aggregation[key]?.find((status) => status.label === label)?.count;
};
