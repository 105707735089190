import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';

import { Body } from '@/components/shared/typography/Body';
import { formatDateStyle } from '@/utils/date';
import { formatBotDuration } from '@/utils/bot/formatBotDuration';

import style from './BotRunsActivityTooltip.module.sass';

type BotRunsActivityTooltipProps = TooltipProps<number, string>;

export const BotRunsActivityTooltip = ({
  payload,
  label,
}: BotRunsActivityTooltipProps) => {
  const { t, i18n } = useTranslation();

  const renderLineName = (name: string | undefined) => {
    switch (name) {
      case 'scraped':
      case 'valid':
      case 'accepted':
      case 'duration':
        return t(`bot:activity.label.${name}`);
      default:
        return name;
    }
  };

  const renderLineLabel = (line: NonNullable<typeof payload>[number], index: number) => {
    const value = line.value || 0;

    return (
      <Body
        size='small'
        className={style.tooltipEntry}
        key={index}>
        <span style={{ color: line.color }}>
          {renderLineName(line.name)}:
        </span>
        &nbsp;
        {
          line.dataKey === 'duration' ?
            formatBotDuration(value, i18n.language) : value
        }
      </Body>
    );
  };

  const renderRunId = () => {
    if (!payload?.length) {
      return null;
    }
    const runId = payload[0].payload.runId;

    return (
      <Body
        size='small'
        className={style.tooltipRunId}>
        {t('bot:runs.runId', { runId })}
      </Body>
    );
  };

  return (
    <div className={style.tooltipBox}>
      {renderRunId()}
      {payload?.map(renderLineLabel)}
      <Body size='small' className={style.tooltipDate}>
        {formatDateStyle(label, i18n.language, { dateStyle: 'long' })}
      </Body>
    </div>
  );
};
