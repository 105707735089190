import classNames from 'classnames/bind';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone, type DropzoneOptions } from 'react-dropzone';
import { useFormikContext, type FieldInputProps } from 'formik';
import { type AxiosError } from 'axios';
import { $SpecialObject } from 'i18next/typescript/helpers';
import lodashHas from 'lodash-es/has';

import { Icon } from '@/components/shared/Icon';
import { StyledLink } from '@/components/shared/StyledLink';
import { Body } from '@/components/shared/typography/Body';
import { Loader } from '@/components/shared/loaders/Loader';
import { Button } from '@/components/shared/buttons/Button';
import { handleFileUploadFallback } from '@/components/shared/form/inputs/utils';
import { getFilenameFromUrl } from '@/utils/string/getFilenameFromUrl';
import { EMPTY_ARRAY } from '@/resources/constants';
import type { IconName, Nullable } from '@/types/shared';

import style from './FileUploader.module.sass';

const cx = classNames.bind(style);

type DropTip = {
  preClick?: string;
  click?: string;
  postClick?: string;
};

type FileUploaderBaseProps = {
  name: string;
  value: string | string[];
  /**
   * @param file file to upload
   * @returns string with new value (optional)
   */
  uploadFile?: (file: File) => Promise<Nullable<string>>;
  onBeforeRemoveFile?: (index: number) => Promise<void>;
  onAfterAddFiles?: (fileUrls: string[]) => void;
  accept?: DropzoneOptions['accept'];
  iconName?: IconName;
  dropTip?: $SpecialObject;
  description?: string;
  rejectionText?: string;
  onChange?: FieldInputProps<string>['onChange'];
  className?: string;
};

type FileUploaderWithHiddenDropzone = FileUploaderBaseProps & {
  shouldHideDropzoneAfterUpload?: boolean;
  isMultiple?: never;
};

type FileUploaderWithDropzone = FileUploaderBaseProps & {
  isMultiple?: boolean;
  shouldHideDropzoneAfterUpload?: never;
};

type FileUploaderProps =
  | FileUploaderWithHiddenDropzone
  | FileUploaderWithDropzone;

export const FileUploader = ({
  name,
  uploadFile,
  accept,
  iconName,
  dropTip,
  description,
  value = '',
  rejectionText,
  onBeforeRemoveFile,
  isMultiple,
  shouldHideDropzoneAfterUpload,
  onAfterAddFiles,
  className,
}: FileUploaderProps) => {
  const { t } = useTranslation();
  const formikHelpers = useFormikContext();
  const { setFieldValue } = formikHelpers;
  const [isBusy, setIsBusy] = useState(false);
  const [busyFileIndex, setBusyFileIndex] = useState<number | undefined>();

  const safeDropTip = (dropTip as DropTip) ?? {
    preClick: t('form:fileUploader.dropTip.preClick'),
    click: t('form:fileUploader.dropTip.click'),
    postClick: t('form:fileUploader.dropTip.postClick'),
  };

  const currentValue = useMemo(() => {
    if (isMultiple) {
      if (Array.isArray(value)) return value;

      console.warn(
        'Invalid `value` prop passed to FileUploader:',
        value,
        '(expected an array of strings).',
      );

      return EMPTY_ARRAY;
    }

    if (typeof value === 'string') return value;

    console.warn(
      'Invalid `value` prop type passed to FileUploader:',
      typeof value,
      '(expected a string).',
    );

    return '';
  }, [
    value,
    isMultiple,
  ]);

  const fileNames = useMemo(() => {
    if (Array.isArray(value)) {
      return value.map((val) => getFilenameFromUrl(val));
    }

    return [getFilenameFromUrl(value)];
  }, [
    value,
  ]);

  const updateValue = (newValue: string | string[]) => {
    if (Array.isArray(currentValue)) {
      setFieldValue(name, currentValue.concat(newValue));

      return;
    }

    setFieldValue(name, newValue);
  };

  const clearValue = async (valueToClearIndex: number) => {
    if (Array.isArray(currentValue)) {
      if (onBeforeRemoveFile) {
        setBusyFileIndex(valueToClearIndex);

        try {
          await onBeforeRemoveFile(valueToClearIndex);
        } finally {
          setBusyFileIndex(undefined);
        }
      }

      const newValue = currentValue.slice();

      newValue.splice(valueToClearIndex, 1);

      setFieldValue(name, newValue);

      return;
    }

    setFieldValue(name, '');
  };

  const handleFileUpload = async (files: File[], isDragReject: boolean) => {
    if (!uploadFile || isDragReject) return;

    setIsBusy(true);

    const [file] = files;

    try {
      if (isMultiple) {
        const promises = Promise.all(files.map(uploadFile));
        const results = await promises;
        const uploadedFileUrls = results.filter(Boolean) as string[];

        if (uploadedFileUrls.length) {
          updateValue(uploadedFileUrls);
          onAfterAddFiles?.(uploadedFileUrls);
        }
      } else {
        const newValue = await uploadFile(file);

        if (newValue) {
          updateValue(newValue);
          onAfterAddFiles?.([newValue]);
        }
      }
    } catch (err) {
      handleFileUploadFallback(
        err as AxiosError,
        formikHelpers,
        name,
        t,
      );
    } finally {
      setIsBusy(false);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    open: openFileDialog,
  } = useDropzone({
    accept: accept,
    onDrop: (files) => handleFileUpload(files, isDragReject),
    maxFiles: isMultiple ? undefined : 1,
    multiple: isMultiple,
    noClick: true,
  });

  const dropzoneClassName = cx('dropzone', className, {
    isDragAccept,
    isDragReject,
    isError: lodashHas(formikHelpers.errors, name),
  });

  const rootProps = getRootProps({ className: dropzoneClassName });
  const inputProps = getInputProps();

  const renderLoader = () => {
    return (
      <div className={style.loaderOverlay}>
        <Loader />
      </div>
    );
  };

  const renderRejectionOverlay = () => {
    return (
      <div className={style.rejectionOverlay}>
        <Body size='base'>
          {rejectionText ?? t('form:fileUploader.rejectionText')}
        </Body>
      </div>
    );
  };

  const renderReplaceButton = () => {
    if (isMultiple) {
      return null;
    }

    return (
      <Button
        variant='light'
        sizeVariant='small'
        tabIndex={-1}
        className={style.replaceButton}
        onClick={openFileDialog}>
        {t('form:fileUploader.replace')}
      </Button>
    );
  };

  const renderFile = (fileName: string | undefined, index: number) => {
    const valueWrapperClassName = cx('valueWrapper', {
      isSingleValue: !shouldHideDropzoneAfterUpload && !isMultiple,
      busy: busyFileIndex === index,
    });

    return (
      <div className={valueWrapperClassName} key={`${fileName}-${index}`}>
        <div className={style.valueFileInfo}>
          {iconName && <Icon name={iconName} className={style.fileIcon} />}
          <Body size='base' className={style.fileName}>
            {fileName ?? value}
          </Body>
        </div>
        <Button.Unstyled
          className={style.removeButton}
          onClick={() => clearValue(index)}>
          <Icon name='Delete' className={style.removeIcon} />
        </Button.Unstyled>
        {renderReplaceButton()}
      </div>
    );
  };

  const renderDropzone = () => {
    return (
      <div {...rootProps}>
        <input {...inputProps} />
        {iconName && <Icon name={iconName} className={style.fileIcon} />}
        <Body size='base' className={style.dropzoneDropTip}>
          {safeDropTip.preClick && <span>{safeDropTip.preClick}&nbsp;</span>}
          <StyledLink
            to='#'
            onClick={openFileDialog}
            effectVariant='reverse'>
            {safeDropTip.click}
          </StyledLink>
          {safeDropTip.postClick && <span>{safeDropTip.postClick}&nbsp;</span>}
        </Body>
        {
          description &&
          <Body size='small' className={style.dropzoneDescription}>
            {description}
          </Body>
        }
        {isBusy && renderLoader()}
        {isDragReject && renderRejectionOverlay()}
      </div>
    );
  };

  const renderFiles = () => {
    return (
      value &&
      fileNames.map(renderFile)
    );
  };

  if (shouldHideDropzoneAfterUpload && !isMultiple) {
    return (
      <>
        {!value && renderDropzone()}
        {renderFiles()}
      </>
    );
  }

  return (
    <>
      {renderDropzone()}
      {renderFiles()}
    </>
  );
};
