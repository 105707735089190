import { useDataQuery } from '@/hooks/shared';
import { fetchListing } from '@/queries/listing';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const useListing = (listingId?: string) => {
  const {
    data: listing,
    ...rest
  } = useDataQuery([QUERY_KEYS.listing, listingId], fetchListing, {
    enabled: Boolean(listingId),
  });

  return {
    listing: listing,
    ...rest,
  };
};
