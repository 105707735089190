import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatRelativeDate } from '@/utils/date';

export const useRelativeTime = (time: string | undefined) => {
  const { t, i18n } = useTranslation();

  const relativeTime = useMemo(() => {
    if (!time) {
      return null;
    }

    return formatRelativeDate(
      time,
      t,
      i18n.language,
      {
        showTime: true,
      },
    );
  }, [
    time,
    i18n.language,
    t,
  ]);

  return relativeTime;
};
