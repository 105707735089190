import { useTranslation } from 'react-i18next';

import { StyledLink } from '@/components/shared/StyledLink';
import { Icon } from '@/components/shared/Icon';
import { useGoBack } from '@/hooks/shared';

import style from './GoBackLink.module.sass';

type GoBackLinkProps = {
  wrapperClassName?: string;
}

export const GoBackLink = ({
  wrapperClassName,
}: GoBackLinkProps) => {
  const { t } = useTranslation();
  const { goBack } = useGoBack();

  return (
    <div className={wrapperClassName}>
      <StyledLink
        className={style.backButton}
        onClick={goBack}>
        <Icon className={style.backButtonIcon} name='ArrowLeft' />
        {t('common:back')}
      </StyledLink>
    </div>
  );
};
