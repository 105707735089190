import * as React from "react";
import { forwardRef } from "react";
const SvgCompanyActive = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M17.0024 21.0039H6.99825C4.78819 21.0039 2.99658 19.2123 2.99658 17.0022V8.99888C2.99658 7.89385 3.89239 6.99805 4.99742 6.99805H19.0032C20.1083 6.99805 21.0041 7.89385 21.0041 8.99888V17.0022C21.0041 19.2123 19.2125 21.0039 17.0024 21.0039Z",
  fill: "#003027",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M7.99854 6.998V4.99717C7.99854 3.89214 8.89434 2.99634 9.99937 2.99634H14.001C15.1061 2.99634 16.0019 3.89214 16.0019 4.99717V6.998",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.99854 6.998V4.99717C7.99854 3.89214 8.89434 2.99634 9.99937 2.99634H14.001C15.1061 2.99634 16.0019 3.89214 16.0019 4.99717V6.998",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 9.99951,
  y: 10.9998,
  width: 4.00167,
  height: 3.00125,
  rx: 0.5,
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M14.001 12.5002H17.5024C19.4362 12.5002 21.0039 10.9326 21.0039 8.99878V8.99878",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.9995 12.5002H6.49804C4.56424 12.5002 2.99658 10.9326 2.99658 8.99878V8.99878",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgCompanyActive);
export default ForwardRef;
