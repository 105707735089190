import { createContext } from 'react';

export type DeleteTrademarkValue = {
  trademarkId: string;
  trademarkRegistrationNumber: string;
  trademarkLogoUrl: string;
  trademarkName: string;
  onSuccess?: () => void;
  onCancel?: () => void;
} | null

export type DeleteTrademarkModalContextValue = {
  openModal: (data: DeleteTrademarkValue | null) => void;
  closeModal: () => void;
}

export const DeleteCompanyTrademarkContext = (
  createContext<DeleteTrademarkModalContextValue | null>(null)
);
