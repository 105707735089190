import { useDataQuery } from '@/hooks/shared';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { fetchCompanyTrademarks } from '@/queries/trademark';
import { PaginationConfig, SortConfig } from '@/types/shared';
import { TrademarksFilterParams } from '@/types/trademark';

export type UseTrademarksParams = {
  filter: TrademarksFilterParams;
  pagination?: PaginationConfig;
  sort?: SortConfig;
}

export const useCompanyTrademarks = (params: UseTrademarksParams) => {
  const {
    data: queryData,
    ...rest
  } = useDataQuery(
    [QUERY_KEYS.companyTrademarks, params],
    fetchCompanyTrademarks,
    {
      enabled: Boolean(params),
    },
  );

  return {
    ...queryData,
    ...rest,
  };
};
