import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useInputHandlers } from '@/hooks/utils';
import { isUrlExternal } from '@/utils/misc';

import style from './ImageUploaderTextInput.module.sass';

import { TextInput } from '../../TextInput';

type ImageUploaderTextInputProps = {
  isBusy?: boolean;
  onSubmit: (value: string) => void | Promise<void>;
  value?: string;
  name: string;
  error?: boolean;
  warning?: boolean;
};

export const ImageUploaderTextInput = ({
  isBusy,
  onSubmit,
  value,
  name,
  error,
  warning,
}: ImageUploaderTextInputProps) => {
  const { t } = useTranslation();

  const [inputValue, updateInputValue, setInputValue] = useInputHandlers('');

  const submitValue = () => {
    if (value === inputValue) {
      return;
    }

    onSubmit(inputValue);
  };

  const isExternal = useMemo(() => {
    return isUrlExternal(value || '');
  }, [
    value,
  ]);

  useEffect(() => {
    if (value && isExternal) {
      setInputValue(value);
    } else {
      setInputValue('');
    }
  }, [
    value,
    setInputValue,
    isExternal,
  ]);

  return (
    <div className={style.textInputWrapper}>
      <div className={style.separator}></div>
      <TextInput
        sizeVariant='medium'
        placeholder={t('form:imageUploader.textInput.placeholder')}
        name={name}
        className={style.textInput}
        value={inputValue}
        disabled={isBusy}
        error={error}
        warning={warning}
        onChange={updateInputValue}
        onBlur={submitValue}
      />
    </div>
  );
};
