import { createContext } from 'react';

export type DefaultNotificationTemplateModalData = {
  templateName: string;
  templateId?: string;
  companyId?: string;
  isActive?: boolean;
};

export type DefaultNotificationTemplateContextValue = {
  openModal: (data: DefaultNotificationTemplateModalData) => void;
  closeModal: () => void;
};

export const DefaultNotificationTemplateContext = createContext<
  DefaultNotificationTemplateContextValue | null
>(null);
