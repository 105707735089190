import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSortParams } from '@/hooks/shared/useSortParams';
import { useIsOnInstance } from '@/hooks/shared';
import { SortableOption } from '@/types/sortable';

export const DEFAULT_STORES_SORT_BY = 'name';

export const useStoresSortParams = () => {
  const { t } = useTranslation();
  const {
    sortBy: sortByParam,
  } = useSortParams();

  const sortBy = sortByParam || DEFAULT_STORES_SORT_BY;

  const { isAdminInstance } = useIsOnInstance();

  const baseOptions = useMemo<SortableOption[]>(() => ([
    {
      value: 'name',
      label: t('store:sortable.labels.nameAz'),
    },
    {
      value: '-name',
      label: t('store:sortable.labels.nameZa'),
    },
  ]), [
    t,
  ]);

  const customerOptions = useMemo<SortableOption[]>(() => ([
    {
      value: 'listings.count',
      label: t('store:sortable.labels.listings.least'),
    },
    {
      value: '-listings.count',
      label: t('store:sortable.labels.listings.most'),
    },
  ]), [
    t,
  ]);

  if (isAdminInstance) {
    return {
      sortBy,
      options: baseOptions,
    };
  }

  return {
    sortBy,
    options: [
      ...baseOptions,
      ...customerOptions,
    ],
  };
};
