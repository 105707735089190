import { StyledLinkExternal } from '@/components/shared/StyledLinkExternal';
import { Body } from '@/components/shared/typography/Body';
import { useConfig } from '@/hooks/shared/useConfig';
import { getCompanyAdminLoginUrl } from '@/utils/misc';

type CompanyAppLinkProps = {
  hostname: string;
  className?: string;
  enabled: boolean;
};

export const CompanyAppLink = ({
  hostname,
  className,
  enabled,
}: CompanyAppLinkProps) => {
  const { config } = useConfig();

  const companyLinkText = `${hostname}.${config.VITE_DOMAIN}`;

  if (!enabled) {
    return (
      <Body size='small' className={className}>
        {companyLinkText}
      </Body>
    );
  }

  return (
    <StyledLinkExternal
      className={className}
      href={getCompanyAdminLoginUrl(hostname, config.VITE_DOMAIN)}>
      {companyLinkText}
    </StyledLinkExternal>
  );
};
