import classNames from 'classnames/bind';

import { FallbackIconBox } from '@/components/shared/FallbackIconBox';
import { ImageWithFallback } from '@/components/shared/ImageWithFallback';
import { type HeaderVariants } from '@/components/shared/typography/Header';
import { MultilineHeader } from '@/components/shared/MultilineHeader';
import { StyledLink } from '@/components/shared/StyledLink';
import { useStopEventPropagation } from '@/hooks/dom';

import style from './ListingTitleWithImage.module.sass';

const cx = classNames.bind(style);

type ListingTitleWithImageProps = {
  productImageUrl?: string;
  productName?: string;
  headerVariant?: HeaderVariants;
  nameWrapperClassName?: string;
  listingRoute?: string;
};

export const ListingTitleWithImage = ({
  productImageUrl,
  productName,
  headerVariant = 'h4',
  nameWrapperClassName,
  listingRoute,
}: ListingTitleWithImageProps) => {
  const nameClassName = cx('productName', nameWrapperClassName);
  const stopEventPropagation = useStopEventPropagation();

  const renderLinkOrHeader = () => {
    const header = (
      <MultilineHeader variant={headerVariant} className={nameClassName}>
        {productName}
      </MultilineHeader>
    );

    if (listingRoute) {
      return (
        <StyledLink
          onClick={stopEventPropagation}
          to={listingRoute}>
          {header}
        </StyledLink>
      );
    }

    return header;
  };

  return (
    <div className={style.productWrapper}>
      <div className={style.imageWrapper}>
        <ImageWithFallback
          src={productImageUrl}
          alt={productName}
          className={style.image}
          fallback={{ icon: <FallbackIconBox /> }}
        />
      </div>
      {renderLinkOrHeader()}
    </div>
  );
};
