import { useAuthUserRole } from '@/hooks/user';
import { UserRoles } from '@/resources/enums';
import { useIsGreyScoutEmployee, useIsOnInstance } from '@/hooks/shared';
import { UserSystemRole } from '@/types/user';

const {
  Guest,
  User,
  Admin,
} = UserRoles;

export const useUserSystemRole = (): UserSystemRole => {
  const { authUserRole } = useAuthUserRole();
  const {
    isCustomerInstance,
    isAdminInstance,
  } = useIsOnInstance();

  const isGreyScoutEmployee = useIsGreyScoutEmployee();

  const safeCheckCustomer = isCustomerInstance && !isGreyScoutEmployee;
  const safeCheckAdmin = isAdminInstance || isGreyScoutEmployee;

  const isGuestRole = authUserRole === Guest;
  const isUserRole = authUserRole === User;
  const isAdminRole = authUserRole === Admin;

  return {
    isGuest: isGuestRole && safeCheckCustomer,
    isUser: isUserRole && safeCheckCustomer,
    isAdmin: isAdminRole && safeCheckCustomer,

    isGsGuest: isGuestRole && safeCheckAdmin,
    isGsUser: isUserRole && safeCheckAdmin,
    isGsAdmin: isAdminRole && safeCheckAdmin,
  };
};
