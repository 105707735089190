import { Link } from 'react-router-dom';
import { type PropsWithChildren } from 'react';
import { type BreadcrumbMatch } from 'use-react-router-breadcrumbs';

import { Icon } from '@/components/shared/Icon';
import { usePersistQueryString } from '@/hooks/browser';
import type {
  IconName,
  PersistedQueryStringKey,
} from '@/types/shared';

import style from './Breadcrumb.module.sass';

type BreadcrumbProps = PropsWithChildren<{
  href: string;
  iconName?: IconName;
  isDisabled?: boolean;
}>;

type BreadcrumbWithPersistedQueryStringProps = BreadcrumbProps & {
  persistedQueryStringKey: PersistedQueryStringKey;
  match: BreadcrumbMatch;
}

export const Breadcrumb = ({
  href,
  iconName,
  children,
  isDisabled,
}: BreadcrumbProps) => {
  const renderIcon = () => {
    return (
      iconName &&
      <Icon name={iconName} className={style.breadcrumbIcon} />
    );
  };

  const renderLinkOrText = () => {
    if (isDisabled) {
      return (
        <div className={style.disabled}>
          {renderIcon()}
          {children}
        </div>
      );
    }

    return (
      <Link className={style.link} to={href}>
        {renderIcon()}
        {children}
      </Link>
    );
  };

  return (
    <div className={style.breadcrumb}>
      {renderLinkOrText()}
      <div className={style.separator}>/</div>
    </div>
  );
};

export const BreadcrumbWithPersistedQueryString = ({
  href,
  persistedQueryStringKey,
  match,
  ...restProps
}: BreadcrumbWithPersistedQueryStringProps) => {
  const persistanceKey = typeof persistedQueryStringKey === 'function'
    ? persistedQueryStringKey(match)
    : persistedQueryStringKey;

  const queryString = usePersistQueryString(persistanceKey);

  const linkTo = queryString ? href + queryString : href;

  return (
    <Breadcrumb
      href={linkTo}
      {...restProps}
    />
  );
};
