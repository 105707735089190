import * as React from "react";
import { forwardRef } from "react";
const SvgAdministrationActive = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M2.99658 16.0018V7.99842C2.99658 5.23585 5.23609 2.99634 7.99867 2.99634H16.002C18.7646 2.99634 21.0041 5.23585 21.0041 7.99842V16.0018C21.0041 18.7643 18.7646 21.0038 16.002 21.0038H7.99867C5.23609 21.0038 2.99658 18.7643 2.99658 16.0018Z",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M15.0017 13.5008V6.99805",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M13.251 16.0018V14.5011C13.251 13.9486 13.6989 13.5007 14.2514 13.5007H15.752C16.3045 13.5007 16.7524 13.9486 16.7524 14.5011V16.0018C16.7524 16.5543 16.3045 17.0022 15.752 17.0022H14.2514C13.6989 17.0022 13.251 16.5543 13.251 16.0018Z",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.99923 10.4995V17.0022",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M10.75 7.99846V9.49909C10.75 10.0516 10.3021 10.4995 9.74958 10.4995H8.24895C7.69644 10.4995 7.24854 10.0516 7.24854 9.49909V7.99846C7.24854 7.44595 7.69644 6.99805 8.24895 6.99805H9.74958C10.3021 6.99805 10.75 7.44595 10.75 7.99846Z",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgAdministrationActive);
export default ForwardRef;
