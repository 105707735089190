import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { sortBy } from '@/utils/array';
import { parens } from '@/utils/string';
import type { AggregationEntry } from '@/types/shared';

type GetCompanyOptionsProps = {
  companyAgg?: AggregationEntry[];
  optionWrapperClassName?: string;
};

export const getCompanyOptions = ({
  companyAgg,
  optionWrapperClassName,
}: GetCompanyOptionsProps) => {
  if (!companyAgg?.length) {
    return [];
  }

  const companies = sortBy(companyAgg, 'label');

  const wrapOption = (contents: JSX.Element) => {
    if (!optionWrapperClassName) {
      return contents;
    }

    return (
      <div className={optionWrapperClassName}>
        {contents}
      </div>
    );
  };

  return companies.map((company) => ({
    value: String(company.id),
    label: wrapOption(
      <SelectInput.OptionWithSubtitle
        label={company.label}
        subtitle={parens(company.count)}
      />,
    ),
  }));
};
