import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import useUnmount from 'react-use/esm/useUnmount';
import classNames from 'classnames/bind';

import { Body } from '@/components/shared/typography/Body';
import { StoreReview } from '@/components/store/StoreReview';
import { BotSubdomain } from '@/components/bot/BotSubdomain';
import { VerticalTable } from '@/components/shared/tables/VerticalTable';
import { CountryLogotype } from '@/components/shared/CountryLogotype';
import { StoreStatus } from '@/components/store/StoreStatus';
import { StoreListItemStoreName } from '@/components/store/StoreListItemStoreName';
import { StyledLinkExternal } from '@/components/shared/StyledLinkExternal';
import { CardContextMenuProps } from '@/components/shared/Card/CardContextMenu';
import { AllowStoreContext } from '@/contexts/store/AllowStoreContext';
import { useIsOnInstance, useMediaQuery, useUnsafeContext } from '@/hooks/shared';
import { useUserSystemRole } from '@/hooks/user';
import { swapIndexes } from '@/utils/array';
import { TableColumnConfig, TableConfig } from '@/types/tables';
import type { Store } from '@/types/store';
import type { ID } from '@/types/shared';

import style from './StoresList.module.sass';

const cx = classNames.bind(style);

type StoresListProps = {
  stores: Store[];
  getStoreRoute: (storeId: ID) => string;
  contextMenu: (store: Store) => CardContextMenuProps;
  onRowClick: (store: Store) => void;
};

export const StoresList = ({
  stores,
  getStoreRoute,
  contextMenu,
  onRowClick,
}: StoresListProps) => {
  const { t } = useTranslation();
  const {
    isAdminInstance,
    isCustomerInstance,
  } = useIsOnInstance();
  const { isGuest } = useUserSystemRole();
  const isTablet = useMediaQuery('(max-width: 1280px)');

  const {
    closeModal,
  } = useUnsafeContext(AllowStoreContext);

  useUnmount(() => closeModal());

  const storeReviewClassName = cx({
    storeReview: isAdminInstance,
  });

  const columns = useMemo<TableColumnConfig<Store>[]>(() => {
    const storeColumns: TableColumnConfig<Store>[] = [
      {
        header: t('store:attributes.channel'),
        width: 200,
        render: (store) => (
          <BotSubdomain
            name={store.linked?.subdomain?.attributes.name}
            url={store.linked?.subdomain?.attributes.url}
            isWrappedToNextLine={true}
          />
        ),
      },
      {
        header: t('store:attributes.name'),
        colSpan: isTablet ? 5 : 8,
        render: (store) => (
          <>
            <StoreListItemStoreName
              storeName={store.attributes.name || t('common:unknown')}
            />
            <StyledLinkExternal
              className={style.storeLink}
              iconName='LinkOpen'
              href={store.attributes.url}>
              {store.attributes.url}
            </StyledLinkExternal>
          </>
        ),
      },
      {
        header: t('store:attributes.country'),
        colSpan: 3,
        className: style.sellersCountryColumn,
        render: (store) => (
          <Body size='base'>
            <CountryLogotype
              countryName={store.linked?.country?.attributes.name}
            />
          </Body>
        ),
      },
      {
        header: t('store:attributes.rating'),
        colSpan: 4,
        render: (store) => {
          return (
            <div className={storeReviewClassName}>
              <StoreReview
                text={store.attributes.rating ?? t('common:unknown')}
                reviewCount={store.attributes.reviewCount}
                reviewCountSize='base'
              />
            </div>
          );
        },
      },
      {
        header: t('store:attributes.brandsCount'),
        colSpan: 3,
        render: (store) => (
          <Body size='base'>
            {store.attributes.brandsCount}
          </Body>
        ),
        className: style.columnRight,
        hidden: isAdminInstance,
      },
      {
        header: t('store:attributes.listingCount'),
        colSpan: 3,
        render: (store) => (
          <Body size='base'>
            {store.attributes.listingsCount}
          </Body>
        ),
        className: style.columnRight,
        hidden: isAdminInstance,
      },
      {
        header: t('store:attributes.email'),
        colSpan: 3,
        render: (store) => (
          <Body size='base' className={style.email}>
            {store.attributes.email ?? t('common:unknown')}
          </Body>
        ),
        hidden: isCustomerInstance,
      },
      {
        header: t('store:attributes.status'),
        colSpan: 4,
        render: (store) => (
          <div className={style.storeStatusWrapper}>
            <StoreStatus
              status={store.attributes.status}
              allowedUntil={store.linked?.whitelisting?.attributes.until}
            />
          </div>
        ),
        hidden: isAdminInstance,
      },
    ];

    if (isAdminInstance) {
      return swapIndexes(storeColumns, 3, storeColumns.length - 2) as TableColumnConfig<Store>[];
    }

    return storeColumns;
  }, [
    t,
    isTablet,
    isAdminInstance,
    isCustomerInstance,
    storeReviewClassName,
  ]);

  const tableConfig = useMemo<TableConfig<Store>>(() => ({
    columns,
    getContextMenuProps: (store) => {
      if (isAdminInstance || isGuest) {
        return {
          shouldDisplayPlaceholder: true,
        };
      }

      return contextMenu(store);
    },
    onRowClick,
    getResourceRoute: (store) => getStoreRoute(store.id),
  }), [
    onRowClick,
    getStoreRoute,
    isAdminInstance,
    isGuest,
    contextMenu,
    columns,
  ]);

  return (
    <VerticalTable
      config={tableConfig}
      data={stores}
    />
  );
};
