import {
  type ReactElement,
  type ReactNode,
} from 'react';
import classNames from 'classnames/bind';

import { Breadcrumbs } from '@/components/shared/Breadcrumbs';
import { Container } from '@/components/shared/containers/Container';

import style from './PageWithBreadcrumbs.module.sass';

const cx = classNames.bind(style);

type PageWithBreadcrumbsProps = {
  children: ReactNode;
  breadcrumbsAside?: ReactElement;
  wrapperClassName?: string;
};

export const PageWithBreadcrumbs = ({
  children,
  breadcrumbsAside,
  wrapperClassName,
}: PageWithBreadcrumbsProps) => {

  const breadcrumbsWrapper = cx(
    style.breadcrumbsWrapper,
    wrapperClassName,
  );

  return (
    <>
      <div className={breadcrumbsWrapper}>
        <div className={style.breadcrumbs}>
          <Container>
            <Breadcrumbs />
          </Container>
        </div>
        {
          breadcrumbsAside &&
          <div className={style.aside}>
            {breadcrumbsAside}
          </div>
        }
      </div>
      {children}
    </>
  );
};
