import { produce } from 'immer';

import { type ListingsQueryKey } from '@/queries/listing/fetchListings';
import type { ListingsFilterParams } from '@/types/listing';

export const removeListingFilterValue = (
  queryKey: ListingsQueryKey,
  filterKey: keyof ListingsFilterParams,
) => {
  return produce(queryKey, (draft) => {
    if (draft[1]?.filter) {
      draft[1].filter[filterKey] = undefined;
    }
  });
};
