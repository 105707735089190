import { TFunction } from 'i18next';

import { Nullable } from '@/types/shared';

type ActivableRecord = {
  attributes: {
    isActive?: boolean;
  };
};

export const checkIsActive = (record: ActivableRecord | undefined) => {
  return record?.attributes.isActive;
};

type GetTooltipMessageForUncreatableAction = {
  isTrademarkAddedToSubdomain: boolean;
  hasActiveLoa: Nullable<number>;
  t: TFunction;
};

export const getTooltipMessageForUncreatableAction = ({
  hasActiveLoa,
  isTrademarkAddedToSubdomain,
  t,
}: GetTooltipMessageForUncreatableAction) => {
  if (!hasActiveLoa) {
    return t('store:listingsList.noActiveLoa');
  }

  if (!isTrademarkAddedToSubdomain) {
    return t('store:listingsList.noTrademarksForChannel');
  }
};
