import { useCallback } from 'react';
import { useQueryParams, NumberParam, withDefault, QueryParamOptions, UrlUpdateType } from 'use-query-params';

import {
  PAGE,
  PER_PAGE,
} from '@/resources/queryParams';

export const DEFAULT_INITIAL_PAGE = 1;
export const DEFAULT_INITIAL_PER_PAGE = 50;
export const DEFAULT_ALLOWED_PER_PAGE_VALUES = [
  10,
  25,
  50,
  100,
];

export const usePaginationParams = (
  initialPage = DEFAULT_INITIAL_PAGE,
  initialPerPage = DEFAULT_INITIAL_PER_PAGE,
  options?: QueryParamOptions,
) => {
  const [params, setValues] = useQueryParams({
    [PAGE]: withDefault(NumberParam, initialPage),
    [PER_PAGE]: withDefault(NumberParam, initialPerPage),
  }, options);

  const page = params.page;
  const perPage = params.perPage;

  const updatePage = useCallback((pageNumber: number | undefined, updateType?: UrlUpdateType) => {
    setValues({ [PAGE]: pageNumber }, updateType);
  }, [
    setValues,
  ]);

  const updatePerPage = useCallback((perPageNumber: number) => {
    setValues({
      [PER_PAGE]: perPageNumber,
      [PAGE]: 1,
    });
  }, [
    setValues,
  ]);

  return {
    page,
    updatePage,
    perPage,
    updatePerPage,
  };
};
