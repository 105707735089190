import { type QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import type {
  Listing,
  ListingsRequestMeta,
  ListingReseller,
  ListingStore,
  ListingSubdomain,

} from '@/types/listing';

export type ListingIncludedRecord =
  | ListingReseller
  | ListingStore
  | ListingSubdomain;

export const fetchListing = async (context: QueryFunctionContext) => {
  const [, listingId] = context.queryKey;

  const url = `/api/listings/${listingId}`;

  const response = await Api.get<
    Listing,
    ListingIncludedRecord,
    ListingsRequestMeta
  >(url);

  return linkCollectionRelationships(response);
};
