import { type QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import { QUERY_KEYS } from '@/resources/dataKeys';
import type {
  Reseller,
  ResellerStore,
  ResellerSubdomain,
} from '@/types/reseller';

type ResellerQueryKey = [typeof QUERY_KEYS.reseller, number | string | undefined];

type ResellerIncludedRecord =
  | ResellerStore
  | ResellerSubdomain;

export const fetchReseller = async (context: QueryFunctionContext<ResellerQueryKey>) => {
  const [, resellerId] = context.queryKey;

  const url = `/api/resellers/${resellerId}`;

  const response = await Api.get<Reseller, ResellerIncludedRecord>(url);

  return linkCollectionRelationships(response);
};
