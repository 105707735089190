import classNames from 'classnames/bind';

import { Icon } from '@/components/shared/Icon';

import style from './LanguageLogotype.module.sass';

const cx = classNames.bind(style);

type LanguageLogotypeProps = {
  language: string;
  className?: string;
};

export const LanguageLogotype = ({
  language,
  className,
}: LanguageLogotypeProps) => {
  const wrapperClassName = cx('wrapper', className);

  return (
    <div className={wrapperClassName}>
      <Icon name='Website' className={style.icon} />
      <span className={style.name}>
        &nbsp;{language}
      </span>
    </div>
  );
};
