import classNames from 'classnames/bind';
import { memo, PropsWithChildren, useCallback } from 'react';
import range from 'lodash-es/range';
import { useTranslation } from 'react-i18next';

import { PaginatePage } from '@/components/pagination/PaginatePage/PaginatePage';
import { Button } from '@/components/shared/buttons';
import { Icon } from '@/components/shared/Icon';
import type { IconName } from '@/types/shared';

import style from './PaginatePages.module.sass';
import { getPagesRange, DEFAULT_OFFSET } from './constants';

const cx = classNames.bind(style);

type PaginatePagesProps = {
  page: number;
  pages: number;
  offset?: number;
  updatePage: (newPage: number) => void;
  onAfterChange?: () => void;
};

type SwitchButtonProps = PropsWithChildren<{
  className?: string;
  leftIconName?: IconName;
  rightIconName?: IconName;
  disabled?: boolean;
  onClick?: () => void;
}>;

const SwitchButton = ({
  className,
  disabled,
  leftIconName,
  rightIconName,
  children,
  onClick,
}: SwitchButtonProps) => {
  const buttonClassName = cx('switchButton', className, {
    disabled,
  });

  return (
    <Button.Unstyled
      onClick={onClick}
      disabled={disabled}
      className={buttonClassName}>
      {
        leftIconName &&
        <Icon name={leftIconName} />
      }
      {children}
      {
        rightIconName &&
        <Icon name={rightIconName} />
      }
    </Button.Unstyled>
  );
};

const Ellipsis = () => {
  return (
    <div className={style.ellipsis}>
      ...
    </div>
  );
};

export const PaginatePages = memo(({
  page,
  pages,
  updatePage,
  onAfterChange,
  offset = DEFAULT_OFFSET,
}: PaginatePagesProps) => {
  const { t } = useTranslation();

  const pagesRange = getPagesRange(page, pages, offset);

  const changePage = useCallback((newPage: number) => {
    updatePage(newPage);

    if (onAfterChange) onAfterChange();
  }, [
    updatePage,
    onAfterChange,
  ]);

  const goToPreviousPage = useCallback(() => {
    changePage(page - 1);
  }, [
    page,
    changePage,
  ]);

  const goToNextPage = useCallback(() => {
    changePage(page + 1);
  }, [
    page,
    changePage,
  ]);

  return (
    <div className={style.wrapper}>
      <SwitchButton
        className={style.prevButton}
        disabled={page <= 1}
        onClick={goToPreviousPage}
        leftIconName='ChevronLeft'>
        <span>{t('common:previous')}</span>
      </SwitchButton>
      {
        pagesRange.from > 1 &&
        <PaginatePage
          page={1}
          pages={pages}
          currentPage={page}
          updatePage={changePage}
        />
      }
      {
        pagesRange.from > 2 &&
        <Ellipsis />
      }
      {range(pagesRange.from, pagesRange.to + 1).map((pageNumber) => (
        <PaginatePage
          key={pageNumber}
          page={pageNumber}
          pages={pages}
          currentPage={page}
          updatePage={changePage}
        />
      ))}
      {
        pagesRange.to < pages - 1 &&
        <Ellipsis />
      }
      {
        pagesRange.to < pages &&
        <PaginatePage
          page={pages}
          pages={pages}
          currentPage={page}
          updatePage={changePage}
        />
      }
      <SwitchButton
        className={style.nextButton}
        disabled={page >= pages}
        onClick={goToNextPage}
        rightIconName='ChevronRight'>
        <span>{t('common:next')}</span>
      </SwitchButton>
    </div>
  );
});

PaginatePages.displayName = 'PaginatePages';
