import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { ViewMode } from '@/resources/enums';
import { Nullable } from '@/types/shared';

export const DEFAULT_VIEW_MODE = ViewMode.List;
const LS_KEY = 'gsViewMode';

type ViewModeStore = {
  preferredViewMode: Nullable<ViewMode>;
  setPreferredViewMode: (mode: ViewMode) => void;
  recentViewMode: Nullable<ViewMode>;
  setRecentViewMode: (mode: ViewMode) => void;
};

export const useViewModeStore = create<ViewModeStore>()(
  persist(
    (set) => ({
      preferredViewMode: DEFAULT_VIEW_MODE,
      recentViewMode: null,
      setPreferredViewMode: (preferredViewMode: ViewMode) => set(() => ({ preferredViewMode })),
      setRecentViewMode: (recentViewMode: ViewMode) => set(() => ({ recentViewMode })),
    }),
    {
      name: LS_KEY,
    },
  ),
);
