import classNames from 'classnames/bind';

import { Kicker } from '@/components/shared/typography/Kicker';
import { Body, type BodyProps } from '@/components/shared/typography/Body';
import { Skeleton } from '@/components/shared/loaders/Skeleton';
import { DetailRow, DetailsTableConfig } from '@/types/tables';

import style from './DetailsTable.module.sass';

const cx = classNames.bind(style);

type DetailsTableProps = {
  config: DetailsTableConfig;
  wrapperClassName?: string;
};

type DetailProps = {
  row: DetailRow;
  bodySize?: BodyProps['size'];
  isLoading?: boolean;
};

const Detail = ({
  row,
  bodySize,
  isLoading,
}: DetailProps) => {
  if (row.hidden) {
    return null;
  }

  const rowBodySize = row.bodySize || bodySize;
  const rowClassNames = cx('detailWrapper', row.className);

  const renderBody = () => {
    if (isLoading) {
      return (
        <div className={row.skeletonsClassName}>
          <Skeleton
            height={24}
            skeletonsNumber={row.skeletonsNumber}
          />
        </div>
      );
    }

    if (typeof row.body === 'string' || typeof row.body === 'number') {
      return (
        <div className={style.text}>
          <Body size={rowBodySize || 'small'}>
            {row.body}
          </Body>
        </div>
      );
    }

    if (rowBodySize) {
      return (
        <Body size={rowBodySize}>
          {row.body}
        </Body>
      );
    }

    return row.body;
  };

  return (
    <div className={rowClassNames} data-cy='detailsTable__detail'>
      <div className={style.kicker}>
        <Kicker size='base' data-cy='detailsTable__detailHeader'>
          {row.header}
        </Kicker>
      </div>
      {renderBody()}
    </div>
  );
};

export const DetailsTable = ({
  config,
  wrapperClassName,
}: DetailsTableProps) => {
  const renderRow = (row: DetailRow, index: number) => {
    return (
      <Detail
        key={index}
        row={row}
        bodySize={config.bodySize}
        isLoading={config.isLoading}
      />
    );
  };

  return (
    <div className={wrapperClassName} data-cy='detailsTable'>
      {config.rows.map(renderRow)}
    </div>
  );
};
