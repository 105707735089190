import { TFunction } from 'i18next';

import { BotRunStatus } from '@/resources/enums';

export const getBotStatusLabel = (status: string, t: TFunction) => {
  switch (status) {
    case BotRunStatus.OK:
      return t('bot:runStatus.success');
    case BotRunStatus.Failed:
      return t('bot:runStatus.failed');
    case BotRunStatus.Stopped:
      return t('bot:runStatus.stopped');
    default:
      return status;
  }
};
