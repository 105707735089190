import { type TFunction } from 'i18next';

import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { parens } from '@/utils/string';
import type { AggregationEntry } from '@/types/shared';

type GetStatusOptionsParams = {
  t: TFunction;
  emailAgg?: AggregationEntry[];
  optionWrapperClassName?: string;
};

export const getEmailOptions = ({
  t,
  emailAgg,
  optionWrapperClassName,
}: GetStatusOptionsParams) => {

  return emailAgg?.map((email) => ({
    value: String(email.id),
    label: (
      <div className={optionWrapperClassName}>
        <SelectInput.OptionWithSubtitle
          label={t(`store:filterable.options.email.${email.label}`)}
          subtitle={parens(email.count)}
        />
      </div>
    ),
  }));
};
