import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSortParams } from '@/hooks/shared/useSortParams';
import { SortableOption } from '@/types/sortable';

const DEFAULT_SORT_BY = 'product_name';

export const useListingsSortParams = (
  customOptions?: SortableOption[],
  customSortBy?: string,
) => {
  const { t } = useTranslation();
  const {
    sortBy: sortByParam,
  } = useSortParams();

  const sortBy = sortByParam || customSortBy || DEFAULT_SORT_BY;

  const defaultOptions = useMemo<SortableOption[]>(() => {
    return ([
      {
        value: 'product_name',
        label: t('listing:sortable.labels.productNameAz'),
      },
      {
        value: '-product_name',
        label: t('listing:sortable.labels.productNameZa'),
      },
      {
        value: 'price',
        label: t('listing:sortable.labels.priceAz'),
      },
      {
        value: '-price',
        label: t('listing:sortable.labels.priceZa'),
      },
    ]);
  }, [
    t,
  ],
  );

  const options = customOptions || defaultOptions;

  return {
    sortBy,
    options,
  };
};
