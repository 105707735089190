import classNames from 'classnames/bind';
import { ReactNode } from 'react';

import { Icon } from '@/components/shared/Icon/Icon';
import type { IconName } from '@/types/shared';

import style from './IconWithGlow.module.sass';

const cx = classNames.bind(style);

type IconWithGlowBase = {
  type?: 'danger' | 'neutral' | 'success' | 'info';
  sizeVariant?: 'small' | 'medium' | 'large' | 'xl';
  isOuterCircleHidden?: boolean;
};

type IconWithGlowBaseWithDefaultIcon = IconWithGlowBase & {
  iconName: IconName;
  customIcon?: never;
};
type IconWithGlowBaseWithCustomIcon = IconWithGlowBase & {
  iconName?: never;
  customIcon: ReactNode;
};

export type IconWithGlowProps = IconWithGlowBaseWithDefaultIcon | IconWithGlowBaseWithCustomIcon;

export const IconWithGlow = ({
  type,
  iconName,
  sizeVariant = 'medium',
  customIcon,
  isOuterCircleHidden,
}: IconWithGlowProps) => {
  const iconWrapperClassName = cx(style.iconWrapper, type, sizeVariant);
  const outerCircleClassName = cx('outerCircle', type);
  const circleClassName = cx('circle', type);
  const iconClassName = cx('icon', type, sizeVariant);

  const renderIcon = () => {
    if (iconName) {
      return (
        <Icon name={iconName} className={iconClassName} />
      );
    }

    return customIcon;
  };

  const icon = (
    <div className={circleClassName}>
      <div className={iconWrapperClassName}>
        {renderIcon()}
      </div>
    </div>
  );

  const renderContent = () => {
    if (isOuterCircleHidden) {
      return icon;
    }

    return (
      <div className={outerCircleClassName}>
        {icon}
      </div>
    );
  };

  return renderContent();
};
