import { useState } from 'react';

import { TextInput } from '@/components/shared/form/inputs/TextInput';
import { Icon } from '@/components/shared/Icon';

import style from './PasswordInput.module.sass';

export const PasswordInput = ({
  ...restProps
}) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const togglePasswordVisibility = () => setIsPasswordShown(!isPasswordShown);

  const inputType = isPasswordShown ? 'text' : 'password';

  return (
    <div className={style.wrapper}>
      <Icon
        name={isPasswordShown ? 'EyeOpen' : 'EyeClosed'}
        className={style.toggleIcon}
        onClick={togglePasswordVisibility}
      />
      <TextInput {...restProps} type={inputType} />
    </div>
  );
};
