import { useEffect } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import {
  Form,
  useFormikContext,
  FormikFormProps as DefaultFormikFormProps,
} from 'formik';

type FormikFormProps = DefaultFormikFormProps & {
  submitOnChange?: boolean;
};

const FormSubmittingOnChange = ({
  ...restProps
}: DefaultFormikFormProps) => {
  const {
    values,
    submitForm,
    dirty,
  } = useFormikContext();

  useEffect(() => {
    if (isEmpty(values) || !dirty) {
      return;
    }

    submitForm();
  }, [
    dirty,
    submitForm,
    values,
  ]);

  return (
    <Form {...restProps} />
  );
};

export const FormikForm = ({
  submitOnChange,
  ...restProps
}: FormikFormProps) => {
  if (!submitOnChange) {
    return <Form {...restProps} />;
  }

  return (
    <FormSubmittingOnChange {...restProps} />
  );
};
