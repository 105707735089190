export const preloadImage = (url: string) => new Promise<void>((resolve, reject) => {
  const image = new Image();

  image.src = url;
  image.srcset = url;

  image.addEventListener('load', () => {
    resolve();
  });

  image.addEventListener('error', () => {
    reject(new Error());
  });
});
