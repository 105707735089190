import * as React from "react";
import { forwardRef } from "react";
const SvgCopy = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("rect", {
  x: 6.99805,
  y: 6.99805,
  width: 14.0058,
  height: 14.0058,
  rx: 2,
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M6.99825 17.0022H4.99742C3.89239 17.0022 2.99658 16.1064 2.99658 15.0013V4.99717C2.99658 3.89214 3.89239 2.99634 4.99742 2.99634H15.0016C16.1066 2.99634 17.0024 3.89214 17.0024 4.99717V6.998",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgCopy);
export default ForwardRef;
