import { produce } from 'immer';

import { type StoresQueryKey } from '@/queries/store/fetchCompanyStores';
import { type StoresFilterParams } from '@/types/store';

export const removeStoresFilterValue = (
  queryKey: StoresQueryKey,
  filterKey: keyof StoresFilterParams,
) => {
  return produce(queryKey, (draft) => {
    if (draft[1]?.filter) {
      draft[1].filter[filterKey] = undefined;
    }
  });
};
