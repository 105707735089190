import { createContext } from 'react';

export type DeleteCompanyModalData = {
  companyId: string;
  companyName: string;
  companyLogoUrl: string;
  onCancel?: () => void;
};

export type DeleteCompanyContextValue = {
  openModal: (data: DeleteCompanyModalData | null) => void;
  closeModal: () => void;
};

export const DeleteCompanyContext = createContext<DeleteCompanyContextValue | null>(null);

