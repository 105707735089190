import useUnmount from 'react-use/esm/useUnmount';

import { BotGridCard } from '@/components/bot/BotGridCard';
import { GridContainer } from '@/components/shared/containers/GridContainer';
import { NavigationCard } from '@/components/shared/NavigationCard';
import { type CardContextMenuProps } from '@/components/shared/Card/CardContextMenu';
import { usePaginationParams } from '@/hooks/shared/usePaginationParams';
import { DeleteBotContext } from '@/contexts/company';
import { useUnsafeContext } from '@/hooks/shared';
import type { Bot } from '@/types/bot';

type BotsGridProps = {
  bots: Bot[];
  pages: number;
  contextMenu: (bot: Bot) => CardContextMenuProps;
};

export const BotsGrid = ({
  bots,
  pages,
  contextMenu,
}: BotsGridProps) => {
  const {
    closeModal,
  } = useUnsafeContext(DeleteBotContext);

  useUnmount(() => closeModal());

  const {
    page,
    updatePage,
  } = usePaginationParams();

  const goToNextPage = () => updatePage(page + 1);

  const renderBot = (bot: Bot) => {
    return (
      <BotGridCard
        key={bot.id}
        bot={bot}
        contextMenu={contextMenu}
      />
    );
  };

  return (
    <GridContainer>
      <>
        {bots.map(renderBot)}
        {
          page < pages &&
          <NavigationCard onClick={goToNextPage} />
        }
      </>
    </GridContainer>
  );
};
