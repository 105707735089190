import classNames from 'classnames/bind';
import { type MouseEventHandler } from 'react';

import { CheckboxInput, type CheckboxInputProps } from '@/components/shared/form/inputs/CheckboxInput';
import { Tooltip } from '@/components/shared/Tooltip';
import { Body } from '@/components/shared/typography/Body';
import { JointCheckboxState } from '@/resources/enums';

import style from './TableCheckbox.module.sass';

const cx = classNames.bind(style);

type TableCheckboxProps = {
  tagName: 'th' | 'td';
  state?: JointCheckboxState;
  onChange?: CheckboxInputProps['onChange'];
  disabled?: boolean;
  className?: string;
  disabledTooltip?: string;
  onWrapperClick?: MouseEventHandler<HTMLDivElement>;
  isHovered?: boolean;
  checkboxClassName?: string;
  text?: string;
};

export const TableCheckbox = ({
  tagName,
  state,
  onChange,
  disabled,
  className,
  disabledTooltip,
  onWrapperClick,
  isHovered,
  checkboxClassName,
  text,
}: TableCheckboxProps) => {
  const Component = tagName;

  const wrapperClassName = cx('wrapper', className);

  return (
    <Component className={wrapperClassName}>
      <div className={style.innerWrapper}>
        <Tooltip
          placement='top'
          startDelay={300}
          disabled={!disabled || !disabledTooltip}
          content={
            <Body size='base'>
              {disabledTooltip}
            </Body>
          }>
          <div onClick={onWrapperClick}>
            <CheckboxInput
              className={checkboxClassName}
              isHovered={isHovered}
              state={state}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
        </Tooltip>
        {
          text &&
          <Body size='medium' className={style.checkboxText}>
            {text}
          </Body>
        }
      </div>
    </Component>
  );
};
