import { useDataMutation } from '@/hooks/shared';
import { updateVeriform } from '@/mutations/veriform';
import { UpdateVeriformAttributes } from '@/types/veriform';

export const useUpdateVeriform = (id?: string, token?: string, businessTypeOtherId?: string) => {
  const mutationKey = ['updateVeriform', id, token, businessTypeOtherId];

  return useDataMutation(
    mutationKey,
    (values: UpdateVeriformAttributes) =>
      updateVeriform(values, mutationKey),
  );
};
