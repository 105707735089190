import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useUnmount from 'react-use/esm/useUnmount';

import { VerticalTable } from '@/components/shared/tables/VerticalTable';
import { Body } from '@/components/shared/typography/Body';
import { StyledLinkExternal } from '@/components/shared/StyledLinkExternal';
import { BotRunStatusInfo } from '@/components/bot/BotRunStatusInfo';
import { BotRunTime } from '@/components/bot/BotRunTime';
import { BotSubdomain } from '@/components/bot/BotSubdomain';
import { BotRunRowsCount } from '@/components/bot/BotRunRowsCount';
import { type CardContextMenuProps } from '@/components/shared/Card/CardContextMenu';
import { MultilineHeader } from '@/components/shared/MultilineHeader';
import { BrandGraphic } from '@/components/brand/BrandGraphic';
import { useMediaQuery, useUnsafeContext } from '@/hooks/shared';
import { DeleteBotContext } from '@/contexts/company';
import { useRoutePathContext } from '@/contexts/shared';
import type { Bot } from '@/types/bot';
import type { ID } from '@/types/shared';
import type { TableConfig } from '@/types/tables';

import style from './BotsList.module.sass';

type BotsListProps = {
  bots: Bot[];
  contextMenu?: (bot: Bot) => CardContextMenuProps;
  getCellClassName?: () => string;
  hasBrandLink?: boolean;
};

export const BotsList = ({
  bots,
  contextMenu,
  getCellClassName,
  hasBrandLink = true,
}: BotsListProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    closeModal,
  } = useUnsafeContext(DeleteBotContext);

  useUnmount(() => closeModal());

  const {
    getInstanceBotRoute,
  } = useRoutePathContext();

  const navigateToBot = useCallback((id: ID) => {
    navigate(getInstanceBotRoute(id));
  }, [
    getInstanceBotRoute,
    navigate,
  ]);

  const isDesktopHd = useMediaQuery('(min-width: 1920px)');

  const tableConfig = useMemo<TableConfig<Bot>>(() => ({
    columns: [
      {
        header: t('bot:attributes.channel'),
        width: 170,
        render: (bot) => (
          <BotSubdomain
            name={bot.linked?.channel?.attributes.name}
            url={bot.linked?.subdomain?.attributes.url}
            isWrappedToNextLine={true}
          />
        ),
      },
      {
        header: t('bot:attributes.name'),
        colSpan: 6,
        render: (bot) => (
          <div className={style.botInfoWrapper}>
            <MultilineHeader variant={isDesktopHd ? 'h4' : 'h5'} className={style.botName}>
              {bot.attributes.name}
            </MultilineHeader>
            <StyledLinkExternal href={bot.attributes.searchTerm} className={style.botLink}>
              {bot.attributes.searchTerm}
            </StyledLinkExternal>
          </div>
        ),
      },
      {
        header: t('bot:attributes.companyName'),
        colSpan: 3,
        className: style.companyColumn,
        render: (bot) => (
          <Body size='base'>
            {bot.linked?.company?.attributes.name}
          </Body>
        ),
      },
      {
        header: t('bot:attributes.brand'),
        colSpan: 3,
        render: (bot) => (
          <BrandGraphic
            brandId={bot?.linked?.brand?.id}
            logoUrl={bot?.linked?.brand?.attributes.logoUrl}
            name={bot?.linked?.brand?.attributes.name}
            companyId={hasBrandLink ? bot?.linked?.company?.id : undefined}
            imageSizeVariant='small'
            nameSizeVariant='small'
          />
        ),
      },
      {
        header: t('bot:attributes.latestRun'),
        colSpan: 6,
        render: (bot) => {
          return (
            <div className={style.latestRunWrapper}>
              <div className={style.latestRun}>
                <BotRunStatusInfo
                  status={bot.linked?.latestRun?.attributes.status}
                  isSlow={bot.linked?.latestRun?.attributes.slow}
                />
                <div className={style.rowsCount}>
                  <BotRunRowsCount
                    count={bot.linked?.latestRun?.attributes.numRowsAccepted}
                  />
                </div>
                <BotRunTime
                  time={bot.linked?.latestRun?.attributes.startedAt}
                  textClassName={style.botRunTime}
                />
              </div>
            </div>
          );
        },
      },
    ],
    getTableClassName: () => style.table,
    getContextMenuProps: contextMenu,
    onRowClick: (bot) => navigateToBot(bot.id),
    getResourceRoute: (bot) => getInstanceBotRoute(bot.id),
    getCellClassName,
  }), [
    t,
    isDesktopHd,
    hasBrandLink,
    contextMenu,
    navigateToBot,
    getInstanceBotRoute,
    getCellClassName,
  ]);

  return (
    <VerticalTable
      config={tableConfig}
      data={bots}
    />
  );
};
