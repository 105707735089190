import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonAsync } from '@/components/shared/buttons';
import { FieldWrapper } from '@/components/shared/form/FieldWrapper';
import { TextInput } from '@/components/shared/form/inputs/TextInput';
import { StyledLink } from '@/components/shared/StyledLink';
import { FieldRenderer } from '@/components/shared/form/FieldRenderer';
import { Body } from '@/components/shared/typography/Body';
import { Icon } from '@/components/shared/Icon';
import { CompaniesLogosHeader } from '@/components/shared/CompaniesLogosHeader';
import { usePasswordResetRequest } from '@/hooks/user/usePasswordResetRequest';
import { useFormikMutationHandler, useGetLocationState } from '@/hooks/shared';

import style from './RequestPasswordForm.module.sass';

type RequestPasswordFormProps = {
  submitButtonText: string;
  isExistingUser?: boolean;
  children?: React.ReactNode;
};

export const RequestPasswordForm = ({
  submitButtonText,
  isExistingUser,
  children,
}: RequestPasswordFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    passwordResetRequestAsync,
    isLoading,
  } = usePasswordResetRequest();

  const validationSchema = yup.object({
    emailAddress: yup.string().required(),
  });

  const enteredEmail = useGetLocationState('email');

  const handleSubmit = useFormikMutationHandler(passwordResetRequestAsync, {
    onSuccess: (_, variables) => navigate('/email-sent', {
      state: {
        emailAddress: variables.emailAddress,
      },
    }),
    transformValues: (values) => {
      return {
        ...values,
        isExistingUser,
      };
    },
  });

  return (
    <div className={style.wrapper}>
      <CompaniesLogosHeader />
      {children}
      <Formik
        initialValues={{ emailAddress: enteredEmail || '' }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}>
        <Form className={style.formWrapper}>
          <FieldWrapper
            name='emailAddress'
            label={t('user:attributes.email')}>
            <FieldRenderer
              name='emailAddress'
              sizeVariant='medium'
              placeholder={t('loginPage:enterEmail')}
              Component={TextInput}
            />
          </FieldWrapper>
          <ButtonAsync
            type='submit'
            width='full'
            isBusy={isLoading}>
            {submitButtonText}
          </ButtonAsync>
        </Form>
      </Formik>
      <StyledLink to='/' className={style.goBackLink}>
        <Icon name='ArrowLeft' />
        <Body size='base'>
          {t('loginPage:backToSignIn')}
        </Body>
      </StyledLink>
    </div>
  );
};
