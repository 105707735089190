import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import type { ActiveLoa } from '@/types/loa';

export const fetchActiveLoa = async (context: QueryFunctionContext) => {
  const [, companyId] = context.queryKey;
  const response = await Api.get<ActiveLoa>(`/api/companies/${companyId}/letter_of_authorisation_activation`);

  return response.data;
};
