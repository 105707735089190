import { intervalToDuration } from 'date-fns';

const parseNumberToTime = (
  number: number | undefined,
  locale: string,
  unit: string,
  unitDisplay?: 'short' | 'long' | 'narrow' | undefined,
) => (
  number ?
    number.toLocaleString(locale, {
      style: 'unit',
      unit: unit,
      unitDisplay: unitDisplay || 'short',
    })
    : ''
);

export const formatDurationTime = (
  durationInSeconds: number | undefined | null,
  locale: string,
) => {
  if (!durationInSeconds || durationInSeconds < 0) {
    return '';
  }

  const {
    days,
    hours,
    minutes,
    seconds,
  } = intervalToDuration({
    start: 0,
    end: durationInSeconds * 1000,
  });

  const parsedHours = parseNumberToTime(hours, locale, 'hour');
  const parsedMinutes = parseNumberToTime(minutes, locale, 'minute');
  const parsedDays = parseNumberToTime(days, locale, 'day', 'narrow');
  const shouldAddSeconds = !parsedDays && !hours && !minutes;
  const time: string[] = [
    parsedDays,
    parsedHours,
    parsedMinutes,
  ];

  if (shouldAddSeconds) {
    const parsedSeconds = parseNumberToTime(seconds, locale, 'second');

    time.push(parsedSeconds);
  }

  return time.filter(Boolean).join(' ');
};
