import { MutationFunction } from 'react-query';

import { Api } from '@/utils/api';
import type { StorageObject } from '@/types/shared';

export const uploadImage: MutationFunction<StorageObject, File> = async (file) => {
  const formData = new FormData();

  formData.append('image', file);

  const response = await Api.post<StorageObject>('/api/images', formData, {
    headers: {
      contentType: 'multipart/form-data',
    },
  });

  return response.data;
};
