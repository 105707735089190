import { SortableSortSelect } from '@/components/shared/sortable/SortableSortSelect';
import { useStoresSortParams } from '@/hooks/stores';

export const StoresSortSelect = () => {
  const {
    sortBy,
    options,
  } = useStoresSortParams();

  return (
    <SortableSortSelect
      sortBy={sortBy}
      options={options}
    />
  );
};
