import classNames from 'classnames/bind';
import { ImgHTMLAttributes, useEffect, useMemo, useState } from 'react';

import { Loader } from '@/components/shared/loaders/Loader';
import { ImageFallback } from '@/types/shared';

import style from './ImageWithFallback.module.sass';

const cx = classNames.bind(style);

type ImageWithFallbackProps = ImgHTMLAttributes<HTMLImageElement> & {
  fallback?: ImageFallback;
  loaderWrapper?: string;
};

export const ImageWithFallback = ({
  src,
  fallback,
  className,
  loaderWrapper,
  ...restProps
}: ImageWithFallbackProps) => {
  const [failedSrcs, setFailedSrcs] = useState<string[]>([]);
  const [isFallbackIconShown, setIsFallbackIconShown] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (fallback?.icon) {
      setIsFallbackIconShown(false);
    }
  }, [
    src,
    fallback,
  ]);

  const stringSrc = String(src);

  const setSrcToFallback = () => {
    setFailedSrcs((oldFailedSrcs) => {
      return oldFailedSrcs.concat(stringSrc);
    });

    if (fallback?.icon) {
      setIsFallbackIconShown(true);
    }
  };

  const fallbackSrc = useMemo(() => {
    return fallback?.src || '';
  }, [
    fallback?.src,
  ]);
  const imageSrc = failedSrcs.includes(stringSrc) ? fallbackSrc : src;

  if (!src || !imageSrc || isFallbackIconShown) {
    return (
      <>
        {fallback?.icon}
      </>
    );
  }

  const imageClassNames = cx('image', className, {
    isHidden: !hasLoaded,
    isShown: hasLoaded,
  });

  const loaderWrapperClassNames = cx(loaderWrapper, {
    isHidden: hasLoaded,
    isShown: !hasLoaded,
  });

  const handleImageLoading = () => {
    setHasLoaded(true);
  };

  return (
    <>
      <div className={loaderWrapperClassNames}>
        <div className={style.loader}>
          <Loader />
        </div>
      </div>
      <img
        {...restProps}
        src={imageSrc}
        className={imageClassNames}
        onError={setSrcToFallback}
        onLoad={handleImageLoading}
      />
    </>
  );
};
