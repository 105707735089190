import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries';
import { UseTrademarksParams } from '@/hooks/trademark/useCompanyTrademarks';
import { QUERY_KEYS } from '@/resources/dataKeys';
import type {
  Trademark,
  TrademarkCompany,
  TrademarksRequestMeta,
} from '@/types/trademark';

export type TrademarksQueryKey = [
  typeof QUERY_KEYS.companyTrademarks,
  UseTrademarksParams | undefined
];

export type CompanyTrademarksIncludedRecord = TrademarkCompany;

export const fetchCompanyTrademarks = async (
  context: QueryFunctionContext<TrademarksQueryKey>,
) => {
  const [, params] = context.queryKey;

  const {
    pagination,
    sort,
    filter,
  } = params || {};

  const page = pagination && {
    size: pagination.perPage,
    number: pagination.page,
  };

  const response = await Api.get<
    Trademark[],
    CompanyTrademarksIncludedRecord,
    TrademarksRequestMeta
  >('api/trademarks', {
    params: {
      page,
      sort: sort?.sort,
      filter,
    },
  });

  const parsedTrademarks = linkCollectionRelationships(response);

  return {
    companyTrademarks: parsedTrademarks,
    meta: response.meta,
  };
};
