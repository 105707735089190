import { type PropsWithChildren } from 'react';

import { ButtonIcon, type ButtonIconProps } from '@/components/shared/buttons';
import { Dropdown } from '@/components/shared/Dropdown';
import type { DropdownTriggerContext } from '@/types/shared';

export type ContextMenuProps = PropsWithChildren & {
  ariaLabel?: string;
  triggerClassName?: string;
  triggerSize?: ButtonIconProps['size'];
  triggerVariant?: ButtonIconProps['variant'];
  onToggle?: (isOpen: boolean) => void;
};

export const ContextMenu = ({
  children,
  ariaLabel,
  triggerClassName,
  triggerSize,
  triggerVariant,
  onToggle,
}: ContextMenuProps) => {
  const renderDropdownTrigger = ({ isOpen, toggleIsOpen }: DropdownTriggerContext) => {
    return (
      <ButtonIcon
        ariaLabel={ariaLabel}
        iconName='MoreVertical'
        isActive={isOpen}
        onClick={toggleIsOpen}
        className={triggerClassName}
        size={triggerSize}
        variant={triggerVariant}
      />
    );
  };

  return (
    <Dropdown
      trigger={renderDropdownTrigger}
      position={['bottom', 'right']}
      onToggle={onToggle}>
      {children}
    </Dropdown>
  );
};
