import type { RouteParam } from '@/types/shared';

import { getSettingsHarvestingRoute } from './setting';

export const getDataProvidersRoute = () => {
  return `${getSettingsHarvestingRoute()}/data-providers`;
};

export const getCreateDataProviderRoute = () => {
  return `${getDataProvidersRoute()}/create`;
};

export const getDataProviderRoute = (dataProviderId: RouteParam) => {
  return `${getDataProvidersRoute()}/${dataProviderId}`;
};

export const getEditDataProviderRoute = (dataProviderId: RouteParam) => {
  return `${getDataProviderRoute(dataProviderId)}/edit`;
};

export const getDeleteDataProviderRoute = (dataProviderId: RouteParam) => {
  return `${getDataProviderRoute(dataProviderId)}/delete`;
};
