import { useMemo } from 'react';

import { Badge, BadgeProps } from '@/components/shared/Badge';
import { UserRoles } from '@/resources/enums';
import { useUserRole } from '@/hooks/companyUser';

type UserRoleBadgeProps = {
  role: UserRoles;
};

export const UserRoleBadge = ({
  role,
}: UserRoleBadgeProps) => {
  const { isAdmin, isUser } = useUserRole();

  const variant: BadgeProps['variant'] = useMemo(() => {
    if (isAdmin) {
      return 'purple';
    }

    if (isUser) {
      return 'primary';
    }

    return 'beige';
  }, [
    isAdmin,
    isUser,
  ]);

  return (
    <Badge variant={variant}>
      {role}
    </Badge>
  );
};
