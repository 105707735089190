import { type TFunction } from 'i18next';

import type { AggregationEntry } from '@/types/shared';

type AgeFilterOptionsData = {
  value: string;
  label: string;
  count: number;
}[];

type AgeFilterConfigEntry = {
  readonly entryId: number;
  readonly labelTranslationKey: string;
};

const AGE_FILTER_CONFIG: AgeFilterConfigEntry[] = [
  {
    entryId: 1,
    labelTranslationKey: 'listing:filterable.labels.age.today' as const,
  },
  {
    entryId: 7,
    labelTranslationKey: 'listing:filterable.labels.age.thisWeek' as const,
  },
  {
    entryId: 31,
    labelTranslationKey: 'listing:filterable.labels.age.thisMonth' as const,
  },
];

export const getAgeFilterOptions = (
  ageAggregation: AggregationEntry[],
  t: TFunction,
) => {
  return AGE_FILTER_CONFIG.reduce((acc, configEntry) => {
    const aggEntry = ageAggregation.find((agg) => agg.id === configEntry.entryId);

    return acc.concat({
      value: String(configEntry.entryId),
      label: t(configEntry.labelTranslationKey),
      count: aggEntry?.count ?? 0,
    });
  }, [] as AgeFilterOptionsData);
};
