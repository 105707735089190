import classNames from 'classnames/bind';
import { QueryParamOptions } from 'use-query-params';

import { PaginatePerPage } from '@/components/pagination/PaginatePerPage';
import { PaginatePages } from '@/components/pagination/PaginatePages';
import {
  DEFAULT_ALLOWED_PER_PAGE_VALUES,
  usePaginationParams,
} from '@/hooks/shared/usePaginationParams';

import style from './Pagination.module.sass';

const cx = classNames.bind(style);

type PaginationProps = {
  pages: number;
  itemsCount?: number;
  totalItemsCount: number;
  initialPerPage?: number;
  queryParamsOptions?: QueryParamOptions;
  wrapperClassName?: string;
  onAfterChangePage?: () => void;
  onAfterChangePerPage?: () => void;
};

export const Pagination = ({
  pages,
  itemsCount,
  totalItemsCount,
  initialPerPage,
  queryParamsOptions,
  wrapperClassName,
  onAfterChangePage,
  onAfterChangePerPage,
}: PaginationProps) => {
  const {
    page,
    updatePage,
    perPage,
    updatePerPage,
  } = usePaginationParams(1, initialPerPage, queryParamsOptions);

  if (!itemsCount) return null;

  const wrapperClassNames = cx('wrapper', wrapperClassName);

  return (
    <div className={wrapperClassNames}>
      <PaginatePerPage
        page={page}
        itemsCount={itemsCount}
        totalItemsCount={totalItemsCount}
        perPage={perPage}
        updatePerPage={updatePerPage}
        allowedValues={DEFAULT_ALLOWED_PER_PAGE_VALUES}
        onAfterChange={onAfterChangePerPage}
      />
      <PaginatePages
        page={page}
        pages={pages}
        updatePage={updatePage}
        onAfterChange={onAfterChangePage}
      />
    </div>
  );
};
