import format from 'date-fns/format';

import { DEFAULT_DATE_TIME_FORMAT } from '@/resources/datesFormat';

import {
  isNotValidDate,
  ensureIsDate,
} from './index';

export const formatDate = (date: string | Date, dateFormat: string) => {
  const dateObject = ensureIsDate(date);

  if (isNotValidDate(dateObject)) {
    return;
  }

  return format(dateObject, dateFormat);
};

export const formatDateToDefaultFormat = (date: string | undefined | null) => {
  if (!date) {
    return;
  }

  return formatDate(date, DEFAULT_DATE_TIME_FORMAT);
};
