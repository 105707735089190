import qs from 'qs';
import applyCaseMiddleware from 'axios-case-converter';
import axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosRequestHeaders,
  type AxiosResponse,
} from 'axios';
import {
  getIdToken,
  type User,
} from '@firebase/auth';
import { t } from 'i18next';

import { AuthHelper } from '@/utils/firebase';
import { type EnvConfig } from '@/utils/environment';
import { sentryLogIssue } from '@/utils/sentry';
import { notification } from '@/utils/notification';
import type { ApiDataResponse, ApiIncludedRecord } from '@/types/shared';

type AxiosDataResponse<TData, TIncluded extends ApiIncludedRecord | null = null, TMeta = unknown> = AxiosResponse<ApiDataResponse<TData, TIncluded, TMeta>>; // eslint-disable-line max-len
type AxiosMethodReturnType<TData, TIncluded extends ApiIncludedRecord | null = null, TMeta = unknown> = Promise<ApiDataResponse<TData, TIncluded, TMeta>>; // eslint-disable-line max-len

const DEFAULT_PROTOCOL = 'http';
const DEFAULT_SUBDOMAIN = 'admin-dev';

const queryParamsFilter = (_name: string, value: unknown) => {
  if (!value) {
    return;
  }

  return value;
};

const instance = axios.create({
  headers: {
    // NOTE: headers are transformed automatically to "Header-Case" by middleware
    accept: 'application/vnd.api+json',
    contentType: 'application/vnd.api+json',
  },
  paramsSerializer: (params) => qs.stringify(params, {
    filter: queryParamsFilter,
    indices: false,
  }),
});

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (!config.baseURL) {
    /**
     * NOTE:
     * this could happen when you try to call Api request before Config service
     * is initialized
     */
    throw new Error('Api not initialized!');
  }

  if (!config.headers) {
    config.headers = {} as AxiosRequestHeaders;
  }

  const auth = AuthHelper.getAuth();

  if (auth.currentUser) {
    const token = await getIdToken(auth.currentUser as User);

    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

let isNotificationFor401Shown = false;

instance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const responseStatus = error.response?.status;

    if (responseStatus === 401) {
      if (window.location.pathname.includes('app/veriforms')) {
        return Promise.reject(error);
      }

      sentryLogIssue(error);

      if (!isNotificationFor401Shown) {
        notification.danger({ body: t('common:errors.tryAgain') });
        isNotificationFor401Shown = true;
        history.back();

        setTimeout(() => {
          isNotificationFor401Shown = false;
        }, 2000);
      }
    }

    return Promise.reject(error);
  },
);

const caseConverterOptions = {
  preservedKeys: (input: string) => {
    return /\./.test(input);
  },
};

applyCaseMiddleware(instance, caseConverterOptions);

const performRequest = async <
  TData,
  TIncluded extends ApiIncludedRecord | null = null,
  TMeta = unknown,
>(
  request: Promise<AxiosDataResponse<TData, TIncluded, TMeta>>,
): Promise<ApiDataResponse<TData, TIncluded, TMeta>> => {
  const response = await request;

  return response.data;
};

/* eslint-disable indent */
const axiosGet = <
  TData,
  TIncluded extends ApiIncludedRecord | null = null,
  TMeta = unknown,
>(
  url: string,
  config?: AxiosRequestConfig<TData>,
): AxiosMethodReturnType<TData, TIncluded, TMeta> => {
  /* eslint-enable indent */

  const request = instance.get<ApiDataResponse<TData, TIncluded, TMeta>>(url, config);

  return performRequest<TData, TIncluded, TMeta>(request);
};

const axiosPost = <TData>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig<TData>,
): AxiosMethodReturnType<TData> => {
  return performRequest(instance.post(url, data, config));
};

/* eslint-disable indent */

const axiosPatch = <
  TData,
  TIncluded extends ApiIncludedRecord | null = null,
>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig<TData>,
): AxiosMethodReturnType<TData, TIncluded> => {
  /* eslint-enable indent */

  return performRequest(instance.patch(url, data, config));
};

const axiosDelete = (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return instance.delete<AxiosResponse>(url, config);
};

const axiosPut = <TData>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig<TData>,
): AxiosMethodReturnType<TData> => {
  return performRequest(instance.put(url, data, config));
};

const axiosInitialize = (config: EnvConfig) => {
  const host = window.location.host;
  const domain = config.VITE_API_DOMAIN;
  const protocol = config.VITE_API_PROTOCOL ?? DEFAULT_PROTOCOL;
  let subdomain = host.split('.')[0] || '';

  if (subdomain.includes('localhost')) {
    subdomain = DEFAULT_SUBDOMAIN;
  }

  const baseUrl = `${protocol}://${subdomain}${subdomain ? '.' : ''}${domain}`;

  instance.defaults.baseURL = baseUrl;
};

export const Api = {
  initialize: axiosInitialize,
  get: axiosGet,
  post: axiosPost,
  patch: axiosPatch,
  delete: axiosDelete,
  put: axiosPut,
};
