import { type PropsWithChildren } from 'react';

import { Badge, type BadgeProps } from '@/components/shared/Badge';

import style from './StatusBadge.module.sass';

type StatusBadgeProps = PropsWithChildren<{
  variant: BadgeProps['variant'];
  sizeVariant?: BadgeProps['sizeVariant'];
}>;

export const StatusBadge = ({
  children,
  variant,
  sizeVariant,
}: StatusBadgeProps) => {
  return (
    <div className={style.wrapper}>
      <Badge sizeVariant={sizeVariant} variant={variant}>
        {children}
      </Badge>
    </div>
  );
};
