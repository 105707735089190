export const swapIndexes = (
  array: unknown[],
  to: number,
  from: number,
) => {
  if (to >= array.length || from >= array.length) {
    return array;
  }

  const tempArray = [...array];
  const tempEl = tempArray[to];

  tempArray[to] = tempArray[from];
  tempArray[from] = tempEl;

  return tempArray;
};
