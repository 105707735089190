import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/shared/Tooltip';
import { Body } from '@/components/shared/typography/Body';
import { Badge } from '@/components/shared/Badge';
import { Icon } from '@/components/shared/Icon';
import { BotRunStatus } from '@/resources/enums';

import style from './BotRunStatusInfo.module.sass';
import { getBadgeProps } from './utils';

type BotRunStatusProps = {
  status: BotRunStatus | undefined;
  isSlow?: boolean;
};

export const BotRunStatusInfo = memo(({
  status,
  isSlow,
}: BotRunStatusProps) => {
  const { t } = useTranslation();

  const badgeProps = useMemo(() => {
    return getBadgeProps(status, t);
  }, [
    t,
    status,
  ]);

  const slowIndicator = useMemo(() => (
    <Tooltip
      placement='top'
      content={<Body size='small'>{t('bot:slow')}</Body>}>
      <Icon name='Slow' className={style.slowIcon} />
    </Tooltip>
  ), [
    t,
  ]);

  const renderStatus = () => {
    return (
      status ? (
        <div className={style.wrapper}>
          <Badge {...badgeProps} />
          {isSlow && slowIndicator}
        </div>
      ) : (
        <Body
          className={style.noRunCompletedText}
          size='base'>
          {t('bot:runStatus.noRunCompleted')}
        </Body>
      )
    );
  };

  return renderStatus();
});

BotRunStatusInfo.displayName = 'BotRunStatusInfo';
