
import { type TFunction } from 'i18next';

import { SelectInput } from '@/components/shared/form/inputs/SelectInput';
import { parens } from '@/utils/string';
import type { AggregationEntry } from '@/types/shared';

import { getAgeFilterOptions } from './getAgeFilterOptions';

type getDateOptionsProps = {
  ageAgg?: AggregationEntry[];
  t: TFunction;
  optionWrapperClassName?: string;
};

export const getDateOptions = ({
  ageAgg,
  t,
  optionWrapperClassName,
}: getDateOptionsProps) => {
  if (!ageAgg) {
    return [];
  }

  const optionsData = getAgeFilterOptions(
    ageAgg,
    t,
  );

  return optionsData.map((datum) => ({
    value: datum.value,
    label: (
      <div className={optionWrapperClassName}>
        <SelectInput.OptionWithSubtitle
          label={datum.label}
          subtitle={parens(datum.count)}
        />
      </div>
    ),
    disabled: datum.count === 0,
  }));
};
