import { useTranslation } from 'react-i18next';

import { RequestPasswordForm } from '@/components/company/user/RequestPasswordForm';
import { UserAuthPageLayout } from '@/components/App/AppLayout/LoginPage/UserAuthPageLayout';
import { LoginManagementHeader } from '@/components/shared/LoginManagementHeader';

export const UserRegistrationPage = () => {
  const { t } = useTranslation();

  return (
    <UserAuthPageLayout>
      <RequestPasswordForm
        submitButtonText={t('loginPage:register')}>
        <LoginManagementHeader
          title={t('loginPage:register')}
          subtitle={t('loginPage:registerInfo')}
        />
      </RequestPasswordForm>
    </UserAuthPageLayout>
  );
};
