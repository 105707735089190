import common from './common.json';
import loginPage from './loginPage.json';
import navigation from './navigation.json';
import company from './company.json';
import form from './form.json';
import user from './user.json';
import trademark from './trademark.json';
import bot from './bot.json';
import account from './account.json';
import dashboard from './dashboard.json';
import notifications from './notifications.json';
import reseller from './reseller.json';
import listing from './listing.json';
import store from './store.json';
import enforcement from './enforcement.json';
import testPurchase from './testPurchase.json';
import veriform from './veriform.json';
import setting from './setting.json';
import channel from './channel.json';
import subdomain from './subdomain.json';
import dataProvider from './dataProvider.json';
import trademarkOffice from './trademarkOffice.json';
import notificationTemplate from './notificationTemplate.json';
import currency from './currency.json';
import keyword from './keyword.json';
import report from './report.json';
import niceClass from './niceClass.json';
import trademarkType from './trademarkType.json';
import loa from './loa.json';
import brand from './brand.json';

export default {
  common,
  loginPage,
  navigation,
  company,
  form,
  user,
  trademark,
  bot,
  account,
  dashboard,
  notifications,
  reseller,
  listing,
  store,
  enforcement,
  testPurchase,
  veriform,
  setting,
  channel,
  subdomain,
  dataProvider,
  trademarkOffice,
  notificationTemplate,
  currency,
  keyword,
  report,
  niceClass,
  trademarkType,
  loa,
  brand,
};
