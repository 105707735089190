import { useTranslation } from 'react-i18next';

import { Body } from '@/components/shared/typography/Body';
import { hasAnyNonNullish } from '@/utils/object';

import style from './FilteringResultsCount.module.sass';

type FilteringResultsCountProps = {
  totalEntries?: number;
  values: Record<string, unknown>;
}

export const FilteringResultsCount = ({
  totalEntries,
  values,
}: FilteringResultsCountProps) => {
  const { t } = useTranslation();

  const isResultShown = (
    Number(totalEntries) > 0 && hasAnyNonNullish(values, { ignore: [false, ''] })
  );

  if (!isResultShown) {
    return null;
  }

  return (
    <Body size='base' className={style.subtitle}>
      {t('common:list.resultsCount', { count: totalEntries })}
    </Body>
  );
};
