import { memo } from 'react';

import * as animatedMedias from '@/assets/animatedMedia';
import type { AnimatedMediaName } from '@/types/shared';

type AnimatedMediaProps = {
  name: AnimatedMediaName;
  className?: string;
};

export const AnimatedMedia = memo(({
  name,
  className,
}: AnimatedMediaProps) => {
  const animatedMedia = animatedMedias[name];

  return (
    <img src={animatedMedia} className={className} />
  );
});

AnimatedMedia.displayName = 'AnimatedMedia';
