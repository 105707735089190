import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { type Company } from '@/types/company';

export const fetchCompany = async (context: QueryFunctionContext) => {
  const [, companyId] = context.queryKey;

  const response = await Api.get<Company>(`/api/companies/${companyId}`);

  return response.data;
};
