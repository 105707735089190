import { type TFunction } from 'i18next';

import { COLORS } from '@/resources/styles';

export const getBotRunLegendLabel = (key: string, t: TFunction) => {
  switch (key) {
    case 'scraped':
      return t('bot:runs.scraped');
    case 'valid':
      return t('bot:runs.valid');
    case 'accepted':
      return t('bot:runs.accepted');
    default:
      return key;
  }
};

export const botRunsLinesSettings = [
  {
    label: 'scraped',
    color: COLORS.STATE_INFO_500,
  },
  {
    label: 'valid',
    color: COLORS.ADDITIONAL_PURPLE_500,
  },
  {
    label: 'accepted',
    color: COLORS.STATE_SUCCESS_500,
  },
  {
    label: 'duration',
    color: COLORS.ADDITIONAL_PURPLE_500,
  },
];
