import * as React from "react";
import { forwardRef } from "react";
const SvgPhone = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M10.8561 13.1441C9.68615 11.9741 8.80415 10.6641 8.21815 9.33512C8.09415 9.05412 8.16715 8.72512 8.38415 8.50812L9.20315 7.69012C9.87415 7.01912 9.87415 6.07012 9.28815 5.48412L8.11415 4.31012C7.33315 3.52912 6.06715 3.52912 5.28615 4.31012L4.63415 4.96212C3.89315 5.70312 3.58415 6.77212 3.78415 7.83212C4.27815 10.4451 5.79615 13.3061 8.24515 15.7551C10.6941 18.2041 13.5551 19.7221 16.1681 20.2161C17.2281 20.4161 18.2971 20.1071 19.0381 19.3661L19.6891 18.7151C20.4701 17.9341 20.4701 16.6681 19.6891 15.8871L18.5161 14.7141C17.9301 14.1281 16.9801 14.1281 16.3951 14.7141L15.4921 15.6181C15.2751 15.8351 14.9461 15.9081 14.6651 15.7841C13.3361 15.1971 12.0261 14.3141 10.8561 13.1441Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgPhone);
export default ForwardRef;
