import { FieldInputProps } from 'formik';

import { RadioGroupInput } from '@/components/shared/form/inputs/RadioGroupInput';
import { useStoreBusinessTypes } from '@/hooks/stores';

type AllowStorePeriodRadioInputProps = FieldInputProps<string>;

export const StoreBusinessTypeRadioInput = ({
  ...restProps
}: AllowStorePeriodRadioInputProps) => {
  const { storeBusinessTypes } = useStoreBusinessTypes();

  const options = storeBusinessTypes?.map((businessType) => ({
    label: businessType.attributes.name,
    value: businessType.id,
  }));

  return (
    <RadioGroupInput
      {...restProps}
      options={options}
    />
  );
};
