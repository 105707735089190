import classNames from 'classnames/bind';
import { PropsWithChildren, useRef } from 'react';

import { Header } from '@/components/shared/typography/Header';
import { useScrollableElementProperties } from '@/hooks/dom';

import style from './SelectInputOptions.module.sass';

const cx = classNames.bind(style);

const OPTIONS_HEIGHT_WITH_PADDING = 48;

type SelectInputOptionsProps = PropsWithChildren & {
  popperTitle?: string;
  optionsListClassName?: string;
}

export const SelectInputOptions = ({
  popperTitle,
  children,
  optionsListClassName,
}: SelectInputOptionsProps) => {

  const ref = useRef(null);

  const {
    isScrolledToBottom,
    isScrollable,
  } = useScrollableElementProperties(ref, OPTIONS_HEIGHT_WITH_PADDING);

  const optionsListClassNames = cx('optionsList', optionsListClassName, {
    isScrolledToBottom,
    isScrollable,
    withPopperTitle: !!popperTitle,
  });

  return (
    <ul className={optionsListClassNames} ref={ref}>
      {
        popperTitle &&
        <Header variant='h4' className={style.popperTitle}>
          {popperTitle}
        </Header>
      }
      {children}
    </ul>
  );
};
