import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSortParams } from '@/hooks/shared/useSortParams';
import { SortableOption } from '@/types/sortable';

export const BOTS_DEFAULT_SORT = '-latest_run.started_at';

export const useBotsSortParams = () => {
  const { t } = useTranslation();
  const {
    sortBy: sortByParam,
    updateSortBy,
  } = useSortParams();

  const sortBy = sortByParam || BOTS_DEFAULT_SORT;

  const options = useMemo<SortableOption[]>(() => ([
    {
      value: '-latest_run.started_at',
      label: t('bot:sortable.labels.lastRunAz'),
    },
    {
      value: 'latest_run.started_at',
      label: t('bot:sortable.labels.lastRunZa'),
    },
    {
      value: '-latest_run.num_rows_accepted',
      label: t('bot:sortable.labels.numRowsAcceptedZa'),
    },
    {
      value: 'latest_run.num_rows_accepted',
      label: t('bot:sortable.labels.numRowsAcceptedAz'),
    },
    {
      value: 'name',
      label: t('bot:sortable.labels.nameAz'),
    },
    {
      value: '-name',
      label: t('bot:sortable.labels.nameZa'),
    },
  ]), [
    t,
  ]);

  return {
    sortBy,
    updateSortBy,
    options,
  };
};
