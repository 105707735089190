import classNames from 'classnames/bind';
import { FieldArrayRenderProps } from 'formik';
import {
  type ChangeEvent,
  type KeyboardEvent,
  useState,
  HTMLProps,
  ClipboardEvent,
  useRef,
  useEffect,
} from 'react';

import { Badge } from '@/components/shared/Badge';
import { Button } from '@/components/shared/buttons';
import { Icon } from '@/components/shared/Icon';
import { TextSpaceVisualizer } from '@/components/shared/TextSpaceVisualizer';
import { MultilineBody } from '@/components/shared/MultilineBody';
import { DotSpacerTextInput } from '@/components/shared/form/inputs/DotSpacerTextInput';
import { TextInput } from '@/components/shared/form/inputs/TextInput';

import style from './TagInput.module.sass';

type TagInputProps = HTMLProps<HTMLInputElement> & {
  name: string;
  arrayHelpers?: FieldArrayRenderProps;
  placeholder?: string;
  error?: string;
  tagsWrapperClassName?: string;
  hasDotSpacer?: boolean;
};

const cx = classNames.bind(style);

export const TagInput = ({
  name,
  placeholder,
  error,
  arrayHelpers,
  tagsWrapperClassName,
  hasDotSpacer,
  ...restProps
}: TagInputProps) => {
  const [inputValue, setInputValue] = useState('');
  const [isInputVisible, setIsInputVisible] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);

  if (!arrayHelpers) {
    throw Error('Formik arrayHelpers are not defined');
  }

  const tags = arrayHelpers.form.values[name] ?? [];
  const hasTags = tags.length;

  const wrapperClassNames = cx('wrapper', {
    hasTags,
    isInputVisible,
  });

  const tagsWrapperClassNames = cx('tagsWrapper', tagsWrapperClassName, {
    hasTags,
  });

  const inputClassNames = cx('input', {
    hasTags,
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !!inputValue) {
      e.preventDefault();
      arrayHelpers.push(inputValue);
      setInputValue('');
    }

    if (e.key === 'Backspace' && !inputValue) {
      e.preventDefault();
      arrayHelpers.remove(tags.length - 1);
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData('text');

    const lines = pastedText.split(/\r?\n/);

    const nonEmptyRows = lines.filter((row) => row.trim().replace(/[\r\n]/g, '') !== '');

    nonEmptyRows.map(arrayHelpers.push);
    e.preventDefault();
  };

  const handleBlur = () => {
    if (inputValue) {
      arrayHelpers.push(inputValue);
      setInputValue('');
      setIsInputVisible(false);
    }
  };

  const handleFocusInput = () => {
    inputRef?.current?.focus();
  };

  const handleWrapperClick = () => {
    setIsInputVisible(true);
    handleFocusInput();
  };

  useEffect(() => {
    if (isInputVisible) {
      handleFocusInput();
    }
  }, [
    isInputVisible,
  ]);

  const renderTag = (tag: string, index: number) => (
    <div key={index}>
      <Badge
        variant='neutral'
        shapeVariant='rectangle'>
        <MultilineBody
          size='small'
          ellipsisVariant='oneLine'>
          {
            hasDotSpacer
              ? <TextSpaceVisualizer text={tag} />
              : tag
          }
        </MultilineBody>
        <Button.Unstyled
          className={style.removeButton}
          onClick={() => arrayHelpers.remove(index)}>
          <Icon name='Delete' className={style.removeIcon} />
        </Button.Unstyled>
      </Badge>
    </div>
  );

  const baseProps = {
    error,
    name,
    autoComplete: 'off',
    wrapperClassName: style.inputWrapper,
    className: inputClassNames,
    placeholder: hasTags ? undefined : placeholder,
    sizeVariant: hasTags ? 'small' : 'medium',
    ...restProps,
    value: inputValue,
    onKeyDown: handleInputKeyDown,
    onChange: handleInputChange,
    onPaste: handlePaste,
    onBlur: handleBlur,
  } as const;

  const renderInput = () => {
    if (hasDotSpacer) {
      return (
        <DotSpacerTextInput {...baseProps} />
      );
    }

    return (
      <TextInput
        {...baseProps}
        inputRef={inputRef}
      />
    );
  };

  return (
    <div
      className={wrapperClassNames}
      onClick={handleWrapperClick}>
      <div className={tagsWrapperClassNames}>
        {tags.map(renderTag)}
      </div>
      {renderInput()}
    </div>
  );
};
