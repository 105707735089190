import classNames from 'classnames/bind';

import { BotChannelLogotype } from '@/components/bot/BotChannelLogotype';
import { Body } from '@/components/shared/typography/Body';
import { removeProtocolFromUrl } from '@/utils/string';

import style from './BotSubdomain.module.sass';

type BotSubdomainProps = {
  name?: string;
  channelClassName?: string;
  url: string | undefined;
  nameSize?: 'small' | 'base';
  isWrappedToNextLine?: boolean;
};

const cx = classNames.bind(style);

export const BotSubdomain = ({
  name,
  url,
  channelClassName,
  nameSize = 'base',
  isWrappedToNextLine = false,
}: BotSubdomainProps) => {
  const botSubdomainClassNames = cx('wrapper', nameSize, {
    isWrappedToNextLine,
  });

  return (
    <div className={botSubdomainClassNames}>
      <div className={cx('name', channelClassName)}>
        <BotChannelLogotype
          name={name}
          url={url}
          bodySize={nameSize}
        />
      </div>
      &nbsp; {/* scalable to fs space instead of margin */}
      <Body size='small' className={style.domain}>
        {removeProtocolFromUrl(url)}
      </Body>
    </div>
  );
};
