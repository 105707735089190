import * as React from "react";
import { forwardRef } from "react";
const SvgEmptyLogFile = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 48,
  height: 48,
  viewBox: "0 0 48 48",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M40 16V10C40 7.79086 38.2091 6 36 6H10C7.79086 6 6 7.79086 6 10V38C6 40.2091 7.79086 42 10 42H20",
  stroke: "currentColor",
  strokeWidth: 3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M14 16H32",
  stroke: "currentColor",
  strokeWidth: 3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M30 30L42 42",
  stroke: "currentColor",
  strokeWidth: 3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M42 30L30 42",
  stroke: "currentColor",
  strokeWidth: 3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M14 24H24",
  stroke: "currentColor",
  strokeWidth: 3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M14 32H20",
  stroke: "currentColor",
  strokeWidth: 3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgEmptyLogFile);
export default ForwardRef;
