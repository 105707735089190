import { useTranslation } from 'react-i18next';

import { AnimatedLoadingDots } from '@/components/shared/AnimatedLoadingDots';
import { Button } from '@/components/shared/buttons';
import { notification } from '@/utils/notification';
import { getFileDownloadedDateSuffix } from '@/utils/file';
import { useCompanyStores, useStoresCsv } from '@/hooks/stores';
import { downloadBlob } from '@/utils/download';

import style from './ExportButton.module.sass';

type RefetchFunction =
  ReturnType<typeof useCompanyStores>['refetch'] | ReturnType<typeof useStoresCsv>['refetch'];

type ExportButtonProps = {
  isFetching: boolean;
  fetchCsv: RefetchFunction;
  notificationBody: string;
  fileName: string;
};

export const ExportButton = ({
  isFetching,
  fetchCsv,
  notificationBody,
  fileName,
}: ExportButtonProps) => {
  const { t, i18n } = useTranslation();

  const exportFileName = getFileDownloadedDateSuffix(
    t,
    i18n,
    fileName,
  );

  const downloadCSV = async () => {
    const { data, error } = await fetchCsv();

    if (error) {
      notification.danger({
        title: t('notifications:common.deleteErrorExportTitle'),
        body: t('common:errors.tryAgainLater'),
      });

      return;
    }

    downloadBlob(
      data as unknown as BlobPart,
      exportFileName,
      'text/csv;charset=utf-16;',
    );

    notification.success({
      body: notificationBody,
    });
  };

  return (
    <Button
      variant='light'
      onClick={downloadCSV}
      className={style.exportLoading}>
      {
        isFetching
          ? (
            <span className={style.exportLoading}>
              {t('common:exporting')}
              <AnimatedLoadingDots />
            </span>
          )
          : t('common:export')
      }
    </Button>
  );
};
