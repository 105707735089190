import { useCallback } from 'react';

import { TableCheckbox } from '@/components/shared/tables/TableCheckbox';
import { JointCheckboxState } from '@/resources/enums';

type HeaderTableCheckboxProps = {
  state?: JointCheckboxState;
  onChange: (ids: string[]) => void;
  itemIds: string[];
  text?: string;
};

export const HeaderTableCheckbox = ({
  state,
  onChange,
  itemIds,
  text,
}: HeaderTableCheckboxProps) => {
  const onCheckboxChange = useCallback(() => {
    if (state) { // when at least 1 item is selected
      onChange([]);
      return;
    }

    onChange(itemIds.slice());
  }, [
    state,
    onChange,
    itemIds,
  ]);

  return (
    <TableCheckbox
      tagName='th'
      state={state}
      onChange={onCheckboxChange}
      disabled={itemIds.length === 0}
      text={text}
    />
  );
};
