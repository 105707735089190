import { getCountry } from 'tld-countries';

import { OS } from '@/resources/enums';
import { getOS } from '@/utils/misc';

const areEmojiFlagsSupported = getOS() !== OS.Windows;

export const detectEmojiFlagsSupport = () => {
  return areEmojiFlagsSupported;
};

export const tldToCountryName = (tld: string | undefined) => {
  if (!tld) return;

  switch (tld) {
    case 'com':
      return 'United States';
    default:
      return getCountry(tld);
  }
};

export const getCountryFlag = (countryName: string | undefined): string => {
  switch (countryName) {
    case 'Ascension Island':
      return '🇦🇨';
    case 'Andorra':
      return '🇦🇩';
    case 'United Arab Emirates':
      return '🇦🇪';
    case 'Afghanistan':
      return '🇦🇫';
    case 'Antigua and Barbuda':
      return '🇦🇬';
    case 'Anguilla':
      return '🇦🇮';
    case 'Albania':
      return '🇦🇱';
    case 'Armenia':
      return '🇦🇲';
    case 'Angola':
      return '🇦🇴';
    case 'Antarctica':
      return '🇦🇶';
    case 'Argentina':
      return '🇦🇷';
    case 'American Samoa':
      return '🇦🇸';
    case 'Austria':
      return '🇦🇹';
    case 'Australia':
      return '🇦🇺';
    case 'Aruba':
      return '🇦🇼';
    case 'Åland Islands':
      return '🇦🇽';
    case 'Azerbaijan':
      return '🇦🇿';
    case 'Bosnia and Herzegovina':
      return '🇧🇦';
    case 'Barbados':
      return '🇧🇧';
    case 'Bangladesh':
      return '🇧🇩';
    case 'Belgium':
      return '🇧🇪';
    case 'Burkina Faso':
      return '🇧🇫';
    case 'Bulgaria':
      return '🇧🇬';
    case 'Bahrain':
      return '🇧🇭';
    case 'Burundi':
      return '🇧🇮';
    case 'Benin':
      return '🇧🇯';
    case 'St. Barthélemy':
      return '🇧🇱';
    case 'Bermuda':
      return '🇧🇲';
    case 'Brunei':
      return '🇧🇳';
    case 'Bolivia':
      return '🇧🇴';
    case 'Netherlands Antilles':
    case 'Caribbean Netherlands':
      return '🇧🇶';
    case 'Brazil':
      return '🇧🇷';
    case 'Bahamas':
      return '🇧🇸';
    case 'Bhutan':
      return '🇧🇹';
    case 'Bouvet Island':
      return '🇧🇻';
    case 'Botswana':
      return '🇧🇼';
    case 'Belarus':
      return '🇧🇾';
    case 'Belize':
      return '🇧🇿';
    case 'Canada':
      return '🇨🇦';
    case 'Cocos [Keeling] Islands':
    case 'Cocos (Keeling) Islands':
      return '🇨🇨';
    case 'Congo [DRC]':
    case 'Congo - Kinshasa':
      return '🇨🇩';
    case 'Central African Republic':
      return '🇨🇫';
    case 'Congo [Republic]':
    case 'Congo - Brazzaville':
      return '🇨🇬';
    case 'Switzerland':
      return '🇨🇭';
    case 'Ivory Coast':
    case 'Côte d\'Ivoire':
      return '🇨🇮';
    case 'Cook Islands':
      return '🇨🇰';
    case 'Chile':
      return '🇨🇱';
    case 'Cameroon':
      return '🇨🇲';
    case 'China':
      return '🇨🇳';
    case 'Colombia':
      return '🇨🇴';
    case 'Clipperton Island':
      return '🇨🇵';
    case 'Costa Rica':
      return '🇨🇷';
    case 'Cuba':
      return '🇨🇺';
    case 'Cape Verde':
      return '🇨🇻';
    case 'Curaçao':
      return '🇨🇼';
    case 'Christmas Island':
      return '🇨🇽';
    case 'Cyprus':
      return '🇨🇾';
    case 'Czechia':
    case 'Czech Republic':
      return '🇨🇿';
    case 'Germany':
      return '🇩🇪';
    case 'Diego Garcia':
      return '🇩🇬';
    case 'Djibouti':
      return '🇩🇯';
    case 'Denmark':
      return '🇩🇰';
    case 'Dominica':
      return '🇩🇲';
    case 'Dominican Republic':
      return '🇩🇴';
    case 'Algeria':
      return '🇩🇿';
    case 'Ceuta and Melilla':
      return '🇪🇦';
    case 'Ecuador':
      return '🇪🇨';
    case 'Estonia':
      return '🇪🇪';
    case 'Egypt':
      return '🇪🇬';
    case 'Western Sahara':
      return '🇪🇭';
    case 'Eritrea':
      return '🇪🇷';
    case 'Spain':
      return '🇪🇸';
    case 'Ethiopia':
      return '🇪🇹';
    case 'European Union':
      return '🇪🇺';
    case 'Finland':
      return '🇫🇮';
    case 'Fiji':
      return '🇫🇯';
    case 'Falkland Islands [Islas Malvinas]':
    case 'Falkland Islands':
      return '🇫🇰';
    case 'Micronesia':
      return '🇫🇲';
    case 'Faroe Islands':
      return '🇫🇴';
    case 'France':
      return '🇫🇷';
    case 'Gabon':
      return '🇬🇦';
    case 'United Kingdom':
      return '🇬🇧';
    case 'Grenada':
      return '🇬🇩';
    case 'Georgia':
      return '🇬🇪';
    case 'French Guiana':
      return '🇬🇫';
    case 'Guernsey':
      return '🇬🇬';
    case 'Ghana':
      return '🇬🇭';
    case 'Gibraltar':
      return '🇬🇮';
    case 'Greenland':
      return '🇬🇱';
    case 'Gambia':
      return '🇬🇲';
    case 'Guinea':
      return '🇬🇳';
    case 'Guadeloupe':
      return '🇬🇵';
    case 'Equatorial Guinea':
      return '🇬🇶';
    case 'Greece':
      return '🇬🇷';
    case 'South Georgia and South Sandwich Islands':
    case 'South Georgia and the South Sandwich Islands':
      return '🇬🇸';
    case 'Guatemala':
      return '🇬🇹';
    case 'Guam':
      return '🇬🇺';
    case 'Guinea-Bissau':
      return '🇬🇼';
    case 'Guyana':
      return '🇬🇾';
    case 'Hong Kong':
    case 'Hong Kong SAR China':
      return '🇭🇰';
    case 'Heard and McDonald Islands':
    case 'Heard Island and McDonald Islands':
      return '🇭🇲';
    case 'Honduras':
      return '🇭🇳';
    case 'Croatia':
      return '🇭🇷';
    case 'Haiti':
      return '🇭🇹';
    case 'Hungary':
      return '🇭🇺';
    case 'Canary Islands':
      return '🇮🇨';
    case 'Indonesia':
      return '🇮🇩';
    case 'Ireland':
      return '🇮🇪';
    case 'Israel':
      return '🇮🇱';
    case 'Isle of Man':
      return '🇮🇲';
    case 'India':
      return '🇮🇳';
    case 'British Indian Ocean Territory':
      return '🇮🇴';
    case 'Iraq':
      return '🇮🇶';
    case 'Iran':
      return '🇮🇷';
    case 'Iceland':
      return '🇮🇸';
    case 'Italy':
      return '🇮🇹';
    case 'Jersey':
      return '🇯🇪';
    case 'Jamaica':
      return '🇯🇲';
    case 'Jordan':
      return '🇯🇴';
    case 'Japan':
      return '🇯🇵';
    case 'Kenya':
      return '🇰🇪';
    case 'Kyrgyzstan':
      return '🇰🇬';
    case 'Cambodia':
      return '🇰🇭';
    case 'Kiribati':
      return '🇰🇮';
    case 'Comoros':
      return '🇰🇲';
    case 'St. Kitts and Nevis':
    case 'Saint Kitts and Nevis':
      return '🇰🇳';
    case 'North Korea':
      return '🇰🇵';
    case 'South Korea':
      return '🇰🇷';
    case 'Kuwait':
      return '🇰🇼';
    case 'Cayman Islands':
      return '🇰🇾';
    case 'Kazakhstan':
      return '🇰🇿';
    case 'Laos':
      return '🇱🇦';
    case 'Lebanon':
      return '🇱🇧';
    case 'St. Lucia':
    case 'Saint Lucia':
      return '🇱🇨';
    case 'Liechtenstein':
      return '🇱🇮';
    case 'Sri Lanka':
      return '🇱🇰';
    case 'Liberia':
      return '🇱🇷';
    case 'Lesotho':
      return '🇱🇸';
    case 'Lithuania':
      return '🇱🇹';
    case 'Luxembourg':
      return '🇱🇺';
    case 'Latvia':
      return '🇱🇻';
    case 'Libya':
      return '🇱🇾';
    case 'Morocco':
      return '🇲🇦';
    case 'Monaco':
      return '🇲🇨';
    case 'Moldova':
      return '🇲🇩';
    case 'Montenegro':
      return '🇲🇪';
    case 'St. Martin':
      return '🇲🇫';
    case 'Madagascar':
      return '🇲🇬';
    case 'Marshall Islands':
      return '🇲🇭';
    case 'Macedonia':
    case 'Macedonia [FYROM]':
      return '🇲🇰';
    case 'Mali':
      return '🇲🇱';
    case 'Myanmar [Burma]':
    case 'Myanmar (Burma)':
      return '🇲🇲';
    case 'Mongolia':
      return '🇲🇳';
    case 'Macau':
    case 'Macao SAR China':
      return '🇲🇴';
    case 'Northern Mariana Islands':
      return '🇲🇵';
    case 'Martinique':
      return '🇲🇶';
    case 'Mauritania':
      return '🇲🇷';
    case 'Montserrat':
      return '🇲🇸';
    case 'Malta':
      return '🇲🇹';
    case 'Mauritius':
      return '🇲🇺';
    case 'Maldives':
      return '🇲🇻';
    case 'Malawi':
      return '🇲🇼';
    case 'Mexico':
      return '🇲🇽';
    case 'Malaysia':
      return '🇲🇾';
    case 'Mozambique':
      return '🇲🇿';
    case 'Namibia':
      return '🇳🇦';
    case 'New Caledonia':
      return '🇳🇨';
    case 'Niger':
      return '🇳🇪';
    case 'Norfolk Island':
      return '🇳🇫';
    case 'Nigeria':
      return '🇳🇬';
    case 'Nicaragua':
      return '🇳🇮';
    case 'Netherlands':
      return '🇳🇱';
    case 'Norway':
      return '🇳🇴';
    case 'Nepal':
      return '🇳🇵';
    case 'Nauru':
      return '🇳🇷';
    case 'Niue':
      return '🇳🇺';
    case 'New Zealand':
      return '🇳🇿';
    case 'Oman':
      return '🇴🇲';
    case 'Panama':
      return '🇵🇦';
    case 'Peru':
      return '🇵🇪';
    case 'French Polynesia':
      return '🇵🇫';
    case 'Papua New Guinea':
      return '🇵🇬';
    case 'Philippines':
      return '🇵🇭';
    case 'Pakistan':
      return '🇵🇰';
    case 'Poland':
      return '🇵🇱';
    case 'St. Pierre and Miquelon':
    case 'Saint Pierre and Miquelon':
      return '🇵🇲';
    case 'Pitcairn Islands':
      return '🇵🇳';
    case 'Puerto Rico':
      return '🇵🇷';
    case 'Gaza Strip':
    case 'Palestinian Territories':
      return '🇵🇸';
    case 'Portugal':
      return '🇵🇹';
    case 'Palau':
      return '🇵🇼';
    case 'Paraguay':
      return '🇵🇾';
    case 'Qatar':
      return '🇶🇦';
    case 'Réunion':
      return '🇷🇪';
    case 'Romania':
      return '🇷🇴';
    case 'Serbia':
      return '🇷🇸';
    case 'Russia':
      return '🇷🇺';
    case 'Rwanda':
      return '🇷🇼';
    case 'Saudi Arabia':
      return '🇸🇦';
    case 'Solomon Islands':
      return '🇸🇧';
    case 'Seychelles':
      return '🇸🇨';
    case 'Sudan':
      return '🇸🇩';
    case 'Sweden':
      return '🇸🇪';
    case 'Singapore':
      return '🇸🇬';
    case 'St. Helena':
    case 'Saint Helena':
      return '🇸🇭';
    case 'Slovenia':
      return '🇸🇮';
    case 'Svalbard and Jan Mayen':
      return '🇸🇯';
    case 'Slovakia':
      return '🇸🇰';
    case 'Sierra Leone':
      return '🇸🇱';
    case 'San Marino':
      return '🇸🇲';
    case 'Senegal':
      return '🇸🇳';
    case 'Somalia':
      return '🇸🇴';
    case 'Suriname':
      return '🇸🇷';
    case 'South Sudan':
      return '🇸🇸';
    case 'São Tomé and Príncipe':
      return '🇸🇹';
    case 'El Salvador':
      return '🇸🇻';
    case 'Sint Maarten':
      return '🇸🇽';
    case 'Syria':
      return '🇸🇾';
    case 'Eswatini':
    case 'Swaziland':
      return '🇸🇿';
    case 'Tristan da Cunha':
      return '🇹🇦';
    case 'Turks and Caicos Islands':
      return '🇹🇨';
    case 'Chad':
      return '🇹🇩';
    case 'French Southern Territories':
      return '🇹🇫';
    case 'Togo':
      return '🇹🇬';
    case 'Thailand':
      return '🇹🇭';
    case 'Tajikistan':
      return '🇹🇯';
    case 'Tokelau':
      return '🇹🇰';
    case 'Timor-Leste':
      return '🇹🇱';
    case 'Turkmenistan':
      return '🇹🇲';
    case 'Tunisia':
      return '🇹🇳';
    case 'Tonga':
      return '🇹🇴';
    case 'Turkey':
      return '🇹🇷';
    case 'Trinidad and Tobago':
      return '🇹🇹';
    case 'Tuvalu':
      return '🇹🇻';
    case 'Taiwan':
      return '🇹🇼';
    case 'Tanzania':
      return '🇹🇿';
    case 'Ukraine':
      return '🇺🇦';
    case 'Uganda':
      return '🇺🇬';
    case 'United Nations':
      return '🇺🇳';
    case 'United States':
    case 'U.S. Outlying Islands':
    case 'U.S. Minor Outlying Islands':
      return '🇺🇸';
    case 'Uruguay':
      return '🇺🇾';
    case 'Uzbekistan':
      return '🇺🇿';
    case 'Vatican City':
      return '🇻🇦';
    case 'St. Vincent and Grenadines':
    case 'Saint Vincent and the Grenadines':
      return '🇻🇨';
    case 'Venezuela':
      return '🇻🇪';
    case 'British Virgin Islands':
      return '🇻🇬';
    case 'U.S. Virgin Islands':
      return '🇻🇮';
    case 'Vietnam':
      return '🇻🇳';
    case 'Vanuatu':
      return '🇻🇺';
    case 'Wallis and Futuna':
      return '🇼🇫';
    case 'Samoa':
      return '🇼🇸';
    case 'Kosovo':
      return '🇽🇰';
    case 'Yemen':
      return '🇾🇪';
    case 'Mayotte':
      return '🇾🇹';
    case 'South Africa':
      return '🇿🇦';
    case 'Zambia':
      return '🇿🇲';
    case 'Zimbabwe':
      return '🇿🇼';
    case 'England':
      return '🏴󠁧󠁢󠁥󠁮󠁧󠁿';
    case 'Scotland':
      return '🏴󠁧󠁢󠁳󠁣󠁴󠁿';
    case 'Wales':
      return '🏴󠁧󠁢󠁷󠁬󠁳󠁿';
    default:
      return '🚩';
  }
};
