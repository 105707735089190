import classNames from 'classnames/bind';
import { useCallback, useDeferredValue, useMemo, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { useFormikContext, type FormikHandlers } from 'formik';
import { useTranslation } from 'react-i18next';
import type { MouseEvent } from 'react';

import selectInputStyle from '@/components/shared/form/inputs/SelectInput/SelectInput.module.sass';
import { CreatableInputValue, type CreatableInputValueProps } from '@/components/shared/form/inputs/CreatableInput/CreatableInputValue';
import { SelectInputLabel } from '@/components/shared/form/inputs/SelectInput/SelectInputLabel';
import { type TextInputProps } from '@/components/shared/form/inputs/TextInput';
import { TextSpaceVisualizer } from '@/components/shared/TextSpaceVisualizer';
import { useFilteredByQuery, useInputHandlers } from '@/hooks/utils';
import { useSyncWidth } from '@/hooks/dom';
import type { SelectOption } from '@/types/shared';

import style from './CreatableInput.module.sass';

const cx = classNames.bind(style);

type OptionType = SelectOption<string, string>;
type OnCreateOptionCallback = (newValue: string) => void;

type NewValueObject = {
  __newValue: true;
  value: null;
  label: string;
};

export type CreatableInputOnCreate = (query: string, onSuccess: OnCreateOptionCallback, onError: () => void) => void; // eslint-disable-line max-len

type CreatableInputProps = {
  placeholder?: string;
  name: string;
  options?: OptionType[];
  value: string[];
  sizeVariant?: TextInputProps['sizeVariant'];
  disabled?: boolean;
  onChange: FormikHandlers['handleChange'];
  error?: string;
  valueVariant?: CreatableInputValueProps['variant'];
  onCreateOption?: CreatableInputOnCreate;
  noOptionsText?: string;
  hasOptionTextSpacer?: boolean;
};

export const CreatableInput = ({
  placeholder,
  name,
  options,
  value,
  disabled,
  onCreateOption,
  noOptionsText,
  error,
  valueVariant = 'tag',
  sizeVariant = 'small',
  hasOptionTextSpacer,
}: CreatableInputProps) => {
  const inputMeasurerRef = useRef<HTMLDivElement | null>(null);
  const inputVisualRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();
  const [isInputBusy, setIsInputBusy] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [query, onChangeQuery, setQuery] = useInputHandlers('');

  const optionsByValue = useMemo(() => {
    return (options || []).reduce((acc, option) => {
      acc[option.value] = option;

      return acc;
    }, {} as Record<string, OptionType>);
  }, [
    options,
  ]);

  const {
    setFieldValue,
  } = useFormikContext();

  useSyncWidth(inputMeasurerRef, inputRef, query);

  const deleteAt = useCallback((index: number) => {
    const newValue = [
      ...value.slice(0, index),
      ...value.slice(index + 1),
    ];

    setFieldValue(name, newValue);
  }, [
    value,
    name,
    setFieldValue,
  ]);

  const updateFieldValue = useCallback(async (newValue: string[]) => {
    setQuery('');

    const entryToCreateIndex = newValue.findIndex((option: unknown) => {
      return option && typeof option === 'object' && '__newValue' in option;
    });

    if (entryToCreateIndex === -1) {
      setFieldValue(name, newValue);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      return;
    }

    const entryToCreate = newValue[entryToCreateIndex] as unknown as NewValueObject;

    if (!onCreateOption) {
      return;
    }

    setIsInputBusy(true);

    onCreateOption(entryToCreate.label, (newEntryValue) => {
      const newUpdatedValue = [
        ...newValue.slice(0, entryToCreateIndex),
        newEntryValue,
        ...newValue.slice(entryToCreateIndex + 1),
      ];

      setIsInputBusy(false);
      setFieldValue(name, newUpdatedValue);

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }, () => {
      setIsInputBusy(false);
      deleteAt(entryToCreateIndex);
    });
  }, [
    deleteAt,
    name,
    setFieldValue,
    onCreateOption,
    setQuery,
  ]);

  const updateValue = useCallback((newValue: string[]) => {
    updateFieldValue(newValue);
    setQuery('');
  }, [
    setQuery,
    updateFieldValue,
  ]);

  const deferredQuery = useDeferredValue(query);

  const filteredOptions = useFilteredByQuery(options || [], deferredQuery, (option) => {
    return option.label;
  });

  const onInputFocus = useCallback(() => {
    setIsFocused(true);
  }, [
    setIsFocused,
  ]);

  const onInputBlur = useCallback(() => {
    setQuery('');
    setIsFocused(false);
  }, [
    setQuery,
    setIsFocused,
  ]);

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, [
    inputRef,
  ]);

  const stopEvent = useCallback((event: MouseEvent) => {
    event.preventDefault();
  }, []);

  const exactMatchedOption = useMemo(() => {
    return options?.find((option) => {
      return option.label === deferredQuery;
    });
  }, [
    deferredQuery,
    options,
  ]);

  const renderOption = (option: OptionType, index: number) => {
    return (
      <Combobox.Option
        key={index}
        value={option.value}
        disabled={option.disabled}>
        {({ selected, active }) => (
          <SelectInputLabel
            active={active}
            selected={selected}
            optionVariant='tickRight'>
            {
              hasOptionTextSpacer
                ? <TextSpaceVisualizer text={option.label} />
                : option.label
            }
          </SelectInputLabel>
        )}
      </Combobox.Option>
    );
  };

  const createOption = useMemo(() => {
    if (deferredQuery.length === 0) {
      return null;
    }

    const renderLabel = () => {
      if (hasOptionTextSpacer) {
        return (
          <div className={style.createOptionWithSpacesVisualization}>
            {t('common:create')}{' '}&quot;<TextSpaceVisualizer text={deferredQuery} />&quot;
          </div>
        );
      }

      return t('bot:form.keywordsInput.createOption', { name: deferredQuery });
    };

    return (
      <Combobox.Option
        value={{ value: null, label: deferredQuery, __newValue: true } as NewValueObject}>
        {({ active }) => (
          <SelectInputLabel
            optionVariant='tickRight'
            active={active}>
            {renderLabel()}
          </SelectInputLabel>
        )}
      </Combobox.Option>
    );
  }, [
    t,
    deferredQuery,
    hasOptionTextSpacer,
  ]);

  const renderSelectedOptions = () => {
    return value.map((optionValue, index) => (
      <CreatableInputValue
        key={index}
        index={index}
        label={optionsByValue[optionValue]?.label}
        value={optionValue}
        deleteAt={deleteAt}
        variant={valueVariant}
        hasOptionTextSpacer={hasOptionTextSpacer}
      />
    ));
  };

  const anySelected = value?.length > 0;

  const inputVisualClassName = cx('inputVisual', sizeVariant, {
    isFocused,
    disabled,
    error,
  });

  const inputClassName = cx('input', {
    hidden: isInputBusy,
  });

  const inputMeasurerClassName = cx('input', 'inputMeasurer');
  const placeholderClassName = cx('placeholder', {
    hidden: query,
  });

  const popperClassName = cx('popper', selectInputStyle.popper, sizeVariant, {
    isFocused,
  });

  const renderPopperContent = () => {
    if (filteredOptions.length === 0 && !createOption) {
      return (
        <div className={style.popperPlaceholder}>
          {noOptionsText}
        </div>
      );
    }

    return (
      <ul className={selectInputStyle.optionsList}>
        {exactMatchedOption ? null : createOption}
        {filteredOptions.map(renderOption)}
      </ul>
    );
  };

  return (
    <div className={style.wrapper}>
      <Combobox
        disabled={disabled}
        value={value}
        onChange={updateValue}
        multiple={true}>
        <div
          ref={inputVisualRef}
          className={inputVisualClassName}
          onMouseDown={isFocused ? stopEvent : undefined}
          onClick={focusInput}>
          {renderSelectedOptions()}
          <span className={style.inputWrapper}>
            {
              placeholder && !anySelected &&
              <span className={placeholderClassName}>
                {placeholder}
              </span>
            }
            <Combobox.Input
              autoComplete='off'
              ref={inputRef}
              className={inputClassName}
              onChange={onChangeQuery}
              displayValue={() => query}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
            />
          </span>
        </div>
        <div ref={inputMeasurerRef} className={inputMeasurerClassName} />
        <Combobox.Options
          as='div'
          onMouseDown={stopEvent}
          static={!disabled}
          className={popperClassName}>
          {renderPopperContent()}
        </Combobox.Options>
      </Combobox>
    </div>
  );
};
