import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconWithGlow } from '@/components/shared/IconWithGlow';
import { Button } from '@/components/shared/buttons';
import { AlertBox } from '@/components/shared/AlertBox';
import { Header } from '@/components/shared/typography/Header';
import { useAuthentication } from '@/hooks/user';
import { AuthorisedCustomerCompanyUserContext } from '@/contexts/user';

import style from './GoogleLoginForm.module.sass';

export const GoogleLoginForm = () => {
  const [fireBaseError, setFireBaseError] = useState<Error>();
  const { isError, setHasTriedToLogin } = useContext(AuthorisedCustomerCompanyUserContext);

  const { t } = useTranslation();
  const { signInWithGoogle } = useAuthentication();

  const googleSignIn = async () => {
    try {
      await signInWithGoogle();
      setHasTriedToLogin(true);
    } catch (err) {
      setFireBaseError(err as Error);
    }
  };

  return (
    <div className={style.wrapper}>
      <Button
        variant='secondary'
        iconName='Google'
        width='full'
        onClick={googleSignIn}>
        {t('loginPage:login.loginWithGoogle')}
      </Button>
      {
        (fireBaseError || isError) &&
        <AlertBox
          variant='danger'
          icon={
            <IconWithGlow
              type='danger'
              sizeVariant='medium'
              iconName='ExclamationCircle'
            />
          }>
          <Header variant='h5'>
            {t('loginPage:errors.googleLoginError')}
          </Header>
        </AlertBox>
      }
    </div>
  );
};
