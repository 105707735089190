import classNames from 'classnames/bind';
import { memo, useCallback, type MouseEvent } from 'react';

import { Button } from '@/components/shared/buttons';
import { Icon } from '@/components/shared/Icon';
import { TextSpaceVisualizer } from '@/components/shared/TextSpaceVisualizer';

import style from './CreatableInputValue.module.sass';

const cx = classNames.bind(style);

export type CreatableInputValueProps = {
  index: number;
  label?: string;
  hasOptionTextSpacer?: boolean;
  value: string | null;
  deleteAt: (index: number) => void;
  variant: 'success' | 'danger' | 'tag';
};

const stopEvent = (event: MouseEvent) => {
  event.preventDefault();
};

export const CreatableInputValue = memo(({
  variant,
  label,
  value,
  deleteAt,
  index,
  hasOptionTextSpacer,
}: CreatableInputValueProps) => {
  const selectedOptionClassName = cx('optionBadge', variant, {
    busy: value === null,
  });

  const deleteValue = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    deleteAt(index);
  }, [
    index,
    deleteAt,
  ]);

  if (!label) {
    return null;
  }

  return (
    <div className={selectedOptionClassName}>
      <span>
        {
          hasOptionTextSpacer
            ? <TextSpaceVisualizer text={label} />
            : label
        }
      </span>
      <Button.Unstyled
        onClick={deleteValue}
        onMouseDown={stopEvent}
        className={style.deleteOptionButton}>
        <Icon name='Delete' className={style.deleteOptionIcon} />
      </Button.Unstyled>
    </div>
  );
});

CreatableInputValue.displayName = 'CreatableInputValue';
