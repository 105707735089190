import { FC } from 'react';
import { useNavigate, type To } from 'react-router-dom';

import { Button, type ButtonProps } from '@/components/shared/buttons/Button';

export type NavButtonProps = Omit<ButtonProps, 'onClick'> & {
  navigateTo: To | number;
  state?: { [key: string]: unknown, };
};

export const NavButton: FC<NavButtonProps> = ({
  navigateTo,
  state,
  ...restProps
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (typeof navigateTo === 'number') {
      navigate(navigateTo);
    } else {
      navigate(navigateTo, { state });
    }
  };

  return (
    <Button {...restProps} onClick={handleClick} />
  );
};
