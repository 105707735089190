import { useDataQuery } from '@/hooks/shared';
import { fetchStoreBusinessTypes } from '@/queries/store';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { StoreBusinessType } from '@/types/store';

export const useStoreBusinessTypes = () => {
  const {
    data: storeBusinessTypes,
    ...rest
  } = useDataQuery<StoreBusinessType[]>(
    QUERY_KEYS.storeBusinessTypes, fetchStoreBusinessTypes,
  );

  return {
    storeBusinessTypes,
    ...rest,
  };
};
