import * as React from "react";
import { forwardRef } from "react";
const SvgLogoGreyScout = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 400,
  height: 81,
  viewBox: "0 0 400 81",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_74_1320)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M68.107 34.5536V30.3842H59.7678V66.8845H68.107V47.9275C68.107 41.209 71.6349 37.9444 78.8903 37.9444H79.5002V29.6774H78.8903C74.0504 29.6774 70.4348 31.3151 68.107 34.5515V34.5536Z",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M102.956 29.6774C97.4051 29.6774 92.732 31.4935 89.0703 35.0756C85.4524 38.6121 83.6205 43.173 83.6205 48.6344C83.6205 54.0957 85.4546 58.6588 89.0703 62.1931C92.732 65.7752 97.4029 67.5913 102.956 67.5913C107.19 67.5913 110.973 66.4865 114.195 64.3071C117.37 62.1278 119.674 59.1807 121.041 55.5507L121.348 54.7351H112.247L112.065 54.9961C109.935 58.0498 106.955 59.5353 102.954 59.5353C100.349 59.5353 98.0545 58.7936 96.1304 57.3299C94.4608 56.0706 93.2739 54.4198 92.5916 52.4144H121.918L121.995 51.899C122.188 50.5983 122.287 49.5 122.287 48.6344C122.287 43.173 120.428 38.6099 116.769 35.0778C113.153 31.4935 108.504 29.6774 102.954 29.6774H102.956ZM113.316 44.8521H92.596C93.2783 42.8489 94.4652 41.1981 96.1348 39.9367C98.0567 38.4751 100.352 37.7334 102.956 37.7334C105.56 37.7334 107.853 38.4751 109.777 39.9388C111.444 41.1982 112.631 42.8489 113.316 44.8543V44.8521Z",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M144.821 60.24L134.577 30.3843H125.815L138.333 66.8845H142.546L137.704 81H146.469L163.827 30.3843H155.064L144.821 60.24Z",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M274.405 29.6774C268.852 29.6774 264.203 31.4935 260.594 35.0713C256.928 38.6078 255.069 43.1708 255.069 48.6322C255.069 54.0935 256.928 58.6566 260.587 62.1887C264.201 65.7731 268.85 67.5892 274.405 67.5892C279.96 67.5892 284.607 65.7731 288.216 62.1953C291.882 58.6588 293.74 54.0957 293.74 48.6344C293.74 43.173 291.882 38.6099 288.222 35.0778C284.607 31.4935 279.958 29.6774 274.407 29.6774H274.405ZM282.305 56.4642C280.25 58.5 277.593 59.5331 274.405 59.5331C271.217 59.5331 268.56 58.5 266.504 56.4642C264.449 54.4285 263.406 51.7924 263.406 48.6322C263.406 45.472 264.449 42.8381 266.504 40.8001C268.56 38.7644 271.217 37.7313 274.405 37.7313C277.593 37.7313 280.247 38.7644 282.305 40.8001C284.361 42.8381 285.401 45.472 285.401 48.6322C285.401 51.7924 284.359 54.4263 282.305 56.4642Z",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M327.764 50.3983C327.764 53.2736 326.915 55.5508 325.232 57.1777C323.604 58.835 321.307 59.6767 318.407 59.6767C315.506 59.6767 313.209 58.835 311.57 57.1668C309.896 55.553 309.05 53.2758 309.05 50.4005V30.3865H300.71V51.1073C300.71 55.8575 302.178 59.8202 305.076 62.8848C308.032 66.0102 311.917 67.5936 316.627 67.5936C321.338 67.5936 324.883 66.1407 327.766 63.2697V66.8867H336.105V30.3843H327.766V50.3983H327.764Z",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M358.887 20.2946L350.548 22.774V30.3842H342.716V38.2967H350.548V66.8845H358.887V38.2967H367.431V30.3842H358.887V20.2946Z",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M51.5624 43.1535C51.7335 46.1028 51.3847 48.9041 50.5137 51.5097L50.4961 51.5641C50.4895 51.625 50.472 51.6663 50.4588 51.7142C49.7765 53.7195 48.8309 55.6117 47.6549 57.3256V57.3321C47.6549 57.3321 47.6484 57.3451 47.6418 57.3495C47.6286 57.3734 47.6111 57.3908 47.6001 57.4104C47.045 58.3043 46.4351 59.1025 45.7528 59.8398C45.3447 60.2879 44.8884 60.7511 44.3092 61.2949C41.6698 63.7287 38.5983 65.3969 34.9234 66.3995C33.5895 66.7736 32.1261 67.052 30.4499 67.2565C28.969 67.4979 27.3718 67.624 25.7395 67.624C18.3415 67.624 12.1326 65.1402 7.29271 60.2422C2.45504 55.3572 0 49.0868 0 41.6136C0 34.1405 2.52963 27.87 7.5143 22.9764C12.4924 18.0827 18.868 15.6011 26.4547 15.6011L26.9484 15.6076C33.5061 15.7098 39.1797 17.7282 43.8287 21.6192L38.8506 28.155C38.6729 27.9918 38.4908 27.8352 38.3087 27.6786C35.2196 25.1709 31.4833 23.8246 27.2051 23.6854C27.0954 23.6789 26.9791 23.6789 26.8628 23.668C26.729 23.6615 26.5951 23.655 26.4547 23.655C21.2375 23.655 16.8803 25.3775 13.4994 28.7661C10.0746 32.1243 8.33704 36.4437 8.33704 41.6158C8.33704 46.7879 10.0746 51.1074 13.4928 54.459C16.8803 57.8541 21.2375 59.5767 26.4547 59.5767C31.3122 59.5767 35.4368 58.0846 38.7168 55.1419C39.3618 54.5677 39.9607 53.9457 40.4895 53.3062C40.5026 53.2932 40.5136 53.2758 40.5268 53.2649L40.5443 53.2475C41.185 52.4015 41.7378 51.4901 42.203 50.5419H21.7004L27.521 42.9904L27.8194 42.6098H51.5185C51.5317 42.7903 51.5558 42.9773 51.5602 43.16L51.5624 43.1535Z",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M187.518 37.2354C180.927 36.3806 177.724 34.1513 177.724 30.4234C177.724 28.3354 178.467 26.7086 179.99 25.458C181.627 24.1421 183.893 23.4744 186.725 23.4744C193.261 23.4744 196.44 26.441 196.44 32.5396V33.1443H204.78V32.5396C204.78 27.261 203.134 23.0524 199.887 20.0292C196.699 17.0648 192.27 15.5597 186.725 15.5597C181.517 15.5597 177.283 16.9647 174.152 19.7291C170.988 22.4261 169.382 26.0234 169.382 30.4212C169.382 38.5469 175.429 43.4971 187.346 45.1349C191.041 45.6851 193.718 46.4616 195.293 47.4425C197.022 48.5582 197.864 50.146 197.864 52.3014C197.864 54.5655 196.991 56.2989 195.192 57.6039C193.314 58.9763 190.705 59.6745 187.436 59.6745C179.942 59.6745 176.298 56.5708 176.298 50.1851V49.5805H167.958V50.1851C167.958 55.6095 169.727 59.9398 173.222 63.0631C176.688 66.0667 181.471 67.5892 187.436 67.5892C193.062 67.5892 197.649 66.1646 201.067 63.3567C204.475 60.5662 206.201 56.8492 206.201 52.3035C206.201 43.943 199.916 38.8731 187.515 37.2354H187.518Z",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M379.263 5.95286C378.435 9.20008 375.553 11.4185 372.077 11.4185H372.033C371.869 11.4207 371.619 11.4294 371.369 11.4664C369.328 11.7774 367.718 13.5239 367.621 15.527C367.518 17.689 368.892 19.5616 370.965 20.0814C371.353 20.1793 371.742 20.1945 372.152 20.2097C372.38 20.2184 372.615 20.2271 372.847 20.2532C375.991 20.5882 378.521 22.8719 379.17 25.8886C379.203 25.8059 379.236 25.7233 379.274 25.6406C379.868 24.3052 380.866 23.4026 382.185 23.0024C382.097 22.9828 382.009 22.9633 381.926 22.9415C378.174 21.9584 375.897 18.3676 376.628 14.5875C377.174 11.76 379.447 9.5198 382.264 8.94343C380.86 8.46059 379.752 7.35571 379.265 5.95068L379.263 5.95286Z",
  fill: "#05E0B6"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M396.299 11.6143C396.049 11.573 395.798 11.5621 395.632 11.5577C391.961 11.5338 388.971 9.04348 388.365 5.49829C388.361 5.47436 388.359 5.45261 388.357 5.42869C388.313 5.13724 388.299 4.84362 388.289 4.5587C388.282 4.38253 388.275 4.20853 388.262 4.03236C388.076 1.7443 386.16 0.0108525 383.804 -0.00219727H383.782C381.568 -0.00219727 379.616 1.76387 379.425 3.94319C379.221 6.26822 380.785 8.32574 383.065 8.73246C383.372 8.78683 383.692 8.79553 384.002 8.80423C384.243 8.81076 384.493 8.81728 384.743 8.84555C384.752 8.84555 384.763 8.84991 384.772 8.84991C387.891 9.21747 390.452 11.6078 391.007 14.6701C391.691 18.4589 389.372 22.0237 385.61 22.9611C385.309 23.035 384.989 23.0872 384.64 23.122C384.377 23.1481 384.098 23.1655 383.793 23.1699C381.81 23.1981 380.417 24.0855 379.653 25.8037C378.894 27.5111 379.168 29.1467 380.445 30.5365C381.621 31.8153 383.137 32.2569 384.826 31.8132C386.522 31.3695 387.604 30.2494 388.043 28.4855C388.131 28.1375 388.144 27.7808 388.148 27.4806C388.152 27.1501 388.179 26.826 388.225 26.5084C388.699 23.259 391.342 20.6991 394.719 20.3794C394.952 20.3576 395.188 20.3511 395.417 20.3446C395.827 20.3337 396.215 20.3228 396.606 20.2293C398.683 19.7356 400.081 17.8782 400.002 15.7141C399.93 13.7109 398.339 11.9471 396.303 11.6099L396.299 11.6143Z",
  fill: "#05E0B6"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M240.767 54.7265C239.422 56.834 237.562 58.2869 235.24 59.0481C232.915 59.8116 230.453 59.7333 227.921 58.8176C224.927 57.7345 222.788 55.8618 221.561 53.2519C220.335 50.6419 220.267 47.8123 221.357 44.8435C222.45 41.8746 224.339 39.754 226.974 38.5382C229.609 37.3224 232.463 37.255 235.455 38.3381C237.985 39.2516 239.92 40.7654 241.206 42.8316C241.298 42.9773 241.375 43.1296 241.458 43.2775L248.395 38.7753C246.122 35.0779 242.711 32.3831 238.24 30.7671C233.022 28.8814 228.027 29.0097 223.4 31.1455C218.733 33.2248 215.411 36.8787 213.522 42.0095C211.636 47.1424 211.805 52.06 214.023 56.6187C216.179 61.2122 219.918 64.4986 225.137 66.3843C229.85 68.0873 234.44 68.1286 238.779 66.5017C243.062 64.9031 246.357 61.9648 248.578 57.7693L248.9 57.1581L241.028 54.3132L240.765 54.7243L240.767 54.7265Z",
  fill: "#003027"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_74_1320"
}, /* @__PURE__ */ React.createElement("rect", {
  width: 400,
  height: 81,
  fill: "white"
}))));
const ForwardRef = forwardRef(SvgLogoGreyScout);
export default ForwardRef;
