import { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import { Header, HeaderVariants } from '@/components/shared/typography/Header';
import { GoBackLink } from '@/components/shared/GoBackLink';

import style from './FormWrapper.module.sass';

const cx = classNames.bind(style);

type FormWrapperProps = {
  title?: ReactNode;
  titleClassName?: string;
  children: ReactNode;
  isBackButtonVisible?: boolean;
  wrapperClassName?: string;
  bodyClassName?: string;
  headerVariant?: HeaderVariants;
  boxPaddingVariant?: 'base' | 'large';
  sizeVariant?: 'size-base' | 'size-large';
  subtitle?: ReactNode;
};

export const FormWrapper: FC<FormWrapperProps> = ({
  title,
  children,
  isBackButtonVisible = true,
  titleClassName,
  wrapperClassName,
  bodyClassName,
  boxPaddingVariant = 'base',
  sizeVariant = 'size-base',
  headerVariant = 'h2',
  subtitle,
}) => {

  const titleClassNames = cx('title', titleClassName);
  const wrapperClassNames = cx('wrapper', wrapperClassName);
  const boxClassNames = cx('box', boxPaddingVariant, sizeVariant);

  return (
    <div className={wrapperClassNames}>
      <div className={boxClassNames}>
        {
          isBackButtonVisible
          &&
          <GoBackLink
            wrapperClassName={style.goBackLink}
          />
        }
        <div className={style.header}>
          {
            title &&
            <Header variant={headerVariant} className={titleClassNames}>
              {title}
            </Header>
          }
          {subtitle}
        </div>
        <div className={bodyClassName}>
          {children}
        </div>
      </div>
    </div>
  );
};
