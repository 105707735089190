import * as React from "react";
import { forwardRef } from "react";
const SvgActionInProgress = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z",
  fill: "#F6F6FE"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.99909 12.5019H4.49846C3.94595 12.5019 3.49805 12.054 3.49805 11.5014V4.49852C3.49805 3.94601 3.94595 3.49811 4.49846 3.49811H10.0008C10.5533 3.49811 11.0012 3.94601 11.0012 4.49852V5.99915",
  stroke: "#8788F3",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.3865 9.58066L12.2283 11.1393C12.4493 11.3252 12.5482 11.6187 12.4848 11.9004C12.4214 12.1821 12.2063 12.405 11.927 12.4783C11.6477 12.5516 11.3509 12.463 11.1573 12.2488L9.52466 10.451",
  stroke: "#8788F3",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.49902 5.99916H8.50027",
  stroke: "#8788F3",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.49902 7.99998H6.49944",
  stroke: "#8788F3",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M8.4622 8.93089L7.7294 9.51463C7.59408 9.6232 7.51132 9.78419 7.50178 9.95741C7.49224 10.1306 7.55681 10.2997 7.67938 10.4225L8.23711 10.9827C8.35863 11.1048 8.52652 11.1692 8.69851 11.1596C8.87051 11.1501 9.03023 11.0675 9.13748 10.9327L9.72573 10.2009C9.86564 10.0271 10.0243 9.86921 10.1989 9.73022L10.9317 9.14598C11.067 9.03751 11.1498 8.8766 11.1593 8.70346C11.1689 8.53031 11.1043 8.36129 10.9818 8.2386L10.426 7.67787C10.3045 7.55577 10.1366 7.49141 9.96461 7.50096C9.79262 7.51052 9.63289 7.59309 9.52564 7.72789L8.9354 8.46019C8.79535 8.63392 8.63667 8.79177 8.4622 8.93089V8.93089Z",
  stroke: "#8788F3",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M8.96184 12.4919H7.96143",
  stroke: "#8788F3",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgActionInProgress);
export default ForwardRef;
