import produce from 'immer';

import { useFilteredQueriesMapping } from '@/hooks/utils';
import { UseStoreParams } from '@/hooks/stores/useCompanyStores';
import { type StoresQueryKey, fetchStores } from '@/queries/store';
import { StoresFilterParams } from '@/types/store';

export const useFilteredStoresQueriesMapping = (
  queryKeyParams: UseStoreParams,
) => {
  return useFilteredQueriesMapping({
    queryFn: fetchStores,
    transformQueryKey: (queryKey, params) => {
      return removeStoresFilterValue(queryKey, params.filterKey);
    },
    queryKey: ['stores', queryKeyParams],
    mapping: [
      { dataKey: 'subdomain', params: { filterKey: 'subdomainId' as const } },
      { dataKey: 'country', params: { filterKey: 'countryId' as const } },
      { dataKey: 'email', params: { filterKey: 'emailPresence' as const } },
    ],
  });
};

const removeStoresFilterValue = (
  queryKey: StoresQueryKey,
  filterKey: keyof StoresFilterParams,
) => {
  return produce(queryKey, (draft) => {
    if (draft[1]?.filter) {
      draft[1].filter[filterKey] = undefined;
    }
  });
};
