import { useEffect, type MutableRefObject } from 'react';

type RefObject = MutableRefObject<HTMLElement | null>;

export const useSyncWidth = (
  sourceRef: RefObject,
  targetRef: RefObject,
  text: string,
) => {
  useEffect(() => {
    if (!sourceRef.current || !targetRef.current) {
      return;
    }

    sourceRef.current.innerText = text;

    const rect = sourceRef.current.getBoundingClientRect();

    targetRef.current.style.width = `${rect.width}px`;
  }, [
    text,
    sourceRef,
    targetRef,
  ]);
};
