export const getSettingsRoute = () => {
  return '/settings';
};

export const getSettingsHarvestingRoute = () => {
  return `${getSettingsRoute()}/harvesting`;
};

export const getSettingsTrademarksRoute = () => {
  return `${getSettingsRoute()}/trademarks`;
};

export const getSettingsEnforcementRoute = () => {
  return `${getSettingsRoute()}/enforcement`;
};
