import { FC, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { ReactQueryClientProvider } from '@/components/App/ReactQueryClientProvider';
import { BrandingGateway } from '@/components/App/BrandingGateway';
import { LoginGateway } from '@/components/App/LoginGateway';
import { AppGateway } from '@/components/App/AppGateway';
import { ErrorBoundary } from '@/components/App/ErrorBoundary';
import { PageLoader } from '@/components/shared/loaders/PageLoader';
import { UserGateway } from '@/components/App/UserGateway';

export const App: FC = () => {
  return (
    <ReactQueryClientProvider>
      <BrowserRouter basename='/app'>
        <ErrorBoundary>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Toaster
              position='top-center'
              toastOptions={{
                duration: 3000,
              }}
            />
            <Suspense fallback={<PageLoader />}>
              <BrandingGateway>
                <UserGateway>
                  <LoginGateway>
                    <AppGateway />
                  </LoginGateway>
                </UserGateway>
              </BrandingGateway>
            </Suspense>
          </QueryParamProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </ReactQueryClientProvider>
  );
};
