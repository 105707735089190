import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ErrorPage } from '@/components/ErrorPage';
import { useBranding } from '@/hooks/branding';

type BrandingGatewayProps = {
  children: ReactElement;
};

export const BrandingGateway = ({
  children,
}: BrandingGatewayProps) => {
  const { t } = useTranslation();

  const {
    isLoading,
    branding,
  } = useBranding();

  if (isLoading) {
    return null;
  }

  if (!branding) {
    return (
      <Routes>
        <Route
          path='*'
          element={
            <ErrorPage
              subtitle={t('common:errors.notFoundCompanySubtile')}
              isControlVisible={false}
            />
          }
        />
      </Routes>
    );
  }

  return (
    <>{children}</>
  );
};
