import { useDataQuery } from '@/hooks/shared';
import { fetchTrademarkOffice } from '@/queries/trademarkOffice';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const useTrademarkOffice = (trademarkOfficeId?: string) => {
  const {
    data: trademarkOffice,
    ...rest
  } = useDataQuery([QUERY_KEYS.trademarkOffice, trademarkOfficeId], fetchTrademarkOffice);

  return {
    trademarkOffice,
    ...rest,
  };
};
