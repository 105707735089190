import qs from 'qs';

import { STORE_ID } from '@/resources/queryParams';
import { getSettingsEnforcementRoute } from '@/utils/routes/setting';
import type { RouteParam } from '@/types/shared';

export const getEnforcementsRoute = () => {
  return '/actions';
};

export const getEnforcementRoute = (enforcementId: RouteParam) => {
  return `${getEnforcementsRoute()}/${enforcementId}`;
};

export const getCreateEnforcementRoute = (
  storeId: RouteParam,
) => {
  const baseRoute = `${getEnforcementsRoute()}/create`;
  const queryString = qs.stringify({
    [STORE_ID]: storeId,
  });

  return baseRoute + '?' + queryString;
};

export const getEditEnforcementRoute = (enforcementId: RouteParam) => {
  return `${getEnforcementRoute(enforcementId)}/edit`;
};

export const getEnforcementNotificationTemplatePreviewRoute = (
  enforcementId: RouteParam,
  notificationTemplateId: RouteParam,
) => {
  return `${getEnforcementRoute(enforcementId)}/notification-templates-preview/${notificationTemplateId}`;
};

export const getEnforcementLoaPreviewRoute = (enforcementId: RouteParam) => {
  return `${getEnforcementRoute(enforcementId)}/letters-of-authorisations-preview`;
};

export const getEnforcementPolicyDefinitionsRoute = () => {
  return `${getSettingsEnforcementRoute()}/policy-definitions`;
};

export const getEnforcementPolicyTypesRoute = () => {
  return `${getSettingsEnforcementRoute()}/policy-types`;
};

export const getCreateEnforcementPolicyTypeRoute = () => {
  return `${getEnforcementPolicyTypesRoute()}/create`;
};

export const getEditEnforcementPolicyTypeRoute = (policyTypeId: RouteParam) => {
  return `${getEnforcementPolicyTypesRoute()}/${policyTypeId}/edit`;
};

export const getEnforcementInfringementTypesRoute = () => {
  return `${getSettingsEnforcementRoute()}/infringement-types`;
};

export const getCreateEnforcementInfringementTypeRoute = () => {
  return `${getEnforcementInfringementTypesRoute()}/create`;
};

export const getEditEnforcementInfringementTypeRoute = (infringementTypeId: RouteParam) => {
  return `${getEnforcementInfringementTypesRoute()}/${infringementTypeId}/edit`;
};

export const getCreateEnforcementPolicyDefinitionRoute = () => {
  return `${getEnforcementPolicyDefinitionsRoute()}/create`;
};

export const getEditEnforcementPolicyDefinitionRoute = (policyDefinitionId: RouteParam) => {
  return `${getEnforcementPolicyDefinitionsRoute()}/${policyDefinitionId}/edit`;
};

export const getEnforcementPolicyDefinitionRoute = (policyDefinitionId: RouteParam) => {
  return `${getEnforcementPolicyDefinitionsRoute()}/${policyDefinitionId}`;
};
