import classNames from 'classnames/bind';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type UrlUpdateType } from 'use-query-params';

import { ButtonIcon } from '@/components/shared/buttons/ButtonIcon';
import { Tooltip } from '@/components/shared/Tooltip';
import { useViewModeContext } from '@/contexts/shared/ViewModeContext';
import { ViewMode } from '@/resources/enums';
import type { IconName } from '@/types/shared';

import style from './ViewModeSelector.module.sass';

const iconNameMapping: Record<ViewMode, IconName> = {
  [ViewMode.Grid]: 'Grid',
  [ViewMode.List]: 'List',
  [ViewMode.Chart]: 'Chart',
};

type ViewModeSelectorProps = {
  updateType?: UrlUpdateType;
  shouldBeHiddenForOneOption?: boolean;
  shouldShowAllOptions?: boolean;
  isModeDisabled?: (viewMode: ViewMode) => boolean;
};

const cx = classNames.bind(style);

export const ViewModeSelector = ({
  updateType,
  isModeDisabled,
  shouldShowAllOptions = false,
  shouldBeHiddenForOneOption = false,
}: ViewModeSelectorProps) => {
  const { t } = useTranslation();
  const {
    viewMode,
    permittedValues,
    setViewMode,
    isChanging,
  } = useViewModeContext();

  const areAllModesVisible = shouldShowAllOptions || permittedValues.length > 2;
  const viewModeIndex = permittedValues.findIndex((v) => v === viewMode);

  const newViewMode = useMemo(() => {
    const newViewModeIndex = (viewModeIndex + 1) % permittedValues.length;

    return permittedValues[newViewModeIndex];
  }, [
    permittedValues,
    viewModeIndex,
  ]);

  const handleViewModeClick = useCallback((mode: ViewMode) => {
    setViewMode(mode, { updateType });
  }, [
    setViewMode,
    updateType,
  ]);

  if (permittedValues.length < 2 && shouldBeHiddenForOneOption) {
    return null;
  }

  const renderModeButton = (mode: ViewMode) => {
    const iconName = iconNameMapping[mode];
    const iconTooltip = <>{t(`common:showAs.${mode}`)}</>;
    const isDisabled = isModeDisabled?.(mode);
    const wrapperClassNames = cx('wrapper', {
      active: areAllModesVisible && viewMode === mode,
      disabled: isDisabled,
    });
    const dataCy = `viewModeSelector-${iconName}`;

    return (
      <Tooltip
        content={iconTooltip}
        delay={[300, 100]}
        key={mode}>
        <div className={wrapperClassNames}>
          <ButtonIcon
            disabled={isChanging || isDisabled}
            onClick={() => handleViewModeClick(mode)}
            iconName={iconName}
            dataCy={dataCy}
          />
        </div>
      </Tooltip>
    );
  };

  if (areAllModesVisible) {
    return (
      <>
        {permittedValues.map(renderModeButton)}
      </>
    );
  }

  return renderModeButton(newViewMode);
};
