import { Api } from '@/utils/api';
import type { ResetPassword, ResetPasswordValues } from '@/types/user';

export const passwordResetRequest = (values: ResetPasswordValues) => {
  const valuesWithoutExisitingUser = {
    ...values,
    isExistingUser: undefined,
  };

  const data = {
    attributes: {
      ...valuesWithoutExisitingUser,
      newAccount: values.isExistingUser ? undefined : true,
    },
    type: 'password_resets',
  };

  return Api.post<ResetPassword>('api/password_resets', { data });
};
