import {
  useQueryParam,
  withDefault,
  ArrayParam,
  type QueryParamOptions,
} from 'use-query-params';

import { isNonNullable } from '@/utils/misc';
import { EMPTY_ARRAY } from '@/resources/constants';
import type { Nullable, ReactStateSetter } from '@/types/shared';

type UseSelectionParamsReturnType = [
  string[],
  ReactStateSetter<Nullable<(string | null)[]>>,
];

export const useSelectionParams = (
  name: string,
  options: QueryParamOptions,
): UseSelectionParamsReturnType => {
  const [values, setValues] = useQueryParam(
    name,
    withDefault(ArrayParam, EMPTY_ARRAY),
    options,
  );

  if (values.length === 0) return [EMPTY_ARRAY, setValues];

  const nonNullableValues = values.filter(isNonNullable) as NonNullable<typeof values[number]>[];

  return [nonNullableValues, setValues];
};
