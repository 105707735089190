export const extractPathnameComponents = (urlString: string) => {
  try {
    const url = new URL(decodeURIComponent(urlString));

    const pathname = url.pathname.replace(/^\//, '');

    return pathname.split('/');
  } catch (error) {
    return;
  }
};
