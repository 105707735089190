import classNames from 'classnames/bind';
import { useRef } from 'react';

import {
  Header,
  type HeaderProps,
} from '@/components/shared/typography/Header/Header';
import { Tooltip } from '@/components/shared/Tooltip';
import { useIsTruncated } from '@/hooks/dom';

import style from './MultilineHeader.module.sass';

type MultilineHeaderProps = HeaderProps & {
  ellipsisVariant?: 'oneLine' | 'doubleLine' | 'tripleLine';
};

const cx = classNames.bind(style);

export const MultilineHeader = ({
  className,
  variant,
  children,
  ellipsisVariant = 'doubleLine',
}: MultilineHeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isTruncated = useIsTruncated(ref);

  const headerClassNames = cx('header', ellipsisVariant, className);

  return (
    <Tooltip
      content={
        <span className={style.content}>
          {children}
        </span>
      }
      disabled={!isTruncated}
      delay={[400, 100]}>
      <span>
        <Header variant={variant}>
          <div
            ref={ref}
            className={headerClassNames}>
            {children}
          </div>
        </Header>
      </span>
    </Tooltip>
  );
};
