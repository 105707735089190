import { QueryFunctionContext } from 'react-query';
import produce from 'immer';

import { Api } from '@/utils/api';
import { UseResellerParams } from '@/hooks/resellers/useResellers';
import {
  linkCollectionRelationships,
} from '@/utils/queries/linkCollectionRelationships';
import type {
  Reseller,
  ResellersCountry,
  ResellersFilterParams,
  ResellersRequestMeta,
} from '@/types/reseller';

export type ResellersQueryKey = [string, UseResellerParams | undefined];
export type ResellersIncludedRecord = ResellersCountry;

export const fetchResellers = async (context: QueryFunctionContext<ResellersQueryKey>) => {
  const [, params = {}] = context.queryKey;

  const {
    filter,
    pagination,
    sort,
  } = params;

  const page = pagination && {
    size: pagination.perPage,
    number: pagination.page,
  };

  const filterParams = buildResellersFilterParams(filter);

  const response = await Api.get<
    Reseller[],
    ResellersIncludedRecord,
    ResellersRequestMeta
  >('api/resellers', {
    params: {
      filter: filterParams,
      page,
      sort: sort?.sort,
    },
  });

  const parsedSellers = linkCollectionRelationships(response);

  return {
    resellers: parsedSellers,
    meta: response.meta,
  };
};

const buildResellersFilterParams = (filter: ResellersFilterParams | undefined) => {
  if (!filter) return filter;

  return produce<ResellersFilterParams>(filter, (draft) => {
    draft['store.brand_id'] = draft.brandId;
    delete draft.brandId;
  });
};
