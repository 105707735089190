import classNames from 'classnames/bind';
import { memo, PropsWithChildren } from 'react';

import style from './BusyContentContainer.module.sass';

const cx = classNames.bind(style);

type BusyContentContainerProps = PropsWithChildren<{
  isBusy?: boolean;
}>;

export const BusyContentContainer = memo(({
  isBusy,
  children,
}: BusyContentContainerProps) => {
  const wrapperClassName = cx('wrapper', {
    isBusy,
  });

  return (
    <div className={wrapperClassName}>
      {children}
    </div>
  );
});

BusyContentContainer.displayName = 'BusyContentContainer';
