import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { type MouseEventHandler, type PropsWithChildren } from 'react';

import { Button } from '@/components/shared/buttons';

import style from './ActionLabel.module.sass';

const cx = classNames.bind(style);

type ActionLabelProps = PropsWithChildren<{
  variant: 'purple' | 'beige';
  linkTo?: string;
  onClick?: MouseEventHandler;
}>;

export const ActionLabel = ({
  variant,
  children,
  onClick,
  linkTo,
}: ActionLabelProps) => {
  const wrapperClassName = cx('wrapper', variant, {
    interactable: onClick || linkTo,
  });

  if (linkTo) {
    return (
      <Link
        to={linkTo}
        onClick={onClick}
        className={wrapperClassName}>
        {children}
      </Link>
    );
  }

  if (onClick) {
    return (
      <Button.Unstyled
        onClick={onClick}
        className={wrapperClassName}>
        {children}
      </Button.Unstyled>
    );
  }

  return (
    <span className={wrapperClassName}>
      {children}
    </span>
  );
};
