import { IconName } from '@/types/shared';

import { AlertBoxProps } from './AlertBox';

export const getIconName = (variant: AlertBoxProps['variant']): IconName => {
  switch (variant) {
    case 'danger':
      return 'Warning';
    case 'info':
      return 'Info';
    default:
      return 'Warning';
  }
};
