import { forwardRef, memo } from 'react';

import * as icons from '@/assets/icons';
import type { SVGComponentProps, IconName } from '@/types/shared';

type IconProps = SVGComponentProps & {
  name: IconName;
};

export const Icon = memo(forwardRef<SVGSVGElement, IconProps>(({
  name,
  ...restProps
}: IconProps, forwardedRef) => {
  const IconComponent = icons[name];

  return (
    <IconComponent {...restProps} ref={forwardedRef} />
  );
}));

Icon.displayName = 'Icon';
