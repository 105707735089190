import classNames from 'classnames/bind';

import { Tooltip } from '@/components/shared/Tooltip';
import {
  Body,
  type BodyProps,
} from '@/components/shared/typography/Body';
import { useRelativeTime } from '@/hooks/utils/';
import { useFullTimeWithDate } from '@/hooks/utils';

import style from './BotRunTime.module.sass';

const cx = classNames.bind(style);

type BotRunTimeProps = {
  time: string | undefined;
  textSize?: BodyProps['size'];
  textClassName?: string;
};

export const BotRunTime = ({
  time,
  textSize = 'small',
  textClassName,
}: BotRunTimeProps) => {
  const relativeTime = useRelativeTime(time);
  const fullTimeWithDate = useFullTimeWithDate(time);
  const textClassNames = cx(style.text, textClassName);

  if (!time) {
    return null;
  }

  return (
    <Tooltip
      placement='top'
      content={<Body size='small'>{fullTimeWithDate}</Body>}>
      <Body size={textSize} className={textClassNames}>
        {relativeTime}
      </Body>
    </Tooltip>
  );
};
