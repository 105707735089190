import { PropsWithChildren, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { Skeleton } from '@/components/shared/loaders/Skeleton';
import { GridContainer } from '@/components/shared/containers/GridContainer';
import { WithQueryLoader } from '@/components/shared/WithQueryLoader';
import { ViewMode } from '@/resources/enums';
import { hasAnyNonNullish } from '@/utils/object';
import { useIsOnInstance, usePaginationParams } from '@/hooks/shared';
import type { Nullable } from '@/types/shared';

import style from './SkeletonLoader.module.sass';

const DEFAULT_SKELETON_LAYOUT_CONFIG = {
  [ViewMode.Grid]: {
    skeletonsNumber: 20,
    height: 250,
  },
  [ViewMode.List]: {
    skeletonsNumber: 11,
    header: {
      skeletonsNumber: 5,
      height: 22,
    },
  },
  [ViewMode.Chart]: {
    skeletonsNumber: 2,
    height: 450,
  },
};

const DEFAULT_CAROUSEL_SKELETON_CONFIG = {
  skeletonsNumber: 3,
  height: DEFAULT_SKELETON_LAYOUT_CONFIG[ViewMode.Grid].height,
};

const RESULTS_COUNT_SKELETONS_HEIGHT = 24.5;
const RESULTS_COUNT_SKELETONS_NUMBER = 1;

type SkeletonLoaderProps = {
  error?: Nullable<Error>;
  isLoading: boolean;
  children: ReactNode;
  viewMode?: ViewMode;
  filterValues?: Record<string, unknown>;
  listColumnsLength?: number;
};

type CarouselSkeletonProps = PropsWithChildren & {
  skeletonsNumber?: number;
  isLoading?: boolean;
};

export const SkeletonLoader = ({
  error,
  isLoading,
  children,
  viewMode,
  filterValues,
  listColumnsLength,
}: SkeletonLoaderProps) => {
  const { isCustomerInstance } = useIsOnInstance();
  const {
    perPage,
  } = usePaginationParams();

  if (isCustomerInstance) {
    return (
      <WithQueryLoader
        isLoading={isLoading}
        error={error}>
        {children}
      </WithQueryLoader>
    );
  }

  const isResultsCountVisible = filterValues
    && hasAnyNonNullish(filterValues, { ignore: [false, ''] });

  if (error) {
    return (
      <Navigate
        to='/404'
        replace={true}
      />
    );
  }

  const renderSkeletonLayout = () => {
    const { Grid, List, Chart } = ViewMode;

    if (viewMode === Grid) {
      const config = DEFAULT_SKELETON_LAYOUT_CONFIG[Grid];

      return (
        <GridContainer>
          <Skeleton
            skeletonsNumber={perPage ?? config.skeletonsNumber}
            height={config.height}
          />
        </GridContainer>
      );
    }

    if (viewMode === List) {
      const config = DEFAULT_SKELETON_LAYOUT_CONFIG[List];

      return (
        <>
          <div className={style.listTitleSkeletons}>
            <Skeleton
              skeletonsNumber={listColumnsLength}
              height={config.header.height}
            />
          </div>
          <div className={style.listWrapper}>
            <Skeleton
              skeletonsNumber={perPage ?? config.skeletonsNumber}
              className={style.skeletonRow}
            />
          </div>
        </>
      );
    }

    if (viewMode === Chart) {
      const config = DEFAULT_SKELETON_LAYOUT_CONFIG[Chart];

      return (
        <div className={style.chartSkeletons}>
          <Skeleton
            skeletonsNumber={config.skeletonsNumber}
            height={config.height}
          />
        </div>
      );
    }

    return null;
  };

  if (isLoading) {
    return (
      <>
        {
          isResultsCountVisible &&
          <Skeleton
            skeletonsNumber={RESULTS_COUNT_SKELETONS_NUMBER}
            height={RESULTS_COUNT_SKELETONS_HEIGHT}
            className={style.filterResultsCount}
          />
        }
        {renderSkeletonLayout()}
      </>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

const CarouselSkeleton = ({
  skeletonsNumber,
  isLoading,
  children,
}: CarouselSkeletonProps) => {
  const config = DEFAULT_CAROUSEL_SKELETON_CONFIG;

  if (isLoading) {
    return (
      <GridContainer>
        <Skeleton
          skeletonsNumber={skeletonsNumber || config.skeletonsNumber}
          height={config.height}
        />
      </GridContainer>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

SkeletonLoader.Carousel = CarouselSkeleton;
