import * as React from "react";
import { forwardRef } from "react";
const SvgFilePdf = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M19 8H15C14.448 8 14 7.552 14 7V3",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.3398 16.55H13.6598",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M10.833 12.751V11.583C10.833 11.261 11.094 11 11.416 11H12.584C12.906 11 13.167 11.261 13.167 11.583V12.751C13.167 13.073 12.906 13.334 12.584 13.334H11.416C11.094 13.333 10.833 13.072 10.833 12.751Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M8 17.417V16.249C8 15.927 8.261 15.666 8.583 15.666H9.751C10.073 15.666 10.334 15.927 10.334 16.249V17.417C10.334 17.739 10.073 18 9.751 18H8.583C8.261 18 8 17.739 8 17.417Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M13.667 17.417V16.249C13.667 15.927 13.928 15.666 14.25 15.666H15.418C15.74 15.666 16.001 15.927 16.001 16.249V17.417C16 17.739 15.739 18 15.417 18H14.249C13.928 18 13.667 17.739 13.667 17.417H13.667Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M14.24 15.6701L12.73 13.3101",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.75977 15.6701L11.2698 13.3101",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgFilePdf);
export default ForwardRef;
