import { createContext, type Dispatch } from 'react';

import { type DatePickerAction } from '@/reducers/datePickerReducer';
import type { Nullable } from '@/types/shared';

type DateType = Nullable<Date>;

export type DatePickerContextValue = {
  selectedDate: DateType;
  updateDate?: (date: DateType) => unknown;
  dispatch?: Dispatch<DatePickerAction>;
  datePickerValue?: DateType;
}

export const DatePickerContext = createContext<DatePickerContextValue>({
  selectedDate: null,
});
