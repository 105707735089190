import { fetchCompanyTrademark } from '@/queries/trademark';
import { useDataQuery } from '@/hooks/shared';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { type Trademark } from '@/types/trademark';

export const useCompanyTrademark = (trademarkId?: string) => {
  const {
    data: trademark,
    ...rest
  } = useDataQuery<Trademark>([QUERY_KEYS.trademark, trademarkId], fetchCompanyTrademark, {
    enabled: Boolean(trademarkId),
  });

  return {
    trademark,
    ...rest,
  };
};
