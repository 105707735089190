import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { type UseStoresCsvParams } from '@/hooks/stores';

export type StoresCsvQueryKey = [string, UseStoresCsvParams];

export const fetchStoresCsv = async (
  context: QueryFunctionContext<StoresCsvQueryKey>,
) => {
  const [, params = {}] = context.queryKey;

  const {
    sort,
    filter,
  } = params;

  const response = await Api.get<BlobPart>('api/stores', {
    params: {
      filter,
      sort: sort?.sort,
    },
    responseType: 'blob',
    headers: {
      accept: 'text/csv',
    },
  });

  return response;
};
