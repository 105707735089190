import { OS } from '@/resources/enums';

export const getOS = (): OS => {
  if (!('platform' in navigator)) {
    return OS.Other;
  }

  switch (navigator.platform) {
    case 'Windows':
    case 'Win32':
      return OS.Windows;
    default:
      return OS.Other;
  }
};
