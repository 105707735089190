import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_DATE_FORMAT } from '@/resources/datesFormat';
import { formatDate, formatTime } from '@/utils/date';

export const useFullTimeWithDate = (time?: string) => {
  const { t, i18n } = useTranslation();

  const timeFullWithDate = useMemo(() => {
    if (!time) {
      return;
    }

    const fullTime = formatTime(time, i18n.language, 'medium');
    const fullDate = formatDate(time, t('form:dateTime.dateFormat', DEFAULT_DATE_FORMAT));

    return `${fullDate} ${fullTime}`;
  }, [
    time,
    i18n.language,
    t,
  ]);

  return timeFullWithDate;
};
