import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EmptyCollection } from '@/components/shared/EmptyCollection';
import { StoresList } from '@/components/store/Stores/StoresList';
import { StoresGrid } from '@/components/store/StoresGrid';
import { StoreContextMenu } from '@/components/store/StoreContextMenu';
import { useViewModeContext } from '@/contexts/shared';
import { StoreStatus, ViewMode } from '@/resources/enums';
import { getStoreRoute } from '@/utils/routes/store';
import emptyStore from '@/assets/images/empty_store.svg';
import { type Store } from '@/types/store';

type StoresProps = {
  stores: Store[];
  pages: number;
  isFilterActive: boolean;
};

export const Stores = ({
  stores,
  pages,
  isFilterActive,
}: StoresProps) => {
  const { t } = useTranslation();
  const {
    viewMode,
  } = useViewModeContext();

  const navigate = useNavigate();

  const navigateToStore = (store: Store) => navigate(getStoreRoute(store.id));

  const contextMenu = (store: Store) => {
    const shouldShowOptions = store?.attributes.status !== StoreStatus.ActionInProgress;

    return {
      options: (
        <StoreContextMenu store={store} />
      ),
      shouldDisplayPlaceholder: !shouldShowOptions,
    };
  };

  if (!stores.length) {
    if (isFilterActive) {
      return (
        <EmptyCollection
          header={t('store:list.noResults')}
          animatedMediaName='MagnifyingGlass'
        />
      );
    }

    return (
      <EmptyCollection
        header={t('store:list.noStores')}
        imageSource={emptyStore}
      />
    );
  }

  if (viewMode === ViewMode.Grid) {
    return (
      <StoresGrid
        contextMenu={contextMenu}
        getStoreRoute={getStoreRoute}
        stores={stores}
        pages={pages}
        onClick={navigateToStore}
      />
    );
  }

  return (
    <StoresList
      contextMenu={contextMenu}
      stores={stores}
      getStoreRoute={getStoreRoute}
      onRowClick={navigateToStore}
    />
  );
};
