import { useTranslation } from 'react-i18next';
import { type MouseEventHandler } from 'react';

import { ActionLabel } from '@/components/shared/ActionLabel';
import { Tooltip } from '@/components/shared/Tooltip';
import { getEnforcementRoute } from '@/utils/routes';
import type { Listing } from '@/types/listing';

type ListingEnforcementIndicatorProps = {
  listing: Listing;
  translationContext?: 'short';
  onClick?: MouseEventHandler;
  withTooltip?: boolean;
};

export const ListingEnforcementIndicator = ({
  listing,
  translationContext,
  onClick,
  withTooltip,
}: ListingEnforcementIndicatorProps) => {
  const { t } = useTranslation();

  const enforcement = listing.linked?.latestEnforcement;

  if (!enforcement) return null;

  const renderActionLabel = (text: string) => {
    const targetPath = getEnforcementRoute(enforcement.id);

    return (
      <ActionLabel
        variant='purple'
        onClick={onClick}
        linkTo={targetPath}>
        {text}
      </ActionLabel>
    );
  };

  if (withTooltip) {
    return (
      <Tooltip
        placement='bottom'
        content={enforcement.linked?.enforcementStatus.attributes.name}>
        <div>
          {renderActionLabel(t('listing:status.name.enforcement'))}
        </div>
      </Tooltip>
    );
  }

  const text = t('listing:status.enforcement', {
    status: enforcement.linked?.enforcementStatus.attributes.name,
    context: translationContext,
  });

  return (
    renderActionLabel(text)
  );
};
