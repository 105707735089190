import classNames from 'classnames/bind';
import { PropsWithChildren } from 'react';

import style from './Kicker.module.sass';

const cx = classNames.bind(style);

type KickerProps = PropsWithChildren<{
  size: 'small' | 'base' | 'large';
  className?: string;
}>;

export const Kicker = ({
  children,
  size,
  className,
}: KickerProps) => {
  const kickerClassName = cx('kicker', className, size);

  return (
    <span className={kickerClassName}>
      {children}
    </span>
  );
};
