import { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';

import { Loader } from '@/components/shared/loaders/Loader';
import { Nullable } from '@/types/shared';

import style from './PdfViewer.module.sass';
import { registerWorkerForPdf } from './utils';

registerWorkerForPdf();

type PdfViewerProps = {
  url: Nullable<string>;
  onLoadSuccess?: () => void;
};

export const PdfViewer = ({
  url,
  onLoadSuccess,
}: PdfViewerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [pages, setPages] = useState<number>();

  const handleSuccessLoad = ({ numPages }: PDFDocumentProxy) => {
    setPages(numPages);
    onLoadSuccess?.();
  };

  const renderPages = () => (
    Array.from(new Array(pages), (_, index) => (
      <Page
        key={index}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        pageNumber={index + 1}
        width={containerRef.current?.offsetWidth}
        className={style.pageWrapper}
      />
    ))
  );

  return (
    <div className={style.wrapper} ref={containerRef}>
      <Document
        file={url}
        loading={
          <div className={style.loaderWrapper}>
            <Loader />
          </div>
        }
        onLoadSuccess={handleSuccessLoad}>
        {renderPages()}
      </Document>
    </div>
  );
};
