import { useState } from 'react';

export type MultiStepNavigation = ReturnType<typeof useMultiStepNavigation>;

export const useMultiStepNavigation = (numberOfSteps: number) => {
  const [currentStep, setCurrentStep] = useState(0);

  const isNextStepAvailable = currentStep < numberOfSteps - 1;
  const isPreviousStepAvailable = currentStep > 0;

  const nextStep = () => {
    if (isNextStepAvailable) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (isPreviousStepAvailable) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  return {
    currentStep,
    nextStep,
    previousStep,
    isNextStepAvailable,
    isPreviousStepAvailable,
    numberOfSteps,
  };
};
