import { GREYSCOUT_COMPANY_ID } from '@/resources/constants';
import { useUser } from '@/hooks/companyUser';
import { useAuthentication } from '@/hooks/user';

export const useIsGreyScoutEmployee = () => {
  const {
    user: authUser,
  } = useAuthentication();

  const userId = authUser?.gsUid;

  const {
    user,
  } = useUser(userId ? String(userId) : undefined);

  const companyId = user?.linked?.company?.id;

  return companyId === GREYSCOUT_COMPANY_ID;
};
