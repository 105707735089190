import difference from 'lodash-es/difference';

import { JointCheckboxState } from '@/resources/enums';
import { areItemsEqual, hasAnyCommonItem } from '@/utils/array';
import type { TableColumnConfig } from '@/types/tables';
import type { Nullable } from '@/types/shared';

import type { BaseItem } from './types';

export const getCellSizeProps = (column: TableColumnConfig<never>) => {
  if (typeof column.colSpan === 'number') {
    return { colSpan: column.colSpan };
  }

  return {
    style: {
      width: column.width,
    },
  };
};

export const getHeaderCheckboxState = (
  itemIds: string[],
  selectedIds: string[],
) => {
  if (selectedIds.length === 0) return;

  if (hasAnyCommonItem(itemIds, selectedIds)) {
    if (areItemsEqual(itemIds, selectedIds)) {
      return JointCheckboxState.Checked;
    }

    return JointCheckboxState.Ambiguous;
  }

  return undefined;
};

export const getSelectableItemIds = (
  items: BaseItem[],
  disabledIds?: Nullable<string[]>,
) => {
  const itemIds = items.map((item) => item.id);

  if (disabledIds) return difference(itemIds, disabledIds);

  return itemIds;
};
