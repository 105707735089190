import { useTranslation } from 'react-i18next';

import { NavButton } from '@/components/shared/buttons';
import { useIsGreyScoutAdmin } from '@/hooks/shared';
import { getStoreEmailEditRoute } from '@/utils/routes';

type EditStoreEmailButtonProps = {
  storeId: string;
};

export const EditStoreEmailButton = ({
  storeId,
}: EditStoreEmailButtonProps) => {
  const { t } = useTranslation();
  const isGreyScoutAdmin = useIsGreyScoutAdmin();

  if (!isGreyScoutAdmin) {
    return null;
  }

  return (
    <NavButton navigateTo={getStoreEmailEditRoute(storeId)}>
      {t('store:actions.editEmail')}
    </NavButton>
  );
};
