import { ReactNode, useRef } from 'react';

import { Body } from '@/components/shared/typography/Body';
import { Tooltip } from '@/components/shared/Tooltip';
import {
  FilterEntry,
  type FilterEntryProps,
} from '@/components/shared/FilterEntry';
import { useIsTruncated } from '@/hooks/dom';

import style from './LengthyFilterEntry.module.sass';

type LengthyFilterEntryProps = {
  onRemove: () => void;
  name: ReactNode | string;
  entryLabel?: string;
  variant?: FilterEntryProps['variant'];
};

export const LengthyFilterEntry = ({
  onRemove,
  name,
  entryLabel,
  variant,
}: LengthyFilterEntryProps) => {
  const nameEntryRef = useRef<HTMLDivElement>(null);
  const isNameTruncated = useIsTruncated(nameEntryRef);

  return (
    <FilterEntry
      onRemove={onRemove}
      variant={variant}>
      <Tooltip
        placement='top'
        disabled={!isNameTruncated}
        content={
          <Body size='small'>{name}</Body>
        }>
        <div className={style.entryWrapper} ref={nameEntryRef}>
          <span className={style.entryLabel}>
            {entryLabel}{' '}
          </span>
          <span className={style.entryName}>
            {name}
          </span>
        </div>
      </Tooltip>
    </FilterEntry>
  );
};
