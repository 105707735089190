import {
  useLayoutEffect,
  useState,
  type RefObject,
} from 'react';

export const useIsTruncated = (elementRef: RefObject<HTMLElement>) => {
  const [isTruncated, setIsTruncated] = useState(false);

  useLayoutEffect(() => {
    if (!elementRef.current) {
      setIsTruncated(false);
      return;
    }

    const entryWidth = elementRef.current.offsetWidth;
    const entryScrollWidth = elementRef.current.scrollWidth;

    const entryHeight = elementRef.current.offsetHeight + 1;
    const entryScrollHeight = elementRef.current.scrollHeight;

    setIsTruncated(
      entryWidth < entryScrollWidth
      || entryHeight < entryScrollHeight,
    );
  }, [
    elementRef,
  ]);

  return isTruncated;
};
