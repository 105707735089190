import { initializeApp } from '@firebase/app';
import {
  getAuth,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  confirmPasswordReset,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from '@firebase/auth';

import { type EnvConfig } from '@/utils/environment';
import { findFirst } from '@/utils/array';
import { identity } from '@/utils/misc';
import type { AuthUser } from '@/types/auth';

import { googleProvider } from './providers';

class FirebaseHelperSingletonClass {
  #app?: ReturnType<typeof initializeApp>;

  initialize(config: EnvConfig) {
    const appConfig = {
      projectId: config.VITE_FIREBASE_PROJECT_ID,
      apiKey: config.VITE_FIREBASE_API_KEY,
      authDomain: config.VITE_FIREBASE_AUTH_DOMAIN,
    };

    this.#app = initializeApp(appConfig);
  }

  getAuth() {
    if (!this.#app) {
      throw new Error('Firebase app not initialized!');
    }

    return getAuth(this.#app);
  }
}

export const AuthHelper = new FirebaseHelperSingletonClass();

const GOOGLE_PROVIDER_ID = 'google.com';

export const firebaseSignInWithGoogle = () => {
  const auth = AuthHelper.getAuth();

  return signInWithPopup(auth, googleProvider);
};

export const firebaseSignInWithEmailAndPassword = (email: string, password: string) => {
  const auth = AuthHelper.getAuth();

  return signInWithEmailAndPassword(auth, email, password);
};

export const firebaseSignOut = () => {
  const auth = AuthHelper.getAuth();

  return signOut(auth);
};

export const isUserSignedByGoogle = (user?: AuthUser) => {
  if (!user) {
    return false;
  }

  const userData = findFirst(user.providerData, identity);

  return userData?.providerId === GOOGLE_PROVIDER_ID;
};

export const firebaseResetPassword = (code: string, newPassword: string) => {
  const auth = AuthHelper.getAuth();

  return confirmPasswordReset(auth, code, newPassword);
};

export const firebaseUpdatePassword = (newPassword: string) => {
  const { currentUser } = AuthHelper.getAuth() ?? {};

  if (!currentUser) {
    return false;
  }

  return updatePassword(currentUser, newPassword);
};

export const firebaseReauthenticateUser = (password: string) => {
  const { currentUser } = AuthHelper.getAuth() ?? {};
  if (!currentUser?.email) {
    return;
  }

  const credential = EmailAuthProvider.credential(currentUser?.email, password);

  return reauthenticateWithCredential(currentUser, credential);
};
