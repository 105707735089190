import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries';
import type { Loa, LoaIncludedRecord } from '@/types/loa';

export const fetchLoa = async (context: QueryFunctionContext) => {
  const [, companyId, loaId] = context.queryKey;
  const response = await Api.get<Loa, LoaIncludedRecord>(`api/companies/${companyId}/letters_of_authorisations/${loaId}`);

  const parsedResponse = linkCollectionRelationships(response);

  return parsedResponse;
};
