import { Icon } from '@/components/shared/Icon';
import { useBranding } from '@/hooks/branding';

import style from './CompaniesLogosHeader.module.sass';

export const CompaniesLogosHeader = () => {
  const { branding } = useBranding();

  if (!branding) {
    return null;
  }

  return (
    <div className={style.wrapper}>
      <Icon name='GreyScoutLarge' className={style.GSlogo} />
      <div className={style.separator} />
      <img
        className={style.logoImage}
        src={branding.attributes.logoUrl}
        alt={branding.attributes.name}
      />
    </div>
  );
};
