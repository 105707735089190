import { useDataMutation } from '@/hooks/shared';
import { uploadImage } from '@/mutations/image';

export const useUploadImage = () => {
  const {
    mutate,
    mutateAsync,
    ...rest
  } = useDataMutation('uploadImage', uploadImage);

  return {
    uploadImage: mutate,
    uploadImageAsync: mutateAsync,
    ...rest,
  };
};
