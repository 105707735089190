import { BotsView } from '@/components/bot/BotsView/BotsView';
import { ViewModeSelectorContext } from '@/components/shared/viewMode/ViewModeSelectorContext';
import { ViewMode } from '@/resources/enums';

type BotsViewRendererProps = {
  companyId?: string;
  canManage: boolean;
  isMainHarvestersView?: boolean;
};

const permittedValues = [ViewMode.Grid, ViewMode.List];

export const BotsViewRenderer = ({
  companyId,
  canManage,
  isMainHarvestersView,
}: BotsViewRendererProps) => {
  return (
    <ViewModeSelectorContext permittedValues={permittedValues}>
      <BotsView
        companyId={companyId}
        canManage={canManage}
        isMainHarvestersView={isMainHarvestersView}
      />
    </ViewModeSelectorContext>
  );
};
