import { useUserRole } from '@/hooks/companyUser';

import { useIsGreyScoutEmployee } from './useIsGreyScoutEmployee';

export const useIsGreyScoutAdmin = () => {
  const { isAdmin } = useUserRole();

  const isGreyScoutEmployee = useIsGreyScoutEmployee();

  return isGreyScoutEmployee && isAdmin;
};
