import { type PropsWithChildren } from 'react';

import style from './FilterEntriesContainer.module.sass';

type FilterEntriesContainerProps = PropsWithChildren;

export const FilterEntriesContainer = ({
  children,
}: FilterEntriesContainerProps) => {
  return (
    <div className={style.wrapper}>
      {children}
    </div>
  );
};
