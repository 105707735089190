import classNames from 'classnames/bind';
import { useState, type ReactNode, MouseEventHandler, MouseEvent } from 'react';

import { AUXILIARY_BUTTON_INDEX } from '@/resources/constants';
import type { BorderRadiusVariant } from '@/types/styles';

import style from './Card.module.sass';
import {
  CardContextMenu,
  type CardContextMenuProps,
} from './CardContextMenu';

const cx = classNames.bind(style);

type CardProps = {
  className?: string;
  children: ReactNode;
  borderRadiusVariant?: BorderRadiusVariant;
  'data-cy'?: string;
  contextMenuProps?: CardContextMenuProps;
  isContextMenuDisabled?: boolean;
  wrapperClassName?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  resourceRoute?: string;
};

export const Card = ({
  className,
  children,
  borderRadiusVariant = 'lg',
  'data-cy': dataCy,
  onClick,
  resourceRoute,
  contextMenuProps,
  isContextMenuDisabled,
  wrapperClassName,
}: CardProps) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const wrapperClassNames = cx('wrapper', `borderRadius-${borderRadiusVariant}`, wrapperClassName, {
    clickable: onClick,
    isContextMenuOpen,
  });
  const cardContextMenuWrapperClassName = cx(
    'contextMenuWrapper',
    contextMenuProps?.wrapperClassName,
  );
  const cardContextMenuArrowClassName = cx(
    'contextMenuArrow',
    contextMenuProps?.arrowClassName,
  );

  const baseProps = {
    className: wrapperClassNames,
    'data-cy': dataCy,
  };

  const renderContent = () => (
    <>
      {children}
      {
        !isContextMenuDisabled &&
        <CardContextMenu
          setIsOpen={setIsContextMenuOpen}
          isOpen={isContextMenuOpen}
          {...contextMenuProps}
          wrapperClassName={cardContextMenuWrapperClassName}
          arrowClassName={cardContextMenuArrowClassName}
        />
      }
    </>
  );

  const contentClassName = cx(baseProps.className, className);

  const openResourceRouteNewTab = (event?: MouseEvent<HTMLDivElement>) => {
    if (!resourceRoute) {
      return;
    }

    if (!event || event.button === AUXILIARY_BUTTON_INDEX) {
      window.open(resourceRoute, '_blank');
    }
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.metaKey || event.ctrlKey) {
      openResourceRouteNewTab();

      return;
    }

    onClick?.(event);
  };

  return (
    <div
      {...baseProps}
      role={onClick ? 'button' : undefined}
      className={contentClassName}
      onClick={handleClick}
      onAuxClick={openResourceRouteNewTab}>
      {renderContent()}
    </div>
  );
};
