import * as React from "react";
import { forwardRef } from "react";
const SvgBulletList = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M12.0063 12.0006H20.0063",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12.0063 19.0006H20.0063",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12.0063 5.00064H20.0063",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M6.50635 12.0013C6.506 12.2772 6.28212 12.5008 6.00614 12.5006C5.73016 12.5005 5.50646 12.2768 5.50635 12.0009C5.50623 11.7249 5.72974 11.501 6.00572 11.5006C6.13854 11.5005 6.26598 11.5532 6.3599 11.6471C6.45382 11.741 6.50651 11.8684 6.50635 12.0013",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M6.50635 5.00127C6.506 5.27725 6.28212 5.50076 6.00614 5.50064C5.73016 5.50052 5.50646 5.27683 5.50635 5.00085C5.50623 4.72487 5.72974 4.50099 6.00572 4.50064C6.13854 4.50047 6.26598 4.55316 6.3599 4.64709C6.45382 4.74101 6.50651 4.86844 6.50635 5.00127",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M6.50635 19.0013C6.506 19.2772 6.28212 19.5008 6.00614 19.5006C5.73016 19.5005 5.50646 19.2768 5.50635 19.0009C5.50623 18.7249 5.72974 18.501 6.00572 18.5006C6.13854 18.5005 6.26598 18.5532 6.3599 18.6471C6.45382 18.741 6.50651 18.8684 6.50635 19.0013",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgBulletList);
export default ForwardRef;
