import classNames from 'classnames/bind';

import style from './AnimatedLoadingDots.module.sass';

const cx = classNames.bind(style);

export const AnimatedLoadingDots = () => {
  const dotOneClassNames = cx('dot', 'one');
  const dotTwoClassNames = cx('dot', 'two');
  const dotThreeClassNames = cx('dot', 'three');

  return (
    <div className={style.loadingDots}>
      <div className={dotOneClassNames}>.</div>
      <div className={dotTwoClassNames}>.</div>
      <div className={dotThreeClassNames}>.</div>
    </div>
  );
};
