import * as React from "react";
import { forwardRef } from "react";
const SvgReportChartActive = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M21 7V17C21 19.209 19.209 21 17 21H7C4.791 21 3 19.209 3 17V7C3 4.791 4.791 3 7 3H17C19.209 3 21 4.791 21 7Z",
  fill: "currentColor",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.333 16.167V12.834C10.333 12.373 9.96 12 9.5 12H7.833C7.373 12 7 12.373 7 12.833V16.06",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M13.667 16.167V8.66701C13.667 8.20701 13.294 7.83401 12.834 7.83401H11.167C10.707 7.83401 10.334 8.20701 10.334 8.66701V16.167",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M17 16.061V10.75C17 10.29 16.627 9.91699 16.167 9.91699H14.5C14.04 9.91699 13.667 10.29 13.667 10.75V16.167",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M17 16.17H7",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgReportChartActive);
export default ForwardRef;
