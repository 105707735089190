import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { ListingsSortSelect } from '@/components/listing/ListingsSortSelect/ListingsSortSelect';
import { SortableOption } from '@/types/sortable';

export const ResellerStoreListingsSortSelect = () => {
  const { t } = useTranslation();

  const options = useMemo<SortableOption[]>(() => {
    return ([
      {
        value: 'product_name',
        label: t('store:sortableStoreListings.labels.productNameAz'),
      },
      {
        value: '-product_name',
        label: t('store:sortableStoreListings.labels.productNameZa'),
      },
      {
        value: '-price',
        label: t('store:sortableStoreListings.labels.prizeHighest'),
      },
      {
        value: 'price',
        label: t('store:sortableStoreListings.labels.prizeLowest'),
      },
    ]);
  }, [
    t,
  ],
  );

  return (
    <ListingsSortSelect sortOptions={options} />
  );
};
