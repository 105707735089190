import { lazy } from 'react';

const AppRoutes = lazy(() => import('@/components/App/AppRoutes'));
const AppBreadcrumbs = lazy(() => import('@/components/App/AppBreadcrumbs'));
const DeleteConfirmationModals = lazy(() => import('@/components/App/DeleteConfirmationModals'));
const Modals = lazy(() => import('@/components/App/Modals'));

export const AppGateway = () => {
  return (
    <Modals>
      <DeleteConfirmationModals>
        <AppBreadcrumbs>
          <AppRoutes />
        </AppBreadcrumbs>
      </DeleteConfirmationModals>
    </Modals>
  );
};
