import { useBranding } from '@/hooks/branding';
import { useCompanyTrademarks } from '@/hooks/trademark/useCompanyTrademarks';

export const useIsTrademarkAddedToSubdomain = (subdomainId?: string) => {
  const {
    error: brandingError,
    branding,
    isLoading: isBrandingLoading,
  } = useBranding();

  const {
    error: errorCompanyTrademarks,
    companyTrademarks,
    isLoading: isTrademarksLoading,
  } = useCompanyTrademarks({
    filter: {
      subdomainId,
      companyId: branding?.attributes.companyId,
    },
  });

  return {
    isTrademarkAddedToSubdomain: Boolean(companyTrademarks?.length),
    isLoading: isBrandingLoading || isTrademarksLoading,
    error: brandingError || errorCompanyTrademarks,
  };
};
