import { ReactNode } from 'react';

import loginImage from '@/assets/images/login.png';

import style from './UserAuthPageLayout.module.sass';

type UserAuthPageLayoutProps = {
  children: ReactNode;
};

export const UserAuthPageLayout = ({
  children,
}: UserAuthPageLayoutProps) => {
  return (
    <div className={style.wrapper}>
      <div className={style.content}>
        <div className={style.form}>
          {children}
        </div>
      </div>
      <div className={style.imageArea}>
        <div className={style.imageWrapper}>
          <img className={style.image} src={loginImage} />
        </div>
      </div>
    </div>
  );
};
