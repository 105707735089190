import { MISSING_RESELLER_ID } from '@/hooks/resellers/useReseller';
import { type ListingReseller } from '@/types/listing';
import { type Reseller } from '@/types/reseller';
import { type ApiRecordRelationshipData } from '@/types/shared';

/*
   * NOTE:
   * id = 1 is a temporary backend solution to indicate missing reseller
 */
export const isResellerValid = (
  reseller:
    | Reseller
    | ListingReseller
    | ApiRecordRelationshipData<'resellers'>,
) => {
  return reseller?.id !== MISSING_RESELLER_ID;
};
