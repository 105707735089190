import classNames from 'classnames/bind';
import { memo } from 'react';

import style from './Loader.module.sass';

const cx = classNames.bind(style);

type LoaderProps = {
  className?: string;
  /**
   * @default medium
   */
  size?: 'medium' | 'medium-small' | 'small';
  /**
   * @default default
   */
  type?: 'default' | 'light';
  animation?: 'default' | 'simple';
};

export const Loader = memo(({
  className,
  size = 'medium',
  type = 'default',
  animation = 'default',
}: LoaderProps) => {
  const loaderClassName = cx('loader', className, size, type);

  return (
    <div className={loaderClassName} data-testid='loader'>
      <div className={style.line} />
      {
        animation === 'default' &&
        <div className={cx('line', 'secondary')} />
      }
    </div>
  );
});

Loader.displayName = 'Loader';
