import classNames from 'classnames/bind';
import {
  type ReactNode,
  isValidElement,
} from 'react';

import type { Nullable } from '@/types/shared';

import style from './ListingPrice.module.sass';

const cx = classNames.bind(style);

type ListingPriceProps = {
  price: Nullable<string>;
  placeholder: string | ReactNode;
  variant?: 'primary' | 'secondary';
  className?: string;
};

export const ListingPrice = ({
  price,
  placeholder,
  variant = 'primary',
  className,
}: ListingPriceProps) => {
  const priceVariant = !price && !isValidElement(placeholder) ? 'muted' : variant;
  const priceWrapperClassNames = cx('price', className, priceVariant);

  if (!price) {
    if (isValidElement(placeholder)) {
      return (
        <div className={priceWrapperClassNames}>
          {placeholder}
        </div>
      );
    }

    return (
      <div className={priceWrapperClassNames}>
        {placeholder}
      </div>
    );
  }

  return (
    <div className={priceWrapperClassNames}>
      {price}
    </div>
  );
};
