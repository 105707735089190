import * as React from "react";
import { forwardRef } from "react";
const SvgNoTime = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 48,
  height: 48,
  viewBox: "0 0 48 48",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("circle", {
  cx: 35.5,
  cy: 35.5,
  r: 8.5,
  stroke: "currentColor",
  strokeWidth: 3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M37.5 35.5H33.5",
  stroke: "currentColor",
  strokeWidth: 3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M24.782 14V24.782H16",
  stroke: "currentColor",
  strokeWidth: 3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M41.883 22C40.8376 12.6357 32.7463 5.66308 23.3304 6.01219C13.9144 6.36129 6.36172 13.914 6.01256 23.3299C5.66339 32.7458 12.636 40.8371 22.0002 41.8827",
  stroke: "currentColor",
  strokeWidth: 3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgNoTime);
export default ForwardRef;
