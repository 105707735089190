import { createContext } from 'react';

export type EditStoreEmailModalData = {
  storeName: string;
  onSubmit: () => void;
  isUpdatingStore: boolean;
}

export type EditStoreEmailContextValue = {
  openModal: (data: EditStoreEmailModalData) => void;
  closeModal: () => void;
}

export const EditStoreEmailContext = createContext<EditStoreEmailContextValue | null>(null);
