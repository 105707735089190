import { useDataQuery } from '@/hooks/shared';
import { fetchSettingsChannel } from '@/queries/settingsChannel';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const useSettingsChannel = (channelId?: string) => {
  const {
    data: settingsChannel,
    ...rest
  } = useDataQuery([QUERY_KEYS.settingsChannel, channelId], fetchSettingsChannel);

  return {
    settingsChannel,
    ...rest,
  };
};
