import { useTranslation } from 'react-i18next';

import style from './UnknownSetting.module.sass';

export const UnknownSetting = () => {
  const { t } = useTranslation();

  return (
    <span className={style.unknown}>{t('common:unknown')}</span>
  );
};
