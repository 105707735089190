import { TFunction } from 'i18next';

import { PasswordPolicyErrors } from '@/types/user';

export const getPasswordRequirements = (
  password: string,
  t: TFunction,
  policyErrors?: PasswordPolicyErrors,
) => {
  const {
    length,
    casing,
    number,
    symbol,
  } = policyErrors ?? {};

  const requirements = [
    {
      label: t('loginPage:resetPassword.passwordRequirements.1'),
      isMeetingRequirement: password && length !== undefined,
      hasError: length,
    },
    {
      label: t('loginPage:resetPassword.passwordRequirements.2'),
      isMeetingRequirement: password && casing !== undefined,
      hasError: casing,
    },
    {
      label: t('loginPage:resetPassword.passwordRequirements.3'),
      isMeetingRequirement: password && number !== undefined,
      hasError: number,
    },
    {
      label: t('loginPage:resetPassword.passwordRequirements.4'),
      isMeetingRequirement: password && symbol !== undefined,
      hasError: symbol,
    },
  ];

  return requirements;
};
