import { type FormikHelpers } from 'formik';
import { camelCase } from 'camel-case';
import { type TFunction } from 'i18next';

import { translateFormikError } from '@/utils/formik/translateFormikError';
import { translateBaseError } from '@/utils/formik/translateBaseError';
import { notification } from '@/utils/notification';
import type { FormikSubmitHandlerOptionsConfig } from '@/hooks/shared/useFormikMutationHandler';
import type {
  ApiDataErrors,
  ApiDataError,
  UseMutationError,
  ApiError,
  FormikSubmitHandlerErrorMessagesMap,
} from '@/types/shared';

const POINTER_SEPARATOR = '/';

const normalizePointer = (pointer: string) => {
  const attributeName = pointer.split(POINTER_SEPARATOR).at(-1) || '';
  const camelCaseName = camelCase(attributeName);

  return camelCaseName;
};

/* eslint-disable indent */

export const handleFormikErrors = <
  TValues,
>(
  error: UseMutationError<ApiDataErrors, TValues>,
  formikHelpers: FormikHelpers<TValues> | undefined,
  t: TFunction,
  errorMessages: FormikSubmitHandlerErrorMessagesMap,
  config?: FormikSubmitHandlerOptionsConfig,
) => {
  /* eslint-enable indent */
  const dataErrors = error.response?.data;

  const settings: FormikSubmitHandlerOptionsConfig = {
    errorReportType: 'formik',
    notification: {
      title: '',
    },
    ...config,
  };

  if (!dataErrors) {
    const baseMessage = t('common:errors.somethingWentWrongError');

    if (settings.errorReportType === 'notification') {
      showNotification(settings.notification.title, baseMessage);
    }

    if (settings?.errorReportType === 'formik') {
      formikHelpers?.setFieldError('base', baseMessage);
    }

    return;
  }

  if (isArrayOfApiDataErrors(dataErrors.errors)) {
    const errorsToFieldMap = dataErrors.errors.reduce((accumulator, errorData) => {
      const normalizedPointer = normalizePointer(errorData.source?.pointer ?? 'base');
      const errorMessage = translateFormikError(
        errorData,
        t,
        settings?.notification.tKeys,
      );

      if (!accumulator[normalizedPointer]) {
        accumulator[normalizedPointer] = [];
      }
      accumulator[normalizedPointer].push(errorMessage);

      return accumulator;
    }, {} as Record<string, string[]>);

    for (const [fieldName, messages] of Object.entries(errorsToFieldMap)) {
      const message = messages.join('\n');

      if (settings?.errorReportType === 'notification') {
        showNotification(settings.notification.title, message);
      }

      if (settings?.errorReportType === 'formik') {
        formikHelpers?.setFieldError(fieldName, message);
        formikHelpers?.setFieldTouched(fieldName, true, false);
      }
    }

    return;
  }

  if (isSingleApiError(dataErrors.errors)) {
    const errorStatus = error.response?.status;
    const errorMessage = translateBaseError(dataErrors.errors, t, errorMessages, errorStatus);

    if (settings?.errorReportType === 'notification') {
      showNotification(settings.notification.title, errorMessage);
    }

    if (settings?.errorReportType === 'formik') {
      formikHelpers?.setFieldError('base', errorMessage);
    }

    return;
  }
};

const isArrayOfApiDataErrors = (errors: ApiDataErrors['errors']): errors is ApiDataError[] => {
  return Array.isArray(errors);
};

const isSingleApiError = (errors: ApiDataErrors['errors']): errors is ApiError => {
  return 'detail' in errors;
};

const showNotification = (
  title: string,
  body: string,
) => {
  notification.danger({ title, body });
};
