import classNames from 'classnames/bind';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CountryFlag } from '@/components/shared/CountryFlag';
import type { Nullable } from '@/types/shared';

import style from './CountryLogotype.module.sass';

const cx = classNames.bind(style);

type CountryLogotypeProps = {
  countryName: Nullable<string>;
  className?: string;
  countryTextElement?: string | ReactNode;
  isFlagShown?: boolean;
};

export const CountryLogotype = ({
  countryName,
  className,
  countryTextElement,
  isFlagShown = true,
}: CountryLogotypeProps) => {
  const { t } = useTranslation();

  const wrapperClassName = cx('wrapper', className);

  if (!countryName) {
    return (
      <div className={wrapperClassName}>
        {t('common:unknown')}
      </div>
    );
  }

  const renderText = () => {
    if (countryTextElement) {
      return countryTextElement;
    }

    return (
      <span className={style.name}>
        {countryName}
      </span>
    );
  };

  return (
    <div className={wrapperClassName}>
      {
        isFlagShown && (
          <>
            <CountryFlag countryName={countryName} />
            &nbsp;&nbsp;
          </>
        )
      }
      {renderText()}
    </div>
  );
};
