import type { Channel } from '@/types/channel';
import type { AggregationEntry } from '@/types/shared';

export const getAvailableAggregationChannels = (
  channels: Channel[] | undefined,
  subdomains: AggregationEntry[] | undefined,
) => {
  if (!channels) return;
  return channels.map((channel) => {
    const availableSubdomains = channel.linked?.subdomains?.filter((subdomain) => (
      subdomains?.some((botSubdomain) => String(botSubdomain.id) === subdomain.id)
    ));

    return {
      ...channel,
      linked: {
        subdomains: availableSubdomains,
      },
    };
  });
};
