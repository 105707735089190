type HasAnyNonNullishOptions = {
  ignore?: unknown[];
  ignoreKeys?: string[];
}

export const hasAnyNonNullish = (
  object: Record<string, unknown>,
  options: HasAnyNonNullishOptions = {},
) => {
  const {
    ignore,
    ignoreKeys,
  } = options;

  const values = [null, undefined] as unknown[];

  if (ignore !== undefined) {
    values.push(...ignore);
  }

  for (const key in object) {
    if (ignoreKeys && ignoreKeys.includes(key)) {
      continue;
    }

    if (!(key in object) || values.includes(object[key])) {
      continue;
    }

    return true;
  }

  return false;
};
