import {
  useQueries,
  type QueryFunction,
  type QueryKey,
} from 'react-query';

import { extractQueriesError } from '@/utils/queries';

// eslint-disable-next-line max-len
type UseFilteredQueriesMetaMappingOptions<TParams extends object, TDataKey extends string, TData, TQueryKey extends QueryKey> = {
  mapping: readonly { dataKey: TDataKey, params: TParams, disabled?: boolean, }[];
  queryFn: QueryFunction<TData, TQueryKey>;
  queryKey: TQueryKey;
  transformQueryKey?: (queryKey: TQueryKey, filterKey: TParams) => TQueryKey;
};

// eslint-disable-next-line max-len
export const useFilteredQueriesMapping = <TParams extends object, TDataKey extends string, TData, TQueryKey extends QueryKey>({
  mapping,
  queryFn,
  transformQueryKey,
  queryKey,
}: UseFilteredQueriesMetaMappingOptions<TParams, TDataKey, TData, TQueryKey>) => {
  const queries = mapping.map((entry) => ({
    queryKey: transformQueryKey ? transformQueryKey(queryKey, entry.params) : queryKey,
    queryFn: queryFn,
    staleTime: Infinity,
    enabled: !entry.disabled,
  }));

  const results = useQueries(queries);

  const dataMapping = mapping.reduce((acc, mappee, index) => {
    acc[mappee.dataKey] = results[index].data;
    return acc;
  }, {} as Record<TDataKey, TData | undefined>);

  return {
    isLoading: results.some((result) => result.isLoading),
    error: extractQueriesError(results),
    dataMapping,
  };
};
