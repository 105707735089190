import classNames from 'classnames/bind';
import uniq from 'lodash-es/uniq';
import { MouseEvent, ReactNode, useCallback } from 'react';
import { useState } from 'react';

import { Kicker } from '@/components/shared/typography/Kicker';
import { Body } from '@/components/shared/typography/Body';
import { BodyTableCheckbox } from '@/components/shared/tables/BodyTableCheckbox';
import { HeaderTableCheckbox } from '@/components/shared/tables/HeaderTableCheckbox';
import { BodyTableRadio } from '@/components/shared/tables/BodyTableRadio';
import { toggle } from '@/utils/array';
import { AUXILIARY_BUTTON_INDEX } from '@/resources/constants';
import type { SingleSelectableConfig, TableColumnConfig, TableConfig } from '@/types/tables';

import style from './VerticalTable.module.sass';
import {
  getCellSizeProps,
  getHeaderCheckboxState,
  getSelectableItemIds,
} from './utils';
import { VerticalTableContextMenu } from './VerticalTableContextMenu';
import type { BaseItem } from './types';

const cx = classNames.bind(style);

type GroupType<TItem> = {
  id?: string | number;
  data: ReactNode;
  items: TItem[];
};

type VerticalTableProps<TItem> = {
  config: TableConfig<TItem>;
  data: TItem[];
  groups?: GroupType<TItem>[];
};

type HeaderProps<TItem> = {
  config: TableConfig<TItem>;
  data: TItem[];
  groups?: GroupType<TItem>[];
};

type HeaderCellProps<TItem> = {
  column: TableColumnConfig<TItem>;
};

type BodyProps<TItem> = {
  config: TableConfig<TItem>;
  data: TItem[];
  groups?: GroupType<TItem>[];

};

type BodyRowProps<TItem> = {
  config: TableConfig<TItem>;
  item: TItem;
  onClick: (event: MouseEvent<HTMLTableRowElement>) => void;
  onAuxClick: (event: MouseEvent<HTMLTableRowElement>) => void;
};

type BodyCellProps<TItem> = {
  column: TableColumnConfig<TItem>;
  item: TItem;
  config: TableConfig<TItem>;
};

export const VerticalTable = <TItem extends BaseItem>({
  config,
  data,
  groups,
}: VerticalTableProps<TItem>) => {
  const tableClassNames = cx('table', config.tableClassName?.());

  return (
    <table className={tableClassNames}>
      <VerticalTable.Header
        config={config}
        data={data}
        groups={groups}
      />
      <VerticalTable.TableBody
        config={config}
        data={data}
        groups={groups}
      />
    </table>
  );
};

const Header = <TItem extends BaseItem>({
  config,
  data,
  groups,
}: HeaderProps<TItem>) => {
  if (config.shouldHideHeader) return null;

  const renderHeaderCell = (column: TableColumnConfig<TItem>, index: number) => {
    if (column.hidden) {
      return null;
    }

    return (
      <VerticalTable.HeaderCell
        key={index}
        column={column}
      />
    );
  };

  const { selectable } = config;
  const renderCustomHeader = selectable?.renderCustomHeader;

  const renderCheckbox = () => {

    if (!selectable || selectable?.multipleSelect === false) return;

    if (selectable.shouldHideHeaderCheckbox) {
      return (
        <th></th>
      );
    }

    const groupItems = groups?.flatMap((e) => e.items) || [];
    const selectableData = [...groupItems, ...(data || [])];

    const selectableItemIds = getSelectableItemIds(selectableData, selectable.disabledIds);

    const checkboxState = getHeaderCheckboxState(
      selectableItemIds,
      selectable.selectedIds,
    );

    const checkboxElement = (
      <HeaderTableCheckbox
        state={checkboxState}
        itemIds={selectableItemIds}
        onChange={selectable.onSelect}
        text={config?.selectable?.headerCheckboxText}
      />
    );

    if (renderCustomHeader) {
      return renderCustomHeader(checkboxElement);
    }

    return checkboxElement;
  };

  return (
    <thead className={style.tableHead}>
      {
        renderCustomHeader
          ? renderCheckbox()
          : (
            <tr>
              {renderCheckbox()}
              {config.columns.map(renderHeaderCell)}
            </tr>
          )
      }
    </thead>
  );
};

const HeaderCell = <TItem extends BaseItem>({
  column,
}: HeaderCellProps<TItem>) => {
  const className = cx('tableCell', 'headerCell', column.className);

  const headerProps = getCellSizeProps(column);

  return (
    <th className={className} {...headerProps}>
      <div className={style.headerCellInner}>
        <Kicker size='base'>
          {column.header}
        </Kicker>
      </div>
    </th>
  );
};

const TableBody = <TItem extends BaseItem>({
  config,
  data,
  groups,
}: BodyProps<TItem>) => {
  const [lastClickedListingIndex, setLastClickedListingIndex] = useState<null | number>(null);
  const allIds = data.map((i) => i.id);

  const openResourceRouteNewTab = useCallback((item: TItem) => {
    if (config.getResourceRoute) {
      const resourceUlr = config.getResourceRoute(item);
      window.open(resourceUlr, '_blank');
    }
  }, [
    config,
  ]);

  const onRowClick = useCallback((
    item: TItem,
    index: number,
    event: MouseEvent<HTMLTableRowElement>,
  ) => {
    if (config.selectable?.onSelect) {
      if (config.selectable?.multipleSelect === false) {
        const {
          onSelect,
        } = config.selectable as SingleSelectableConfig;

        if (typeof item.id === 'string') {
          onSelect(item.id);
        }
        return;
      }

      const isRowSelected = config.selectable?.selectedIds?.includes(item.id);

      const {
        onSelect,
        selectedIds,
      } = config.selectable;

      if (event.shiftKey) {
        if (lastClickedListingIndex === null || isRowSelected) {
          return;
        }

        const newSelectedListingsIds = allIds.slice(
          Math.min(lastClickedListingIndex, index), Math.max(lastClickedListingIndex, index) + 1,
        );

        onSelect(uniq([...selectedIds, ...newSelectedListingsIds]));

        return;
      }

      onSelect(toggle(selectedIds, item.id));

      const newIndex = isRowSelected ? null : index;
      setLastClickedListingIndex(newIndex);
    }

    if (event.metaKey || event.ctrlKey) {
      openResourceRouteNewTab(item);

      return;
    }

    config.onRowClick?.(item);
  }, [
    allIds,
    config,
    lastClickedListingIndex,
    setLastClickedListingIndex,
    openResourceRouteNewTab,
  ]);

  const renderItemRow = (item: TItem, index: number) => {
    const handleAuxClick = (e: MouseEvent<HTMLTableRowElement>) => {
      if (e.button === AUXILIARY_BUTTON_INDEX) {
        openResourceRouteNewTab(item);
      }
    };

    return (
      <VerticalTable.BodyRow
        key={item.id}
        config={config}
        item={item}
        onClick={(event) => onRowClick(item, index, event)}
        onAuxClick={handleAuxClick}
      />
    );
  };

  const handleMouseDown = (event: MouseEvent<HTMLTableSectionElement>) => {
    if (event.shiftKey) {
      event.preventDefault();
    }
  };

  const renderGroups = () => {
    if (!groups?.length) {
      return null;
    }

    const tableGroupClassNames = cx('table', 'tableGroup');
    const colSpanValue =
      config.columns.reduce((acc, { colSpan = 0 }) => acc + (colSpan ?? 1), 0) + 1;
    return (
      <tr>
        <td colSpan={colSpanValue}>
          {groups.map(({ id, data: groupData, items }) => (
            <table className={tableGroupClassNames} key={id}>
              <tbody className={style.tableBody}>
                <tr>
                  <td colSpan={colSpanValue}>
                    {groupData}
                  </td>
                </tr>
                {items.map(renderItemRow)}
              </tbody>
            </table>
          ))}
        </td>
      </tr>
    );
  };

  return (
    <tbody className={style.tableBody} onMouseDown={handleMouseDown}>
      {renderGroups()}
      {data.map(renderItemRow)}
    </tbody>
  );
};

const BodyRow = <TItem extends BaseItem>({
  config,
  item,
  onClick,
  onAuxClick,
}: BodyRowProps<TItem>) => {
  const [isHovered, setIsHovered] = useState(false);

  const isRowDisabled = config.selectable?.disabledIds?.includes(item.id);

  const toggleHover = () => setIsHovered(!isHovered);

  const renderBodyCell = (column: TableColumnConfig<TItem>, bodyCellIndex: number) => {
    if (column.hidden) {
      return null;
    }

    return (
      <VerticalTable.BodyCell
        key={bodyCellIndex}
        column={column}
        item={item}
        config={config}
      />
    );
  };

  const active = config.isRowActive?.(item);
  const isSelectable = (config.selectable && !isRowDisabled);
  const isClickable = isSelectable || config.onRowClick || config.onRowDoubleClick;
  const rowClassName = config.getRowClassName?.(item);
  const isSelected = config.selectable?.selectedIds?.includes(item.id);
  const isError = config.selectable?.errorsIds?.includes(item.id);

  const contextMenuProps = config.getContextMenuProps?.(item);
  const doesContextMenuHaveOptions =
    !!contextMenuProps?.options || contextMenuProps?.shouldDisplayPlaceholder;
  const shouldAutoDisableContextMenu =
    !doesContextMenuHaveOptions && config.isContextMenuDisabled === undefined;
  const isContextMenuDisabled = config.isContextMenuDisabled || shouldAutoDisableContextMenu;

  const contextMenuWrapperClassName = cx(
    'contextMenuWrapper',
    contextMenuProps?.wrapperClassName,
  );
  const contextMenuArrowClassName = cx(
    'contextMenuArrow',
    contextMenuProps?.arrowClassName,
  );

  const hasContextMenu = doesContextMenuHaveOptions || !isContextMenuDisabled;

  const className = cx('bodyRow', rowClassName, {
    active,
    isSelected,
    isClickable,
    disableSelection: isRowDisabled,
    hasContextMenu,
    isError,
  });

  const contextMenuCellClassNames = cx(
    'contextMenuCell',
    config.getCellClassName?.(item),
  );

  const onDoubleClick = () => {
    config.onRowDoubleClick?.(item);
  };

  const checkboxClassName = cx({ checkbox: isSelectable });

  const renderCheckbox = () => {
    if (!config.selectable) return;

    if (config.selectable.multipleSelect === false) {
      return (
        <BodyTableRadio
          id={item.id}
          selectedIds={config.selectable.selectedIds}
          disabled={isRowDisabled}
          className={style.bodyCell}
          disabledTooltip={config.selectable.bodyCheckboxDisabledTooltip}
          isHovered={isHovered}
          onChange={() => undefined}
        />
      );
    }

    return (
      <BodyTableCheckbox
        id={item.id}
        selectedIds={config.selectable.selectedIds}
        onChange={() => undefined}
        disabled={isRowDisabled}
        className={style.bodyCell}
        checkboxClassName={checkboxClassName}
        disabledTooltip={config.selectable.bodyCheckboxDisabledTooltip}
        isHovered={isHovered}
      />
    );
  };

  const renderContextMenu = () => {
    if (isContextMenuDisabled || config.selectable) {
      return null;
    }

    return (
      <td className={contextMenuCellClassNames} colSpan={1}>
        <VerticalTable.ContextMenu
          {...contextMenuProps}
          wrapperClassName={contextMenuWrapperClassName}
          arrowClassName={contextMenuArrowClassName}
        />
      </td>
    );
  };

  return (
    <tr
      onMouseEnter={toggleHover}
      onAuxClick={onAuxClick}
      onMouseLeave={toggleHover}
      role={isClickable ? 'button' : 'listitem'}
      className={className}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      data-cy={config.rowDataCy}>
      {renderCheckbox()}
      {config.columns.map(renderBodyCell)}
      {renderContextMenu()}
    </tr>
  );
};

const BodyCell = <TItem extends BaseItem>({
  column,
  item,
  config,
}: BodyCellProps<TItem>) => {
  const className = cx(
    'tableCell',
    'bodyCell',
    column.className,
    config.getCellClassName?.(item),
  );

  const renderColumnBody = () => {
    const renderedBody = column.render(item);

    if (typeof renderedBody === 'object') {
      return renderedBody;
    }

    return (
      <Body size='base'>
        {renderedBody}
      </Body>
    );
  };

  return (
    <td className={className} colSpan={column.colSpan}>
      {renderColumnBody()}
    </td>
  );
};

VerticalTable.Header = Header;
VerticalTable.HeaderCell = HeaderCell;
VerticalTable.TableBody = TableBody;
VerticalTable.BodyRow = BodyRow;
VerticalTable.BodyCell = BodyCell;
VerticalTable.ContextMenu = VerticalTableContextMenu;
