import classNames from 'classnames/bind';

import { FallbackIconBox } from '@/components/shared/FallbackIconBox';
import { ImageWithFallback } from '@/components/shared/ImageWithFallback';

import style from './ElementGraphic.module.sass';

type ElementGraphicProps = {
  logoUrl: string;
  name?: string;
  wrapperClassName?: string;
  imageClassName?: string;
};

const cx = classNames.bind(style);

export const ElementGraphic = ({
  logoUrl,
  name,
  wrapperClassName,
  imageClassName,
}: ElementGraphicProps) => {
  const wrapperClassNames = cx('wrapper', wrapperClassName);
  const imageClassNames = cx('image', imageClassName);

  return (
    <div className={wrapperClassNames}>
      <ImageWithFallback
        src={logoUrl}
        alt={name}
        className={imageClassNames}
        loaderWrapper={style.loaderWrapper}
        fallback={{ icon: <FallbackIconBox wrapperClassName={style.fallbackIconBoxWrapper} /> }}
      />
    </div>
  );
};
