import * as React from "react";
import { forwardRef } from "react";
const SvgGreyScout = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 25,
  viewBox: "0 0 24 25",
  fill: "currentColor",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#clip0_272_7107)"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M8.63209 5.4624C8.01859 7.89905 5.88289 9.56354 3.30679 9.56354H3.27439C3.15289 9.56565 2.96689 9.57137 2.78149 9.59998C1.26979 9.83428 0.0754948 11.144 0.00409484 12.6465C-0.0730052 14.2694 0.945495 15.6746 2.48119 16.0643C2.76829 16.1369 3.05599 16.1486 3.36079 16.161C3.52969 16.1679 3.70429 16.1748 3.87679 16.1935C6.20719 16.4443 8.08129 18.1579 8.56249 20.4223C8.58649 20.3597 8.61199 20.2979 8.63899 20.2368C9.07909 19.2339 9.81889 18.5576 10.7963 18.257C10.7309 18.2428 10.6667 18.2275 10.604 18.2106C7.82449 17.4737 6.13669 14.779 6.67759 11.9424C7.08229 9.82103 8.76619 8.13907 10.8542 7.70782C9.81379 7.34583 8.99209 6.51705 8.63239 5.4627L8.63209 5.4624Z",
  fill: "currentColor"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M21.2566 9.71086C21.0715 9.67985 20.8858 9.67171 20.7625 9.6684C18.0421 9.65063 15.8269 7.78136 15.3775 5.12215C15.3745 5.10469 15.373 5.08752 15.3703 5.07005C15.3373 4.85081 15.3283 4.63067 15.3205 4.41715C15.3157 4.28585 15.3109 4.15424 15.3004 4.02294C15.1627 2.30635 13.7422 1.00445 11.9962 0.995117H11.9797C10.3387 0.995117 8.89212 2.3196 8.75112 3.95548C8.60052 5.70007 9.76002 7.245 11.4481 7.54887C11.6761 7.58982 11.9134 7.59645 12.1429 7.60308C12.3223 7.60819 12.5077 7.61362 12.6919 7.6347C12.6991 7.6356 12.706 7.63711 12.7132 7.63801C15.0244 7.91447 16.9231 9.70725 17.3335 12.006C17.8411 14.8489 16.1215 17.5234 13.3339 18.2272C13.1107 18.2836 12.8731 18.3221 12.6154 18.348C12.4201 18.3676 12.214 18.3805 11.9869 18.3838C10.5175 18.4055 9.48552 19.0702 8.91972 20.3597C8.35692 21.6411 8.56002 22.869 9.50592 23.911C10.3768 24.8704 11.5 25.2017 12.7531 24.8689C14.0098 24.5356 14.812 23.6956 15.1369 22.3727C15.2011 22.1109 15.2122 21.8444 15.2146 21.618C15.217 21.3707 15.2368 21.1274 15.2713 20.8889C15.6226 18.451 17.5816 16.529 20.0839 16.2896C20.2567 16.273 20.4313 16.2682 20.6002 16.2637C20.905 16.255 21.1927 16.2468 21.4807 16.1773C23.0209 15.8063 24.0562 14.4128 23.9977 12.7893C23.9437 11.2859 22.7653 9.96203 21.2566 9.71026V9.71086Z",
  fill: "currentColor"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", {
  id: "clip0_272_7107"
}, /* @__PURE__ */ React.createElement("rect", {
  width: 24,
  height: 24,
  fill: "currentColor",
  transform: "translate(0 0.995117)"
}))));
const ForwardRef = forwardRef(SvgGreyScout);
export default ForwardRef;
