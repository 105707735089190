import classNames from 'classnames/bind';
import { ReactNode } from 'react';

import style from './Tabs.module.sass';
import { TabControl, TabControlProps } from './TabControl';

export type TabConfig = TabControlProps & {
  body?: ReactNode;
  isHidden?: boolean;
};

type TabsProps = {
  config: TabConfig[];
  tabsWrapperClassName?: string;
  isSmallAmountAllowed?: boolean;
  tabVariant?: TabControlProps['variant'];
  hasUnderline?: boolean;
};

const cx = classNames.bind(style);

export const Tabs = ({
  config,
  tabsWrapperClassName,
  isSmallAmountAllowed,
  tabVariant,
  hasUnderline,
}: TabsProps) => {
  const renderTabControls = () => {
    const tabs = config.filter(({ isHidden }) => !isHidden);

    if (!tabs) {
      return null;
    }

    if (!isSmallAmountAllowed && tabs.length < 2) {
      return null;
    }

    const tabsWrapperClassNames = cx('wrapper', tabsWrapperClassName);

    return (
      <div className={tabsWrapperClassNames}>
        {tabs.map((tab, i) => (
          <TabControl
            key={i}
            variant={tabVariant}
            hasUnderline={hasUnderline}
            {...tab}
          />
        ))}
      </div>
    );
  };

  const renderActiveTabContent = () => {
    const activeTab = config.find(({ isActive }) => isActive);

    return activeTab?.body;
  };

  return (
    <>
      {renderTabControls()}
      {renderActiveTabContent()}
    </>
  );
};
