import { CustomerCompanyJumbotron } from '@/components/shared/CustomerCompanyJumbotron';
import { Veriform } from '@/components/veriform/Veriform';

import style from './VeriformPage.module.sass';

export const VeriformPage = () => {
  return (
    <>
      <div className={style.header}>
        <CustomerCompanyJumbotron textLogo={true} />
      </div>
      <Veriform />
    </>
  );
};
