import { QueryFunctionContext } from 'react-query';

import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import { Api } from '@/utils/api';
import type {
  StoreCountry,
  StoreBusinessType,
} from '@/types/store';
import { Veriform } from '@/types/veriform';

type VeriformIncludedRecord =
  | StoreCountry
  | StoreBusinessType

export const fetchVeriform = async (context: QueryFunctionContext) => {
  const [, id, token] = context.queryKey;

  const response = await Api.get<
    Veriform,
    VeriformIncludedRecord
  >(`/api/veriforms/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return linkCollectionRelationships(response);
};
