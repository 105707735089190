import { createContext } from 'react';

export type DeleteCompanyUserModalData = {
  userId: string;
  companyId: string;
  companyName: string;
  userName: string;
  onSuccess?: () => void;
  onCancel?: () => void;
};

export type DeleteCompanyUserContextValue = {
  openModal: (data: DeleteCompanyUserModalData | null) => void;
  closeModal: () => void;
};

export const DeleteCompanyUserContext = createContext<DeleteCompanyUserContextValue | null>(null);
