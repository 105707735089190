import {
  ensureIsDate,
  isNotValidDate,
} from './index';

type Options = Intl.DateTimeFormatOptions;

export const formatDateStyle = (date: string | Date, locale: string, options: Options = {}) => {
  const dateObject = ensureIsDate(date);

  if (isNotValidDate(dateObject)) {
    return '';
  }

  const formatter = new Intl.DateTimeFormat(locale, options);

  return formatter.format(dateObject);
};
