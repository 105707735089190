import { useDataQuery } from '@/hooks/shared';
import { fetchReseller } from '@/queries/resellers';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const MISSING_RESELLER_ID = '1';

export const useReseller = (resellerId?: string | number) => {
  const {
    data: reseller,
    ...rest
  } = useDataQuery([QUERY_KEYS.reseller, resellerId], fetchReseller, {
    enabled: Boolean(resellerId) && resellerId !== MISSING_RESELLER_ID,
  });

  return {
    reseller: reseller,
    ...rest,
  };
};
