import { PageWithBreadcrumbs } from '@/components/shared/PageWithBreadcrumbs';
import { Container } from '@/components/shared/containers/Container';
import { StoreDetailsHeader } from '@/components/store/StoreDetailsHeader';
import { StoreSettings } from '@/components/store/StoreSettings';
import type { Store } from '@/types/store';

type StoreDetailsProps = {
  store: Store;
};

export const StoreDetails = ({
  store,
}: StoreDetailsProps) => {
  return (
    <PageWithBreadcrumbs>
      <Container>
        <StoreDetailsHeader store={store} />
        <StoreSettings store={store} />
      </Container>
    </PageWithBreadcrumbs>
  );
};
