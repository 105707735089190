import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePersistQueryStringsStore } from '@/hooks/zustsand/persistQueryStrings';

type UsePersistQueryStringOptions = {
  write?: boolean;
};

export const usePersistQueryString = (
  key: string,
  options: UsePersistQueryStringOptions = {},
) => {
  const {
    write,
  } = options;

  const {
    search: urlQueryString,
  } = useLocation();

  const { queryStrings, setQueryStrings } = usePersistQueryStringsStore();

  const value = queryStrings?.[key];

  useEffect(() => {
    if (write) setQueryStrings(key, urlQueryString);
  }, [
    urlQueryString,
    write,
    key,
    setQueryStrings,
  ]);

  return value;
};
