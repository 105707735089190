import { CompanyType } from '@/resources/enums';
import { useCompanyType } from '@/hooks/branding';

export const useIsOnInstance = () => {
  const { companyType } = useCompanyType();

  return {
    isAdminInstance: companyType === CompanyType.Admin,
    isCustomerInstance: companyType === CompanyType.Customer,
  };
};
