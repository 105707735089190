import { AllowStoreButton } from '@/components/store/AllowStoreButton';
import { EditStoreEmailButton } from '@/components/store/EditStoreEmailButton';
import { useIsOnInstance } from '@/hooks/shared';
import type { Store } from '@/types/store';

import style from './StoreActions.module.sass';

type StoreActionsProps = {
  store: Store;
};

export const StoreActions = ({
  store,
}: StoreActionsProps) => {
  const { isAdminInstance } = useIsOnInstance();

  const renderActions = () => {
    if (isAdminInstance) {
      return (
        <EditStoreEmailButton storeId={store.id} />
      );
    }

    return (
      <AllowStoreButton store={store} />
    );
  };

  return (
    <div className={style.wrapper}>
      {renderActions()}
    </div>
  );
};
