import { useEffect } from 'react';

export type Shortcut = {
  /**
 * @param sequence if length === 1 => should be lowercase letter
 * @param sequence if length > 1 => should start with uppercase letter
 */
  sequence: string[];
  action: (event: KeyboardEvent) => void;
  enabled: boolean;
};

const isMac = /mac/i.test(navigator.platform || '');

export const metaKey = isMac ? 'Meta' : 'Control';

export const metaLabel = isMac ? '⌘' : '⌃';
export const altLabel = isMac ? '⌥' : 'alt';
export const shiftLabel = '⇧';

export const useShortcuts = (shortcuts: Shortcut[]) => {
  useEffect(() => {
    if (!shortcuts?.length) {
      return;
    }

    const pressedKeys: Set<string> = new Set();

    const handleKeyDown = (event: KeyboardEvent) => {
      const { key } = event;

      pressedKeys.add(key);

      shortcuts.forEach((shortcut) => {
        const {
          sequence,
          action,
          enabled,
        } = shortcut;

        if (!enabled) {
          return;
        }

        if (sequence?.every((sequenceKey) => pressedKeys.has(sequenceKey))) {
          action(event);
          pressedKeys.delete(key);
        }
      });
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    shortcuts,
  ]);
};
