import { type FormikErrors, type FieldProps } from 'formik';

export const getErrorMessage = (props: FieldProps, fieldName: string) => {
  const rawError = props.form.errors[fieldName];

  if (Array.isArray(rawError)) {
    return extractError(rawError.at(0));
  }

  return extractError(rawError);
};

const extractError = (error?: FormikErrors<unknown> | string) => {
  if (typeof error === 'object') {
    return;
  }

  return error;
};
