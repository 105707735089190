import { useState, useEffect } from 'react';

import { toMediaQuery, type ToMediaQueryObjectParam } from '@/utils/misc';

export const useMediaQuery = (param: string | ToMediaQueryObjectParam) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const query = toMediaQuery(param);
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    media.addEventListener('change', listener);

    return () => media.removeEventListener('change', listener);
  }, [
    matches,
    param,
  ]);

  return matches;
};
