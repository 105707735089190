import { type ChangeEventHandler, useState, useCallback } from 'react';

import type { ReactStateSetter } from '@/types/shared';

type UseInputHandlersResult = [
  string,
  ChangeEventHandler<HTMLInputElement>,
  ReactStateSetter<string>,
];

export const useInputHandlers = (defaultValue: string) => {
  const [value, setValue] = useState(defaultValue);

  const handleEvent: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setValue(event.target.value);
  }, [
    setValue,
  ]);

  return [
    value,
    handleEvent,
    setValue,
  ] as UseInputHandlersResult;
};
