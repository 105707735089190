import classNames from 'classnames/bind';
import {
  useEffect,
  useRef,
  type HTMLProps,
} from 'react';

import style from './TextAreaInput.module.sass';

const cx = classNames.bind(style);

type TextAreaInputProps = Omit<HTMLProps<HTMLTextAreaElement>, 'children'> & {
  disabled?: boolean;
  value?: string;
  error?: string | boolean;
  minRows?: number;
};

const DEFAULT_MIN_ROWS = 2;

export const TextAreaInput = ({
  className,
  error,
  minRows = DEFAULT_MIN_ROWS,
  name,
  value,
  ...restProps
}: TextAreaInputProps) => {
  const elementClassName = cx('textarea', className, {
    error,
  });

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      const { scrollX, scrollY } = window;
      const {
        scrollLeft,
        scrollTop,
      } = document.documentElement || document.body.parentNode || document.body;

      textAreaRef.current.style.setProperty('height', 'auto');
      textAreaRef.current.style.setProperty('height', `${textAreaRef.current.scrollHeight}px`, 'important');

      window.scrollTo(scrollX || scrollLeft, scrollY || scrollTop);
    }
  }, [
    value,
  ]);

  return (
    <textarea
      ref={textAreaRef}
      rows={minRows}
      name={name}
      value={value}
      {...restProps}
      className={elementClassName}
    />
  );
};
