import { ViewMode } from '@/resources/enums';
import type { Nullable } from '@/types/shared';

export const extractViewMode = (
  value: Nullable<string>,
  defaultValue: ViewMode,
) => {
  const viewModeEntry = Object.entries(ViewMode).find(([, key]) => {
    return key === value;
  });

  return viewModeEntry ? viewModeEntry[1] : defaultValue;
};
