import { type TFunction } from 'i18next';

import { RunningBotStatus } from '@/components/bot/BotRunStatusInfo';
import { type BadgeProps } from '@/components/shared/Badge';
import { BotRunStatus } from '@/resources/enums';

export const getBadgeProps = (status: BotRunStatus | undefined, t: TFunction): BadgeProps => {
  if (status === BotRunStatus.OK) {
    return {
      variant: 'success',
      children: t('bot:runStatus.success'),
    };
  }

  if (status === BotRunStatus.Failed) {
    return {
      variant: 'danger',
      children: t('bot:runStatus.failed'),
    };
  }

  if (status === BotRunStatus.Stopped) {
    return {
      variant: 'purple',
      children: t('bot:runStatus.stopped'),
    };
  }

  if (status === BotRunStatus.Running) {
    return {
      variant: 'neutral',
      children: <RunningBotStatus />,
    };
  }

  return {
    variant: 'neutral',
    children: t('bot:runStatus.running'),
  };
};
