import classNames from 'classnames/bind';
import { PropsWithChildren, type HTMLProps } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@/components/shared/Icon';
import type { IconName } from '@/types/shared';

import style from './LinkBadge.module.sass';

const cx = classNames.bind(style);

type AnchorElementProps = Omit<HTMLProps<HTMLAnchorElement>,
  | 'rel'
>;

type AnchorProps = AnchorElementProps & {
  iconName?: IconName;
  noopener?: boolean;
  noreferrer?: boolean;
  nofollow?: boolean;
  to?: never;
};

type LinkProps = PropsWithChildren & AnchorElementProps & {
  to: string;
  className?: string;
  iconName?: IconName;
  noopener?: never;
  noreferrer?: never;
  nofollow?: never;
  target?: never;
  href?: never;
};

type LinkBadgeProps = AnchorProps | LinkProps;

export const LinkBadge = ({
  children,
  className,
  iconName,
  target = '_blank',
  noopener = true,
  noreferrer = true,
  nofollow = true,
  to,
  ...restProps
}: LinkBadgeProps) => {
  const linkClassNames = cx('link', className);

  if (to) {
    return (
      <Link
        className={linkClassNames}
        to={to}>
        {children}
      </Link>
    );
  }

  // not actually a class names but works
  const rel = cx({
    noopener,
    noreferrer,
    nofollow,
  });

  return (
    <a
      className={linkClassNames}
      target={target}
      rel={rel}
      {...restProps}>
      {
        iconName &&
        <Icon name={iconName} className={style.icon} />
      }
      <span className={style.content}>
        {children}
      </span>
    </a>
  );
};
