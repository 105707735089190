import { useDataQuery } from '@/hooks/shared';
import { fetchDataProvider } from '@/queries/dataProvider';
import { QUERY_KEYS } from '@/resources/dataKeys';
import type { DataProvider } from '@/types/dataProvider';

export const useDataProvider = (dataProviderId?: string) => {
  const {
    data: dataProvider,
    ...rest
  } = useDataQuery<DataProvider>([
    QUERY_KEYS.dataProvider,
    dataProviderId,
  ], fetchDataProvider, {
    enabled: Boolean(dataProviderId),
  });

  return {
    dataProvider,
    ...rest,
  };
};
