import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useListings } from '@/hooks/listing';
import { AGE } from '@/resources/queryParams';
import { WEEK_OLD } from '@/resources/listings';

type UseSetDefaultListingsAgeFilter = {
  storeId: string | undefined;
  navigationLink: string;
}

export const useSetDefaultListingsAgeFilter = (
  {
    storeId,
    navigationLink,
  }: UseSetDefaultListingsAgeFilter) => {
  const navigate = useNavigate();
  const [hasLoaded, setHasLoaded] = useState(false);

  const {
    isLoading: isInitialListingsMetaLoading,
    error: initialListingsMetaError,
    meta,
  } = useListings({
    filter: {
      'store.id': storeId,
    },
  }, {
    enabled: Boolean(storeId),
  });

  const hasAnyWeekOldListings = Boolean(
    meta?.aggregation.age.find(({ id }) => id === WEEK_OLD)?.count,
  );

  useEffect(() => {
    if (!meta) {
      return;
    }

    setHasLoaded(true);

    if (
      hasAnyWeekOldListings
    ) {
      const queryString = `${AGE}=${String(WEEK_OLD)} `;
      navigate(`${navigationLink}${queryString}`, { replace: true });
    }
  }, [hasAnyWeekOldListings, meta, navigate, navigationLink]);

  return {
    meta,
    hasLoaded,
    initialListingsMetaError,
    isInitialListingsMetaLoading,
  };
};
