import { type TFunction } from 'i18next';

import { BotScheduledInfo, BotSlowRun } from '@/resources/enums';
import type { Channel } from '@/types/channel';
import { NonNullableArray } from '@/types/shared';

export const getSubdomainById = (
  channels: Channel[],
  subdomainId: string,
) => {
  const subdomains = channels
    .map((channel) => channel.linked?.subdomains)
    .flat()
    .filter(Boolean);

  // casting to non-nullable as there's `.filter(Boolean)` to remove undefineds

  return (subdomains as NonNullableArray<typeof subdomains>).find((subdomain) => {
    return subdomain.id === subdomainId;
  });
};

export const getSlowRunInfo = (duration: string, t: TFunction) => {
  switch (duration) {
    case BotSlowRun.Slow:
      return t('bot:duration.label.slow');
    case BotSlowRun.NotSlow:
      return t('bot:duration.label.notSlow');
    default:
      return duration;
  }
};

export const getChannelNameBySubdomainId = (
  channels: Channel[],
  subdomainId: string,
): string => {

  for (const channel of channels) {
    if (!channel.linked) {
      continue;
    }

    const subdomainChannel = channel?.linked?.subdomains?.
      find((subdomain) => (subdomain.id === String(subdomainId)));

    if (subdomainChannel) {
      return channel.attributes.name;
    }
  }

  return '';
};

export const getSchedulingInfo = (scheduling: string, t: TFunction) => {
  switch (scheduling) {
    case BotScheduledInfo.Scheduled:
      return t('bot:scheduling.label.scheduled');
    case BotScheduledInfo.NotScheduled:
      return t('bot:scheduling.label.notScheduled');
    default:
      return scheduling;
  }
};
