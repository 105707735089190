import { Config } from '@/utils/environment';

export const isUrlExternal = (url: string) => {
  return !isUrlInternal(url);
};

export const isUrlInternal = (url: string) => {
  const DOMAIN_NAME = Config.getAll().VITE_STORAGE_DOMAIN;
  const BUCKET_NAME = Config.getAll().VITE_STORAGE_BUCKET;

  if (!BUCKET_NAME) return false;

  const regex = new RegExp(`^https?://${DOMAIN_NAME}`);

  return regex.test(url) && url.includes(BUCKET_NAME);
};
