import * as React from "react";
import { forwardRef } from "react";
const SvgSearches = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M10 20.9099H3",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M18.853 15.006C20.336 16.489 20.176 18.99 18.375 20.255C17.229 21.059 15.628 21.059 14.482 20.255C12.68 18.991 12.521 16.489 14.004 15.006C15.344 13.667 17.515 13.667 18.853 15.006",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M21.0001 22.0001L18.8521 19.8521",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M4.22021 10.78V20.91",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M19.6991 10.778C21.3971 10.766 22.5041 9.085 21.7681 7.636L20.0521 4.259C19.6621 3.489 18.8401 3 17.9381 3H6.0621C5.1601 3 4.3381 3.489 3.9471 4.259L2.2311 7.636C1.4951 9.085 2.6021 10.766 4.3001 10.778C5.7171 10.778 6.8661 9.81 6.8661 8.615V8.567C6.8661 9.788 8.0151 10.778 9.4321 10.778C10.8511 10.778 12.0001 9.788 12.0001 8.567C12.0001 9.788 13.1491 10.778 14.5661 10.778C15.9831 10.778 17.1321 9.788 17.1321 8.567V8.615C17.1331 9.81 18.2821 10.778 19.6991 10.778V10.778Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgSearches);
export default ForwardRef;
