import classNames from 'classnames/bind';
import { type PropsWithChildren } from 'react';

import { Icon } from '@/components/shared/Icon';
import { StyledLink, type StyledLinkProps } from '@/components/shared/StyledLink';
import { useStopEventPropagation } from '@/hooks/dom';
import type { IconName } from '@/types/shared';

import style from './StyledLinkExternal.module.sass';

const cx = classNames.bind(style);

type CompanyAppLinkProps = PropsWithChildren<{
  iconName?: IconName | null;
  className?: string;
  href: string;
  variant?: StyledLinkProps['variant'];
  ellipsisVariant?: 'oneLine' | 'doubleLine';
}>;

export const StyledLinkExternal = ({
  href,
  className,
  children,
  variant = 'info',
  iconName = 'LinkOpen',
  ellipsisVariant = 'oneLine',
}: CompanyAppLinkProps) => {
  const stopEventPropagation = useStopEventPropagation();

  const linkClassName = cx('link', className, ellipsisVariant);
  const linkTextClassName = cx('linkText', ellipsisVariant);

  return (
    <StyledLink
      className={linkClassName}
      onClick={stopEventPropagation}
      onAuxClick={stopEventPropagation}
      href={href}
      variant={variant}
      target='_blank'
      rel='noopener noreferrer nofollow'>
      {
        iconName &&
        <Icon name={iconName} className={style.linkIcon} />
      }
      <span className={linkTextClassName}>
        {children}
      </span>
    </StyledLink>
  );
};
