import { type MutateOptions, type UseMutateAsyncFunction } from 'react-query';
import { useTranslation } from 'react-i18next';

import { handleFormikErrors } from '@/utils/formik';
import type {
  ApiDataErrors,
  UseMutationError,
  FormikSubmitHandler,
  FormikSubmitHandlerErrorMessagesMap,
} from '@/types/shared';

export type FormikSubmitHandlerOptionsConfig = {
  errorReportType?: 'notification' | 'formik';
  notification: {
    title: string;
    tKeys?: Record<string, string | undefined>;
  };
};

// eslint-disable-next-line max-len
interface FormikSubmitHandlerOptions<TData, TError, TValues> extends MutateOptions<TData, TError, TValues> {
  transformValues?: (values: TValues) => TValues;
  errorMessages?: FormikSubmitHandlerErrorMessagesMap;
  config?: FormikSubmitHandlerOptionsConfig;
}
/* eslint-disable indent */

export const useFormikMutationHandler = <
  TData,
  TValues,
  TError extends UseMutationError<ApiDataErrors, TValues>,
>(
  mutationAsyncFn: UseMutateAsyncFunction<TData, TError, TValues>,
  options: FormikSubmitHandlerOptions<TData, TError, TValues> = {},
) => {
  /* eslint-enable indent */

  const { t } = useTranslation();

  const {
    onSuccess,
    onError,
    onSettled,
    transformValues,
    errorMessages = {},
    config,
  } = options;

  const handleFormikSubmit: FormikSubmitHandler<TValues> = async (
    values,
    formikHelpers,
  ) => {
    const transformedValues = transformValues ? transformValues(values) : values;

    await mutationAsyncFn(transformedValues, {
      onError: (error, variables, context) => {
        handleFormikErrors(error, formikHelpers, t, errorMessages, config);

        onError?.(error, variables, context);
      },
      onSuccess: (data, variables, context) => {
        // customization goes here

        onSuccess?.(data, variables, context);
      },
      onSettled(data, error, variables, context) {
        onSettled?.(data, error, variables, context);
      },
    });
  };

  return handleFormikSubmit;
};
