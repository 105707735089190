
import { Api } from '@/utils/api';

type RemoveStoreFromWhiteListValues = {
  storeId: string;
  whitelistingId: string | undefined;
};

export const removeStoreFromWhiteList = async (
  values: RemoveStoreFromWhiteListValues,
) => {
  const response = await Api.delete(`/api/company_stores/${values.storeId}/whitelistings/${values.whitelistingId}`);

  return response.data;
};

