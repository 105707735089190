import classNames from 'classnames/bind';
import { RefObject, type HTMLProps } from 'react';

import { Button } from '@/components/shared/buttons';
import { Icon } from '@/components/shared/Icon';
import { IconName } from '@/types/shared';

import style from './TextInput.module.sass';

export type TextInputProps = HTMLProps<HTMLInputElement> & {
  alignRight?: boolean;
  sizeVariant?: 'small' | 'medium';
  error?: string | boolean;
  warning?: string | boolean;
  wrapperClassName?: string;
  iconName?: IconName;
  iconClassName?: string;
  onClear?: () => void;
  clearable?: boolean;
  isIconReversed?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
};

const cx = classNames.bind(style);

export const TextInput = ({
  alignRight,
  value,
  error,
  className,
  wrapperClassName,
  warning,
  sizeVariant = 'small',
  iconName,
  isIconReversed,
  onClear,
  clearable,
  inputRef,
  ...restProps
}: TextInputProps) => {
  const inputClassName = cx('input', sizeVariant, className, {
    alignRight,
    error,
    warning,
    withIcon: iconName,
  });

  const wrapperCombinedClassName = cx('wrapper', wrapperClassName, {
    isIconReversed: isIconReversed && iconName,
  });
  const buttonClassNames = cx('button', 'iconPosition');
  const iconClassNames = cx('icon', 'iconPosition');

  const renderIcon = () => {
    if (value && clearable) {
      return (
        <Button.Unstyled
          onClick={onClear}
          className={buttonClassNames}>
          <Icon
            name='Delete'
            className={style.removeIcon}
          />
        </Button.Unstyled>
      );
    }

    if (iconName) {
      return (
        <Icon
          name={iconName}
          className={iconClassNames}
        />
      );
    }

    return null;
  };

  return (
    <div className={wrapperCombinedClassName}>
      <input
        {...restProps}
        value={value || ''}
        id={restProps.name}
        className={inputClassName}
        ref={inputRef}
      />
      {renderIcon()}
    </div>
  );
};
