import { Api } from '@/utils/api';
import { UpdateVeriformAttributes } from '@/types/veriform';

export const updateVeriform = async (
  values: UpdateVeriformAttributes,
  mutationKey: (string | undefined)[],
) => {
  const [, id, token, businessTypeOtherId] = mutationKey;

  const data = {
    // remove business type other if business type is other than given business type other id
    attributes: {
      ...values,
      serialNumbers: values?.serialNumbers?.split?.(','),
      businessTypeOther: (
        values?.businessTypeId === businessTypeOtherId ? values?.businessTypeOther : ''
      ),
    },
    type: 'veriforms',
  };

  const response = await Api.patch(`/api/veriforms/${id}`, { data }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
