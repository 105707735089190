import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PageLoader } from '@/components/shared/loaders/PageLoader';
import { useUser } from '@/hooks/companyUser';
import { useAuthentication } from '@/hooks/user';
import { useBranding } from '@/hooks/branding';
import {
  AuthorisedCustomerCompanyUserContext,
  AuthorisedCustomerCompanyUserContextValue,
} from '@/contexts/user/AuthorisedCustomerCompanyUserContext';
import { useIsGreyScoutAdmin } from '@/hooks/shared';

type UserGatewayProps = PropsWithChildren;

export const UserGateway = ({
  children,
}: UserGatewayProps) => {
  const [hasTriedToLogin, setHasTriedToLogin] = useState(false);

  const {
    user: authUser,
    loading: isAuthUserLoading,
  } = useAuthentication();

  const location = useLocation();

  const {
    branding,
  } = useBranding();

  const userId = authUser?.gsUid;

  const {
    user: companyUser,
    isLoading: isCompanyUserLoading,
  } = useUser(userId ? String(userId) : undefined);

  const isGreyScoutAdmin = useIsGreyScoutAdmin();

  const companyUserId = companyUser?.linked?.company?.id;
  const brandingUserId = branding?.attributes.companyId;

  const isCustomerCompanyUserAuthorized = useMemo(() => (
    String(brandingUserId) === companyUserId || isGreyScoutAdmin
  ), [
    brandingUserId,
    companyUserId,
    isGreyScoutAdmin,
  ]);

  useEffect(() => {
    setHasTriedToLogin(false);
  }, [location]);

  const contextValue = useMemo<AuthorisedCustomerCompanyUserContextValue>(() => ({
    isCustomerCompanyUserAuthorized,
    isError: !isCustomerCompanyUserAuthorized && hasTriedToLogin,
    setHasTriedToLogin,
    hasTriedToLogin,
  }), [
    isCustomerCompanyUserAuthorized,
    hasTriedToLogin,
  ]);

  if (isAuthUserLoading || isCompanyUserLoading) {
    return (
      <PageLoader />
    );
  }

  return (
    <AuthorisedCustomerCompanyUserContext.Provider value={contextValue}>
      {children}
    </AuthorisedCustomerCompanyUserContext.Provider>
  );
};
