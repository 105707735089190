import { useDataQuery } from '@/hooks/shared';
import { fetchReport } from '@/queries/report';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { Report } from '@/types/report';

export const useReport = (reportId?: string) => {
  const {
    data: report,
    ...rest
  } = useDataQuery<Report>([QUERY_KEYS.report, reportId], fetchReport, {
    staleTime: 0,
  });

  return {
    report,
    ...rest,
  };
};
