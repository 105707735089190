import type { Nullable } from '@/types/shared';

export const getAgeFilterEntryTranslationKey = (value: Nullable<string>) => {
  switch (value) {
    case '1':
      return 'listing:filterable.labels.age.today';
    case '7':
      return 'listing:filterable.labels.age.thisWeek';
    case '31':
      return 'listing:filterable.labels.age.thisMonth';
    default:
      return null;
  }
};
