import { useRbac } from '@/hooks/user';
import { useIsOnInstance } from '@/hooks/shared';

export const useIsCompanyDomainLinkEnabled = (isRemoteAdminAllowed: boolean) => {
  const { isAdminInstance } = useIsOnInstance();

  const canManage = useRbac({
    isRemoteAdminAllowed,
    companyId: undefined,
    rolesWhitelist: [
      'isGsAdmin',
    ],
  });

  return isRemoteAdminAllowed && isAdminInstance && canManage;
};
