import classNames from 'classnames/bind';

import { CountryFlag } from '@/components/shared/CountryFlag';
import { Body, BodyProps } from '@/components/shared/typography/Body';
import { MultilineBody } from '@/components/shared/MultilineBody';
import { getTldFromUrl } from '@/utils/string';

import style from './BotChannelLogotype.module.sass';

const cx = classNames.bind(style);

type BotChannelLogotypeProps = {
  name?: string;
  url: string | undefined;
  bodySize?: BodyProps['size'];
};

export const BotChannelLogotype = ({
  name,
  url,
  bodySize = 'base',
}: BotChannelLogotypeProps) => {
  const channelLogotypeClassName = cx(style.channelLogotype);

  return (
    <Body size={bodySize} className={channelLogotypeClassName}>
      <div className={style.name}>
        <MultilineBody size={bodySize}>
          {name}
        </MultilineBody>
      </div>
      &nbsp;
      <CountryFlag tld={getTldFromUrl(url)} />
    </Body>
  );
};
