import { useDataQuery } from '@/hooks/shared';
import { fetchStores } from '@/queries/store/fetchStores';
import { QUERY_KEYS } from '@/resources/dataKeys';
import {
  StoresFilterParams,
  StoreSortConfig,
  StoresPaginationConfig,
} from '@/types/store';

export type UseStoreParams = {
  pagination?: StoresPaginationConfig;
  sort?: StoreSortConfig;
  filter?: StoresFilterParams;
};

export const useStores = (params?: UseStoreParams) => {
  const {
    data: queryData,
    ...rest
  } = useDataQuery([QUERY_KEYS.stores, params], fetchStores);

  return {
    ...queryData,
    ...rest,
  };
};
