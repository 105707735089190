import { QueryFunctionContext } from 'react-query';

import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import { Api } from '@/utils/api';
import type { Nullable } from '@/types/shared';
import type {
  Store,
  StoreReseller,
  StoreSubdomain,
} from '@/types/store';

type StoreIncludedRecord =
  | StoreReseller
  | StoreSubdomain

type FetchStoreQueryKey = [string, Nullable<string>];

export const fetchStore = async (context: QueryFunctionContext<FetchStoreQueryKey>) => {
  const [, storeId] = context.queryKey;
  const response = await Api.get<Store, StoreIncludedRecord>(`/api/stores/${storeId}`);

  return linkCollectionRelationships(response);
};
