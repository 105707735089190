
import { TableCheckbox } from '@/components/shared/tables/TableCheckbox';
import { JointCheckboxState } from '@/resources/enums';

type BodyTableCheckboxProps = {
  id: string;
  selectedIds: string[];
  onChange: () => void;
  disabled?: boolean;
  className?: string;
  disabledTooltip?: string;
  isHovered?: boolean;
  checkboxClassName?: string;
};

export const BodyTableCheckbox = ({
  id,
  selectedIds,
  onChange,
  disabled,
  className,
  disabledTooltip,
  isHovered,
  checkboxClassName,
}: BodyTableCheckboxProps) => {
  const isSelected = selectedIds.includes(id);

  return (
    <TableCheckbox
      isHovered={isHovered}
      tagName='td'
      state={isSelected ? JointCheckboxState.Checked : undefined}
      disabled={disabled}
      className={className}
      onChange={onChange}
      checkboxClassName={checkboxClassName}
      disabledTooltip={disabledTooltip}
    />
  );
};
