import classNames from 'classnames/bind';
import { type ReactNode } from 'react';
import { ErrorMessage } from 'formik';

import style from './FieldErrors.module.sass';

const cx = classNames.bind(style);

type PropsWithName = {
  name: string;
  children?: never;
}

type PropsWithChildren = {
  name?: never;
  children: ReactNode;
}

type FieldErrorsProps = (PropsWithName | PropsWithChildren) & {
  variant?: 'danger' | 'warning';
};

export const FieldErrors = ({
  name,
  children,
  variant = 'danger',
}: FieldErrorsProps) => {
  const className = cx('wrapper', variant);

  return (
    <div className={className}>
      {name ? <ErrorMessage name={name} /> : children}
    </div>
  );
};
