import classNames from 'classnames/bind';
import { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Body } from '@/components/shared/typography/Body';
import { Header } from '@/components/shared/typography/Header';
import { HeaderProps } from '@/components/shared/typography/Header/Header';
import { Icon } from '@/components/shared/Icon';

import style from './SectionHeader.module.sass';

const cx = classNames.bind(style);

export type SectionHeaderProps = PropsWithChildren<{
  title: ReactNode;
  subtitle?: ReactNode;
  className?: string;
  headerSizeVariant?: HeaderProps['variant'];
  linkToCollection?: string;
  isChevronVisible?: boolean;
}>;

type HeaderWrapperProps = PropsWithChildren<{
  linkToCollection?: string;
  isChevronVisible?: boolean;
}>;

export type AsideActionsProps = PropsWithChildren<unknown>;

const AsideActions = ({
  children,
}: AsideActionsProps) => {
  return (
    <div className={style.asideActions}>
      {children}
    </div>
  );
};

export type AdditionalActionsProps = PropsWithChildren<unknown>;

const AdditionalActions = ({
  children,
}: AdditionalActionsProps) => {
  return (
    <div className={style.additionalActions}>
      {children}
    </div>
  );
};

export type SubtitleProps = PropsWithChildren<unknown>;

const Subtitle = ({
  children,
}: SubtitleProps) => {
  return (
    <Body size='medium' className={style.subtitle}>
      {children}
    </Body>
  );
};

const HeaderWrapper = ({
  children,
  isChevronVisible,
  linkToCollection,
}: HeaderWrapperProps) => {
  return (
    linkToCollection ? (
      <Link
        to={linkToCollection}
        className={style.link}>
        {children}
        {
          isChevronVisible &&
          <Icon
            name='ChevronRight'
            className={style.chevron}
          />
        }
      </Link>
    ) : <>{children}</>
  );
};

const HeaderText = ({
  title,
  isChevronVisible,
  linkToCollection,
  variant,
}: SectionHeaderProps & HeaderProps) => {

  return (
    <HeaderWrapper
      isChevronVisible={isChevronVisible}
      linkToCollection={linkToCollection}>
      <Header
        variant={variant}
        className={style.title}>
        {title}
      </Header>
    </HeaderWrapper>
  );
};

export const SectionHeader = ({
  title,
  children,
  subtitle,
  className,
  headerSizeVariant = 'h2',
  linkToCollection,
  isChevronVisible,
}: SectionHeaderProps) => {
  const wrapperClassName = cx('wrapper', className);

  return (
    <div className={wrapperClassName} data-cy='sectionHeader'>
      <div className={style.titleWrapper}>
        <HeaderText
          title={title}
          isChevronVisible={isChevronVisible || !subtitle}
          linkToCollection={linkToCollection}
          variant={headerSizeVariant}
        />
        {
          subtitle &&
          <SectionHeader.Subtitle>{subtitle}</SectionHeader.Subtitle>
        }
      </div>
      {children}
    </div>
  );
};

SectionHeader.AsideActions = AsideActions;
SectionHeader.Subtitle = Subtitle;
SectionHeader.AdditionalActions = AdditionalActions;
