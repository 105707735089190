import * as React from "react";
import { forwardRef } from "react";
const SvgBold = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 25,
  height: 25,
  viewBox: "0 0 25 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M6.74951 12.0004H13.7499C15.683 12.0004 17.2501 13.5675 17.2501 15.5006V15.5006C17.2501 17.4337 15.683 19.0008 13.7499 19.0008H6.74951V5H12.5828C14.5159 5 16.083 6.56708 16.083 8.50019V8.50019C16.083 10.4333 14.5159 12.0004 12.5828 12.0004",
  stroke: "currentColor",
  strokeWidth: 1.50008,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgBold);
export default ForwardRef;
