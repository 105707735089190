import { BotRunStatus } from '@/resources/enums';
import type { Nullable } from '@/types/shared';

export const botStatusToId = (status: Nullable<string>) => {
  switch (status) {
    case BotRunStatus.Failed:
      return 4;
    case BotRunStatus.Stopped:
      return 5;
    case BotRunStatus.OK:
      return 6;
    default:
      return undefined;
  }
};
