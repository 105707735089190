import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { FormikHandlers, type FieldInputProps } from 'formik';

import { Body } from '@/components/shared/typography/Body';

import style from './BinarySwitchInput.module.sass';

const cx = classNames.bind(style);

type BinarySwitchInputProps = Omit<FieldInputProps<boolean>, 'onBlur'> & {
  disabled?: boolean;
  onBlur?: FormikHandlers['handleBlur'];
  labels?: {
    hasLabels: boolean;
    onText?: string;
    offText?: string;
  };
};

export const BinarySwitchInput = ({
  value,
  disabled,
  labels,
  ...restProps
}: BinarySwitchInputProps) => {
  const { t } = useTranslation();

  const wrapperClassName = cx('wrapper', {
    disabled,
    hasLabels: labels?.hasLabels,
  });

  const renderLabel = (labelText: string) => {
    if (!labels?.hasLabels) {
      return;
    }

    return (
      <Body size='base'>
        {labelText}
      </Body>
    );
  };

  return (
    <label className={wrapperClassName}>
      {renderLabel(labels?.offText ?? t('common:off'))}
      <input
        type='checkbox'
        checked={value || false}
        disabled={disabled}
        {...restProps}
        className={style.input}
      />
      <div className={style.switch} />
      {renderLabel(labels?.onText ?? t('common:on'))}
    </label>
  );
};
