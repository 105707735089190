import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { BrandDetails } from '@/types/brand';

export const fetchBrand = async (context: QueryFunctionContext) => {
  const [, brandId] = context.queryKey;

  const response = await Api.get<BrandDetails>(`/api/brands/${brandId}`);

  return response.data;
};
