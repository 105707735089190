type OptionsType = {
  placeholder: string;
}
export const toNumericValueOrPlaceholder = (
  value: number | null,
  options: OptionsType = {
    placeholder: '-',
  },
) => {
  if (value !== 0 && value !== null) {
    return value;
  }

  return options.placeholder;
};
