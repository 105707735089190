import * as React from "react";
import { forwardRef } from "react";
const SvgImageAccess = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M21.0041 13.0005V17.0022C21.0041 19.2122 19.2125 21.0038 17.0024 21.0038H6.99825C4.78819 21.0038 2.99658 19.2122 2.99658 17.0022V6.998C2.99658 4.78795 4.78819 2.99634 6.99825 2.99634H10.9999",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M2.99658 13.0005L4.29228 11.7048C4.74462 11.2524 5.35812 10.9983 5.99783 10.9983C6.63753 10.9983 7.25103 11.2524 7.70337 11.7048L12.0003 16.0017",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.00098 21.0038L13.2988 14.706C14.2407 13.764 15.7679 13.764 16.7099 14.706L20.6507 18.6468",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 17.5026,
  cy: 6.49776,
  r: 4.00167,
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16 5L19 8",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M19 5L16 8",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgImageAccess);
export default ForwardRef;
