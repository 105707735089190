import { useTranslation } from 'react-i18next';

import { getStoreStatusLabel } from '@/utils/store';
import type { Nullable } from '@/types/shared';

export const useStoreStatusLabel = (status: Nullable<string>) => {
  const { t } = useTranslation();

  return getStoreStatusLabel(status, t);
};
