import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import type { NotificationTemplate } from '@/types/notificationTemplate';

export const fetchNotificationTemplate = async (context: QueryFunctionContext) => {
  const [, notificationTemplateId, companyId] = context.queryKey;
  const response = await Api.get<NotificationTemplate>(`api/companies/${companyId}/notification_overrides/${notificationTemplateId}`);

  return response.data;
};
