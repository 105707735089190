import { forwardRef, type HTMLProps, type ReactNode } from 'react';
import classNames from 'classnames/bind';

import { Icon } from '@/components/shared/Icon';
import type { IconName } from '@/types/shared';

import style from './Button.module.sass';

const cx = classNames.bind(style);

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  children: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  sizeVariant?: 'small' | 'medium' | 'large';
  iconName?: IconName;
  variant?: 'primary' | 'secondary' | 'danger' | 'dangerOutline' | 'light';
  width?: 'full' | 'fitContent';
  iconWrapperClassName?: string;
}

interface ButtonIconProps {
  children: ReactNode;
  className?: string;
}

type SkeletonProps = {
  sizeVariant: ButtonProps['sizeVariant'];
}

export const ButtonIcon = ({
  children,
  className,
}: ButtonIconProps) => {
  const iconWrapperClassName = cx('iconWrapper', className);

  return (
    <div className={iconWrapperClassName}>
      {children}
    </div>
  );
};

export type UnstyledButtonProps = Omit<ButtonProps, 'variant' | 'sizeVariant' | 'Icon'> & {
  ariaLabel?: string;
};

const Unstyled = forwardRef<HTMLButtonElement, UnstyledButtonProps>(({
  className,
  type = 'button',
  ariaLabel,
  ...restProps
}, ref) => {
  const buttonClassName = cx('buttonUnstyled', className);

  return (
    <button
      ref={ref}
      {...restProps}
      className={buttonClassName}
      type={type}
      aria-label={ariaLabel}
    />
  );
});

Unstyled.displayName = 'Button.Unstyled';

export const Button = ({
  children,
  iconName,
  sizeVariant = 'medium',
  type = 'button',
  variant = 'primary',
  width,
  className,
  iconWrapperClassName,
  ...restProps
}: ButtonProps) => {
  const buttonClassNames = cx('button', variant, sizeVariant, className, width);
  return (
    <button
      {...restProps}
      type={type}
      className={buttonClassNames}>
      {
        iconName &&
        <ButtonIcon className={iconWrapperClassName}>
          <Icon name={iconName} className={style.icon} />
        </ButtonIcon>
      }
      {children}
    </button>
  );
};

const Skeleton = ({
  sizeVariant,
}: SkeletonProps) => {
  return (
    <div className={cx('skeleton', sizeVariant)} />
  );
};

Button.displayName = 'Button';

Button.ButtonIcon = ButtonIcon;
Button.Unstyled = Unstyled;
Button.Skeleton = Skeleton;
