import { RouteParam } from '@/types/shared';

export const getStoresRoute = () => {
  return '/sellers';
};

export const getStoreRoute = (storeId: RouteParam) => {
  return `${getStoresRoute()}/${storeId}`;
};

export const getStoreEmailEditRoute = (storeId: RouteParam) => {
  return `${getStoresRoute()}/${storeId}/edit`;
};
