import { useTranslation } from 'react-i18next';

import { CustomerCompanyJumbotron } from '@/components/shared/CustomerCompanyJumbotron';
import { IconWithGlow } from '@/components/shared/IconWithGlow';
import { Header } from '@/components/shared/typography/Header';
import { Body } from '@/components/shared/typography/Body';
import { Button } from '@/components/shared/buttons';
import { useGoBack } from '@/hooks/shared';

import style from './VeriformPageConfirmationPage.module.sass';

export const VeriformPageConfirmationPage = () => {
  const { t } = useTranslation();
  const { goBack } = useGoBack();

  return (
    <>
      <div className={style.logos}>
        <CustomerCompanyJumbotron textLogo={true} />
      </div>
      <div className={style.content}>
        <IconWithGlow
          type='success'
          iconName='DoneCircle'
          sizeVariant='xl'
        />
        <Header
          variant='h2'
          className={style.header}>
          {t('store:veriform.confirmation.header')}
        </Header>
        <Body
          size='medium'
          className={style.subheader}>
          {t('store:veriform.confirmation.subheader')}
        </Body>
        <Button
          className={style.button}
          sizeVariant='medium'
          onClick={goBack}>
          {t('store:veriform.confirmation.button')}
        </Button>
      </div>
    </>
  );
};
