import { type QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import type { DataProvider } from '@/types/dataProvider';

export const fetchDataProvider = async (context: QueryFunctionContext) => {
  const [, dataProviderId] = context.queryKey;
  const response = await Api.get<DataProvider>(`/api/bot_providers/${dataProviderId}`);

  return response.data;
};
