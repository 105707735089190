import { useQueryParams, StringParam } from 'use-query-params';

import {
  COMPANY_ID,
  NAME,
  DURATION,
  STATUS,
  SUBDOMAIN_ID,
  BRAND_ID,
  SCHEDULING,
} from '@/resources/queryParams';

export const useBotsFilterParams = () => {
  const [values, setValues] = useQueryParams({
    [STATUS]: StringParam,
    [COMPANY_ID]: StringParam,
    [DURATION]: StringParam,
    [SUBDOMAIN_ID]: StringParam,
    [NAME]: StringParam,
    [SCHEDULING]: StringParam,
    [BRAND_ID]: StringParam,
  });

  return {
    values,
    setValues,
  };
};
