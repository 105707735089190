import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { BadgeProps } from '@/components/shared/Badge';
import { StatusBadge } from '@/components/shared/StatusBadge';
import { Tooltip } from '@/components/shared/Tooltip';
import { getDaysLeft } from '@/utils/date';
import { useStoreStatusLabel } from '@/hooks/stores/useStoreStatusLabel';
import { StoreStatus as StoreStatusEnum } from '@/resources/enums';

import style from './StoreStatus.module.sass';

const STORE_STATUS_VARIANTS: Record<string, BadgeProps['variant']> = {
  [StoreStatusEnum.Greylisted]: 'neutral',
  [StoreStatusEnum.Allowed]: 'success',
  [StoreStatusEnum.ActionInProgress]: 'purple',
};

type StoreStatusProps = {
  status: StoreStatusEnum | string;
  allowedUntil?: string;
  badgeSizeVariant?: BadgeProps['sizeVariant'];
};

export const StoreStatus = ({
  status,
  allowedUntil,
  badgeSizeVariant,
}: StoreStatusProps) => {
  const { t } = useTranslation();

  const statusLabel = useStoreStatusLabel(status);

  if (allowedUntil) {
    const daysLeft = getDaysLeft(allowedUntil);
    const formattedDate = format(new Date(allowedUntil), 'dd/MM/yyyy');

    return (
      <StatusBadge
        variant={STORE_STATUS_VARIANTS[status]}>
        <Tooltip
          content={formattedDate}>
          <div>
            {statusLabel}
            <span className={style.daysLeft}>
              {' - '}{t('store:allowedStatusDaysLeft', { count: daysLeft })}
            </span>
          </div>
        </Tooltip>
      </StatusBadge>
    );
  }

  return (
    <StatusBadge
      sizeVariant={badgeSizeVariant}
      variant={STORE_STATUS_VARIANTS[status]}>
      {statusLabel}
    </StatusBadge>
  );
};
