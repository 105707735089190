import { fetchBots } from '@/queries/bot';
import { useDataQuery } from '@/hooks/shared';
import { QUERY_KEYS } from '@/resources/dataKeys';
import type {
  BotsFilterQuery,
  BotsPaginationConfig,
  BotsSortConfig,
} from '@/types/bot';

export type UseBotsParams = {
  filter?: BotsFilterQuery;
  pagination?: BotsPaginationConfig;
  sort?: BotsSortConfig;
};

export const useBots = ({
  ...params
}: UseBotsParams) => {
  const {
    data: queryData,
    ...rest
  } = useDataQuery([QUERY_KEYS.bots, params], fetchBots);

  return {
    ...queryData,
    ...rest,
  };
};
