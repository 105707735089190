import { useDataQuery } from '@/hooks/shared';
import { fetchListings } from '@/queries/listing';
import { QUERY_KEYS } from '@/resources/dataKeys';
import type {
  ListingsSortConfig,
  ListingsPaginationConfig,
  ListingsFilterParams,
} from '@/types/listing';

type UseListingsOptions = {
  enabled: boolean;
};

export type UseListingsParams = {
  filter?: ListingsFilterParams;
  pagination?: ListingsPaginationConfig;
  sort?: ListingsSortConfig;
};

export const useListings = (
  params: UseListingsParams,
  options?: UseListingsOptions,
) => {
  const {
    data: queryData,
    ...rest
  } = useDataQuery([QUERY_KEYS.listings, params], fetchListings, {
    enabled: options?.enabled,
  });

  return {
    ...queryData,
    ...rest,
  };
};
