import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { Card } from '@/components/shared/Card';
import { MultilineHeader } from '@/components/shared/MultilineHeader';
import { StyledLinkExternal } from '@/components/shared/StyledLinkExternal';
import { BotSubdomain } from '@/components/bot/BotSubdomain';
import { BotLatestRunDetails } from '@/components/bot/BotLatestRunDetails';
import { type CardContextMenuProps } from '@/components/shared/Card/CardContextMenu';
import { useRoutePathContext } from '@/contexts/shared';
import type { Bot } from '@/types/bot';

import style from './BotGridCard.module.sass';

const cx = classNames.bind(style);

type BotGridCardProps = {
  bot: Bot;
  className?: string;
  contextMenu: (bot: Bot) => CardContextMenuProps;
};

export const BotGridCard = ({
  bot,
  className,
  contextMenu,
}: BotGridCardProps) => {
  const navigate = useNavigate();

  const {
    getInstanceBotRoute,
  } = useRoutePathContext();

  const boutRoute = getInstanceBotRoute(bot.id);

  const navigateToBot = () => {
    navigate(boutRoute);
  };

  const wrapperClassName = cx('wrapper', className);

  return (
    <Card
      onClick={navigateToBot}
      resourceRoute={boutRoute}
      className={wrapperClassName}
      borderRadiusVariant='lg'
      data-cy='botCard'
      contextMenuProps={contextMenu(bot)}>
      <div className={style.cardBody}>
        <BotSubdomain
          name={bot.linked?.channel?.attributes.name}
          url={bot.linked?.subdomain?.attributes.url}
          channelClassName={style.channelContainer}
        />
        <div className={style.headerWrapper}>
          <MultilineHeader
            variant='h4'
            className={style.botName}>
            {bot.attributes.name}
          </MultilineHeader>
        </div>
        <div className={style.linkWrapper}>
          <StyledLinkExternal className={style.botLink} href={bot.attributes.searchTerm}>
            {bot.attributes.searchTerm}
          </StyledLinkExternal>
        </div>
      </div>
      <div className={style.cardFooter}>
        <BotLatestRunDetails
          botRun={bot.linked?.latestRun}
        />
      </div>
    </Card>
  );
};
