import * as React from "react";
import { forwardRef } from "react";
const SvgFileGavelActive = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M7.99867 21.0038H4.99742C3.89239 21.0038 2.99658 20.108 2.99658 19.003V4.99717C2.99658 3.89214 3.89239 2.99634 4.99742 2.99634H16.002C17.107 2.99634 18.0028 3.89214 18.0028 4.99717V7.99842",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M4.99742 21.0038H7.99867H16.002C17.004 21.0038 18.0006 20.2006 18.0028 19.003C18.004 18.3826 18.0042 16.302 18.004 14L18.0028 7.99842V4.99717C18.0028 3.89214 17.107 2.99634 16.002 2.99634H4.99742C3.89239 2.99634 2.99658 3.89214 2.99658 4.99717V19.003C2.99658 20.108 3.89239 21.0038 4.99742 21.0038Z",
  fill: "#003027"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.99867 21.0038H4.99742C3.89239 21.0038 2.99658 20.108 2.99658 19.003V4.99717C2.99658 3.89214 3.89239 2.99634 4.99742 2.99634H16.002C17.107 2.99634 18.0028 3.89214 18.0028 4.99717V7.99842M7.99867 21.0038C10.3324 21.0038 15.2004 21.0038 16.002 21.0038M7.99867 21.0038H16.002M18.0028 7.99842C18.0033 9.52574 18.0038 11.8586 18.004 14M18.0028 7.99842L18.004 14M16.002 21.0038C17.004 21.0038 18.0006 20.2006 18.0028 19.003C18.004 18.3826 18.0042 16.302 18.004 14",
  stroke: "#003027",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M16.773 15.1614L20.4566 18.2787C20.8985 18.6504 21.0964 19.2375 20.9697 19.8009C20.8429 20.3643 20.4126 20.81 19.854 20.9566C19.2955 21.1032 18.7018 20.9262 18.3147 20.4976L15.0493 16.9021",
  fill: "#F5FAFA"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16.773 15.1614L20.4566 18.2787C20.8985 18.6504 21.0964 19.2375 20.9697 19.8009C20.8429 20.3643 20.4126 20.81 19.854 20.9566C19.2955 21.1032 18.7018 20.9262 18.3147 20.4976L15.0493 16.9021",
  stroke: "#003027",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M6.99805 7.9985H13.0005",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M6.99805 12.0002H8.99888",
  stroke: "#F5FAFA",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M12.9246 13.862L11.459 15.0294C11.1884 15.2466 11.0229 15.5686 11.0038 15.915C10.9847 16.2614 11.1139 16.5997 11.359 16.8452L12.4745 17.9657C12.7175 18.2099 13.0533 18.3386 13.3973 18.3195C13.7413 18.3004 14.0607 18.1352 14.2752 17.8656L15.4517 16.402C15.7315 16.0543 16.0489 15.7386 16.3981 15.4606L17.8637 14.2921C18.1343 14.0752 18.2998 13.7534 18.3189 13.4071C18.338 13.0608 18.2088 12.7228 17.9637 12.4774L16.8523 11.3559C16.6092 11.1117 16.2735 10.983 15.9295 11.0021C15.5855 11.0212 15.266 11.1864 15.0515 11.456L13.871 12.9206C13.5909 13.268 13.2736 13.5837 12.9246 13.862Z",
  fill: "#F5FAFA",
  stroke: "#003027",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgFileGavelActive);
export default ForwardRef;
