import { useTranslation } from 'react-i18next';

import { UserAuthPageLayout } from '@/components/App/AppLayout/LoginPage/UserAuthPageLayout';
import { RequestPasswordForm } from '@/components/company/user/RequestPasswordForm';
import { LoginManagementHeader } from '@/components/shared/LoginManagementHeader';

export const ForgotPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <UserAuthPageLayout>
      <RequestPasswordForm
        submitButtonText={t('loginPage:resetPasswordText')}
        isExistingUser={true}>
        <LoginManagementHeader
          title={t('loginPage:forgotPassword')}
          subtitle={t('loginPage:noWorries')}
        />
      </RequestPasswordForm>
    </UserAuthPageLayout>
  );
};
