import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries';
import { User, UserIncludedRecord } from '@/types/user';

export const fetchUser = async ({ queryKey }: QueryFunctionContext) => {
  const [, userId] = queryKey;
  const response = await Api.get<User, UserIncludedRecord>(`/api/users/${userId}`);

  const user = linkCollectionRelationships(response);

  return user;
};
