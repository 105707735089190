import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import { type UseBotsParams } from '@/hooks/bot';
import type {
  Bot,
  BotChannel,
  BotCompany,
  BotRun,
  BotSubdomain,
  BotsRequestMeta,
} from '@/types/bot';

export type BotsIncludedRecord =
  | BotChannel
  | BotCompany
  | BotSubdomain
  | BotRun;

export type BotsQueryKey = [string, UseBotsParams | undefined];

export const fetchBots = async (context: QueryFunctionContext<BotsQueryKey>) => {
  const [, params = {}] = context.queryKey;

  const {
    filter,
    pagination,
    sort,
  } = params;

  const page = pagination && {
    size: pagination.perPage,
    number: pagination.page,
  };

  const response = await Api.get<Bot[], BotsIncludedRecord, BotsRequestMeta>('/api/bots', {
    params: {
      filter: filter,
      page: page,
      sort: sort?.sort,
    },
  });

  const parsedBots = linkCollectionRelationships(response);

  return {
    bots: parsedBots,
    meta: response.meta,
  };
};
