import * as React from "react";
import { forwardRef } from "react";
const SvgSignOut = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M2.99658 12.0002H14.0012",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.9995 8.99878L14.0008 12L10.9995 15.0013",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M5.99756 9.13585V7.39813C5.99813 6.44473 6.67133 5.62418 7.60623 5.43731L18.6108 3.03031C19.225 2.95936 19.8399 3.15663 20.2982 3.57162C20.7565 3.98661 21.0137 4.57903 21.0038 5.19721V19.003C21.0041 19.5931 20.7438 20.1532 20.2927 20.5336C19.8415 20.914 19.2454 21.0758 18.6638 20.9758L7.65925 19.083C6.69882 18.9178 5.9971 18.0847 5.99756 17.1102V14.9723",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgSignOut);
export default ForwardRef;
