import * as React from "react";
import { forwardRef } from "react";
const SvgEyeClosed = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M10 5.31663C10.659 5.11158 11.3294 5 11.9998 5C15.4948 5 18.9898 8.033 20.8818 11.533C21.0388 11.824 21.0388 12.177 20.8818 12.468C20.4863 13.1993 20.0209 13.9103 19.5 14.5774",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.5 6.58594C5.69025 7.80233 4.1898 9.54843 3.11775 11.531C2.96075 11.822 2.96075 12.175 3.11775 12.466C5.00975 15.966 8.50475 18.999 11.9998 18.999C13.9221 18.999 15.8444 18.0815 17.5 16.6735",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M14.5989 13.5C14.4705 13.7221 14.3113 13.9313 14.1213 14.1213C12.9497 15.2929 11.0502 15.2929 9.87866 14.1213C8.70709 12.9497 8.70709 11.0502 9.87866 9.87868C10.0226 9.73478 10.1774 9.60855 10.3406 9.5",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M21.0071 20.009L4 3.00195",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgEyeClosed);
export default ForwardRef;
