import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import { Report, ReportCompany } from '@/types/report';

export const fetchReport = async (context: QueryFunctionContext) => {
  const [, reportId] = context.queryKey;

  const response = await Api.get<Report, ReportCompany>(`/api/reporting/reports/${reportId}`);

  return linkCollectionRelationships(response);
};
