import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import every from 'lodash-es/every';
import get from 'lodash-es/get';
import { useFormikContext } from 'formik';

import { ButtonToggleInput } from '@/components/shared/form/inputs/ButtonToggleInput';
import { FieldRenderer } from '@/components/shared/form/FieldRenderer';
import { StyledLink } from '@/components/shared/StyledLink';
import { Body } from '@/components/shared/typography/Body';
import { Tooltip } from '@/components/shared/Tooltip';
import { isObject } from '@/utils/misc';
import type { SelectOption } from '@/types/shared';

import style from './CheckboxButtonsGroupInput.module.sass';

export type CheckboxButtonsGroupInputProps = {
  options: SelectOption[];
  name: string;
  isArrayField?: boolean;
  isHelperButtonHidden?: boolean;
};

export const CheckboxButtonsGroupInput = memo(({
  options,
  name,
  isArrayField,
  isHelperButtonHidden,
}: CheckboxButtonsGroupInputProps) => {
  const { t } = useTranslation();

  const {
    setFieldValue,
    values: value,
  } = useFormikContext();

  const values = get(value, name);

  const isAllSelected = useMemo(() => {
    if (isHelperButtonHidden) {
      return;
    }

    if (isArrayField) {
      return values.length === options.length;
    }

    return every(Object.entries(values), '1');
  }, [
    values,
    isArrayField,
    options.length,
    isHelperButtonHidden,
  ]);

  const selectOrDeselectAll = useCallback(() => {
    if (isHelperButtonHidden) {
      return;
    }

    if (isArrayField) {
      const newArrayValues = isAllSelected
        ? []
        : options.map((option) => option.value);

      setFieldValue(name, newArrayValues);
      return;
    }

    const initialValue = isObject(values) ? { ...values } : {};

    const newObjectValues = options.reduce((acc, option) => {
      return {
        ...acc,
        [option.value]: !isAllSelected,
      };
    }, initialValue);

    setFieldValue(name, newObjectValues);
  }, [
    values,
    setFieldValue,
    isAllSelected,
    name,
    options,
    isArrayField,
    isHelperButtonHidden,
  ]);

  const renderOption = (option: SelectOption, index: number) => {
    const label = (
      <FieldRenderer
        key={option.tooltipProps ? undefined : index}
        isArrayField={isArrayField}
        name={isArrayField ? name : `${name}.${option.value}`}
        entryValue={isArrayField ? option.value : undefined}
        className={style.button}
        Component={ButtonToggleInput}>
        {option.label}
      </FieldRenderer>
    );

    if (option.tooltipProps) {
      return (
        <Tooltip
          key={index}
          {...option.tooltipProps}>
          <div>
            {label}
          </div>
        </Tooltip>
      );
    }

    return label;
  };

  return (
    <div className={style.wrapper}>
      <div className={style.buttonsWrapper}>
        {options.map(renderOption)}
      </div>
      {
        !isHelperButtonHidden &&
        <StyledLink
          tabIndex={-1}
          className={style.helperButton}
          onClick={selectOrDeselectAll}>
          <Body size='base'>
            {isAllSelected ? t('common:deselectAll') : t('common:selectAll')}
          </Body>
        </StyledLink>
      }
    </div>
  );
});

CheckboxButtonsGroupInput.displayName = 'CheckboxButtonsGroupInput';
