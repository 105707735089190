import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

import { Tooltip } from '@/components/shared/Tooltip';
import { Body } from '@/components/shared/typography/Body';

import style from './TextSpaceVisualizer.module.sass';
import { countLeadingSpaces, countTrailingSpaces } from './utils';

export const DEFAULT_INDICATOR_CHAR = ' ';
export const SPACE_INDICATOR_ELEMENT = <span className={style.indicator} />;

type TextSpaceVisualizerProps = {
  text?: string;
  indicatorChar?: string;
  isTooltipHidden?: boolean;
};

export const TextSpaceVisualizer = ({
  text,
  indicatorChar = DEFAULT_INDICATOR_CHAR,
  isTooltipHidden,
}: TextSpaceVisualizerProps) => {
  const { t } = useTranslation();

  if (!text) {
    return <>-</>;
  }

  const leadingSpacesCount = countLeadingSpaces(text);
  const trailingSpacesCount = countTrailingSpaces(text);

  const leadingIndicators = Array(leadingSpacesCount).fill(indicatorChar);
  const trailingIndicators = Array(trailingSpacesCount).fill(indicatorChar);

  const hasStartIndicator = leadingSpacesCount > 0;
  const hasEndIndicator = trailingSpacesCount > 0;

  const areSpacesVisualized = hasStartIndicator || hasEndIndicator;

  if (!areSpacesVisualized) {
    return <>{text}</>;
  }
  const renderIndicator = (_: unknown, index: number) => (
    <Fragment key={index}>
      {SPACE_INDICATOR_ELEMENT}
    </Fragment>
  );

  const textWithIndicators = (
    <>
      {leadingIndicators.map(renderIndicator)}
      <span className={style.text}>
        {text.trim()}
      </span>
      {trailingIndicators.map(renderIndicator)}
    </>
  );

  const visibleContent = (
    <span>
      {textWithIndicators}
    </span>
  );

  if (isTooltipHidden) {
    return (
      visibleContent
    );
  }

  return (
    <Tooltip
      sizeVariant='base'
      content={
        <div className={style.tooltipContent}>
          <Body size='base'>
            {textWithIndicators}
          </Body>
          <Body size='small'>
            {SPACE_INDICATOR_ELEMENT} {t('common:spaceIndicator')}
          </Body>
        </div>
      }>
      {visibleContent}
    </Tooltip>
  );
};
