import { useNavigate } from 'react-router-dom';

import { useDataQuery } from '@/hooks/shared';
import { fetchVeriform } from '@/queries/veriform';

export const useVeriform = (id?: string, token?: string) => {
  const navigate = useNavigate();

  const {
    data: values,
    ...rest
  } = useDataQuery(
    ['veriform', id, token],
    fetchVeriform,
    {
      staleTime: 1000,
      onError: () => {
        navigate(`/veriforms/${id}/${token}/expired`);
      },
    },
  );

  return {
    values,
    ...rest,
  };
};
