import { useTranslation } from 'react-i18next';

import { useDataMutation } from '@/hooks/shared';
import { removeStoreFromWhiteList } from '@/mutations/store';
import { queryClient } from '@/resources/queryClient';
import { notification } from '@/utils/notification';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const useRemoveStoreFromWhiteList = (
  storeId: string | undefined,
  resellerId: string | undefined,
) => {
  const { t } = useTranslation();

  return useDataMutation(
    'removeStoreFromWhiteList',
    removeStoreFromWhiteList,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.companyStores]);
        queryClient.invalidateQueries([QUERY_KEYS.companyStore, storeId]);
        queryClient.invalidateQueries([QUERY_KEYS.resellers]);
        queryClient.invalidateQueries([QUERY_KEYS.reseller, resellerId]);

        notification.info({
          title: t('notifications:store.greyListSuccess.title'),
          body: t('notifications:store.greyListSuccess.body'),
        });
      },
    },
  );
};
