import { useMemo, type ChangeEvent } from 'react';
import clamp from 'lodash-es/clamp';
import { useTranslation } from 'react-i18next';

import { ToolbarSelect } from '@/components/shared/ToolbarSelect';
import type { SelectOption } from '@/types/shared';

import style from './PaginatePerPage.module.sass';

type PaginatePerPageProps = {
  perPage: number;
  page: number;
  itemsCount?: number;
  totalItemsCount: number;
  allowedValues: number[];
  dropdownPosition?: 'top' | 'bottom';
  updatePerPage: (perPage: number) => void;
  onAfterChange?: () => void;
};

export const PaginatePerPage = ({
  perPage,
  page,
  itemsCount = 0,
  totalItemsCount,
  updatePerPage,
  allowedValues,
  onAfterChange,
  dropdownPosition = 'top',
}: PaginatePerPageProps) => {
  const { t } = useTranslation();

  const selectOptions = useMemo(() => {
    return allowedValues.map<SelectOption>((value) => ({
      label: value,
      value: String(value),
    }));
  }, [
    allowedValues,
  ]);

  const handleSelectChange = () => (valueOrEvent: string | ChangeEvent) => {
    const newPerPage = Number(valueOrEvent);

    if (!isFinite(newPerPage) || newPerPage === perPage) return;

    updatePerPage(newPerPage);

    if (onAfterChange) onAfterChange();
  };

  const from = clamp((page - 1) * perPage + 1, 0, totalItemsCount);
  const to = clamp(from + itemsCount - 1, from, totalItemsCount);

  const label = (
    <span className={style.label}>
      {t('common:pagination.resultsInfo', {
        from,
        to,
        count: totalItemsCount,
      })}
    </span>
  );

  return (
    <ToolbarSelect
      label={label}
      inputName='perPage'
      options={selectOptions}
      onChange={handleSelectChange}
      value={String(perPage)}
      inputClassName={style.selectInput}
      isInputInverted={dropdownPosition === 'top'}
      popperAlign='left'
    />
  );
};
