import * as React from "react";
import { forwardRef } from "react";
const SvgWebsite = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M21 12H3",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.0005 16L3.93018 16",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.0005 8H3.93018",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M12 3C16.971 3 21.0012 7.02944 21 12C20.9988 16.9706 16.971 21 12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M10.6255 4.05923C8.45816 8.88462 8.45816 15.1154 10.6255 19.9408C11.2601 21.3531 12.7399 21.3531 13.3745 19.9408C15.5418 15.1154 15.5418 8.88462 13.3745 4.05923C12.7389 2.64692 11.2601 2.64692 10.6255 4.05923Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgWebsite);
export default ForwardRef;
