import classNames from 'classnames/bind';
import { Fragment, useEffect, useRef, useState } from 'react';

import { TextInputProps } from '@/components/shared/form/inputs/TextInput';
import { countLeadingSpaces, countTrailingSpaces } from '@/components/shared/TextSpaceVisualizer/utils';
import { SPACE_INDICATOR_ELEMENT } from '@/components/shared/TextSpaceVisualizer/TextSpaceVisualizer';

import style from './DotSpacerTextInput.module.sass';

const cx = classNames.bind(style);

type DotSpacerTextInputProps = TextInputProps & {
  value: string;
};

export const DotSpacerTextInput = ({
  value,
  ...restProps
}: DotSpacerTextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const howManyLeadingDots = countLeadingSpaces(value);
  const howManyTrailingDots = countTrailingSpaces(value);

  const {
    sizeVariant,
    className,
    error,
    warning,
    wrapperClassName,
    ...rest
  } = restProps;

  const mimicInputClassNames = cx(
    'mimicInput',
    sizeVariant,
    className,
    {
      error,
      warning,
    },
  );

  const handleFocusInput = () => {
    inputRef.current?.focus();
  };

  const renderDots = (length: number) => {
    return (
      Array.from({ length })
        .map((_, index) => (
          <Fragment key={index}>
            {SPACE_INDICATOR_ELEMENT}
          </Fragment>
        ))
    );
  };

  const renderTrailingDots = () => {
    if (value.trim().length < 1) {
      return null;
    }

    return renderDots(howManyTrailingDots);
  };

  const [inputWidth, setInputWidth] = useState('auto');
  const [inputFontSize, setInputFontSize] = useState('');
  const spanRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(`${spanRef.current.offsetWidth + 16}px`);
    }
  }, [
    value,
  ]);

  useEffect(() => {
    if (inputRef.current) {
      const computedStyle = window.getComputedStyle(inputRef.current);

      setInputFontSize(computedStyle.fontSize);
    }
  }, []);

  const inputStyles = {
    marginLeft: `calc(${-0.25 * howManyLeadingDots}em)`, // 0.25em is equal to char '0'
    marginRight: `calc(${-0.25 * howManyTrailingDots}em - 12px)`,
    width: inputWidth,
  };

  return (
    <div className={wrapperClassName}>
      <div
        onClick={handleFocusInput}
        className={mimicInputClassNames}>
        <div className={style.dotsWrapper}>
          {renderDots(howManyLeadingDots)}
        </div>
        <div>
          <input
            {...rest}
            value={value}
            className={style.input}
            ref={inputRef}
            style={inputStyles}
          />
        </div>
        <div className={style.dotsWrapper}>
          {renderTrailingDots()}
        </div>
      </div>
      <div
        className={style.dimensionsDirector}
        ref={spanRef}
        style={{ fontSize: inputFontSize }}>
        {value || rest?.placeholder || ''}
      </div>
    </div>
  );
};
