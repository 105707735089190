import { Formik } from 'formik';

import { FormikForm } from '@/components/shared/formik/FormikForm';
import { ViewModeSelector } from '@/components/shared/viewMode/ViewModeSelector';
import { StoresSortSelect } from '@/components/store/StoresSortSelect/StoresSortSelect';
import { StoresFilters } from '@/components/store/StoresFilters';
import { StoresFilterEntries } from '@/components/store/StoresFilterEntries';
import { type StoresFiltersProps } from '@/components/store/StoresFilters/StoresFilters';
import { FilteringResultsCount } from '@/components/shared/FilteringResultsCount';
import { usePaginationParams } from '@/hooks/shared/usePaginationParams';
import { useStoresFilterParams } from '@/hooks/stores';
import { removeEmptyValues } from '@/utils/queries/removeEmptyValues';
import { type Channel } from '@/types/channel';
import { type AggregationEntry } from '@/types/shared';
import { type StoresFilterParams } from '@/types/store';

import style from './StoresViewToolbar.module.sass';

type StoresViewToolbarProps = {
  channels?: Channel[];
  aggregationMapping: StoresFiltersProps['aggregationMapping'];
  areFiltersDisabled?: boolean;
  filterValues: StoresFilterParams;
  countries: AggregationEntry[] | undefined;
  totalEntries: number | undefined;
};

export const StoresViewToolbar = ({
  channels,
  aggregationMapping,
  areFiltersDisabled,
  filterValues,
  countries,
  totalEntries,
}: StoresViewToolbarProps) => {
  const {
    setValues,
  } = useStoresFilterParams();

  const {
    updatePage,
  } = usePaginationParams();

  if (!channels) {
    return null;
  }

  const updateQueryParams = (values: StoresFilterParams) => {
    updatePage(1);
    setValues(removeEmptyValues(values));
  };

  return (
    <Formik
      validateOnBlur={false}
      enableReinitialize={true}
      validate={updateQueryParams}
      onSubmit={updateQueryParams}
      initialValues={filterValues}>
      <FormikForm className={style.wrapper}>
        <div className={style.mainToolbar}>
          <StoresFilters
            channels={channels}
            aggregationMapping={aggregationMapping}
            disabled={areFiltersDisabled}
          />
          <div className={style.toolbarAside}>
            <StoresSortSelect />
            <ViewModeSelector />
          </div>
        </div>
        <StoresFilterEntries
          countries={countries}
          filterValues={filterValues}
          aggregationMapping={aggregationMapping}
        />
        <FilteringResultsCount
          totalEntries={totalEntries}
          values={filterValues}
        />
      </FormikForm>
    </Formik>
  );
};
