import { createContext, useContext } from 'react';

export type DropdownContextValue = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

export const DropdownContext = createContext<DropdownContextValue>({
  isOpen: false,
  setIsOpen: () => { /* ... */ },
});

export const useDropdownContext = () => {
  return useContext(DropdownContext);
};
