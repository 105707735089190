import { removeProtocolFromUrl } from './removeProtocolFromUrl';

export const getTldFromUrl = (url?: string) => {
  if (!url) {
    return url;
  }

  const withoutProtocol = removeProtocolFromUrl(url);

  const domainName = withoutProtocol?.split('/')[0];
  const tld = domainName?.split('.').slice(-1)[0];

  return tld;
};
