import { MutationFunction } from 'react-query';

import { Api } from '@/utils/api';
import type { UploadVeriformInvoiceValues, VeriformInvoice } from '@/types/veriform';

// eslint-disable-next-line max-len
export const uploadVeriformInvoice: MutationFunction<VeriformInvoice, UploadVeriformInvoiceValues> = async (
  values,
) => {
  const url = `/api/veriforms/${values.veriformId}/invoices`;
  const formData = new FormData();

  formData.append('file', values.file);

  const response = await Api.post<VeriformInvoice>(url, formData, {
    headers: {
      contentType: 'multipart/form-data',
      authorization: `Bearer ${values.token}`,
    },
  });

  return response.data;
};
