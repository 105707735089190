import { useStoreBusinessTypes } from '@/hooks/stores/useStoreBusinessTypes';
import { BUSINESS_TYPE_OTHER_NAME } from '@/resources/store';

export const useStoreOtherBusinessTypeId = () => {
  const { storeBusinessTypes } = useStoreBusinessTypes();

  const otherBusinessTypeId = storeBusinessTypes?.find(
    (type) => type.attributes.name === BUSINESS_TYPE_OTHER_NAME,
  )?.id;

  return otherBusinessTypeId;
};
