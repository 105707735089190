import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import {
  useLayoutEffect,
  useState,
  useRef,
  type FormEvent,
  type ChangeEvent,
} from 'react';

import { Button } from '@/components/shared/buttons';
import { Tooltip } from '@/components/shared/Tooltip';
import { useInputHandlers } from '@/hooks/utils';
import { disallowNonNumericKeys } from '@/utils/misc';

import style from './PaginatePage.module.sass';

const cx = classNames.bind(style);

type PaginatePageProps = {
  page: number;
  pages: number;
  currentPage: number;
  updatePage: (newPage: number) => void;
}

type CurrentPageProps = {
  currentPage: number;
  pages: number;
  updatePage: (newPage: number) => void;
}

const CurrentPage = ({
  currentPage,
  updatePage,
  pages,
}: CurrentPageProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const measurerRef = useRef<HTMLDivElement | null>(null);
  const [inputValue, handleInputChange, setInputValue] = useInputHandlers(String(currentPage));
  const [tooltipMessage, setTooltipMessage] = useState('');

  useLayoutEffect(() => {
    if (!inputRef.current) return;

    if (!measurerRef.current) {
      inputRef.current.style.width = '';
      return;
    }

    const newWidth = measurerRef.current.clientWidth;

    inputRef.current.style.width = `${newWidth}px`;
  }, [
    currentPage,
  ]);

  const changeCurrentPage = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (Number(inputValue) === currentPage) {
      setInputValue(String(currentPage));
    } else {
      if (
        Number(inputValue) > pages
        ||
        Number(inputValue) <= 0
      ) {
        return;
      }

      updatePage(Number(inputValue));
    }

    inputRef.current?.blur();
  };

  const resetInput = () => {
    setInputValue(String(currentPage));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const parsedValue = Number(value);

    if (parsedValue > pages) {
      setTooltipMessage(t('common:pagination.lessThanPagesInfo', { count: pages }));
    } else if (value === '0' || parsedValue < 0) {
      setTooltipMessage(t('common:pagination.moreThanPagesInfo'));
    } else {
      setTooltipMessage('');
    }

    handleInputChange(event);
  };

  const buttonClassName = cx('pageButton', 'active');
  const inputClassName = cx('pageButtonText', 'pageButtonInput');
  const measurerClassName = cx('pageButtonText', 'inputMeasurer');

  return (
    <Tooltip
      visible={!!tooltipMessage}
      disabled={!tooltipMessage}
      content={tooltipMessage}>
      <div className={buttonClassName}>
        <form
          noValidate={true}
          onSubmit={changeCurrentPage}>
          <input
            min={1}
            max={pages}
            type='number'
            ref={inputRef}
            className={inputClassName}
            value={inputValue}
            onKeyDown={disallowNonNumericKeys}
            onChange={handleChange}
            onBlur={resetInput}
            data-testid='pageInput'
          />
          <div
            ref={measurerRef}
            className={measurerClassName}>
            {currentPage}
          </div>
        </form>
      </div>
    </Tooltip>
  );
};

export const PaginatePage = ({
  page,
  pages,
  currentPage,
  updatePage,
}: PaginatePageProps) => {
  if (page === currentPage) {
    return (
      <CurrentPage
        pages={pages}
        currentPage={currentPage}
        updatePage={updatePage}
      />
    );
  }

  const buttonClassName = cx('pageButton', 'switchable');

  return (
    <Button.Unstyled
      className={buttonClassName}
      onClick={() => updatePage(page)}>
      <div className={style.pageButtonText}>{page}</div>
    </Button.Unstyled>
  );
};
