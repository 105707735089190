import lastDayOfWeekHelper from 'date-fns/lastDayOfWeek';
import isEqual from 'date-fns/isEqual';
import addDays from 'date-fns/addDays';
import { default as formatDate } from 'date-fns/format';
import { differenceInDays, parseISO } from 'date-fns';

import { WEEK_START_DAY } from '@/resources/time';

type WeekStartDay = 0 | 1 | 2 | 3 | 4 | 5 | 6;
type MonthFormat = Intl.DateTimeFormatOptions['month'];
type WeekDayFormat = Intl.DateTimeFormatOptions['weekday'];

export { formatRelativeDate } from './formatRelativeDate';
export { ensureIsDate } from './ensureIsDate';
export { formatTime } from './formatTime';
export { formatDate } from './formatDate';
export { formatDateStyle } from './formatDateStyle';
export { isNotValidDate } from './isNotValidDate';
export { formatDurationTime } from './formatDurationTime';

export const firstDayOfWeek = (date: Date, weekStartDay: WeekStartDay = WEEK_START_DAY) => {
  const weekDay = weekStartDay === 0 ? date.getDay() : (date.getDay() || 7);

  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - weekDay + weekStartDay);
};

export const lastDayOfWeek = (date: Date, weekStartDay: WeekStartDay = WEEK_START_DAY) => {
  return lastDayOfWeekHelper(date, {
    weekStartsOn: weekStartDay,
  });
};

export const midnight = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const isDayEqual = (date1: Date, date2: Date) => {
  return isEqual(midnight(date1), midnight(date2));
};

export const getMonthName = (
  monthIndex: number,
  locale: string,
  format: MonthFormat = 'long',
) => {
  const date = new Date(2000, monthIndex); // any year, we only care about month

  return new Intl.DateTimeFormat(locale, { month: format }).format(date);
};

export const getWeekDayName = (
  dayIndex: number,
  locale: string,
  format: WeekDayFormat = 'long',
) => {
  const date = new Date(1970, 0, 4 + dayIndex + WEEK_START_DAY);

  return new Intl.DateTimeFormat(locale, { weekday: format }).format(date);
};

export const addDaysToToday = (days: number) => {
  const today = new Date();
  const until = addDays(today, days);

  return formatDate(until, 'yyyy-MM-dd');
};

export const getDaysLeft = (endDateString?: string) => {
  if (!endDateString) {
    return;
  }
  const endDate = parseISO(endDateString);
  const today = new Date();

  if (isDayEqual(endDate, today)) {
    return 0;
  }

  const daysLeft = differenceInDays(endDate, today) + 1;

  return daysLeft;
};
