import { AxiosError } from 'axios';

import { Api } from '@/utils/api';
import { type Branding } from '@/types/branding';

export const fetchBranding = async () => {
  try {
    const response = await Api.get<Branding>('/api/branding');

    return response.data;
  } catch (e) {
    const error = e as AxiosError;

    if (error.response?.status === 404) {
      return;
    }

    throw error;
  }
};
