import * as React from "react";
import { forwardRef } from "react";
const SvgFileDone = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M7 2.25C5.48079 2.25 4.25 3.48079 4.25 5V19C4.25 20.5192 5.48079 21.75 7 21.75H13C13.4142 21.75 13.75 21.4142 13.75 21C13.75 20.5858 13.4142 20.25 13 20.25H7C6.30921 20.25 5.75 19.6908 5.75 19V5C5.75 4.30921 6.30921 3.75 7 3.75H13.25V7C13.25 7.96621 14.0338 8.75 15 8.75H18.25V15C18.25 15.4142 18.5858 15.75 19 15.75C19.4142 15.75 19.75 15.4142 19.75 15V8V7.828C19.75 7.09886 19.4598 6.39916 18.9443 5.88367L16.1163 3.05567C15.6008 2.54018 14.9011 2.25 14.172 2.25H14H7ZM14.75 3.89195V7C14.75 7.13779 14.8622 7.25 15 7.25H18.108C18.0497 7.13832 17.9744 7.03505 17.8837 6.94433L15.0557 4.11633C14.9649 4.02561 14.8617 3.95025 14.75 3.89195ZM21.5303 18.5303C21.8232 18.2374 21.8232 17.7626 21.5303 17.4697C21.2374 17.1768 20.7626 17.1768 20.4697 17.4697L18 19.9393L16.5303 18.4697C16.2374 18.1768 15.7626 18.1768 15.4697 18.4697C15.1768 18.7626 15.1768 19.2374 15.4697 19.5303L17.4697 21.5303C17.7626 21.8232 18.2374 21.8232 18.5303 21.5303L21.5303 18.5303ZM7.25 11C7.25 10.5858 7.58579 10.25 8 10.25H14C14.4142 10.25 14.75 10.5858 14.75 11C14.75 11.4142 14.4142 11.75 14 11.75H8C7.58579 11.75 7.25 11.4142 7.25 11ZM8 13.25C7.58579 13.25 7.25 13.5858 7.25 14C7.25 14.4142 7.58579 14.75 8 14.75H14C14.4142 14.75 14.75 14.4142 14.75 14C14.75 13.5858 14.4142 13.25 14 13.25H8ZM7.25 17C7.25 16.5858 7.58579 16.25 8 16.25H12.33C12.7442 16.25 13.08 16.5858 13.08 17C13.08 17.4142 12.7442 17.75 12.33 17.75H8C7.58579 17.75 7.25 17.4142 7.25 17Z",
  fill: "currentColor"
}));
const ForwardRef = forwardRef(SvgFileDone);
export default ForwardRef;
