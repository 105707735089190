type TransformerFn = (key: string) => string;

export const transformKeys = <TValue>(
  input: Record<string, TValue>,
  transformKey: TransformerFn,
): Record<string, TValue> => {
  const entries = Object.entries(input).map(([key, value]) => {
    return [transformKey(key), value];
  });

  const transformed = Object.fromEntries(entries);

  return transformed;
};
