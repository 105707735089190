import { useDataQuery } from '@/hooks/shared';
import { fetchBotRun } from '@/queries/bot/fetchBotRun';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const useBotRun = (botId?: string, botRunId?: string) => {
  const {
    data: botRun,
    ...rest
  } = useDataQuery([
    QUERY_KEYS.botRun,
    botId,
    botRunId,
  ], fetchBotRun, {
    enabled: !!botId && !!botRunId,
  },
  );

  return {
    botRun,
    ...rest,
  };
};
