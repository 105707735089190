import classNames from 'classnames/bind';
import { ReactNode, type PropsWithChildren } from 'react';

import { Body } from '@/components/shared/typography/Body';
import { Icon } from '@/components/shared/Icon';

import style from './AlertBox.module.sass';
import { getIconName } from './utils';

const cx = classNames.bind(style);

export type AlertBoxProps = PropsWithChildren & {
  variant: 'neutral' | 'danger' | 'info';
  icon?: ReactNode;
};

export const AlertBox = ({
  variant,
  children,
  icon,
}: AlertBoxProps) => {
  const className = cx('alert', variant);

  const renderIcon = () => {
    if (icon) {
      return icon;
    }

    return (
      <Icon name={getIconName(variant)} />
    );
  };

  return (
    <Body
      size='base'
      className={className}>
      <div className={style.iconWrapper}>
        {renderIcon()}
      </div>
      {children}
    </Body>
  );
};
