import { useDataQuery } from '@/hooks/shared';
import { fetchCompanyStores } from '@/queries/store';
import { QUERY_KEYS } from '@/resources/dataKeys';
import {
  StoresFilterParams,
  StoreSortConfig,
  StoresPaginationConfig,
} from '@/types/store';

export type UseStoreParams = {
  pagination?: StoresPaginationConfig;
  sort?: StoreSortConfig;
  filter?: StoresFilterParams;
};

export const useCompanyStores = (params?: UseStoreParams) => {
  const {
    data: queryData,
    ...rest
  } = useDataQuery([QUERY_KEYS.companyStores, params], fetchCompanyStores);

  return {
    ...queryData,
    ...rest,
  };
};
