import { createContext, useContext } from 'react';

import { SetViewModeOptions } from '@/components/shared/viewMode/ViewModeSelectorContext';
import { ViewMode } from '@/resources/enums';
import type { Nullable } from '@/types/shared';

export type ViewModeContextValue = Nullable<{
  viewMode: ViewMode;
  defaultValue: ViewMode;
  permittedValues: ViewMode[];
  isChanging: boolean;
  setViewMode: (newViewMode: ViewMode, options?: SetViewModeOptions) => void;
}>;

export const ViewModeContext = createContext<ViewModeContextValue>(null);

export const useViewModeContext = () => {
  const contextValue = useContext(ViewModeContext);

  if (!contextValue) {
    throw new Error('ViewModeContext not found');
  }

  return contextValue;
};
