import * as React from "react";
import { forwardRef } from "react";
const SvgEmailWarning = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M18.8462 13.9756L21.7582 19.1506C22.4752 20.4246 21.5542 22.0006 20.0922 22.0006H14.2692C12.8062 22.0006 11.8862 20.4266 12.6032 19.1506L15.5152 13.9756C16.2442 12.6756 18.1152 12.6756 18.8462 13.9756V13.9756Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M20.0063 11.0006V6.03363",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.0063 18.0006H5.00635C3.34935 18.0006 2.00635 16.6576 2.00635 15.0006V6.03363",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M9.60835 11.2646L3.09135 7.83164C2.42435 7.47964 2.00635 6.78764 2.00635 6.03364V6.00064C2.00635 4.89564 2.90135 4.00064 4.00635 4.00064H17.9733C19.0963 4.00064 20.0063 4.91064 20.0063 6.03364V6.05364C20.0063 6.79564 19.5953 7.47764 18.9383 7.82364L12.4043 11.2656C11.5293 11.7246 10.4833 11.7246 9.60835 11.2646Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M17.1804 17.6227V15.8687",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M17.1794 19.5577C17.1314 19.5577 17.0924 19.5967 17.0934 19.6447C17.0934 19.6927 17.1324 19.7317 17.1804 19.7317C17.2284 19.7317 17.2664 19.6927 17.2664 19.6447C17.2664 19.5967 17.2274 19.5577 17.1794 19.5577",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgEmailWarning);
export default ForwardRef;
