import { fetchKeyword } from '@/queries/company';
import { useDataQuery } from '@/hooks/shared';
import { QUERY_KEYS } from '@/resources/dataKeys';
import type { KeywordDetails } from '@/types/keyword';

export const useKeyword = (companyId?: string, keywordId?: string) => {
  const {
    data: keyword,
    ...rest
  } = useDataQuery<KeywordDetails>([
    QUERY_KEYS.keyword,
    companyId,
    keywordId,
  ], fetchKeyword, {
    enabled: Boolean(companyId),
  });

  return {
    keyword,
    ...rest,
  };
};
