import { produce } from 'immer';

import { type BotsQueryKey } from '@/queries/bot/fetchBots';
import type { BotsFilterQuery } from '@/types/bot';
import type { Nullable } from '@/types/shared';

export const filterValueToParam = (value: Nullable<string>) => {
  if (value === 'slow') {
    return 'true';
  } else if (value === 'notSlow') {
    return 'false';
  }

  if (value === 'scheduled') {
    return 'true';
  }

  if (value === 'notScheduled') {
    return 'false';
  }
};

export const removeBotFilterValue = (
  queryKey: BotsQueryKey,
  filterKey: keyof BotsFilterQuery,
) => {
  return produce(queryKey, (draft) => {
    if (draft[1]?.filter) {
      draft[1].filter[filterKey] = undefined;
    }
  });
};
