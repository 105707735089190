import * as yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type FirebaseError } from '@firebase/util';

import { TextInput } from '@/components/shared/form/inputs/TextInput';
import { FieldWrapper } from '@/components/shared/form/FieldWrapper';
import { ButtonAsync } from '@/components/shared/buttons';
import { PasswordInput } from '@/components/shared/form/inputs/PasswordInput';
import { StyledLink } from '@/components/shared/StyledLink';
import { FieldRenderer } from '@/components/shared/form/FieldRenderer';
import { AlertBox } from '@/components/shared/AlertBox';
import { IconWithGlow } from '@/components/shared/IconWithGlow';
import { Header } from '@/components/shared/typography/Header';
import { useAuthentication } from '@/hooks/user';
import { AuthorisedCustomerCompanyUserContext } from '@/contexts/user';

import style from './PasswordLoginForm.module.sass';
import { handleLoginFormikError } from './utils';

export interface LoginFormValues {
  email: string;
  password: string;
}

type PasswordLoginFormProps = {
  isUserNotAuthorisedForThisCompanyError?: boolean;
};

export const PasswordLoginForm = ({
  isUserNotAuthorisedForThisCompanyError,
}: PasswordLoginFormProps) => {
  const [
    errorMessage,
    setErrorMessage,
  ] = useState<'invalidCredentials' | 'tooManyRequests' | ''>('');
  const [isBusy, setIsBusy] = useState(false);
  const { isError, setHasTriedToLogin } = useContext(AuthorisedCustomerCompanyUserContext);

  useEffect(() => {
    const error = isUserNotAuthorisedForThisCompanyError ? 'invalidCredentials' : '';
    setErrorMessage(error);
  }, [
    isUserNotAuthorisedForThisCompanyError,
  ]);

  const { t } = useTranslation();
  const { signInWithEmailAndPassword } = useAuthentication();

  const submitLoginForm = async (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>,
  ) => {
    setIsBusy(true);

    try {
      await signInWithEmailAndPassword(values.email, values.password);

      setHasTriedToLogin(true);
    } catch (fireBaseError) {
      setHasTriedToLogin(true);

      const error = fireBaseError as FirebaseError;

      handleLoginFormikError(error, formikHelpers, t);

      if (error.code === 'auth/invalid-login-credentials') {
        setErrorMessage('invalidCredentials');
      }
      if (error.code === 'auth/too-many-requests') {
        setErrorMessage('tooManyRequests');
      }
    } finally {
      setIsBusy(false);
    }
  };

  const initialValues: LoginFormValues = {
    email: '',
    password: '',
  } as const;

  const validationSchema = yup.object({
    email: yup.string().required(),
    password: yup.string().required(),
  });

  const renderNotAuthorisedUserError = () => {
    if (isError) {
      return (
        <div className={style.alertWrapper}>
          <AlertBox
            variant='danger'
            icon={
              <IconWithGlow
                type='danger'
                sizeVariant='medium'
                iconName='ExclamationCircle'
              />
            }>
            <Header variant='h5'>
              {t(`loginPage:errors.${errorMessage}`)}
            </Header>
          </AlertBox>
        </div>
      );
    }
  };

  return (
    <div className={style.wrapper}>
      <Formik
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={submitLoginForm}>
        {({ values }) => (
          <Form className={style.form}>
            <FieldWrapper
              name='email'
              label={t('loginPage:label.email')}>
              <FieldRenderer
                name='email'
                sizeVariant='medium'
                placeholder={t('loginPage:enterEmail')}
                Component={TextInput}
              />
            </FieldWrapper>
            <FieldWrapper
              name='password'
              label={t('loginPage:label.password')}>
              <FieldRenderer
                name='password'
                sizeVariant='medium'
                placeholder={t('loginPage:enterPassword')}
                Component={PasswordInput}
              />
            </FieldWrapper>
            <div className={style.forgotPassword}>
              <StyledLink
                replace={true}
                to='/forgot-password'
                effectVariant='reverse'
                state={{ email: values.email }}>
                {t('loginPage:forgotPassword')}
              </StyledLink>
            </div>
            {renderNotAuthorisedUserError()}
            <ButtonAsync
              width='full'
              type='submit'
              variant='primary'
              isBusy={isBusy}>
              {t('loginPage:submit')}
            </ButtonAsync>
          </Form>
        )}
      </Formik>
      <div className={style.register}>
        <span className={style.registerText}>
          {t('loginPage:getAccessToAccount')}
        </span>
        {' '}
        <StyledLink
          effectVariant='reverse'
          to='/register-me'
          replace={true}>
          {t('loginPage:register')}
        </StyledLink>
      </div>
    </div>
  );
};
