import clamp from 'lodash-es/clamp';

export const DEFAULT_OFFSET = 2;

export const getPagesRange = (
  page: number,
  pages: number,
  offset: number,
) => {
  const rangeFrom = clamp(page - offset, 1, pages);
  const rangeTo = clamp(page + offset, 1, pages);

  return {
    from: rangeFrom,
    to: rangeTo,
  };
};
