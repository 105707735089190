import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import {
  Badge,
  type BadgeProps,
} from '@/components/shared/Badge/Badge';

type BooleanBadgeProps = {
  value?: boolean;
  trueText?: ReactNode;
  falseText?: ReactNode;
  sizeVariant?: BadgeProps['sizeVariant'];
  shapeVariant?: BadgeProps['shapeVariant'];
};

export const BooleanBadge = ({
  value,
  trueText,
  falseText,
  sizeVariant,
  shapeVariant,
}: BooleanBadgeProps) => {
  const { t } = useTranslation();

  if (value === true) {
    return (
      <Badge
        sizeVariant={sizeVariant}
        variant='success'
        shapeVariant={shapeVariant}>
        {trueText || t('common:yes')}
      </Badge>
    );
  }

  return (
    <Badge
      sizeVariant={sizeVariant}
      variant='danger'
      shapeVariant={shapeVariant}>
      {falseText || t('common:no')}
    </Badge>
  );
};
