import classNames from 'classnames/bind';

import { Button, type ButtonProps } from '@/components/shared/buttons/Button/Button';
import { Loader } from '@/components/shared/loaders/Loader';
import { Icon } from '@/components/shared/Icon';

import style from './ButtonAsync.module.sass';

interface ButtonAsyncProps extends ButtonProps {
  isBusy?: boolean;
}

const cx = classNames.bind(style);

export const ButtonAsync = ({
  isBusy,
  children,
  disabled,
  iconName,
  ...restProps
}: ButtonAsyncProps) => {
  const buttonContentClassName = cx('buttonContent', {
    isBusy,
  });

  const renderLoader = () => {
    return (
      <div className={style.loaderWrapper}>
        <Loader size='small' />
      </div>
    );
  };

  return (
    <Button
      {...restProps}
      disabled={isBusy ? true : disabled}>
      <div className={buttonContentClassName}>
        {
          iconName &&
          <Button.ButtonIcon>
            <Icon name={iconName} />
          </Button.ButtonIcon>
        }
        {children}
      </div>
      {isBusy ? renderLoader() : null}
    </Button>
  );
};

ButtonAsync.ButtonIcon = Button.ButtonIcon;
