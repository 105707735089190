import { createContext } from 'react';

export type DeleteBotModalData = {
  botId: string;
  botName: string;
  companyId: string | undefined;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export type DeleteBotContextValue = {
  openModal: (data: DeleteBotModalData) => void;
  closeModal: () => void;
}

export const DeleteBotContext = createContext<DeleteBotContextValue | null>(null);
