import { useFormikContext } from 'formik';
import { useCallback } from 'react';

export const useBuildClearValue = <T>() => {
  const {
    setFieldValue,
  } = useFormikContext();

  const buildClearValue = useCallback((inputName: Exclude<keyof T, number | symbol>) => {
    return () => {
      setFieldValue(inputName, null);
    };
  }, [
    setFieldValue,
  ]);

  return buildClearValue;
};
