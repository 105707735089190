import { FieldArrayRenderProps, type FieldInputProps } from 'formik';
import { type ChangeEvent, type PropsWithChildren } from 'react';

import { SwitchableButton } from '@/components/shared/buttons/SwitchableButton';
import { SelectOption } from '@/types/shared';

type ButtonToggleInputBaseProps = PropsWithChildren<{
  className?: string;
}>;

type ButtonToggleInputObjectProps = FieldInputProps<boolean> & ButtonToggleInputBaseProps & {
  arrayHelpers?: never;
  entryValue?: never;
};

type EntryValue = SelectOption['value'];

type ButtonToggleInputArrayProps = FieldInputProps<EntryValue> & ButtonToggleInputBaseProps & {
  arrayHelpers: FieldArrayRenderProps;
  entryValue: EntryValue;
};

type ButtonToggleInputProps = ButtonToggleInputObjectProps | ButtonToggleInputArrayProps;

export const ButtonToggleInput = ({
  name,
  children,
  className,
  onChange,
  value,
  arrayHelpers,
  entryValue,
}: ButtonToggleInputProps) => {
  const values: EntryValue[] = arrayHelpers?.form.values[name];

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (arrayHelpers) {
      const isChecked = event.target.checked;

      isChecked
        ? arrayHelpers.push(entryValue)
        : arrayHelpers.remove(values.indexOf(entryValue));

      return;
    }
    onChange(name)(event);
  };

  return (
    <SwitchableButton
      className={className}
      isActive={arrayHelpers ? values.includes(entryValue) : value}
      onChange={onInputChange}>
      {children}
    </SwitchableButton>
  );
};
