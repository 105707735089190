import { formatDurationTime } from '@/utils/date';
import { toNumericValueOrPlaceholder } from '@/utils/misc';

export const formatBotDuration = (duration: number | null, language: string) => {
  const numericValueOrPlaceHolder = toNumericValueOrPlaceholder(duration);

  if (typeof numericValueOrPlaceHolder === 'number') {
    return formatDurationTime(duration, language);
  }

  return numericValueOrPlaceHolder;
};

export const formatBotDurationToMinutes = (duration: number) => {
  const numericValueOrPlaceHolder = toNumericValueOrPlaceholder(duration, { placeholder: '0' });

  if (typeof numericValueOrPlaceHolder === 'number') {
    return (duration / 60).toFixed(0);
  }

  return numericValueOrPlaceHolder;
};
