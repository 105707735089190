import { RouteParam } from '@/types/shared';

export const getBotsRoute = () => {
  return '/harvesters';
};

export const getBotRoute = (botId: RouteParam) => {
  return `${getBotsRoute()}/${botId}`;
};

export const getCreateBotRoute = () => {
  return `${getBotsRoute()}/create`;
};

export const getCreateMultipleBotsRoute = () => {
  return `${getCreateBotRoute()}/multiple`;
};

export const getDeleteBotRoute = (botId: RouteParam) => {
  return `${getBotRoute(botId)}/delete`;
};

export const getEditBotRoute = (botId: RouteParam) => {
  return `${getBotRoute(botId)}/edit`;
};

export const getBotRunRoute = (botRunId: RouteParam) => {
  return `runs/${botRunId}`;
};
