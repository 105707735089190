import exampleConfig from '../../../public/env.example.json';

export type EnvConfig = Partial<typeof exampleConfig>;

class ConfigSingletonClass {
  #config?: EnvConfig;

  initialize(config: EnvConfig) {
    this.#config = config;
  }

  getAll(): EnvConfig {
    return this.#config ?? {};
  }
}

export const Config = new ConfigSingletonClass();
