import { useDataQuery } from '@/hooks/shared';
import { fetchSettingsChannels } from '@/queries/settingsChannel';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const useSettingsChannels = () => {
  const {
    data: settingsChannels,
    ...rest
  } = useDataQuery(QUERY_KEYS.settingsChannels, fetchSettingsChannels);

  return {
    settingsChannels,
    ...rest,
  };
};
