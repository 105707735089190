import { useCallback } from 'react';
import { setUser as sentrySetUser } from '@sentry/browser';
import { useAuthState } from 'react-firebase-hooks/auth';
import { camelCase } from 'camel-case';
import { type User as FirebaseUser } from '@firebase/auth';

import { transformKeys } from '@/utils/misc';
import {
  AuthHelper,
  firebaseSignInWithGoogle,
  firebaseSignOut,
  firebaseSignInWithEmailAndPassword,
  firebaseResetPassword,
  firebaseUpdatePassword,
  firebaseReauthenticateUser,
} from '@/utils/firebase';
import type { AuthUser, AuthUserCustomAttributes, ExtendedUserCredential } from '@/types/auth';

const extractCustomAttributes = (rawUser: FirebaseUser): AuthUserCustomAttributes | undefined => {
  // firebase's User type doesn't declare `reloadUserInfo` but it exists
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customAttributesRaw = (rawUser as any)?.reloadUserInfo?.customAttributes;

  try {
    const customAttributes = JSON.parse(customAttributesRaw);

    return transformKeys(customAttributes, camelCase) as unknown as AuthUserCustomAttributes;
  } catch (error) {
    return {};
  }
};

const transformUser = (rawUser?: FirebaseUser | null): AuthUser | null => {
  if (!rawUser) {
    return null;
  }

  if (rawUser.email) {
    sentrySetUser({ email: rawUser.email });
  }

  const customAttributes = extractCustomAttributes(rawUser);

  return {
    ...rawUser,
    ...customAttributes,
  };
};

export const useAuthentication = () => {
  const auth = AuthHelper.getAuth();

  const [rawUser, loading, error] = useAuthState(auth);

  const signInWithGoogle = useCallback(() => {
    return firebaseSignInWithGoogle();
  }, []);

  const signInWithEmailAndPassword = useCallback(async (email: string, password: string) => {
    const results = await firebaseSignInWithEmailAndPassword(email, password);

    return results as ExtendedUserCredential;
  }, []);

  const signOut = useCallback(() => {
    sentrySetUser(null);
    return firebaseSignOut();
  }, []);

  const resetPassword = useCallback((code: string, newPassword: string) => {
    return firebaseResetPassword(code, newPassword);
  }, []);

  const changePassword = useCallback((newPassword: string) => {
    return firebaseUpdatePassword(newPassword);
  }, []);

  const reauthenticateUser = useCallback((password: string) => {
    return firebaseReauthenticateUser(password);
  }, []);

  const user = transformUser(rawUser);

  return {
    user,
    signInWithGoogle,
    signInWithEmailAndPassword,
    signOut,
    loading,
    error,
    resetPassword,
    changePassword,
    reauthenticateUser,
  };
};
