import classNames from 'classnames/bind';
import { type ReactElement } from 'react';

import style from './GridContainer.module.sass';

const cx = classNames.bind(style);

type GridContainerProps = {
  children: ReactElement | ReactElement[];
  className?: string;
};

export const GridContainer = ({
  children,
  className,
}: GridContainerProps) => {
  const wrapperClassName = cx('wrapper', className);

  return (
    <div className={wrapperClassName}>
      {children}
    </div>
  );
};
