import classNames from 'classnames/bind';

import { type SectionHeaderProps, SectionHeader } from '@/components/shared/SectionHeader';

import style from './PageSectionHeader.module.sass';

type PageSectionHeaderProps = SectionHeaderProps & {
  wrapperClassName?: string;
};

const cx = classNames.bind(style);

export const PageSectionHeader = ({
  wrapperClassName,
  ...restProps
}: PageSectionHeaderProps) => {
  const wrapperClassNames = cx('wrapper', wrapperClassName);

  return (
    <div className={wrapperClassNames}>
      <SectionHeader {...restProps} />
    </div>
  );
};

PageSectionHeader.AsideActions = SectionHeader.AsideActions;
PageSectionHeader.AdditionalActions = SectionHeader.AdditionalActions;
