import { useEffect } from 'react';
import usePrevious from 'react-use/esm/usePrevious';
import { Swiper as SwiperCore } from 'swiper';

import type { Nullable } from '@/types/shared';

export const useEnsureActiveSlideVisibility = (
  swiper: Nullable<SwiperCore>,
  activeSlideIndex: number | undefined,
) => {
  const prevActiveSlideIndex = usePrevious(activeSlideIndex);

  useEffect(() => {
    if (
      !swiper
      || activeSlideIndex === undefined
      || activeSlideIndex === prevActiveSlideIndex
    ) {
      return;
    }

    const activeSlideElement = swiper.slides[activeSlideIndex];
    const sliderWrapperElement = swiper.el;

    const activeSlideRect = activeSlideElement.getBoundingClientRect();
    const sliderWrapperRect = sliderWrapperElement.getBoundingClientRect();

    if (activeSlideRect.left > sliderWrapperRect.right) {
      swiper.slideTo(activeSlideIndex);
    } else if (activeSlideRect.right < sliderWrapperRect.left) {
      swiper.slideTo(activeSlideIndex);
    }
  }, [
    swiper,
    activeSlideIndex,
    prevActiveSlideIndex,
  ]);
};
