import { TFunction } from 'i18next';

import { AggregationEntry } from '@/types/shared';

type GetBrandFilterEntry = {
  brandAgg: AggregationEntry[];
  brandId: string;
  t: TFunction;
};

export const getBrandFilterEntry = ({
  brandAgg,
  brandId,
  t,
}: GetBrandFilterEntry) => {
  const brandAggEntry = brandAgg.find((brand) => String(brand.id) === brandId);

  return brandAggEntry?.label ?? t('brand:noBrand');
};
