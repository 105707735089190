import { fetchBrand } from '@/queries/brand';
import { useDataQuery } from '@/hooks/shared';
import { QUERY_KEYS } from '@/resources/dataKeys';

export const useBrand = (brandId?: string) => {
  const {
    data: brand,
    ...rest
  } = useDataQuery([QUERY_KEYS.brand, brandId], fetchBrand);

  return {
    brand,
    ...rest,
  };
};
