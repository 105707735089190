import classNames from 'classnames/bind';
import Tippy, { type TippyProps } from '@tippyjs/react/headless';
import { useCallback } from 'react';

import style from './Tooltip.module.sass';

export type TooltipProps = Omit<TippyProps, 'render'> & {
  startDelay?: number;
  sizeVariant?: 'base' | 'medium';
};

type RenderAttrs = Parameters<Required<TippyProps>['render']>[0];

// NOTE: `children` element *MUST* forward ref prop
const cx = classNames.bind(style);

export const Tooltip = ({
  children,
  content,
  startDelay = 0,
  sizeVariant,
  ...restProps
}: TooltipProps) => {
  const tooltipClassNames = cx('tippy', sizeVariant);

  const renderTippy = useCallback((attrs: RenderAttrs) => (
    <div
      className={tooltipClassNames}
      tabIndex={-1}
      {...attrs}>
      {content}
      <div data-popper-arrow={true} />
    </div>
  ), [
    content,
    tooltipClassNames,
  ]);

  return (
    <Tippy
      render={renderTippy}
      delay={[startDelay, 0]}
      duration={[0, 1000]}
      {...restProps}>
      {children}
    </Tippy>
  );
};
