import * as React from "react";
import { forwardRef } from "react";
const SvgRedo = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M12 3.25C7.16779 3.25 3.25 7.16779 3.25 12C3.25 16.8322 7.16779 20.75 12 20.75C16.8322 20.75 20.75 16.8322 20.75 12C20.75 11.5858 20.4142 11.25 20 11.25C19.5858 11.25 19.25 11.5858 19.25 12C19.25 16.0038 16.0038 19.25 12 19.25C7.99621 19.25 4.75 16.0038 4.75 12C4.75 7.99621 7.99621 4.75 12 4.75C14.2677 4.75 16.2932 5.79186 17.6234 7.42501H15.953C15.5388 7.42501 15.203 7.76079 15.203 8.17501C15.203 8.58922 15.5388 8.92501 15.953 8.92501H19.0135C19.0229 8.92519 19.0324 8.92519 19.0418 8.92501H19.488C19.9022 8.92501 20.238 8.58922 20.238 8.17501V4.63901C20.238 4.22479 19.9022 3.88901 19.488 3.88901C19.0738 3.88901 18.738 4.22479 18.738 4.63901V6.41907C17.1337 4.48425 14.7114 3.25 12 3.25Z",
  fill: "currentColor"
}));
const ForwardRef = forwardRef(SvgRedo);
export default ForwardRef;
