import * as React from "react";
import { forwardRef } from "react";
const SvgDash = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  strokeWidth: 2,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("rect", {
  x: 3,
  y: 10,
  width: 18,
  height: 4,
  fill: "currentColor",
  stroke: "none"
}));
const ForwardRef = forwardRef(SvgDash);
export default ForwardRef;
