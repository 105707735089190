import { useDataQuery } from '@/hooks/shared';
import { fetchBot } from '@/queries/bot';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { type Bot } from '@/types/bot';

export const useBot = (botId?: string) => {
  const {
    data: bot,
    ...rest
  } = useDataQuery<Bot>([QUERY_KEYS.bot, botId], fetchBot, {
    enabled: Boolean(botId),
  });

  return {
    bot,
    ...rest,
  };
};
