import lodashSortBy from 'lodash-es/sortBy';

import { Comparable, Nullable } from '@/types/shared';

type Callback<TItem> = (item: TItem) => Comparable;

export const sortBy = <TItem, >(
  collection: TItem[],
  callback: Nullable<Callback<TItem> | string>,
): TItem[] => {
  if (!callback) {
    return collection;
  }

  return lodashSortBy(collection, callback);
};
