import classNames from 'classnames/bind';
import { MouseEventHandler, type AnchorHTMLAttributes } from 'react';
import { Link, type LinkProps as LinkProps } from 'react-router-dom';

import style from './StyledLink.module.sass';

const cx = classNames.bind(style);

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export type StyledLinkProps = (LinkProps | AnchorProps) & {
  variant?: 'primary' | 'info';
  effectVariant?: 'default' | 'reverse';
  onClick?: MouseEventHandler;
};

export const StyledLink = ({
  className,
  effectVariant = 'default',
  variant = 'primary',
  onClick,
  ...restProps
}: StyledLinkProps) => {
  const linkClassName = cx('link', variant, className, {
    reverseEffect: effectVariant === 'reverse',
  });

  if ('to' in restProps) {
    return (
      <Link
        onClick={onClick}
        className={linkClassName}
        {...restProps}
      />
    );
  }

  return (
    <a
      className={linkClassName}
      onClick={onClick}
      {...restProps}
    />
  );
};
