import { type ReactNode } from 'react';

import type { Nullable } from '@/types/shared';

import { addressToLines } from './utils';
import style from './AddressLines.module.sass';

type AddressLinesProps = {
  addressLine?: Nullable<string>;
  placeholder?: ReactNode;
};

export const AddressLines = ({
  addressLine,
  placeholder,
}: AddressLinesProps) => {
  if (!addressLine) {
    return placeholder ? <>{placeholder}</> : null;
  }

  const renderLine = (line: string, index: number) => {
    return (
      <p className={style.line} key={index}>
        {line.trim()}
      </p>
    );
  };

  const lines = addressToLines(addressLine);

  return (
    <>
      {lines.map(renderLine)}
    </>
  );
};
