import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const LS_KEY = 'gsPersistQueryStrings';

type PersistQueryStrings = {
  queryStrings: Record<string, string> | undefined;
  setQueryStrings: (key: string, value: string) => void;
};

export const usePersistQueryStringsStore = create<PersistQueryStrings>()(
  persist(
    (set) => ({
      queryStrings: undefined,
      setQueryStrings: (
        key: string,
        value: string,
      ) => set((state) => ({
        queryStrings: {
          ...state.queryStrings,
          [key]: value,
        },
      })),
    }),
    {
      name: LS_KEY,
    },
  ),
);
