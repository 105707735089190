import { useFilteredQueriesMapping } from '@/hooks/utils';
import { UseStoreParams } from '@/hooks/stores/useCompanyStores';
import { fetchCompanyStores } from '@/queries/store';

import { removeStoresFilterValue } from './utils';

export const useFilteredStoresQueriesMapping = (
  queryKeyParams: UseStoreParams,
) => {
  return useFilteredQueriesMapping({
    queryFn: fetchCompanyStores,
    transformQueryKey: (queryKey, params) => {
      return removeStoresFilterValue(queryKey, params.filterKey);
    },
    queryKey: ['companyStores', queryKeyParams],
    mapping: [
      { dataKey: 'subdomain', params: { filterKey: 'subdomainId' as const } },
      { dataKey: 'country', params: { filterKey: 'countryId' as const } },
      { dataKey: 'status', params: { filterKey: 'status' as const } },
      { dataKey: 'brand', params: { filterKey: 'brandId' as const } },
    ],
  });
};
