import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import {
  FC,
  useRef,
  useState,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { type FieldInputProps } from 'formik';
import { AxiosError } from 'axios';

import { Loader } from '@/components/shared/loaders/Loader';
import { StyledLink } from '@/components/shared/StyledLink';
import { ImageUploaderTextInput } from '@/components/shared/form/inputs/ImageUploader/ImageUploaderTextInput';
import { ImageWithFallback } from '@/components/shared/ImageWithFallback';
import { Body } from '@/components/shared/typography/Body';
import { FieldErrors } from '@/components/shared/form/FieldErrors';
import { Tabs } from '@/components/shared/Tabs';
import { Button } from '@/components/shared/buttons';
import { FallbackIconBox } from '@/components/shared/FallbackIconBox';
import { Icon } from '@/components/shared/Icon';
import { preloadImage } from '@/utils/misc';
import { useUploadImage } from '@/hooks/image';
import { useConfig } from '@/hooks/shared/useConfig';
import { sentryLogIssue } from '@/utils/sentry';
import { SENTRY_413_ERROR_MESSAGE } from '@/resources/sentry';

import style from './ImageUploader.module.sass';

const cx = classNames.bind(style);

enum ImageUploaderState {
  Idle,
  FilePreloadFailed,
  UrlPreloadFailed,
}

enum ImageUploaderMode {
  Url = 'url',
  File = 'file',
}

enum UrlInputStyleState {
  Warning,
  Error,
  Neutral
}

type ImageUploaderProps = FieldInputProps<string> & {
  description?: string;
  defaultValue?: string;
  isTextInputVisible?: boolean;
  className?: string;
  wrapperClassName?: string;
  customPlaceholder?: ReactNode;
  dropZoneAccept?: Accept;
  customUpload?: (file: File) => Promise<string>;
  customUploadOnError?: (error: unknown) => void;
};

export const ImageUploader: FC<ImageUploaderProps> = ({
  description,
  value,
  onChange,
  name,
  isTextInputVisible,
  defaultValue = '',
  className,
  wrapperClassName,
  customPlaceholder,
  dropZoneAccept = { 'image/*': [] },
  customUpload,
  customUploadOnError,
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [uploaderState, setUploaderState] = useState(ImageUploaderState.Idle);
  const [viewMode, setViewMode] = useState(isTextInputVisible ?
    ImageUploaderMode.Url : ImageUploaderMode.File,
  );
  const [isImageUploadOverlayVisible, setIsImageUploadOverlayVisible] = useState(false);
  const urlInputStyleState = useRef(UrlInputStyleState.Neutral);

  const { config: { VITE_TMDN_URL } } = useConfig();
  const { t } = useTranslation();

  const isDefaultValue = value === defaultValue;
  const isUploadByUrlVisible = isTextInputVisible && viewMode === ImageUploaderMode.Url;

  const {
    uploadImageAsync,
    error: uploadError,
  } = useUploadImage();

  const updateValue = (newValue: string) => {
    onChange(name)(newValue);
  };

  const handleCustomError = async (file: File) => {
    if (!customUpload) {
      return;
    }

    try {
      const results = await customUpload(file);

      updateValue(results);
    } catch (error) {
      if (customUploadOnError) {
        customUploadOnError(error);
        return;
      }

      setUploaderState(ImageUploaderState.FilePreloadFailed);
    }
  };

  const uploadFile = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length && customUploadOnError) {
      const [failedFile] = rejectedFiles;

      handleCustomError(failedFile.file);
      return;
    }

    setIsBusy(true);
    setUploaderState(ImageUploaderState.Idle);

    const [file] = acceptedFiles;

    try {
      if (customUpload) {
        handleCustomError(file);

        return;
      }

      try {
        const uploadedImage = await uploadImageAsync(file);
        const imageUrl = uploadedImage.attributes.url;

        await preloadImage(imageUrl);

        updateValue(imageUrl);
      } catch (e) {
        const error = e as AxiosError;

        if (error.response?.status === 413) {
          sentryLogIssue(SENTRY_413_ERROR_MESSAGE);
        }

        setUploaderState(ImageUploaderState.FilePreloadFailed);
      }
    } finally {
      setIsBusy(false);
    }
  };

  const removeImage: MouseEventHandler = (event) => {
    event.stopPropagation();

    updateValue(defaultValue);
  };

  const checkInputUrl = async (imageUrl?: string) => {
    if (!imageUrl) {
      updateValue('');
      setUploaderState(ImageUploaderState.Idle);
      urlInputStyleState.current = UrlInputStyleState.Neutral;

      return;
    }

    try {
      await preloadImage(imageUrl);

      setUploaderState(ImageUploaderState.Idle);
    } catch (e) {
      const error = e as AxiosError;

      if (error.response?.status === 413) {
        sentryLogIssue(SENTRY_413_ERROR_MESSAGE);
      }

      setUploaderState(ImageUploaderState.UrlPreloadFailed);
    } finally {
      updateValue(imageUrl);
    }
  };

  const renderActionButton = () => {
    if (value && !isDefaultValue) {
      return (
        <Button
          variant='light'
          tabIndex={-1}
          sizeVariant='small'
          className={style.removeButton}
          onClick={removeImage}>
          {t('common:remove')}
        </Button>
      );
    }

    return null;
  };

  const renderValueOrPlaceholder = () => {
    if (value && !isDefaultValue) {
      const fallbackIcon = (
        <div className={style.fallbackIconBoxWrapper}>
          <FallbackIconBox />
        </div>
      );

      return (
        <div
          className={style.previewWrapper}
          onMouseEnter={() => setIsImageUploadOverlayVisible(true)}
          onMouseLeave={() => setIsImageUploadOverlayVisible(false)}>
          {viewMode === ImageUploaderMode.File && renderUploadImageOverlay()}
          <ImageWithFallback
            src={value}
            className={style.previewImage}
            fallback={{ icon: fallbackIcon }}
          />
        </div>
      );
    }

    if (viewMode === ImageUploaderMode.Url) {
      return (
        <div className={style.placeholderWrapper}>
          <Body size='small' className={style.urlDescription}>
            <span>
              {t('form:imageUploader.urlTip')}&nbsp;
            </span>
          </Body>
        </div>
      );
    }

    if (customPlaceholder) {
      return customPlaceholder;
    }

    return (
      <div className={style.placeholderWrapper}>
        <Icon name='Image' className={style.placeholderIcon} />
        <Body size='base' className={style.dropTip}>
          <span>
            {t('form:imageUploader.dropTip.preClick')}&nbsp;
          </span>
          <StyledLink
            to='#'
            effectVariant='reverse'>
            {t('form:imageUploader.dropTip.click')}
          </StyledLink>
          <span>
            &nbsp;{t('form:imageUploader.dropTip.postClick')}
          </span>
        </Body>
        {
          description &&
          <Body size='small' className={style.description}>{description}</Body>
        }
      </div>
    );
  };

  const renderLoader = () => (
    isBusy &&
    <div className={style.loaderOverlay}>
      <Loader />
    </div>
  );

  const renderDropZone = () => (
    <div {...rootProps}>
      {renderValueOrPlaceholder()}
      {renderActionButton()}
      {renderLoader()}
      <input {...inputProps} />
    </div>
  );

  const renderUploadImageOverlay = () => {
    const uploaderOverlayClassNames = cx('uploaderOverlay', {
      visible: isImageUploadOverlayVisible,
    });

    return (
      <div className={uploaderOverlayClassNames}>
        <Button
          variant='light'
          className={style.replaceImageButton}>
          {t('form:imageUploader.replaceImage')}
        </Button>
      </div>
    );
  };

  const renderErrorOrWarning = () => {
    if (uploadError) {
      return (
        <FieldErrors>
          {t('form:imageUploader.errors.uploadError')}
        </FieldErrors>
      );
    }

    if (uploaderState === ImageUploaderState.FilePreloadFailed) {
      return (
        <FieldErrors>
          {t('form:imageUploader.errors.filePreloadError')}
        </FieldErrors>
      );
    }

    if (
      uploaderState === ImageUploaderState.UrlPreloadFailed
      && VITE_TMDN_URL
      && value.includes(VITE_TMDN_URL)
    ) {
      urlInputStyleState.current = UrlInputStyleState.Warning;

      return (
        <FieldErrors variant='warning'>
          {t('form:imageUploader.warnings.urlBlocked')}
        </FieldErrors>
      );
    }

    if (uploaderState === ImageUploaderState.UrlPreloadFailed) {
      urlInputStyleState.current = UrlInputStyleState.Error;

      return (
        <FieldErrors>
          {t('form:imageUploader.warnings.urlPreloadWarning')}
        </FieldErrors>
      );
    }

    urlInputStyleState.current = UrlInputStyleState.Neutral;

    return null;
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
  } = useDropzone({
    accept: dropZoneAccept,
    onDrop: uploadFile,
    maxFiles: 1,
    multiple: false,
  });

  const dropZoneClassName = cx('dropzone', className, {
    isDragAccept,
    isUploadingByUrl: isUploadByUrlVisible && isDefaultValue,
    isAreaHeightReduced: isUploadByUrlVisible,
    disableDropZone: viewMode === ImageUploaderMode.Url,
  });

  const rootProps = getRootProps({ className: dropZoneClassName });
  const inputProps = getInputProps();

  const config = [
    {
      isActive: viewMode === ImageUploaderMode.Url,
      handleClick: () => setViewMode(ImageUploaderMode.Url),
      label: t('form:imageUploader.uploadBy.url'),
      body: (
        <>
          {renderDropZone()}
          <ImageUploaderTextInput
            warning={urlInputStyleState.current === UrlInputStyleState.Warning}
            error={urlInputStyleState.current === UrlInputStyleState.Error}
            isBusy={isBusy}
            onSubmit={checkInputUrl}
            value={value}
            name={name}
          />
        </>
      ),
      isHidden: !isTextInputVisible,
    },
    {
      isActive: viewMode === ImageUploaderMode.File,
      handleClick: () => setViewMode(ImageUploaderMode.File),
      label: t('form:imageUploader.uploadBy.file'),
      body: (
        <>
          {renderDropZone()}
        </>
      ),
    },
  ];

  return (
    <div className={wrapperClassName}>
      <Tabs
        config={config}
        tabsWrapperClassName={style.tabsWrapper}
      />
      {renderErrorOrWarning()}
    </div>
  );
};
