import classNames from 'classnames/bind';
import { isValidElement, useState } from 'react';

import { ContextMenu } from '@/components/shared/ContextMenu';
import { Dropdown } from '@/components/shared/Dropdown';
import { Icon } from '@/components/shared/Icon';
import type {
  ResourceContextMenuOption,
  ResourceContextMenuProps,
} from '@/types/resourceContextMenu';

type VerticalTableContextMenuOption = ResourceContextMenuOption;
export type VerticalTableContextMenuProps = ResourceContextMenuProps;

import style from './VerticalTableContextMenu.module.sass';

const cx = classNames.bind(style);

export const VerticalTableContextMenu = ({
  options,
  wrapperClassName,
  arrowClassName,
  buttonClassName,
  buttonSize = 'normal',
  buttonVariant = 'primaryGhost',
  shouldDisplayPlaceholder,
}: VerticalTableContextMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!options || shouldDisplayPlaceholder) {
    return (
      <div className={wrapperClassName}>
        <Icon
          name='ArrowRight'
          className={arrowClassName}
        />
      </div>
    );
  }

  const renderMenuOption = (option: VerticalTableContextMenuOption) => (
    <Dropdown.ClickableItem
      {...option}
      navigateTo={option.to}
      key={option.label}>
      {option.label}
    </Dropdown.ClickableItem>
  );

  const renderContent = () => {
    const shouldMapOptions = Array.isArray(options) && !isValidElement(options?.[0]);

    return (
      <ContextMenu
        triggerClassName={buttonClassName}
        triggerSize={buttonSize}
        triggerVariant={buttonVariant}
        ariaLabel='vertical-table-context-menu'
        onToggle={setIsOpen}>
        <>
          {
            shouldMapOptions
              ? options
                .filter(({ isHidden }) => !isHidden)
                .map(renderMenuOption)
              : options
          }
        </>
      </ContextMenu>
    );
  };

  const wrapperClassNames = cx('wrapper', wrapperClassName, {
    isOpen,
  });

  return (
    <div className={wrapperClassNames}>
      {renderContent()}
    </div>
  );
};
