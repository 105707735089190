import classNames from 'classnames/bind';
import { ReactNode } from 'react';

import { NavButton, type NavButtonProps } from '@/components/shared/buttons';
import { Body } from '@/components/shared/typography/Body';
import { Header } from '@/components/shared/typography/Header';
import { AnimatedMedia } from '@/components/shared/AnimatedMedia';
import { type HeaderProps } from '@/components/shared/typography/Header/Header';
import loginImage from '@/assets/images/login.png';
import type { AnimatedMediaName } from '@/types/shared';

import style from './EmptyCollection.module.sass';

const cx = classNames.bind(style);

type EmptyCollectionBaseProps = {
  header: string;
  subHeader?: string;
  createItemLink?: string;
  createItemText?: string;
  createItemVariant?: NavButtonProps['variant'];
  createItemState?: NavButtonProps['state'];
  createItemSizeVariant?: NavButtonProps['sizeVariant'];
  isPlaceholderShown?: boolean;
  wrapperClassName?: string;
  imageClassName?: string;
  imageSizeVariant?: 'small' | 'base' | 'medium' | 'large';
  headerSizeVariant?: HeaderProps['variant'];
  customCreateItemControl?: ReactNode;
};

type EmptyCollectionPlaceholderProps = {
  animatedMediaName?: AnimatedMediaName;
  imageSource?: never;
} | {
  animatedMediaName?: never;
  imageSource?: string;
};

type EmptyCollectionProps = EmptyCollectionBaseProps & EmptyCollectionPlaceholderProps;

export const EmptyCollection = ({
  header,
  subHeader,
  createItemLink,
  createItemText,
  createItemState,
  createItemVariant = 'primary',
  createItemSizeVariant = 'medium',
  imageSource = loginImage,
  animatedMediaName,
  isPlaceholderShown = true,
  wrapperClassName,
  imageClassName,
  imageSizeVariant = 'base',
  headerSizeVariant = 'h4',
  customCreateItemControl,
}: EmptyCollectionProps) => {
  const wrapperClassNames = cx('wrapper', wrapperClassName);
  const imageClassNames = cx('image', imageClassName, imageSizeVariant);

  const renderPlaceholder = () => {
    if (!isPlaceholderShown) return null;

    if (animatedMediaName) {
      return (
        <AnimatedMedia
          name={animatedMediaName}
          className={imageClassNames}
        />
      );
    }

    return (
      <img
        className={imageClassNames}
        src={imageSource}
      />
    );
  };

  return (
    <div className={wrapperClassNames}>
      {renderPlaceholder()}
      <div className={style.info}>
        <Header variant={headerSizeVariant}>{header}</Header>
        {
          subHeader &&
          <Body
            size='base'
            className={style.subHeader}>
            {subHeader}
          </Body>
        }
        <div className={style.controlsWrapper}>
          {
            createItemLink &&
            createItemText &&
            <NavButton
              className={style.control}
              variant={createItemVariant}
              sizeVariant={createItemSizeVariant}
              state={createItemState}
              navigateTo={createItemLink}>
              {createItemText}
            </NavButton>
          }
          {customCreateItemControl}
        </div>
      </div>
    </div>
  );
};
