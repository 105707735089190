import { produce } from 'immer';
import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import { type UseListingsParams } from '@/hooks/listing/useListings';
import type { Nullable } from '@/types/shared';
import type {
  Listing,
  ListingsFilterParams,
  ListingsRequestMeta,
} from '@/types/listing';

export type ListingsQueryKey = [string, UseListingsParams | undefined];

type ListingsFilterQuery = ListingsFilterParams & {
  age: never;
  'age.lt': Nullable<string>;
};

export const fetchListings = async (context: QueryFunctionContext<ListingsQueryKey>) => {
  const [, params = {}] = context.queryKey;

  const {
    pagination,
    sort,
    filter,
  } = params;

  const page = pagination && {
    size: pagination.perPage,
    number: pagination.page,
  };

  const filterParams = buildListingsFilterParams(filter);

  const response = await Api.get<
    Listing[],
    never,
    ListingsRequestMeta
  >('api/listings', {
    params: {
      filter: filterParams,
      page,
      sort: sort?.sort,
    },
  });

  const parsedListings = linkCollectionRelationships(response);

  return {
    listings: parsedListings,
    meta: response.meta,
  };
};

export const buildListingsFilterParams = (filter: ListingsFilterParams | undefined) => {
  if (!filter) return filter;

  return produce<ListingsFilterParams, ListingsFilterQuery>(filter, (draft) => {
    draft['age.lt'] = draft.age;
    delete draft.age;

    draft['store.name'] = draft.storeName;
    delete draft.storeName;

    draft['store.status'] = draft.storeStatus;
    delete draft.storeStatus;

    draft['brand.id'] = draft.brandId;
    delete draft.brandId;
  });
};
