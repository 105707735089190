import { type FirebaseError } from '@firebase/util';
import { type FormikHelpers } from 'formik';
import { type TFunction } from 'i18next';

import { type LoginFormValues } from './PasswordLoginForm';

export const handleLoginFormikError = (
  error: FirebaseError,
  formikHelpers: FormikHelpers<LoginFormValues>,
  t: TFunction,
) => {
  switch (error.code) {
    case 'auth/invalid-email':
      formikHelpers.setFieldError('email', t('loginPage:errors.email.invalid'));
      break;
    case 'auth/user-not-found':
      formikHelpers.setFieldError('email', t('loginPage:errors.email.notFound'));
      break;
    default:
      formikHelpers.setFieldError('base', t('loginPage:errors.base.requestFailed'));
      break;
  }
};
