import * as React from "react";
import { forwardRef } from "react";
const SvgSettings = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M3.89983 15.647L5.29783 15.861C6.32983 16.019 7.06683 16.943 6.99083 17.984L6.88783 19.395C6.85783 19.806 7.08183 20.193 7.45283 20.372L8.48683 20.87C8.85783 21.049 9.30083 20.982 9.60383 20.703L10.6428 19.743C11.4088 19.035 12.5908 19.035 13.3578 19.743L14.3968 20.703C14.6998 20.983 15.1418 21.049 15.5138 20.87L16.5498 20.371C16.9198 20.193 17.1428 19.807 17.1128 19.397L17.0098 17.984C16.9338 16.943 17.6708 16.019 18.7028 15.861L20.1008 15.647C20.5078 15.585 20.8358 15.28 20.9278 14.878L21.1828 13.76C21.2748 13.358 21.1118 12.941 20.7718 12.709L19.6048 11.91C18.7438 11.32 18.4808 10.168 19.0008 9.26302L19.7058 8.03702C19.9108 7.68002 19.8768 7.23302 19.6198 6.91102L18.9048 6.01402C18.6478 5.69202 18.2198 5.55902 17.8258 5.68002L16.4738 6.09402C15.4748 6.40002 14.4098 5.88702 14.0258 4.91602L13.5078 3.60302C13.3558 3.21902 12.9848 2.96702 12.5718 2.96802L11.4258 2.97102C11.0128 2.97202 10.6428 3.22602 10.4928 3.61102L9.98783 4.90902C9.60783 5.88602 8.53783 6.40302 7.53583 6.09502L6.12783 5.66302C5.73283 5.54102 5.30283 5.67502 5.04583 5.99902L4.33583 6.89702C4.07883 7.22202 4.04783 7.67002 4.25683 8.02702L4.97783 9.25602C5.50883 10.162 5.24883 11.325 4.38283 11.918L3.22983 12.708C2.88983 12.941 2.72683 13.358 2.81883 13.759L3.07383 14.877C3.16483 15.28 3.49283 15.585 3.89983 15.647V15.647Z",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M13.916 10.084C14.974 11.142 14.974 12.858 13.916 13.916C12.858 14.974 11.142 14.974 10.084 13.916C9.02603 12.858 9.02603 11.142 10.084 10.084C11.142 9.02603 12.858 9.02603 13.916 10.084",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgSettings);
export default ForwardRef;
