import { fetchSubdomain } from '@/queries/subdomain';
import { useDataQuery } from '@/hooks/shared';
import { QUERY_KEYS } from '@/resources/dataKeys';
import { Subdomain } from '@/types/subdomain';

export const useSubdomain = (subdomainId?: string) => {
  const {
    data: subdomain,
    ...rest
  } = useDataQuery<Subdomain>([QUERY_KEYS.subdomain, subdomainId], fetchSubdomain, {
    enabled: Boolean(subdomainId),
  });

  return {
    subdomain,
    ...rest,
  };
};
