import { useMemo } from 'react';

import { CountryFlag } from '@/components/shared/CountryFlag';
import {
  SelectInput,
  type SelectInputProps,
} from '@/components/shared/form/inputs/SelectInput';
import { useCountries } from '@/hooks/country';
import type { SelectOption } from '@/types/shared';

import style from './CountrySelectInput.module.sass';

type CountrySelectInputProps = Omit<SelectInputProps, 'options'> & {
  // ...
};

export const CountrySelectInput = ({
  ...restProps
}: CountrySelectInputProps) => {
  const { countries } = useCountries();

  const options = useMemo<SelectOption[]>(() => {
    if (!countries) {
      return [];
    }

    return countries.map((country) => {
      return {
        value: String(country.id),
        label: (
          <div className={style.wrapper}>
            <div className={style.text}>
              {country.attributes.name}
            </div>
            <div className={style.flag}>
              <CountryFlag countryName={country.attributes.name} />
            </div>
          </div>
        ),
      };
    });
  }, [
    countries,
  ]);

  return (
    <SelectInput
      options={options}
      {...restProps}
    />
  );
};
