import classNames from 'classnames/bind';

import { Icon } from '@/components/shared/Icon';
import { useBranding } from '@/hooks/branding';

import style from './BrandingJumbotron.module.sass';

type BrandingJumbotronProps = {
  hasMinifiedLogo?: boolean;
};

const cx = classNames.bind(style);

export const BrandingJumbotron = ({
  hasMinifiedLogo,
}: BrandingJumbotronProps) => {
  const { branding } = useBranding();

  if (!branding) {
    return null;
  }

  const logoClassNames = cx('GSlogo', {
    hasMinifiedLogo,
  });

  const logoImageClassNames = cx('logoImage', {
    hasMinifiedLogo,
  });

  return (
    <div className={style.wrapper}>
      {
        hasMinifiedLogo &&
        <Icon name='GreyScout' className={logoClassNames} />
      }
      <img
        className={logoImageClassNames}
        src={branding.attributes.logoUrl}
        alt={branding.attributes.name}
      />
    </div>
  );
};
