import { QueryFunctionContext } from 'react-query';

import { Api } from '@/utils/api';
import { linkCollectionRelationships } from '@/utils/queries/linkCollectionRelationships';
import type { Trademark, TrademarkCompany, TrademarkNiceClassifications, TrademarkTrademarkType } from '@/types/trademark';

type TrademarkIncludedRecord =
  | TrademarkCompany
  | TrademarkTrademarkType
  | TrademarkNiceClassifications

export const fetchCompanyTrademark = async (context: QueryFunctionContext) => {
  const [, trademarkId] = context.queryKey;

  const url = `/api/trademarks/${trademarkId}`;

  const response = await Api.get<Trademark, TrademarkIncludedRecord>(url);

  const trademark = linkCollectionRelationships(response);

  return trademark;
};
