import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSortParams } from '@/hooks/shared/useSortParams';
import { SortableOption } from '@/types/sortable';

export const DEFAULT_RESELLERS_SORT_BY = 'name';

export const useResellersSortParams = () => {
  const { t } = useTranslation();
  const {
    sortBy: sortByParam,
  } = useSortParams();

  const sortBy = sortByParam || DEFAULT_RESELLERS_SORT_BY;

  const options = useMemo<SortableOption[]>(() => ([
    {
      value: 'name',
      label: t('reseller:sortable.labels.nameAz'),
    },
    {
      value: '-name',
      label: t('reseller:sortable.labels.nameZa'),
    },
    {
      value: 'listings.count',
      label: t('reseller:sortable.labels.listings.least'),
    },
    {
      value: '-listings.count',
      label: t('reseller:sortable.labels.listings.most'),
    },
  ]), [
    t,
  ]);

  return {
    sortBy,
    options,
  };
};
