import { useTranslation } from 'react-i18next';

import { CustomerCompanyJumbotron } from '@/components/shared/CustomerCompanyJumbotron';
import { IconWithGlow } from '@/components/shared/IconWithGlow';
import { Header } from '@/components/shared/typography/Header';
import { Body } from '@/components/shared/typography/Body';
import { Button } from '@/components/shared/buttons';
import { GS_COPYRIGHT_EMAIL } from '@/resources/store';

import style from './VeriformExpiredPage.module.sass';

export const VeriformExpiredPage = () => {
  const { t } = useTranslation();

  const onClick = () => {
    window.location.href = `mailto: ${GS_COPYRIGHT_EMAIL}}`;
  };

  return (
    <>
      <div className={style.logos}>
        <CustomerCompanyJumbotron textLogo={true} />
      </div>
      <div className={style.content}>
        <IconWithGlow
          type='danger'
          iconName='NoTime'
          sizeVariant='xl'
        />
        <Header
          variant='h2'
          className={style.header}>
          {t('store:veriform.expired.header')}
        </Header>
        <Body
          size='medium'
          className={style.subheader}>
          {t('store:veriform.expired.subheader')}
        </Body>
        <Button
          href='mailto:copyright@greyscout.com'
          className={style.button}
          onClick={onClick}
          sizeVariant='medium'>
          {t('store:veriform.expired.button')}
        </Button>
      </div>
    </>
  );
};

