import * as React from "react";
import { forwardRef } from "react";
const SvgImageLink = (props, ref) => /* @__PURE__ */ React.createElement("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ref,
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M21.0037 10.9996V17.0021C21.0037 19.2121 19.2121 21.0037 17.0021 21.0037H6.99792C4.78786 21.0037 2.99625 19.2121 2.99625 17.0021V6.99792C2.99625 4.78786 4.78786 2.99625 6.99792 2.99625H9.99917",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M2.99625 13.0004L4.29195 11.7047C4.74429 11.2524 5.35779 10.9982 5.9975 10.9982C6.6372 10.9982 7.2507 11.2524 7.70304 11.7047L12 16.0017",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M7.00092 21.0038L13.2987 14.706C14.2407 13.764 15.7678 13.764 16.7098 14.706L20.6506 18.6468",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M15.0013 8.99875C13.3441 8.99773 12.001 7.65462 12 5.9975V5.9975C12.001 4.34038 13.3441 2.99727 15.0013 2.99625",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M18.0025 2.99625C19.6596 2.99727 21.0027 4.34038 21.0037 5.9975V5.9975C21.0027 7.65462 19.6596 8.99773 18.0025 8.99875",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M15.0013 5.9975H18.0025",
  stroke: "currentColor",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
const ForwardRef = forwardRef(SvgImageLink);
export default ForwardRef;
