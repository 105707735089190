import classNames from 'classnames/bind';
import { forwardRef } from 'react';

import { Icon } from '@/components/shared/Icon/Icon';
import { IconName } from '@/types/shared';

import style from './IconBox.module.sass';

const cx = classNames.bind(style);

type IconBoxProps = {
  iconName: IconName;
  wrapperClassName?: string;
};

export const IconBox = forwardRef<HTMLDivElement, IconBoxProps>(({
  iconName,
  wrapperClassName,
}, ref) => {
  const wrapperClassNames = cx('wrapper', wrapperClassName);

  return (
    <div className={wrapperClassNames} ref={ref}>
      <Icon name={iconName} />
    </div>
  );
});

IconBox.displayName = 'IconBox';
