import { FirebaseError } from '@firebase/util';

import { PasswordPolicyErrors } from '@/types/user';

const areAllValuesEmpty = (
  obj: {
    [key: string]: unknown;
  },
) => {
  return Object.values(obj).every((val) => val === '' || val === null || val === undefined);
};

export const handleFirebasePasswordPolicyError = (
  error: FirebaseError,
  errorPolicyCallback: (errors: PasswordPolicyErrors) => void,
  errorCodeCallback: (code: string) => void,
) => {
  const passwordPolicyErrorContent = 'Missing password requirements';
  const otherAuthError = 'auth/weak-password';
  const firebaseMessage = error.message;

  const isPolicyViolated =
    firebaseMessage.includes(passwordPolicyErrorContent)
    || firebaseMessage.includes(otherAuthError);

  if (isPolicyViolated) {
    const isTooShort =
      firebaseMessage.includes('at least 12 characters')
      || firebaseMessage.includes('at least 6 characters');
    const isTooLong = firebaseMessage.includes('at most 24 characters');
    const isMissingUppercase = firebaseMessage.includes('lower case character');
    const isMissingLowerCase = firebaseMessage.includes('upper case character');
    const isMissingNumber = firebaseMessage.includes('contain a numeric character');
    const isMissingSymbol = firebaseMessage.includes('contain a non-alphanumeric character');

    const otherErrorsRegex = /\[(.*?)\]/;
    const passwordRequirementsMatch = error.message.match(otherErrorsRegex);

    /* eslint-disable */
    const passwordPolicyErrors: PasswordPolicyErrors = {
      length: isTooShort || isTooLong,
      casing: isMissingUppercase || isMissingLowerCase,
      number: isMissingNumber,
      symbol: isMissingSymbol,
      other: null
    };
    /* eslint-enable */

    const areStaticErrorsEmpty = areAllValuesEmpty(passwordPolicyErrors);

    if (areStaticErrorsEmpty && passwordRequirementsMatch) {
      passwordPolicyErrors.other = passwordRequirementsMatch[1].split(', ').join('\n');
    }

    return errorPolicyCallback(passwordPolicyErrors);
  }

  errorCodeCallback(error.code);
};

