import { useTranslation } from 'react-i18next';

import { BotSubdomain } from '@/components/bot/BotSubdomain';
import { StyledLinkExternal } from '@/components/shared/StyledLinkExternal';
import { Header } from '@/components/shared/typography/Header';
import { StoreReview } from '@/components/store/StoreReview';
import { StoreActions } from '@/components/store/StoreDetailsHeader/StoreActions';
import { useUserRole } from '@/hooks/companyUser';
import type { Store } from '@/types/store';

import style from './StoreDetailsHeader.module.sass';

type StoreDetailsHeaderProps = {
  store: Store;
};

export const StoreDetailsHeader = ({
  store,
}: StoreDetailsHeaderProps) => {
  const { t } = useTranslation();
  const { isGuest } = useUserRole();

  const renderActions = () => {
    if (isGuest) {
      return null;
    }

    return (
      <StoreActions store={store} />
    );
  };

  return (
    <div>
      <BotSubdomain
        name={store.linked?.subdomain?.linked?.channel.attributes.name}
        url={store.linked?.subdomain?.attributes.url}
      />
      <div className={style.headerWrapper}>
        <Header variant='h2' className={style.header}>
          {store.attributes.name || t('common:unknown')}
        </Header>
        {renderActions()}
      </div>
      <div className={style.subheader}>
        <StyledLinkExternal
          className={style.subheaderLink}
          href={store.attributes.url}>
          {store.attributes.url}
        </StyledLinkExternal>
        <StoreReview
          text={store.attributes.rating ?? t('common:unknown')}
          reviewCount={store.attributes.reviewCount}
          reviewCountSize='base'
        />
      </div>
    </div>
  );
};
