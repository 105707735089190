import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import { Card } from '@/components/shared/Card';
import { MultilineHeader } from '@/components/shared/MultilineHeader';
import { UserAvatar } from '@/components/user/UserAvatar';
import { UserRoleBadge } from '@/components/user/UserRoleBadge';
import { CountryLogotype } from '@/components/shared/CountryLogotype';
import { LanguageLogotype } from '@/components/shared/LanguageLogotype';
import { type CardContextMenuProps } from '@/components/shared/Card/CardContextMenu';
import { useRoutePathContext } from '@/contexts/shared';
import type {
  CompanyUser,
  User,
} from '@/types/user';

import style from './UserGridCard.module.sass';

const cx = classNames.bind(style);

type UserGridCardProps = {
  user: User | CompanyUser;
  className?: string;
  contextMenu: (bot: User | CompanyUser) => CardContextMenuProps;
};

export const UserGridCard = ({
  user,
  className,
  contextMenu,
}: UserGridCardProps) => {
  const navigate = useNavigate();

  const {
    getInstanceUserRoute,
  } = useRoutePathContext();

  const cardClassName = cx('wrapper', className);
  const userName = `${user.attributes.firstName} ${user.attributes.lastName}`;

  const userRoute = getInstanceUserRoute(user.id);

  return (
    <Card
      onClick={() => navigate(userRoute)}
      resourceRoute={userRoute}
      className={cardClassName}
      data-cy='userCard'
      contextMenuProps={contextMenu(user)}>
      <div className={style.avatarWrapper}>
        <UserAvatar
          firstName={user.attributes.firstName}
          lastName={user.attributes.lastName}
        />
      </div>
      <MultilineHeader
        variant='h4'
        className={style.name}>
        {userName}
      </MultilineHeader>
      <div className={style.userInfo}>
        <CountryLogotype
          countryName={user.linked?.country.attributes.name}
          className={style.userCountry}
        />
        <LanguageLogotype
          language={user.linked?.language.attributes.name}
          className={style.userCountry}
        />
        <UserRoleBadge role={user.attributes.role} />
      </div>
    </Card>
  );
};
